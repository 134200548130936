<script>
import { omit } from 'lodash'
import FormRender from '~/components/FormRender'
import Step2 from '~/components/user-verify/step2'
import BindS2cAccount from '~/components/BindS2cAccount'
import ENUM from '~/core/enum'

export default {
  components: {
    BindS2cAccount
  },
  head() {
    return {
      title: '用户信息完善'
    }
  },
  meta: {
    private: true
  },
  data() {
    return {
      loading: false,
      model: {}
    }
  },
  async mounted() {
    this.model = await this.$api.fetchUserDetail()
    const status = this.$store.state.user?.status

    if (status !== ENUM.USER_STATUS.PASS) {
      this.$router.push('/user-verify')
    }
  },
  methods: {
    handleSubmit() {
      this.loading = true
      this.$refs.form.validate(async (isPass) => {
        if (!isPass) {
          this.loading = false
          return
        }

        try {
          await this.$api.updateUserInfo(
            omit(this.model, ['id_image', 'live_prove_image'])
          )

          this.$router.push('/account')
          this.$Message.success('您的资料已更新')
        } catch (err) {
          console.error(err)
        }

        this.loading = false
      })
    },
    filterAddress(rows) {
      rows.forEach((item) => {
        if (item.prop === 'country') {
          item.node.componentOptions.propsData.disabled = true
        }
      })
      return rows
    }
  },
  render() {
    const { model } = this
    const rowItems2 = [
      {
        render: <Address filter={this.filterAddress} v-model={this.model} />
      },
      {
        render: <div class="divide" />
      },
      {
        col: 12,
        prop: 'email',
        label: '联系邮箱',
        node: <Input v-model={model.email} maxlength="30" />,
        rules: {
          type: 'email',
          required: true,
          trigger: 'blur',
          pattern: /^[A-Za-zd]+([-_.][A-Za-zd]+)*@([A-Za-zd]+[-.])+[A-Za-zd]{2,5}$/g,
          message: '请输入正确的邮箱'
        }
      },
      {
        col: 12,
        prop: 'phone',
        label: '联系电话',
        node: <Input v-model={model.phone} disabled />,
        rules: {
          required: true,
          trigger: 'blur',
          message: '请输入联系电话'
        }
      }
    ]
    const rowItems3 = [
      {
        col: 12,
        prop: 'username_s2c',
        label: 'Send2china绑定用户',
        node: <Input disabled v-model={model.username_s2c} />,
        rules: {
          required: false,
          message: '请选择证件类型'
        }
      },
      {
        col: 12,
        label: '',
        node: <Bind-s2c-account class="bind-account" />
      }
    ]
    return (
      <div class="user-info-container account-container">
        <div class="account-banner">
          <span>个人资料</span>
        </div>
        <div class="contents">
          <Form
            ref="form"
            props={{ model }}
            label-position="right"
            label-width={140}
            class="user-verify-step1"
          >
            <div class="rows-even">
              <div class="rows">
                <FormRender rows={rowItems2} />
              </div>
              <div class="rows">
                <FormRender rows={rowItems3} />
              </div>
            </div>

            <div class="rows address-info">
              <h2>地址证明文件</h2>
              <Step2
                defaultIdPreview={model.id_image}
                defaultLivePreview={model.live_prove_image}
                hide-btn={true}
              ></Step2>

              <div class="divide" />
              <FormItem class="actions">
                <Button
                  loading={this.loading}
                  onClick={this.handleSubmit}
                  type="primary"
                >
                  确认提交
                </Button>
              </FormItem>
            </div>
          </Form>
        </div>
      </div>
    )
  }
}
</script>

<style lang="less">
.user-info-container {
  .user-verify-step1 {
    margin: 50px auto 50px;

    .rows {
      width: 950px;
      margin: 0 auto;
    }

    .rows-even {
      width: 100%;
      background: #fbfbfb;
      padding: 40px 0 20px;
    }

    .divide {
      margin: 0 0 25px;
      background: #ddd;
    }

    .address-info {
      padding: 40px 80px 0;

      h2 {
        text-align: center;
        font-size: 12px;
        color: #000000;
        opacity: 0.5;
        margin-bottom: 40px;
      }
    }

    .actions {
      text-align: right;
    }
  }

  .user-verify-step2 {
    .ivu-col-span-24:first-child {
      display: none;
    }
  }

  .ivu-upload {
    width: 400px;

    .preview {
      width: 100%;

      img {
        max-width: 270px;
        object-fit: scale-down;
      }
    }
  }
}
</style>
