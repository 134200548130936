<template>
  <div class="login-wrapper reversion flexRow">
    <div class="login-box-wrapper">
      <div class="logo new-mb-40">
        <a href="/"><img src="../assets/images/index/logo.svg"/></a>
      </div>
      <div class="login-box">
        <div class="login-title flexRow new-mb-40">
          <h3>用户登录</h3>
          <span>还没有账户? <a href="/register">请点击注册</a></span>
        </div>

        <div class="login-tab flexRow new-mb-40">
          <a
            :class="{
              active: model.loginMethod === LOGIN_METHOD.phone
            }"
            @click="changeTab(LOGIN_METHOD.phone)"
            >手机号登录</a
          >
          <a
            :class="{ active: model.loginMethod === LOGIN_METHOD.code }"
            @click="changeTab(LOGIN_METHOD.code)"
            >验证码登录</a
          >
          <a
            :class="{
              active: model.loginMethod === LOGIN_METHOD.username
            }"
            @click="changeTab(LOGIN_METHOD.username)"
            >用户名登录</a
          >
        </div>
        <div class="login-account-box">
          <Form
            ref="form"
            hide-required-mark
            :model="model"
            :rules="rules"
            label-position="top"
          >
            <FormItem
              v-if="
                [LOGIN_METHOD.phone, LOGIN_METHOD.code].includes(
                  model.loginMethod
                )
              "
              label="手机号码"
              prop="phone"
            >
              <NewPhoneSelect v-model="model.phone" />
            </FormItem>

            <FormItem
              v-if="model.loginMethod === LOGIN_METHOD.username"
              label="用户名"
              prop="username"
            >
              <Input
                v-model="model.username"
                name="username"
                placeholder="请输入您的用户名"
              />
            </FormItem>

            <FormItem
              v-if="
                [LOGIN_METHOD.phone, LOGIN_METHOD.username].includes(
                  model.loginMethod
                )
              "
              label="密码"
              prop="password"
            >
              <Input
                v-model="model.password"
                type="password"
                placeholder="请输入您的密码"
              />
            </FormItem>

            <FormItem v-else label="验证码" prop="code" class="code">
              <Input v-model="model.code" type="text" />
              <a class="code-btn" @click="handleSendVerifyCode">
                {{ time === 60 ? '获取验证码' : `${time}s` }}
              </a>
            </FormItem>

            <FormItem>
              <Rbutton
                size="large"
                class="login-btn"
                type="primary"
                @click="submit"
              >
                {{ fetching ? '登录中...' : '登录' }}
              </Rbutton>
              <button type="submit" style="display: none" @click="submit">
                submit
              </button>
            </FormItem>
          </Form>
          <div class="login-tips"><a href="/forget">忘记密码？</a></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Rbutton from '../components/revision/RButton'
import NewPhoneSelect from '../components/newPhoneSelect'

const LOGIN_METHOD = {
  phone: 'phone',
  username: 'username',
  code: 'code'
}

export const updateLoginInfo = (vm, { token, user, sessionid }) => {
  vm.$cookies.set('token', token, {
    maxAge: 60 * 60 * 24 * 365 * 10,
    path: '/',
    domain: window.location.hostname
      .split('.')
      .slice(-2)
      .join('.')
  })
  vm.$cookies.set('sessionid', sessionid, {
    maxAge: 60 * 60 * 24 * 365 * 10,
    path: '/'
  })

  vm.$store.commit('SET_TOKEN', token)
  vm.$store.commit('SET_USER', user)
}

const LAST_LOGIN_METHOD = 'LAST_LOGIN_METHOD'

export default {
  components: { Rbutton, NewPhoneSelect },
  head() {
    return {
      title: '登录'
    }
  },
  data() {
    return {
      LOGIN_METHOD,
      fetching: false,
      codeFetching: false,
      time: 60,
      model: {
        loginMethod: LOGIN_METHOD.phone,
        username: '',
        password: '',
        phone: '',
        code: ''
      },
      rules: {
        username: [
          {
            required: true,
            message: '用户名最少3位，不包含特殊字符',
            trigger: 'blur',
            pattern: /^[@.\w_-]{3,16}$/g
          }
        ],
        phone: [
          {
            required: true,
            message: '请输入手机号',
            trigger: 'blur'
          }
        ],
        code: [
          {
            required: true,
            message: '请输入验证码',
            trigger: 'blur'
          }
        ],
        password: [
          {
            required: true,
            message: '请输入密码',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  mounted() {
    const affCode = this.$route.query.aff_code
    const source = this.$route.query.source
    if (affCode) {
      this.$cookies.set('aff_code', affCode, {
        path: '/'
      })
    }
    if (source) {
      this.$cookies.set('source', source, {
        path: '/'
      })
    }
    this.model.inviteCode = this.$cookies.get('aff_code')
    this.model.source = this.$cookies.get('source')

    const last_login_method = localStorage.getItem(LAST_LOGIN_METHOD)
    if (last_login_method) {
      this.model.loginMethod = last_login_method
    }

    if (this.$store.state.user) {
      this.toNext()
    }
  },
  methods: {
    toNext() {
      const { callbackUrl } = this.$route.query
      if (!callbackUrl) {
        this.$router.replace('/account')
        return
      }

      if (callbackUrl.includes('?')) {
        window.location.replace(
          `${callbackUrl}&token=${this.$store.state.token}`
        )
      } else {
        window.location.replace(
          `${callbackUrl}?token=${this.$store.state.token}`
        )
      }
    },

    timer() {
      const t = setInterval(() => {
        if (this.time > 1) {
          this.time--
        } else {
          clearInterval(t)
          this.time = 60
        }
      }, 1000)
    },
    async handleSendVerifyCode() {
      if (this.codeFetching || this.time !== 60) return
      try {
        this.codeFetching = true
        await this.$api.sendVerifyCode({ phone: this.model.phone })
        this.timer()
      } catch (error) {
        console.log(error)
      } finally {
        this.codeFetching = false
      }
    },
    changeTab(loginMethod) {
      this.model.loginMethod = loginMethod

      const { username, phone } = this.model

      this.$refs.form.resetFields()

      this.model.username = username
      this.model.phone = phone
    },
    submit() {
      window.event.preventDefault()
      if (this.fetching) return
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return
        }
        this.fetching = true
        try {
          const { loginMethod } = this.model

          const model = {
            ...this.model,
            loginMethod:
              loginMethod === LOGIN_METHOD.code
                ? LOGIN_METHOD.phone
                : loginMethod,
            phone:
              loginMethod === LOGIN_METHOD.username ? '' : this.model.phone,
            username:
              loginMethod === LOGIN_METHOD.username ? this.model.username : ''
          }

          const { token, user, sessionid } =
            loginMethod === LOGIN_METHOD.code
              ? await this.$api.phoneLoginOrRegister({
                  phone: this.model.phone,
                  code: this.model.code
                })
              : await this.$api.login(model)

          updateLoginInfo(this, { token, user, sessionid })

          window.dataLayer.push({
            event: 'login',
            method: loginMethod
          })

          localStorage.setItem(LAST_LOGIN_METHOD, loginMethod)
          this.toNext()
        } catch (error) {
          console.error(error)
          this.fetching = false
        }
      })
    }
  },
  layout() {
    return 'new-login'
  }
}
</script>

<style lang="less">
@import url('../iconfont/index.less');
.login-wrapper {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  .login-box-wrapper {
    width: 540px;
    .logo {
      text-align: center;
    }
    .code {
      position: relative;
      .code-btn {
        position: absolute;
        right: 10px;
        top: 10px;
        font-size: 14px;
        color: #e56131;
      }
    }
    .login-box {
      padding: 60px 95px;
      border-radius: 6px;
      box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.08);
      border: solid 1px rgba(0, 0, 0, 0.1);
      .login-title {
        justify-content: space-between;
        align-items: center;
        color: #000;
        h3 {
          font-size: 28px;
          font-weight: 600;
        }
        span {
          font-size: 14px;
          a {
            color: #e56131;
            text-decoration: underline;
          }
        }
      }
      .login-tab {
        a {
          width: 50%;
          height: 30px;
          position: relative;
          text-align: center;
          color: #666;
          &::after {
            content: '';
            height: 2px;
            width: 100%;
            background: #222;
            opacity: 0.1;
            position: absolute;
            bottom: 0px;
            left: 0;
          }

          &.active {
            color: #e56131;
            &::after {
              background: #e56131;
              opacity: 1;
            }
          }
        }
      }
      .login-account-box {
        .login-btn {
          margin-top: 10px;
          width: 100%;
        }
        .login-tips {
          text-align: center;
          a {
            color: #666;
          }
        }
      }

      .ivu-form-item-label {
        a {
          color: #e56131;
        }
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .new-actions {
    min-width: 100%;
    .new-action__main {
      .login-wrapper {
        align-items: flex-start;
        .login-box-wrapper {
          width: 100%;
          .logo {
            margin-top: 32px;
            margin-bottom: 60px;
          }
          .login-box {
            border: none;
            box-shadow: none;
            padding: 0 15px 0;
          }
        }
      }
    }
  }
}
</style>
