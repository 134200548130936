<template>
  <div class="new-aff-wrapper reversion">
    <RBanner
      bg-color="#f6fbf0"
      :bg-imgae="require('../assets/images/new-aff/banner.png')"
      @click="btnClick"
    >
      <template v-slot:title>立即加入 iSHOPPER 合作伙伴计划</template>
      <template v-slot:subtitle>
        <span v-if="!isMobile"
          >欢迎所有对我们的服务有兴趣的个人与团队，我们提供丰厚的佣金比例</span
        >
        <span v-else
          ><p class="special">
            欢迎所有对我们的服务有兴趣的个人与团队，<br />
            我们提供丰厚的佣金比例
          </p>
        </span>
      </template>
      <template v-slot:btn
        >现在注册立享最高20%退税优惠
        <IconFont
          class="banner-arrow-right"
          size="28"
          color="#fff"
          name="arrow-right"
      /></template>
    </RBanner>
    <Apply />
    <Target />
    <Kefu />
    <Advantage />
    <div class="new-aff-jieshi new-center-container">
      <a class="aff-jieshi-btn">以上所有内容iShopper拥有最终解释权</a>
    </div>
  </div>
</template>

<script>
import IconFont from '../iconfont'
import RBanner from '../components/revision/RBanner'
import Apply from '../components/aff/Apply'
import Target from '../components/aff/Target'
import Kefu from '../components/aff/Kefu'
import Advantage from '../components/index/Advantage'
export default {
  components: {
    IconFont,
    RBanner,
    Apply,
    Target,
    Kefu,
    Advantage
  },
  head() {
    return {
      title: '合作伙伴'
    }
  },
  layout() {
    return 'new-default'
  },
  data() {
    return {
      isMobile: null
    }
  },
  mounted() {
    this.comMobile()
  },
  methods: {
    btnClick() {
      this.$router.push('/register')
    },
    comMobile() {
      const width = document.body.clientWidth
      this.isMobile = width <= 640
    }
  }
}
</script>

<style lang="less">
.new-aff-wrapper {
  .new-aff-jieshi {
    padding-bottom: 100px;
    .aff-jieshi-btn {
      display: block;
      margin: 0 auto;
      font-size: 14px;
      font-weight: 600;
      height: 40px;
      line-height: 40px;
      width: 302px;
      color: #222;
      text-align: center;
      box-sizing: border-box;
      border-radius: 6px;
      border: 1px dashed #222;
    }
  }
}
@media screen and (max-width: 640px) {
  .new-center-container {
    width: auto;
    overflow: hidden;
  }
  .new-aff-wrapper {
    .new-aff-jieshi {
      padding-bottom: 40px;
    }
    .new-about-banner {
      .about-banner-center {
        background-position: center 145px;
        h3 {
          padding: 436px 60px 0;
        }
        .special {
          line-height: 20px;
          padding: 10px 0;
          font-size: 14px;
        }
      }
    }
  }
}
</style>
