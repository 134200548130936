<template>
  <div class="bussiness-wrapper-step6">
    <div class="container flexColumn">
      <LandingBussinessBtns />
    </div>
  </div>
</template>

<script>
import LandingBussinessBtns from '../../../components/landing/LandingBussinessBtns'
export default {
  components: {
    LandingBussinessBtns
  }
}
</script>

<style lang="less">
.bussiness-wrapper-step6 {
  background: #fff;
  .container {
    align-items: center;
    padding-top: 80px;
    padding-bottom: 80px;
    .btns-two {
      margin-bottom: 30px;
    }
  }
}
@media screen and (max-width: 640px) {
  .bussiness-wrapper-step6 {
    .container {
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }
}
</style>
