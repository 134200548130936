<script>
import FormRender from '~/components/FormRender'

function getObjectURL(file) {
  let url = null
  if (window.createObjectURL !== undefined) {
    // basic
    url = window.createObjectURL(file)
  } else if (window.URL !== undefined) {
    // mozilla(firefox)
    url = window.URL.createObjectURL(file)
  } else if (window.webkitURL !== undefined) {
    // webkit or chrome
    url = window.webkitURL.createObjectURL(file)
  }
  return url
}

export default {
  inheritAttrs: false,
  props: {
    onSubmit: {
      type: Function,
      default: () => {}
    },
    hideBtn: {
      type: Boolean,
      default: false
    },
    defaultIdPreview: {
      type: String,
      default: ''
    },
    defaultLivePreview: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      id_image: null,
      live_prove_image: null,
      idPreview:
        this.defaultIdPreview ||
        `${this.$API_URL}${this.$store.state?.user.id_image}`,
      livePreview:
        this.defaultLivePreview ||
        `${this.$API_URL}${this.$store.state?.user.live_prove_image}`
    }
  },

  computed: {
    headers() {
      return {
        Authorization: `JWT ${this.$store.state.token}`
      }
    }
  },

  methods: {
    handleSuccess(field, file) {
      this.$set(this, field, file.name)
    },
    handleFormatError() {
      this.$Message.error('请选择图片文件')
    },
    handleMaxSize() {
      this.$Message.error('请上传小于 8M 大小的图片文件')
    }
  },

  render() {
    const rowItems = [
      {
        col: 24,
        prop: 'id_image',
        label: '证件扫描件',
        node: (
          <Upload
            headers={this.headers}
            props={{
              onSuccess: (_, file) => this.handleSuccess('id_image', file),
              onRemove: () => {
                this.id_image = null
                this.idPreview = null
              },
              onError: () => {
                this.id_image = null
                this.idPreview = null
              },
              beforeUpload: (file) => (this.idPreview = getObjectURL(file)),
              onFormatError: this.handleFormatError,
              onExceededSize: this.handleMaxSize
            }}
            format={['jpg', 'jpeg', 'gif', 'png']}
            max-size={8192}
            name="id_image"
            action={this.$API_URL + '/api/core/user/update_info/'}
            placeholder="请选择文件"
          >
            <div>
              <Input readonly value={this.id_image} />
              <Button type="info">选取文件</Button>
            </div>
            {this.idPreview && (
              <div class="preview">
                <img src={this.idPreview} />
              </div>
            )}
          </Upload>
        )
      },
      {
        col: 24,
        prop: 'live_prove_image',
        label: '居住地址证明扫描件',
        node: (
          <Upload
            headers={this.headers}
            props={{
              onSuccess: (_, file) =>
                this.handleSuccess('live_prove_image', file),
              onRemove: () => {
                this.live_prove_image = null
                this.livePreview = null
              },
              onError: () => {
                this.live_prove_image = null
                this.livePreview = null
              },
              beforeUpload: (file) => (this.livePreview = getObjectURL(file)),
              onFormatError: this.handleFormatError,
              onExceededSize: this.handleMaxSize
            }}
            format={['jpg', 'jpeg', 'gif', 'png']}
            max-size={8192}
            name="live_prove_image"
            action={this.$API_URL + '/api/core/user/update_info/'}
            placeholder="请选择文件"
          >
            <div>
              <Input readonly value={this.live_prove_image} />
              <Button type="info">选取文件</Button>
            </div>
            {this.livePreview && (
              <div class="preview">
                <img src={this.livePreview} />
              </div>
            )}
          </Upload>
        )
      }
    ]

    return (
      <div>
        <Form
          label-position="right"
          label-width={200}
          class="user-verify-step2"
        >
          <div class="rows">
            <FormRender rows={rowItems} />
            {!this.hideBtn && (
              <div>
                {' '}
                <div class="divide" />
                <FormItem class="actions">
                  <Button
                    disabled={!(this.idPreview && this.livePreview)}
                    onClick={this.onSubmit}
                    type="primary"
                  >
                    确认提交
                  </Button>
                </FormItem>
              </div>
            )}
          </div>
        </Form>
      </div>
    )
  }
}
</script>

<style lang="less">
.user-verify-step2 {
  margin: 50px auto 50px;

  .ivu-upload-select {
    display: flex;
    flex-flow: row wrap;

    .ivu-input-type-text {
      width: 275px;
      margin-right: 10px;
    }
  }

  .ivu-upload {
    width: auto;
    height: auto;
    display: block;
  }

  .preview {
    padding: 10px 0;
    img {
      width: 100%;
    }
  }

  .rows {
    width: 600px;
    margin: 0 auto;
  }

  .divide {
    margin: 0 0 25px;
    background: #ddd;
  }

  .actions {
    text-align: right;
    margin-right: 120px;
  }
}
</style>
