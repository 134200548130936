<template>
  <div class="username-container">
    {{ user.username }}

    <Poptip
      trigger="hover"
      @on-popper-show="updateQrcode"
      @on-popper-hide="updateUser"
    >
      <div slot="content">
        <div v-if="!user.mp_openid">
          <img
            class="w-[140px] h-[140px] !rounded-none mx-auto"
            :src="qrcodeUrl"
            alt=""
          />

          <div class="text-center">
            <h3 class="mb-2">扫码关注微信通知</h3>
            <p class="text-muted text-[12px]">
              您可以第一时间收到服务消息
            </p>
          </div>
        </div>
        <div v-else>
          <div class="text-center">
            <a class="mb-2 !static" @click="handleUnbind">取消关注</a>

            <p class="text-muted text-[12px]">
              您将不再接收微信通知
            </p>
          </div>
        </div>
      </div>
      <span
        class="w-[14px] h-[14px] inline-block ml-1"
        :class="{ 'animate-pulse': activeBindUser }"
      >
        <svg
          id="Layer_1"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 2500 2500"
          style="enable-background:new 0 0 2500 2500;"
          xml:space="preserve"
        >
          <style type="text/css">
            .st1 {
              fill: #ffffff;
            }
          </style>
          <path
            class="st0"
            :class="{
              'fill-[#2dc100]': user.mp_openid,
              'fill-[#888]': !user.mp_openid
            }"
            d="M2500,2125c0,207.1-167.9,375-375,375H375c-207.1,0-375-167.9-375-375V375C0,167.9,167.9,0,375,0h1750
	c207.1,0,375,167.9,375,375V2125z"
          />
          <g>
            <path
              class="st1"
              d="M1673.4,932.3c-201.8,10.5-377.2,71.7-519.7,209.9C1009.7,1281.8,944,1452.9,962,1665
		c-78.9-9.8-150.7-20.5-222.9-26.6c-25-2.1-54.6,0.9-75.7,12.8c-70.1,39.6-137.4,84.3-217.1,134.1c14.6-66.2,24.1-124.1,40.9-179.8
		c12.3-41,6.6-63.7-31.1-90.4c-242.2-171-344.3-426.9-267.9-690.4C258.8,581,432.4,433.1,668.3,356.1
		c322-105.2,683.8,2.1,879.6,257.7C1618.5,706.1,1661.9,809.8,1673.4,932.3L1673.4,932.3z M744.7,850.2
		c1.9-48.2-39.9-91.6-89.5-93.1c-50.8-1.5-92.5,37.3-94,87.4c-1.5,50.7,37.3,91.4,88.4,92.7C700.3,938.5,742.8,899.6,744.7,850.2
		L744.7,850.2z M1229.2,757.1c-49.8,0.9-91.8,43.3-91,91.7c0.9,50.1,42.2,89.4,93.4,88.8c51.3-0.6,90.3-40.3,89.8-91.5
		C1321,795.9,1279.9,756.2,1229.2,757.1z"
            />
            <path
              class="st1"
              d="M2126.7,2190.2c-63.9-28.4-122.5-71.1-184.9-77.6c-62.2-6.5-127.5,29.4-192.5,36
		c-198.1,20.3-375.6-34.9-521.9-170.3c-278.3-257.4-238.5-652.1,83.4-863.1c286.2-187.5,705.9-125,907.6,135.2
		c176.1,227,155.4,528.3-59.6,719c-62.2,55.2-84.6,100.6-44.7,173.4C2121.6,2156.3,2122.4,2173.3,2126.7,2190.2L2126.7,2190.2z
		 M1399.4,1486.1c40.7,0,74.2-31.8,75.7-72c1.6-42.5-32.6-78.2-75.2-78.4c-42.2-0.2-77.5,36-76,77.9
		C1325.3,1453.7,1359,1486,1399.4,1486.1L1399.4,1486.1z M1868.2,1335.9c-39.5-0.3-73,32-74.6,71.9c-1.7,42.6,31.4,77.7,73.6,77.8
		c40.8,0.1,73.1-30.7,74.5-71.3C1943.4,1371.5,1910.2,1336.2,1868.2,1335.9z"
            />
          </g>
        </svg>
      </span>
    </Poptip>

    <Tooltip
      v-if="user.username === user.phone"
      content="修改用户名"
      placement="top-start"
    >
      <a href="javascript:;" @click="setVisible(true)">
        <Icon type="ios-create-outline" />
      </a>
    </Tooltip>

    <Modal
      v-model="visible"
      width="350px"
      class-name="express-dialog-container"
      title="修改用户名"
      :mask-closable="false"
      @cancel="setVisible(false)"
    >
      <Input v-model="username" placeholder="请输入新的用户名" />
      <template #footer>
        <Button type="text" @click="setVisible(false)">取消</Button>
        <Button type="primary" @click="handleSubmit">
          确定
        </Button>
      </template>
    </Modal>
  </div>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
import { useVm } from '~/use'

export default {
  props: {
    user: {
      type: Object,
      required: true
    },
    onSuccess: {
      type: Function,
      required: true
    }
  },
  setup({ onSuccess, user }) {
    const vm = useVm()
    const visible = ref(false)
    const username = ref('')
    const activeBindUser = ref(false)
    const qrcodeUrl = ref(null)

    const handleSubmit = () => {
      const onOk = async () => {
        try {
          const data = await vm.$api.updateUsername({
            username: username.value
          })
          setVisible(false)
          onSuccess(data)
        } catch (err) {
          console.error(err)
        }
        vm.$Modal.remove()
      }

      vm.$Modal.confirm({
        title: '谨慎操作',
        content: `您仅能修改一次用户名，确定修改成 ${username.value} 吗?`,
        loading: true,
        onOk
      })
    }

    const updateQrcode = async () => {
      if (vm.$store.state.user.mp_openid) {
        return false
      }

      try {
        const res = await vm.$api.fetchBindWechatQrcode()
        qrcodeUrl.value = res.url
      } catch (err) {
        console.error(err)
      }
    }

    const updateUser = () => {
      vm.$store.dispatch('updateUserDetail')
    }

    const handleUnbind = async () => {
      await vm.$api.wechatUnbind()
      updateUser()
      user.mp_openid = ''
      updateQrcode()
    }

    const setVisible = (value) => {
      visible.value = value
    }

    onMounted(() => {
      // 闪闪提示下客户
      if (!user.mp_openid) {
        activeBindUser.value = true

        setTimeout(() => {
          activeBindUser.value = false
        }, 4000)
      }
    })

    return {
      setVisible,
      handleSubmit,
      activeBindUser,
      visible,
      username,
      qrcodeUrl,
      updateQrcode,
      updateUser,
      handleUnbind
    }
  }
}
</script>

<style lang="less" scoped>
.username-container {
  display: flex;
  align-items: center;

  a {
    font-size: 1.2em;
    position: relative;
    top: 2px;
    left: 12px;
  }
}
</style>
