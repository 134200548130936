<template>
  <AddressPicker v-slot="slotProps" :on-select="handleSelect">
    <div class="relative top-[5px]">
      {{ address }}
      <Tooltip
        content="我们将根据您填写的地址信息生成发票。请务必填写您的真实地址，并且确保您的账单地址收件人姓名跟 iShopper 实名认证姓名一致，否则您的返佣提现申请将无法通过"
        placement="top"
        transfer-class-name="full-tooltip w-[380px]"
      >
        <a @click="slotProps.toggle(true)">
          <span v-if="address">
            修改地址
          </span>
          <span v-else>
            选择地址
          </span>
        </a>
      </Tooltip>
    </div>
  </AddressPicker>
</template>

<script>
import { ref } from '@vue/composition-api'
import { useVm } from '@/use'
import { addressStringify } from '~/utils/index'

import AddressPicker from '~/components/AddressPicker'

export default {
  components: {
    AddressPicker
  },
  computed: {
    address() {
      const address = this.currentItem
      if (!this.currentItem) {
        return
      }

      return `${address?.name} | ${addressStringify(address)}`
    }
  },
  setup() {
    const vm = useVm()
    const currentItem = ref(null)

    const handleSelect = (data) => {
      currentItem.value = data
      vm.$parent.$emit('on-form-change', data.id)
      vm.$emit('input', data.id)
    }

    return {
      currentItem,
      handleSelect
    }
  }
}
</script>

<style lang="scss" scoped></style>
