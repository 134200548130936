<template>
  <div class="new-about">
    <RBanner
      bg-color="#f8f7f4"
      :bg-imgae="require('../assets/images/new-about/banner.png')"
      @click="btnClick"
    >
      <template v-slot:title>全英唯一线上海淘自助退税平台</template>
      <template v-slot:subtitle>
        <span v-if="!isMobile"
          >iShopper海淘直通车 · 十年金融税务备案 · 十万用户信誉保障</span
        >
        <span v-else
          ><p>iShopper海淘直通车</p>
          十年金融税务备案 · 十万用户信誉保障
        </span>
      </template>
      <template v-slot:btn
        >现在注册立享最高20%退税优惠
        <IconFont
          class="banner-arrow-right"
          size="28"
          color="#fff"
          name="arrow-right"
      /></template>
    </RBanner>
    <Introduce />
    <Reimbursement />
    <Logistics />
    <Team />
    <Advert />
    <Partner />
  </div>
</template>

<script>
import IconFont from '../iconfont'
import RBanner from '../components/revision/RBanner'
import Introduce from '../components/about/Introduce'
import Reimbursement from '../components/index/Reimbursement'
import Logistics from '../components/about/Logistics'
import Team from '../components/about/Team'
import Advert from '../components/index/Advert'
import Partner from '../components/index/Partner'
export default {
  components: {
    IconFont,
    RBanner,
    Introduce,
    Reimbursement,
    Logistics,
    Team,
    Advert,
    Partner
  },
  head() {
    return {
      title: '关于我们'
    }
  },
  layout() {
    return 'new-default'
  },
  data() {
    return {
      isMobile: null
    }
  },
  mounted() {
    this.comMobile()
  },
  methods: {
    btnClick() {
      this.$router.push('/register')
    },
    comMobile() {
      const width = document.body.clientWidth
      this.isMobile = width <= 640
    }
  }
}
</script>

<style lang="less">
.banner-arrow-right {
  font-weight: 500;
  margin-left: 10px;
}
@media screen and (max-width: 640px) {
  .new-center-container {
    width: auto;
  }
  .partner-wrapper {
    .new-center-container {
      padding: 0;
    }
  }
}
</style>
