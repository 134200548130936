<template>
  <Modal v-model="visible" class="referral-link-container" title="我的推广链接">
    <div v-if="url" class="url-container">
      <qrcode :value="qr_url" :options="{ width: 200 }" />
      <Row :gutter="5">
        <i-col span="20">
          <Input readonly :value="url" />
        </i-col>
        <i-col span="4">
          <Button
            v-clipboard:copy="url"
            v-clipboard:success="
              () => $Message.success(`已复制推广链接：${url}`)
            "
            type="primary"
            >复制</Button
          >
        </i-col>
      </Row>
      <Row class="aff-code-container" :gutter="5">
        <i-col span="20">
          <p style="width:190px;">邀请码: {{ user.affiliate_code }}</p>
        </i-col>
        <i-col span="4">
          <Button
            v-clipboard:copy="user.affiliate_code"
            v-clipboard:success="
              () => $Message.success(`已复制邀请码：${user.affiliate_code}`)
            "
            type="primary"
            >复制</Button
          >
        </i-col>
      </Row>
    </div>
  </Modal>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {
      visible: true,
      url: '',
      qr_url: ''
    }
  },
  computed: {
    ...mapState(['user'])
  },
  mounted() {
    this.fetchUrl()
  },
  methods: {
    fetchUrl() {
      this.url =
        'https://www.ishopper.com/register?aff_code=' +
        this.user.affiliate_code +
        '&source=link'
      this.qr_url =
        'https://www.ishopper.com/register?aff_code=' +
        this.user.affiliate_code +
        '&source=qr'
    }
  }
}
</script>

<style lang="less">
.referral-link-container {
  .url-container {
    width: 100%;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    padding: 0 0 10px;
    canvas {
      margin-bottom: 5px;
    }

    .aff-code-container {
      p {
        margin-top: 30px;
      }

      button {
        margin: 20px 0 0;
      }
    }
  }
  .ivu-modal-footer {
    display: none;
  }
}
</style>
