<template>
  <div>
    <Form ref="form" :model="model" class="account-cards-register-container">
      <h1 v-if="!readonly">申请新卡</h1>

      <div
        v-if="!readonly || (readonly && model.firstname)"
        class="form-container"
      >
        <div class="container">
          <FormRender :rows="rows" />
          <Alert style="margin:0 0 20px 160px;width:430px">
            <p>
              账户余额需至少1英镑才可申请新卡，在新用户推广期间，当余额少于20英镑时，iShopper会为您垫付10英镑的工本费和10英镑消费卡预存金额中不足的部分，方便您无门槛体验我们提供的优质采购和退税服务。
            </p>
          </Alert>

          <FormItem class="actions">
            <div class="divide" />
            <nuxt-link to="/account/cards">
              <Button type="info">返 回</Button>
            </nuxt-link>
            <Button
              v-if="!readonly"
              :loading="loading"
              type="primary"
              @click="beforeSubmit"
            >
              确认提交
            </Button>
            <Button
              v-if="showEdit && !showUpdate"
              type="primary"
              @click="showEditBtn"
            >
              修改
            </Button>
            <span>
              <Button v-if="showUpdate" type="primary" @click="updateCard">
                提交
              </Button>
              <Button v-if="showUpdate" type="primary" @click="cancelUpdate">
                取消
              </Button>
            </span>
          </FormItem>
        </div>
      </div>
    </Form>

    <Modal
      v-model="confirmVisible"
      :width="540"
      footer-hide
      :mask-closable="false"
    >
      <div class="ivu-modal-confirm-head">
        <div
          class="ivu-modal-confirm-head-icon ivu-modal-confirm-head-icon-warning"
        >
          <i class="ivu-icon ivu-icon-ios-alert" />
        </div>
        <div class="ivu-modal-confirm-head-title">使用规则</div>
      </div>
      <div class="leading-[1.8]">
        <p>
          您正在申请的iShopper消费卡，仅限于您的消费同时满足以下条件时使用：
        </p>
        <p class="font-bold">1、在英国境内支付即将邮寄出境的奢侈品消费</p>
        <p class="font-bold">2、在iShopper进行退税申请</p>

        <p class="mt-[15px]">
          该消费卡<strong>不支持</strong>您进行奢侈品自用消费（不会邮寄出英国境外）和日常消费（车票、食物、日用品拍够等）等不符合以上使用条件的消费，否则您的消费卡会被冻结，甚至影响您已经申请的退税。
        </p>
      </div>

      <div class="ivu-modal-confirm-footer">
        <Checkbox v-model="confirmFlag">同意</Checkbox>
        <Button
          type="primary"
          :disabled="!confirmFlag"
          :loading="loading"
          @click="handleSubmit"
        >
          确认申请
        </Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import { omit, cloneDeep } from 'lodash'

import NewPhoneSelect from '~/components/newPhoneSelect'
import FormRender from '~/components/FormRender'

export default {
  components: {
    FormRender
  },
  props: {
    readonly: {
      type: Boolean
    },
    id: {
      type: [Number, String],
      default: undefined
    }
  },
  data() {
    return {
      loading: false,
      model: {
        to_company: false,
        firstname: '',
        surname: '',
        birthday: '1990/1/1',
        gender: '',
        email: '',
        phone: '',
        apply_reason: '',
        postcode: null,
        country: 'UK',
        province: null,
        city: null,
        county: '',
        street1: null,
        street2: null,
        street3: null
      },
      showEdit: false,
      showUpdate: false,
      confirmVisible: true,
      confirmFlag: false
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.user
    },
    rows() {
      const model = this.model
      let columns = [
        {
          col: 24,
          prop: 'surname',
          label: 'Surname',
          node: <Input v-model={model.surname} />,
          rules: {
            trigger: 'blur',
            pattern: /^[\w\s]+$/g,
            message: '请输入英文',
            required: true
          }
        },
        {
          col: 24,
          prop: 'firstname',
          label: 'First Name',
          node: <Input v-model={model.firstname} />,
          rules: {
            trigger: 'blur',
            pattern: /^[\w\s]+$/g,
            message: '请输入英文',
            required: true
          }
        },
        {
          col: 24,
          prop: 'birthday',
          label: 'Date of birth',
          node: (
            <DatePicker
              style="width:100%"
              v-model={model.birthday}
              type="date"
              placeholder="Date of birth"
            ></DatePicker>
          ),
          rules: {
            trigger: 'blur',
            validator(rule, value, callback) {
              console.log(value)
              if (value === '') {
                callback(new Error('请选择生日日期'))
                return
              }
              const isUnder16 =
                new Date().getFullYear() - new Date(value).getFullYear() < 16

              if (isUnder16) {
                callback(new Error('年龄小于16周岁不合法规'))
                return
              }

              callback()
            },
            required: true
          }
        },
        {
          col: 24,
          prop: 'gender',
          label: 'Gender',
          node: (
            <Select v-model={model.gender}>
              <Option value={1}>Male</Option>
              <Option value={0}>Female</Option>
            </Select>
          ),
          rules: {
            message: '请选择性别',
            required: true
          }
        },
        {
          col: 24,
          prop: 'email',
          label: 'Email',
          node: <Input v-model={model.email} />,
          rules: {
            type: 'email',
            trigger: 'blur',
            pattern: /^[A-Za-zd]+([-_.][A-Za-zd]+)*@([A-Za-zd]+[-.])+[A-Za-zd]{2,5}$/g,
            message: '请输入正确的邮箱',
            required: true
          }
        },
        {
          col: 24,
          prop: 'phone',
          label: 'Mobile',
          node: <NewPhoneSelect v-model={model.phone} />,
          rules: {
            message: '请输入手机号码',
            required: true
          }
        },
        {
          col: 24,
          prop: 'apply_reason',
          label: 'Remark',
          node: <Input v-model={model.apply_reason} placeholder="Note" />,
          rules: {}
        },
        {
          render: (
            <div class="form-line">
              <span>配送地址</span>
            </div>
          )
        },
        {
          render: (
            <Address
              class="register-address"
              v-model={this.model}
              filter={this.filterAddressRows}
            />
          )
        }
      ]

      if (this.readonly) {
        columns = columns.map((item) => {
          if (item.prop && !this.showUpdate) {
            let value = model[item.prop]

            if (item.prop === 'gender') {
              value = value === 1 ? 'Male' : 'Female'
            }

            if (item.prop === 'apply_reason') {
              value = value || '无'
            }

            return {
              ...item,
              node: <span style="line-height:42px">{value}</span>
            }
          } else {
            return item
          }
        })

        columns.splice(-1, 1, {
          col: 24,
          label: '地址',
          node: (
            <div class="special-address">
              <Address
                style="line-height:42px;"
                text-mode={!this.showUpdate}
                class="register-address"
                v-model={this.model}
                filter={this.filterAddressRows}
              />
            </div>
          ),
          rules: {}
        })
      }

      return columns
    }
  },
  watch: {
    'model.country'(value, oldValue) {
      if (value === 'UK' || value === oldValue || !oldValue) {
        return
      }

      this.$nextTick(() => {
        this.model.country = 'UK'
        this.model.province = null
        this.model.city = 'Bicester'
        this.model.postcode = 'OX26 6QB'
        this.model.county = null
        this.model.street3 = 'Victoria Rd'
        this.model.street2 = 'Minton Place'
        this.model.street1 = 'Unit 6'

        this.$Modal.info({
          title: '温馨提示',
          okText: '知道了',
          render: () => (
            <span>
              消费卡邮寄仅限英国地区, 卡片将会寄送至 iShopper 后发送到您的邮箱,
              详情请
              <a href="javascript:;" onClick={this.toService}>
                联系客服
              </a>
            </span>
          )
        })
      })
    }
  },
  mounted() {
    this.showEdit = !!this.$route.query.showEdit
    if (this.readonly) {
      this.getCardLog()
    }
  },
  methods: {
    toService() {
      const user = this.$store.state.user
      if (user) {
        window._MEIQIA('metadata', {
          id: user.id,
          email: user.email,
          username: user.username,
          phone: user.phone
        })
      }

      window._MEIQIA('showPanel')
    },
    async getCardLog() {
      const { data } = await this.$api.applyCardLog({
        apply_id: this.$route.query.id
      })
      // console.log(data)
      const item = data[0] || {}
      this.model = {
        ...item,
        firstname: item.firstname || item.first_name,
        apply_reason: item.apply_reason || item.reason
      }
    },
    beforeUpdateCard() {
      this.$refs.form.validate((isPass) => {
        if (!isPass) {
          return
        }

        this.confirmVisible = true
      })
    },
    async updateCard() {
      this.loading = true
      const model = cloneDeep(omit(this.model))
      model.birthday = this.$dayjs(model.birthday).format('YYYY-MM-DD')
      model.apply_id = this.$route.query.id
      try {
        await this.$api.updateapplyCard(model)
        this.loading = false
        this.$Message.success('修改成功')
        this.model = model
        this.showUpdate = false
      } catch (err) {
        console.error(err)
        this.loading = false
      }
    },
    cancelUpdate() {
      this.getCardLog()
      this.showUpdate = false
    },
    showEditBtn() {
      this.showUpdate = true
    },
    filterAddressRows(rows) {
      return rows.map((item) => {
        return { ...item, col: 24 }
      })
    },
    getGoodsTemplate() {
      return {}
    },
    beforeSubmit() {
      this.$refs.form.validate((isPass) => {
        if (!isPass) {
          return
        }

        this.confirmVisible = true
      })
    },
    async handleSubmit() {
      this.loading = true
      const model = cloneDeep(this.model)
      model.birthday = this.$dayjs(model.birthday).format('YYYY-MM-DD')
      model.to_company =
        model.city === 'Bicester' &&
        model.street3 === 'Victoria Rd' &&
        model.street2 === 'Minton Place' &&
        model.street1 === 'Unit 6'

      try {
        await this.$api.applyCard(model)
        this.loading = false
        this.confirmVisible = false
        this.$Message.success('开卡申请提交成功，请耐心等待！')
        this.$router.replace('/account/cards')

        window.dataLayer.push({
          event: 'user_conversion',
          conversion_action: 'apply_card'
        })
      } catch (err) {
        console.error(err)
        this.loading = false
      }
    }
  }
}
</script>

<style lang="less">
.account-cards-register-container {
  h1 {
    text-align: center;
    margin: 40px 0 0;
    font-weight: normal;
  }

  .reason-container {
    padding: 50px 0 30px;
    width: 570px;
    margin: 0 auto;
    h1 {
      font-size: 24px;
      font-weight: normal;
      color: #3b3b3b;
      text-align: center;
      margin-bottom: 15px;
    }

    textarea {
      padding: 15px 20px;
    }
  }

  .form-render {
    display: flex;
    flex-flow: column wrap;
  }

  .form-container {
    padding: 40px 0 50px;
    margin-left: -90px;

    .container {
      display: flex;
      flex-flow: column wrap;
      align-items: center;
    }

    .ivu-form-item {
      display: flex;
      .ivu-form-item-label {
        width: 150px;
        margin-right: 10px;
      }
      .ivu-form-item-content {
        width: 500px;
      }
    }

    .actions {
      .divide {
        width: 570px;
        background: #efefef;
        margin: 10px 0 35px -140px;

        & + a {
          margin: 0 30px 0 0;
        }
      }

      .ivu-form-item-content {
        width: 100%;
        margin-left: 310px;
      }
    }
  }

  .form-line {
    height: 1px;
    background: #eee;
    margin: 0 0 25px 0;
    position: relative;
    left: 40px;

    span {
      position: absolute;
      top: -12px;
      left: 122px;
      font-weight: bold;
    }
  }

  .register-address {
    .ivu-col-span-8 {
      width: 100%;
      margin-bottom: 15px;
    }
  }

  .special-address {
    .ivu-form-item-label {
      flex-shrink: 0;
      width: 150px !important;
    }
    .ivu-form-item-content {
      width: 300px !important;
    }
  }
}
</style>
