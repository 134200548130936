<template>
  <div class="new-about-team">
    <div class="new-center-container">
      <h3 class="new-title">我们的团队</h3>
      <div class="about-team-list flexRow">
        <div class="about-team-item">
          <div class="img-box">
            <img v-lazy="require(`../../assets/images/new-about/team1.png`)" />
          </div>
          <h5>伦敦<span>London</span></h5>
        </div>
        <div class="about-team-item">
          <div class="img-box">
            <img v-lazy="require(`../../assets/images/new-about/team2.png`)" />
          </div>
          <h5>比斯特<span>Bicester</span></h5>
        </div>
        <div class="about-team-item">
          <div class="img-box">
            <img v-lazy="require(`../../assets/images/new-about/team3.png`)" />
          </div>
          <h5>北京<span>Beijing</span></h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less">
.new-about-team {
  padding-top: 100px;
  padding-bottom: 100px;
  .new-title {
    margin-bottom: 70px;
  }
  .about-team-list {
    justify-content: space-around;
    .about-team-item {
      // width: 350px;
      .img-box {
        width: 350px;
        height: 350px;
        border-radius: 6px;
        box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.1);
        border: solid 1px rgba(0, 0, 0, 0.1);
        overflow: hidden;
        img {
          object-fit: contain;
          width: 348px;
          width: 348px;
        }
      }
      h5 {
        margin-top: 32px;
        text-align: center;
        font-size: 16px;
        color: #222;
        font-weight: 600;
        position: relative;
        span {
          margin-left: 10px;
          font-weight: normal;
        }

        &::after {
          content: '';
          height: 2px;
          border-radius: 1px;
          background-color: #222;
          width: 40px;
          position: absolute;
          left: 50%;
          margin-left: -20px;
          top: -10px;
        }
      }
    }
  }
}
@media screen and (max-width: 640px) {
  .new-about-team {
    padding-top: 40px;
    padding-bottom: 40px;
    .new-title {
      margin-bottom: 50px;
    }
    .about-team-list {
      flex-direction: column;
      .about-team-item {
        padding-bottom: 40px;
      }
    }
  }
}
</style>
