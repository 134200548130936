<template>
  <Form
    ref="form"
    :model="model"
    :label-width="140"
    hide-required-mark
    label-colon
  >
    <div class="account-order-delivery-container">
      <div class="order-info">
        <div class="container">
          <h3 class="title-line"><span>订单号</span></h3>
          <h1>
            <span>{{ info.number }}</span>
            <a :href="info.invoice_url" download target="_blank">
              点击下载商业发票
            </a>
          </h1>

          <Row :gutter="80">
            <i-col span="10" offset="1">
              <div class="panel">
                <h3>商业发票明细</h3>
                <div>
                  <Data-table
                    class="table-blue"
                    hide-page
                    :when-fetch="
                      () => ({
                        data: info.purchases
                      })
                    "
                    :columns="columns"
                  />
                </div>
                <div class="total">
                  <Currency :value="totalPrice" type="£" />
                </div>
              </div>
            </i-col>
            <i-col span="11">
              <div class="panel">
                <h3>收件人信息</h3>
                <Row>
                  <i-col span="6">
                    <div class="title">收货人姓名</div>
                    <div>{{ info.recipient_name }}</div>
                  </i-col>
                  <i-col span="12">
                    <div class="title">身份证号码</div>
                    <div>{{ info.recipient_cid }}</div>
                  </i-col>
                  <i-col span="6">
                    <div class="title">手机号码</div>
                    <div>{{ info.recipient_phone }}</div>
                  </i-col>
                  <i-col span="6">
                    <div class="title">收货人国家/地区</div>
                    <div>{{ this.$ENUM.COUNTRY[info.recipient_country] }}</div>
                  </i-col>
                  <i-col span="6">
                    <div class="title">所在省</div>
                    <div>{{ info.recipient_province }}</div>
                  </i-col>
                  <i-col span="6">
                    <div class="title">所在市</div>
                    <div>{{ info.recipient_city }}</div>
                  </i-col>
                  <i-col span="6">
                    <div class="title">所在区</div>
                    <div>{{ info.recipient_county }}</div>
                  </i-col>
                  <i-col span="24">
                    <div class="title">详细地址</div>
                    <div>{{ info.recipient_street }}</div>
                  </i-col>
                </Row>
              </div>
            </i-col>
          </Row>
        </div>
      </div>

      <div class="express-info bg-gray">
        <div class="container">
          <Bind-s2c-account class="bind-account" />
          <FormItem label="请选择快递公司" prop="express_cmp">
            <Select v-model="model.express_cmp">
              <Option value="send2china">Send2china(UK)</Option>
              <Option value="other">其他</Option>
            </Select>
          </FormItem>

          <template v-if="model.express_cmp === 'send2china'">
            <FormItem
              class="weight"
              label="包裹计价重量"
              prop="weight"
              :rules="{ required: true }"
            >
              <Input v-model="model.weight" type="number" />
              <span>kg</span>
            </FormItem>
          </template>

          <template v-else>
            <FormItem
              label="快递公司"
              prop="express_name"
              :rules="{ required: true }"
            >
              <Input v-model="model.express_name" type="text" />
            </FormItem>
            <FormItem label="快递单号" prop="tracking_number">
              <Input v-model="model.tracking_number" type="text" />
            </FormItem>
            <FormItem label="上传快递单" prop="other_label">
              <div>
                <img v-if="show_label === true" :src="label_url" />
                <ExtUpload
                  ref="upload"
                  type="drag"
                  :format="['jpg', 'jpeg', 'png', 'gif', 'pdf']"
                  :on-format-error="() => $Message.error('请选择图片文件')"
                  :default-file-list="otherLabelDefaultList"
                  :show-upload-list="false"
                  :on-success="handleSuccess"
                  :data="upload_invoice_data"
                  name="invoice"
                  :action="$API_URL + '/api/order/upload_label/'"
                >
                  <div class="upload-list">
                    <Icon type="ios-camera" size="35" />
                  </div>
                </ExtUpload>
              </div>
            </FormItem>
            <FormItem label="上传报关单" prop="cn23_label">
              <div>
                <img v-if="show_cn23 === true" :src="cn23_url" />
                <ExtUpload
                  ref="upload"
                  type="drag"
                  :format="['jpg', 'jpeg', 'png', 'gif', 'pdf']"
                  :on-format-error="() => $Message.error('请选择图片文件')"
                  :default-file-list="cn23LabelDefaultList"
                  :show-upload-list="false"
                  :on-success="handleCN23Success"
                  :data="upload_cn23_data"
                  name="cn23"
                  :action="$API_URL + '/api/order/upload_label/'"
                >
                  <div class="upload-list">
                    <Icon type="ios-camera" size="35" />
                  </div>
                </ExtUpload>
              </div>
            </FormItem>
          </template>
        </div>
      </div>

      <div class="actions">
        <div class="container text-center">
          <Button type="info" @click="$router.go(-1)">返回</Button>
          <Button :loading="loading" type="primary" @click="handleSubmit"
            >确认提交</Button
          >
        </div>
      </div>
      <Modal
        v-model="visible"
        title="温馨提示"
        class="delivery-drawback-container"
        width="360px"
        @on-ok="okRedirect"
      >
        <p>
          1. 【包裹备注】:
          请务必正确填写对应物品iShopper物流订单号（示例：ISP01234567）,
          否则不予退税;
        </p>
        <p>
          2. 【邮费支付】: 请务必使用iShopper卡付款，否则不予退税.
        </p>
      </Modal>
    </div>
  </Form>
</template>

<script>
import { throttle } from 'lodash'

import BindS2cAccount from '~/components/BindS2cAccount'

export default {
  scrollToTop: true,
  components: {
    BindS2cAccount
  },
  data() {
    return {
      info: {},
      otherLabelDefaultList: [],
      cn23LabelDefaultList: [],
      orderSerivces: [],
      columns: [
        {
          title: '票据编号',
          key: 'item_id'
        },
        {
          title: '品牌(英文)',
          key: 'brand'
        },
        {
          title: '描述(英文)',
          key: 'desc'
        },
        {
          title: '金额',
          render: (_, { row }) => {
            return <Currency color="normal" value={row.price} />
          }
        }
      ],
      parcelPrice: 0,
      loading: false,
      model: {
        service: null,
        weight: null,
        express_cmp: 'send2china',
        return_method: 'dropoff',
        express_name: '',
        tracking_number: ''
      },
      visible: false,
      redirect_url: '',
      upload_invoice_data: {
        action: `upload_invoice`,
        order_id: this.$route.query.id
      },
      upload_cn23_data: {
        action: `upload_cn23`,
        order_id: this.$route.query.id
      },
      show_label: false,
      label_url: '',
      show_cn23: false,
      cn23_url: ''
    }
  },
  computed: {
    totalPrice() {
      return this.info.purchases.reduce((total, cur) => {
        return total + Number(cur.price)
      }, 0)
    },
    realModel() {
      const { info } = this

      return {
        recipient_name: info.recipient_name,
        recipient_cid: info.recipient_cid,
        recipient_phone: info.recipient_phone,
        recipient_province: info.recipient_province,
        recipient_city: info.recipient_city,
        recipient_county: info.recipient_county,
        recipient_street: info.recipient_street,
        express_cmp: info.express_cmp,
        ...this.model
      }
    }
  },
  async asyncData({ $api, route }) {
    const [info, orderSerivces] = await Promise.all([
      $api.fetchOrderDelivery(route.query.id),
      $api.fetchOrderServices()
    ])

    return {
      info,
      orderSerivces
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.info.express_cmp) {
        this.model.express_cmp = this.info.express_cmp
        this.model.express_name = this.info.express_name
        this.model.tracking_number = this.info.tracking_number
        this.model.weight = this.info.weight
      }
    })
  },
  methods: {
    countParcelPrice: throttle(async function(weight) {
      // 动态计算包裹价格
      const { price } = await this.$api.fetchOrderServicePrice({
        service_id: this.model.service,
        weight
      })

      this.parcelPrice = price
    }, 1500),
    handleSubmit() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          console.log('from not vaild')
          return
        }

        try {
          this.loading = true
          const msg = await this.$api.saveOrderDelivery(
            this.$route.query.id,
            this.realModel
          )
          console.log('redirect_url:')
          console.log(msg.redirect_url)
          if (msg.redirect_url) {
            this.redirect_url = msg.redirect_url
            this.visible = true
            // setTimeout(() => window.open(msg.redirect_url), 3000)
            // window.open(msg.redirect_url)
          } else {
            this.$router.go(-1)
          }
        } catch (err) {
          console.error(err)
        }
        this.loading = false
      })
    },
    okRedirect() {
      window.open(this.redirect_url)
      this.$router.replace(`/account/order?curTab=1`)
    },
    handleSuccess({ data }) {
      this.show_label = true
      this.label_url = data.other_label
    },
    handleCN23Success({ data }) {
      this.show_cn23 = true
      this.cn23_url = data.cn23_label
    }
  }
}
</script>

<style lang="less">
.account-order-delivery-container {
  padding: 50px 0 0;

  .order-info {
    .title-line {
      font-size: 20px;
      font-weight: normal;
      color: #3b3b3b;
      margin-bottom: 20px;

      & + h1 {
        font-size: 24px;
        font-weight: normal;
        color: #78b74a;
        text-align: center;
        border-bottom: 1px solid rgb(231, 231, 231);
        padding-bottom: 10px;
        margin-bottom: 30px;
      }
    }

    .panel {
      padding-bottom: 50px;
      h3 {
        font-size: 18px;
        font-weight: normal;
        color: #3b3b3b;
        text-align: center;
        margin-bottom: 20px;
      }

      .title {
        font-size: 16px;
        font-weight: normal;
        color: #a4a4a4;
        margin-bottom: 5px;
        & + div {
          margin-bottom: 25px;
        }
      }

      .total {
        text-align: right;
        margin-top: 10px;

        .currency-int {
          font-size: 22px;
        }

        .currency-decimal {
          font-size: 16px;
        }
      }
    }
  }

  .express-info {
    padding: 50px 0 20px;

    .container {
      width: 410px;
      position: relative;

      .bind-account {
        position: absolute;
        right: -220px;
      }
    }

    .weight {
      .ivu-form-item-content {
        white-space: nowrap;
        span {
          margin: 0 10px;
        }
      }
    }

    .currency-container {
      text-align: right;
      .currency-int {
        font-size: 24px;
      }

      .currency-decimal {
        font-size: 18px;
      }
    }
  }

  .actions {
    padding: 30px 0 100px;

    button {
      margin-left: 30px;
    }
  }
  .upload-list {
    width: 100%;
    height: 100%;
    display: block;
  }
}
</style>
