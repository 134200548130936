<template>
  <div class="account-balance-container">
    <Tabs v-model="curTab">
      <!-- 账户金额 -->
      <TabPane class="tab-balance" label="账户金额" name="overview">
        <div class="overview-container container">
          <div class="account-item">
            <div class="account-type">预定订单总值</div>
            <BalanceDeposit :value="balanceData.gbp_balance" />
          </div>
          <div class="account-item">
            <div class="account-type">未清算预定</div>
            <currency type="£" :value="balanceData.settlement_gbp_balance" />
          </div>
          <div class="account-item">
            <div class="account-type">推广奖金</div>
            <currency type="£" :value="balanceData.gbp_balance_fanyong" />
            <nuxt-link to="/account/balance/affiliate-detail">
              <Button type="info">查看明细</Button>
            </nuxt-link>
          </div>
        </div>

        <!-- <Deposit /> -->
        <!-- <div class="tips-container bg-gray">
          <div class="container">
            <h1><span>注意事项</span></h1>
            <ul>
              <li>
                iShopper账户的预付金额在支付成功后，需2个工作日才可达到iShopper账户（微信支付宝支付货款需额外考虑中国假期）
              </li>
              <li>
                iShopper账户金额提现到iShopper消费卡，可在发起申请1个工作日内到账
              </li>
              <li>
                iShopper在线支付仅支持人民币支付，支付成功后将自动根据实时汇率兑换成英镑到账
              </li>
              <li>
                如果ishopper消费卡余额为0，则需要用户通过将iShopper账户余额提现到消费卡才可进行订单或者商品的支付
              </li>
              <li>
                请警惕任何形式的钓鱼和虚假信息，谨防未经授权的人员或机构谎称为iShopper或代理，冒用iShopper商标或公司名称（语种）变体,
                以及合作者名字进行诈骗。
              </li>
              <li>
                对出现于任何未经iShopper授权的网站、门户网站或电子邮件上的内容或信息，所达成的任何交易或导致的和将要导致的任何性质和形式的损失与损害，iShopper将不承担任何责任。
              </li>
            </ul>
          </div>
        </div> -->
      </TabPane>

      <!-- 余额明细 -->
      <TabPane class="tab-detail" label="余额明细" name="detail">
        <div class="toolbar">
          <RadioGroup v-model="range" @on-change="handleRefreshDetail">
            <Radio
              v-for="rangeItem of rangeOptions"
              :key="rangeItem.name"
              :label="rangeItem.value"
            >
              {{ rangeItem.name }}
            </Radio>
          </RadioGroup>
          <DatePicker
            v-model="dateRange"
            format="yyyy/MM/dd"
            type="daterange"
            placement="bottom-end"
            placeholder="起始时间 - 结束时间"
            style="width: 250px"
            @on-change="handleRefreshDetail"
          />
          <Button
            style="margin-left:30px"
            icon="md-download"
            type="error"
            ghost
            @click="getThreeMonthData"
            >下载最近三个月的数据</Button
          >
          <Tooltip
            transfer
            max-width="200"
            content="如需下载更多记录，请联系我们的客服人员"
          >
            <Icon size="40" color="red" type="ios-help" />
          </Tooltip>
        </div>

        <div class="detail-table bg-gray">
          <div class="container">
            <DataTable
              ref="detailDataTable"
              class="table-blue"
              :when-fetch="fetchBalance"
              :columns="columns1"
            />
          </div>
        </div>
      </TabPane>

      <!-- 转账历史 -->
      <TabPane class="tab-detail" label="转账历史" name="transfer">
        <div class="toolbar" />

        <div class="detail-table bg-gray">
          <div class="container">
            <DataTable
              class="table-blue"
              :when-fetch="fetchTransferList"
              :columns="transferCols"
            />
          </div>
        </div>
      </TabPane>
    </Tabs>

    <PayOfTransferDialog
      :visible.sync="payOfTransferVisible"
      :number="number"
      :amount="claimed_amount"
      :click-call-back="dmcallBack"
    />
  </div>
</template>

<script>
import PayOfTransferDialog from '~/components/PayOfTransferDialog'
// import Deposit from '~/components/purchase/Deposit.vue'
import BalanceDeposit from '~/components/BalanceDeposit.vue'

export default {
  components: {
    PayOfTransferDialog,
    // Deposit,
    BalanceDeposit
  },
  head() {
    return {
      title: '爆款预定'
    }
  },
  data() {
    return {
      payOfTransferVisible: false,
      claimed_amount: '',
      number: '',
      curTab: 'overview',
      rangeOptions: [
        { name: '全部', value: '' },
        { name: '本日', value: 'day' },
        { name: '本周', value: 'week' },
        { name: '本月', value: 'month' },
        { name: '本年', value: 'year' }
      ],
      range: '',
      dateRange: [],
      columns1: [
        {
          title: 'ID',
          key: 'id',
          align: 'center',
          width: 80
        },
        {
          title: '日期',
          key: 'created_at',
          align: 'center',
          width: 120,
          render: (_, { row }) => {
            return (
              <span>
                {this.$dayjs(row.created_at).format('YYYY/MM/DD HH:mm')}
              </span>
            )
          }
        },
        {
          title: '账户种类',
          align: 'center',
          key: 'in_gbp',
          width: 100,
          render: (_, { row }) => {
            let tp = ''
            if (row.in_gbp) {
              tp = '英镑'
            } else if (row.in_cny) {
              tp = '人民币'
            }
            return <span>{tp}</span>
          }
        },
        {
          title: '金额',
          align: 'center',
          key: 'amount',
          render: (_, { row }) => {
            let tp = ''
            if (row.in_gbp) {
              tp = '£'
            } else if (row.in_cny) {
              tp = '￥'
            }
            return <Currency color="normal" value={row.amount} type={tp} />
          }
        },
        {
          title: '余额',
          align: 'center',
          key: 'balance',
          render: (_, { row }) => {
            let tp = ''
            if (row.in_gbp) {
              tp = '£'
            } else if (row.in_cny) {
              tp = '￥'
            }
            return this.$ENUM.PAY_METHOD[row.method] !== '余额' ? (
              <span>-</span>
            ) : (
              <Currency color="normal" value={row.balance} type={tp} />
            )
          }
        },
        {
          title: '待清算余额',
          align: 'center',
          key: 'settlement_balance',
          render: (_, { row }) => {
            let tp = ''
            if (row.in_gbp) {
              tp = '£'
            } else if (row.in_cny) {
              tp = '￥'
            }
            return (
              <Currency
                color="normal"
                value={row.settlement_balance}
                type={tp}
              />
            )
          }
        },
        {
          title: '支付方式',
          align: 'center',
          key: 'method',
          render: (_, { row }) => {
            return <span>{this.$ENUM.PAY_METHOD[row.method]}</span>
          }
        },
        {
          title: '方式',
          align: 'center',
          key: '',
          render: (_, { row }) => {
            let tp = ''
            if (row.amount < 0) {
              tp = '转出'
            } else {
              tp = '转入'
            }
            return <span>{tp}</span>
          }
        },
        {
          title: '清算状态',
          align: 'center',
          key: 'is_settle',
          render: (_, { row }) => {
            if (row.is_settle) {
              return <span>清算完成</span>
            } else {
              return <span>清算中</span>
            }
          }
        },
        {
          title: '备注',
          align: 'center',
          key: 'notes'
        }
      ],
      balanceData: {
        gbp_balance: 0,
        rmb_balance: 0,
        gbp_balance_fanyong: 0,
        rate: 0,
        data: []
      },
      transferCols: [
        {
          title: '编号',
          key: 'number',
          align: 'center',
          width: 225
        },
        {
          title: '转账发起时间',
          align: 'center',
          key: 'created_at',
          width: 180,
          render: (_, { row }) => {
            return (
              <span>
                {this.$dayjs(row.created_at).format('YYYY/MM/DD HH:mm:ss')}
              </span>
            )
          }
        },
        {
          title: '转账金额',
          align: 'center',
          width: 180,
          key: 'claimed_amount',
          render: (_, { row }) => {
            return (
              <Currency color="normal" value={row.claimed_amount} type="£" />
            )
          }
        },
        {
          title: '到账金额',
          align: 'center',
          key: 'real_amount',
          render: (_, { row }) => {
            return <Currency color="normal" value={row.real_amount} type="£" />
          }
        },
        {
          title: '状态',
          align: 'center',
          key: 'status',
          render: (_, { row }) => {
            return <span>{this.$ENUM.BANK_TRANSFER_STATUS[row.status]}</span>
          }
        },
        {
          title: '备注',
          key: 'note',
          align: 'center',
          width: 225
        },
        {
          title: '操作',
          align: 'center',
          render: (_, { row }) => {
            return (
              <div>
                <Button
                  onClick={() => this.updateSelectDialog(row)}
                  type="text"
                  size="small"
                >
                  转账信息
                </Button>
              </div>
            )
          }
        }
      ]
    }
  },
  async asyncData({ $api }) {
    const balanceData = await $api.fetchBalance()
    return {
      balanceData
    }
  },
  methods: {
    async getThreeMonthData() {
      const link = document.createElement('a')
      const res = await this.$api.exportBalance(
        {
          range: this.range,
          startDate: this.dateRange[0],
          endDate: this.dateRange[1]
        },
        { responseType: 'arraybuffer' }
      )

      const blob = new Blob([res], {
        type: 'application/vnd.ms-excel;charset=utf-8'
      })
      const objectUrl = URL.createObjectURL(blob) // 创建URL
      link.href = objectUrl
      link.download = '余额明细.xls' // 自定义文件名
      link.click() // 下载文件
      URL.revokeObjectURL(objectUrl) // 释放内存
    },
    async fetchBalance(page, perPage) {
      const data = await this.$api.fetchBalance({
        range: this.range,
        startDate: this.dateRange[0],
        endDate: this.dateRange[1],
        page,
        perPage
      })
      return data
    },
    handleRefreshDetail() {
      this.$nextTick(() => {
        this.$refs.detailDataTable.fetchData(1)
      })
    },
    async fetchTransferList(page, perPage) {
      const data = await this.$api.fetchBankTransfer({
        page,
        perPage
      })
      return data
    },
    updateSelectDialog(row) {
      this.payOfTransferVisible = true
      this.claimed_amount = row.claimed_amount
      this.number = row.number
    },
    dmcallBack() {}
  }
}
</script>

<style lang="less">
.account-balance-container {
  width: 100vw;
  padding: 30px 0 0;

  .ivu-tabs-bar {
    border: none;
  }

  .ivu-tabs-nav-scroll {
    display: flex;
    justify-content: center;
  }

  // 账户余额
  .tab-balance {
    .overview-container {
      padding: 30px 0 50px;
      display: flex;
      justify-content: center;

      .account-item {
        & + .account-item {
          margin-left: 150px;
        }

        .account-type {
          font-size: 16px;
          color: #a4a4a4;
        }

        button {
          margin-bottom: 15px;
        }
      }
    }

    .tips-container {
      padding: 70px 0;
      .container {
        width: 620px;
      }

      h1 {
        font-size: 24px;
        font-weight: normal;
        color: #ea5d35;
        text-align: center;
        margin-bottom: 40px;

        span {
          background: #f8f8f8;
          padding: 0 5px;
        }

        &::after {
          content: '';
          width: 100%;
          height: 1px;
          background: #e7e7e7;
          display: block;
          margin-top: -17px;
        }
      }

      ul {
        li {
          margin-bottom: 5px;
          &::before {
            width: 10px;
            content: '●';
            color: #fb5120;
            margin-left: -15px;
            display: inline-block;
          }
        }
      }

      .account-info {
        margin: 15px 0;
        border-top: 2px solid #92cbd8;
        border-bottom: 2px solid #92cbd8;
        display: flex;
        justify-content: space-between;
        padding: 10px 0;

        div div + div {
          margin-top: 5px;
        }
      }

      p {
        line-height: 1.8;
      }
    }
  }

  // 余额明细
  .tab-detail {
    .toolbar {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px 0;
      margin-bottom: 60px;

      .ivu-radio-group {
        margin-right: 40px;
      }
    }
    .detail-table {
      padding: 60px 0;

      .container {
        margin-top: -110px;
      }
    }
  }
}
</style>
