<template>
  <a
    class="r-button"
    :class="`r-${type} s-${size}`"
    :href="href"
    @click="onClick"
    ><slot
  /></a>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'default'
    },
    size: {
      type: String,
      default: 'default'
    },
    href: {
      type: String,
      default: 'javascript:;'
    }
  },
  methods: {
    onClick() {
      this.$emit('click')
    }
  }
}
</script>

<style lang="less">
.r-button {
  display: inline-flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  font-weight: 600;
  &.s-default {
    height: 32px;
    line-height: 32px;
    font-size: 14px;
    border-radius: 4px;
  }
  &.s-middle {
    height: 42px;
    line-height: 42px;
    font-size: 16px;
    border-radius: 6px;
  }
  &.s-large {
    height: 50px;
    line-height: 50px;
    font-size: 16px;
    border-radius: 6px;
  }

  &.r-default {
    border: solid 1px #e56131;
    color: #e56131;
    box-shadow: 0 2px 2px 0 rgba(229, 97, 49, 0.08);
    &:hover {
      border: solid 1px #222;
      color: #222;
    }
  }
  &.r-rdefault {
    border: solid 1px #222;
    color: #222;
    box-shadow: 0 2px 2px 0 rgba(229, 97, 49, 0.08);
    &:hover {
      border: solid 1px #e56131;
      color: #e56131;
    }
  }
  &.r-primary {
    color: #fff;
    background: #e56131;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.08);
    border: 1px solid #e56131;

    &:hover {
      background: #e77146;
      border: 1px solid #e77146;
    }
  }
  &.r-white {
    color: #e56131;
    background: #fff;
    border: 1px solid #fff;
  }
}
</style>
