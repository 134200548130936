<template>
  <div class="user-verify-step3">
    <div class="areegerment">
      <iframe ref="iframe" src="/t&c.html" @load="handleIframeLoad" />
    </div>
    <Form ref="form" :model="model" :rules="rules" class="sign-container">
      <div class="container">
        <div class="sign-title"><span>电子签名</span></div>
        <div class="sign-contents">
          <div>
            <h3>您的姓名:</h3>
            <FormItem prop="fullname">
              <Input
                v-model="model.fullname"
                placeholder="请输入您的姓名"
                @on-change="(e) => updateIframe('.mark-5', e.target.value)"
              />
            </FormItem>
          </div>
          <div>
            <h3>输入您的签名:</h3>
            <FormItem>
              <canvas />
            </FormItem>
          </div>
        </div>
        <div>
          <Checkbox v-model="ok_click">
            勾选此项确认您的签名为真实签名，如果使用虚假签名有可能使您在本站的权益失去法律保护</Checkbox
          >
        </div>
        <div class="mt-5">
          <Checkbox v-model="sign_ok_click">
            勾选此项确认同意iShopper使用您的签名，为您在iShopper平台发生的交易，生成您的签名票据</Checkbox
          >
        </div>
      </div>
    </Form>
    <div class="actions flex justify-between items-center">
      <a class="!text-[#2D8cF0] flex items-center" @click="customerService">
        <IconFont size="24" name="headset-help" />
        在线客服
      </a>

      <div>
        <Button type="info" @click="reSign">重新签名</Button>
        <Button
          :loading="loading"
          type="primary"
          :disabled="!ok_click || !sign_ok_click"
          @click="handleSubmit"
          >同意条款并提交</Button
        >
      </div>
    </div>
  </div>
</template>

<script>
import Draw from '~/components/Draw'
import { addressStringify } from '~/utils/index'

export default {
  inheritAttrs: false,
  props: {
    onSubmit: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      iframe: null,
      model: {
        fullname: null
      },
      rules: {
        fullname: {
          required: true,
          message: '请输入姓名',
          trigger: 'blur'
        }
      },
      loading: false,
      ok_click: false,
      sign_ok_click: false
    }
  },
  mounted() {
    this.$nextTick(() => {
      const canvas = document.querySelector('canvas')
      this.draw = new Draw(canvas)
    })

    // 更新基本信息
    this.$store.dispatch('updateUserDetail').then(this.updateDoc)

    const canvas = document.querySelector('canvas')

    // 更新签名
    canvas.addEventListener('mousemove', this.updateSign)

    this.$once('hook:beforeDestroy', () => {
      canvas.removeEventListener('mousemove', this.updateSign)
    })
  },

  methods: {
    customerService() {
      const user = this.$store.state.user
      if (user) {
        window._MEIQIA('metadata', {
          id: user.id,
          email: user.email,
          username: user.username,
          phone: user.phone
        })
      }
      window._MEIQIA('showPanel')
    },
    handleIframeLoad() {
      this.updateDoc()
    },
    reSign() {
      this.draw.clear()
    },
    updateSign() {
      try {
        const canvas = document.querySelector('canvas')
        this.$refs.iframe.contentDocument
          .querySelector('.user-sign')
          .setAttribute('src', canvas.toDataURL('image/png'))
      } catch (err) {
        console.error(err)
      }
    },
    updateIframe(selector, value) {
      try {
        this.$refs.iframe.contentDocument.querySelector(
          selector
        ).innerHTML = value
      } catch (err) {
        console.error(err)
      }
    },
    updateDoc() {
      const user = this.$store.state.user

      let info = []
      if (user.company_name) {
        info = [
          user.company_name,
          user.company_address,
          user.company_number,
          user.company_vat
        ]
      } else {
        info = [
          user.fullname || this.model.fullname,
          user.id_number,
          user.country,
          addressStringify(user),
          user.email,
          user.phone
        ]
      }

      info = info.filter(Boolean).join(',')

      this.updateIframe('.mark-2', info)
      this.updateIframe('.mark-3', info)
      this.updateIframe('.mark-4', `${user.default_rebate_rate}%`)
    },
    handleSubmit() {
      this.loading = true
      this.$refs.form.validate(async (isPass) => {
        if (!isPass) {
          this.loading = false
          return
        }

        const signFile = await fetch(this.draw.getPNGImage())
          .then((res) => res.blob())
          .then((buffer) => new Blob([buffer], { type: 'image/png' }))

        const formData = new FormData()
        formData.append('fullname', this.model.fullname)
        formData.append('signature', signFile)

        try {
          await this.$api.updateUserInfo(formData)
          await this.$api.confirmUserInfo({ action: 'confirm' })
          this.$store.commit('SET_USER', {
            ...this.$store.state.user,
            status: 0
          })
          this.onSubmit()
        } catch (err) {
          console.error(err)
        }

        this.loading = false
      })
    }
  }
}
</script>

<style lang="less">
.user-verify-step3 {
  .areegerment {
    width: 1000px;
    height: 600px;
    background-color: #ffffff;
    margin: 30px auto 0;
    color: #a7a7a7;
    line-height: 20px;
    font-size: 12px;
    position: relative;
    z-index: 1;
    border: 1px solid #ccc;

    iframe {
      width: 100%;
      height: 100%;
      border: none;
    }
  }

  .sign-container {
    background: #fafafa;
    margin-top: -20px;

    h3 {
      margin: 20px 0 20px;
    }

    .sign-title {
      font-size: 24px;
      color: #ea5d35;
      text-align: center;
      position: relative;

      span {
        position: relative;
        z-index: 1;
        padding: 0 10px;
        background: #fafafa;
      }

      &::after {
        content: '';
        width: 100%;
        height: 1px;
        position: absolute;
        top: 20px;
        left: 0;
        background: #e7e7e7;
      }
    }

    .sign-contents {
      display: flex;
      justify-content: space-between;
      text-align: center;
      padding: 0 60px;

      .ivu-input {
        width: 305px;
        text-align: center;
      }
    }

    .container {
      width: 800px;
      padding: 50px 0 30px;
    }
  }

  .actions {
    width: 800px;
    margin: 0 auto;
    padding: 30px 0;
    text-align: right;

    button {
      margin-left: 20px;
    }
  }

  canvas {
    width: 305px;
    height: 100px;
    background-color: #ffffff;
    border-radius: 20px;
    border: solid 1px #cccccc;
  }
}
</style>
