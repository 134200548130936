<script>
export default {
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  setup({ value }) {
    return () => value
  }
}
</script>
