<template>
  <div class="new-news-wrapper reversion">
    <RBanner
      bg-color="#fff7eb"
      :bg-imgae="require('../../assets/images/new-news/banner.png')"
      @click="customerService"
    >
      <template v-slot:title>全英唯一线上海淘自助退税平台</template>
      <template v-slot:subtitle>
        获取ishopper第一手最新资讯及折扣相关信息
      </template>
      <template v-slot:btn
        >联系客服了解
        <IconFont
          class="banner-arrow-right"
          size="28"
          color="#fff"
          name="arrow-right"
      /></template>
    </RBanner>
    <div class="new-news-content new-center-container">
      <h4 class="new-title">折扣资讯</h4>
      <div class="news-list flexRow">
        <div v-for="(item, i) in data" :key="i" class="news-item">
          <div class="news-item-box">
            <a :href="`/news/${item.id}`">
              <img v-lazy="item.cover" />
              <p class="date">
                {{ $dayjs(item.created_at).format('YYYY-MM-DD') }}
              </p>
              <h5>{{ item.title }}</h5>
            </a>
          </div>
        </div>
      </div>
      <div v-if="data.length < paginator.count" class="btn">
        <RButton size="middle" type="rdefault" @click="more"
          >查看更多<IconFont reverse="x" size="30" name="arrow-up"
        /></RButton>
      </div>
    </div>
  </div>
</template>

<script>
import IconFont from '../../iconfont'
import RBanner from '../../components/revision/RBanner'
import RButton from '../../components/revision/RButton'
export default {
  components: {
    RBanner,
    IconFont,
    RButton
  },
  head() {
    return {
      title: '折扣资讯'
    }
  },
  layout() {
    return 'new-default'
  },
  data() {
    return {
      isMobile: null,
      data: [],
      paginator: {}
    }
  },
  async asyncData({ $api }) {
    const newsList = await $api.fetchNews({ category: 3 })
    console.log(newsList)
    return newsList
  },
  mounted() {
    this.comMobile()
  },
  methods: {
    customerService() {
      const user = this.$store.state.user
      if (user) {
        window._MEIQIA('metadata', {
          id: user.id,
          email: user.email,
          username: user.username,
          phone: user.phone
        })
      }
      window._MEIQIA('showPanel')
    },
    comMobile() {
      const width = document.body.clientWidth
      this.isMobile = width <= 640
    },
    async more() {
      const { data, paginator } = await this.$api.fetchNews({
        category: 3,
        page: this.paginator.page + 1
      })
      this.data.push(data)
      this.paginator = paginator
    }
  }
}
</script>

<style lang="less">
.new-news-wrapper {
  .new-news-content {
    padding: 100px 14px;
    .new-title {
      margin-bottom: 70px;
    }
    .news-list {
      flex-wrap: wrap;
      .news-item {
        width: 350px;
        height: 350px;
        box-sizing: border-box;
        margin-bottom: 60px;

        &:not(:nth-child(3n)) {
          margin-right: 30px;
        }

        .news-item-box {
          border-radius: 6px;
          box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.08);
          border: solid 1px rgba(0, 0, 0, 0.1);
          background-color: #fff;
          transition: 0.35s, transform 0.35s;
          width: 100%;
          height: 100%;
          position: relative;
          overflow: hidden;

          img {
            width: 348px;
            height: 220px;
            border-radius: 6px;
            object-fit: cover;
          }
          p {
            color: #999;
            font-size: 14px;
            padding: 20px;
          }
          h5 {
            padding: 0 20px;
            font-size: 18px;
            font-weight: 500;
            color: #000;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }

        &:hover {
          .news-item-box {
            transform: translateY(-30px);
          }
        }
      }
    }
    .btn {
      text-align: center;
      a {
        width: 194px;
        .iconfont {
          font-weight: normal;
        }
      }
    }
  }
}
@media screen and (max-width: 640px) {
  .new-center-container {
    width: auto;
  }
  .new-news-wrapper {
    .new-news-content {
      padding: 40px 14px;
      .news-list {
        .news-item {
          width: 100%;
          height: 348px;
          margin-bottom: 20px;

          &:not(:nth-child(3n)) {
            margin-right: 0;
          }

          img {
            width: 100%;
            height: 217px;
          }
        }
      }
    }
  }
}
</style>
