<template>
  <nuxt-child />
</template>

<script>
export default {
  head() {
    return {
      title: '发货订单'
    }
  }
}
</script>
