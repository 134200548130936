<template>
  <div class="new-qa-wrapper reversion">
    <RBanner
      bg-color="#f6fbfd"
      :bg-imgae="require('../assets/images/new-qa/banner.png')"
    >
      <template v-slot:title>全英唯一线上海淘自助退税平台</template>
      <template v-slot:subtitle>
        如果您有任何疑问或建议，请联系我们为您提供帮助
      </template>
      <template v-slot:btn
        >联系客服支持
        <IconFont
          class="banner-arrow-right"
          size="28"
          color="#fff"
          name="arrow-right"
      /></template>
    </RBanner>
    <List
      :category-list="categoryList"
      :list="list"
      :current-id="currentId"
      @getCategory="getCategory"
      @showText="showText"
      @search="search"
    />
    <Kefu />
  </div>
</template>

<script>
import IconFont from '../iconfont'
import RBanner from '../components/revision/RBanner'
import List from '../components/qa/List'
import Kefu from '../components/qa/Kefu'
export default {
  components: {
    IconFont,
    RBanner,
    List,
    Kefu
  },
  head() {
    return {
      title: '常见问题'
    }
  },
  layout() {
    return 'new-default'
  },
  data() {
    return {
      isMobile: null,
      currentId: null,
      categoryList: [],
      array: [],
      list: []
    }
  },
  async asyncData({ $api }) {
    const { data: qaList } = await $api.fetchFQACate()

    const { data } = await $api.fetchFQA()
    const array = qaList.map((item) => {
      return {
        id: item.id,
        name: item.name,
        list: []
      }
    })
    data.forEach((item) => {
      array.forEach((a) => {
        if (a.id === item.faq_category.id) {
          a.list.push({ ...item, active: false, content: '' })
        }
      })
    })

    return {
      categoryList: qaList,
      array,
      list: array
    }
  },
  mounted() {
    this.comMobile()
  },
  methods: {
    comMobile() {
      const width = document.body.clientWidth
      this.isMobile = width <= 640
    },
    async showText(i, index, id, val) {
      if (
        this.list[i].list[index].content === '' &&
        this.list[i].list[index].active === false
      ) {
        const { data } = await this.$api.fetchFQA({ id })
        if (val) {
          this.list[i].list[index].content = data[0].content.replaceAll(
            val,
            `<span class="font-red">${val}</span>`
          )
        } else {
          this.list[i].list[index].content = data[0].content
        }
        this.list[i].list[index].active = !this.list[i].list[index].active
      } else {
        this.list[i].list[index].active = !this.list[i].list[index].active
      }
    },
    getCategory(id) {
      this.currentId = id
      this.array.forEach((item) => {
        if (item.id === id) {
          this.list = [item]
        }
      })
    },
    async search(val) {
      this.active = null
      const { data } = await this.$api.fetchFQA({
        q: val
      })
      if (val === '') {
        this.list = this.array
        return
      }
      if (data.length === 0) {
        this.list = []
        return
      }

      const list = this.categoryList.map((item) => {
        return {
          id: item.id,
          name: '',
          list: []
        }
      })
      data.forEach((item) => {
        list.forEach((category) => {
          if (item.faq_category.id === category.id) {
            category.list.push({
              ...item,
              title: item.title.includes(val)
                ? item.title.replaceAll(
                    val,
                    `<span class="font-red">${val}</span>`
                  )
                : item.title,
              content: '',
              active: false
            })
          }
        })
      })

      this.list = list
    }
  }
}
</script>

<style lang="less">
@media screen and (max-width: 640px) {
  .new-center-container {
    width: auto;
  }
}
</style>
