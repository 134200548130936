<template>
  <ArticleLayout color="#65b4bd" title="商业合作" :data="textData" />
</template>

<script>
import ArticleLayout from '~/components/ArticleLayout'
export default {
  layout: 'default',
  head() {
    return {
      title: '商业合作'
    }
  },
  components: {
    ArticleLayout
  },
  asyncData() {
    return {
      textData: '暂无内容'
    }
  }
}
</script>

<style lang="less"></style>
