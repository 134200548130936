<template>
  <img :src="url" alt="" @load="whenLoad" />
</template>

<script>
const CDN_URL = process.env.STATIC_CDN_URL

export default {
  props: {
    src: {
      type: String,
      required: true
    },
    process: {
      type: String,
      default: ''
    },
    whenLoad: {
      type: Function,
      default: () => {}
    }
  },
  computed: {
    url() {
      if (this.src.indexOf('http') === 0) {
        return this.src
      }

      const url = CDN_URL + this.src
      const process = this.process
      return `${url}${process ? `?image_process=${process}` : ''}`
    }
  },
  methods: {}
}
</script>

<style lang="scss"></style>
