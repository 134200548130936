<template>
  <Tooltip placement="top">
    <template v-slot:content>
      <p class="mb-[5px]">
        扫描二维码，通过手机拍照上传
      </p>
      <p>
        <qrcode
          :value="url"
          class="border block border-solid border-[#dcdee2] mt-[2px] rounded-[4px]"
          :options="{ width: 200 }"
        />
      </p>
    </template>
    <span class="text-center w-[80px]">
      <qrcode
        :value="url"
        class="border block border-dashed border-[#dcdee2] mt-[2px] rounded-[4px] w-[80px] h-[80px]"
        :options="{ width: 80 }"
      />
      <p><Icon type="ios-phone-portrait" />手机上传</p>
    </span>
  </Tooltip>
</template>

<script>
import { onMounted } from '@vue/composition-api'
import { useStore } from '~/use'
import { useWs } from '~/use/useWs'

export default {
  props: {
    update: {
      type: Function,
      required: true
    }
  },

  setup(props) {
    const store = useStore()
    const ws = useWs()

    const url = process.server
      ? ''
      : `${location.origin}/m/upload?token=${store.state.token}&uid=${store.state.user.id}`

    const getFileFromUrl = async (url, name, defaultType = 'image/jpeg') => {
      const response = await fetch(url)
      const data = await response.blob()
      return new File([data], name, {
        type: data.type || defaultType
      })
    }

    const startWS = () => {
      ws.emit('join_room', { room_id: store.state.user.id })
      ws.off('room_msg').on('room_msg', async ({ data, type }) => {
        if (type !== 'image' || document.hidden) {
          return
        }

        const file = await getFileFromUrl(data.url, `${data.name}`)
        props.update({ ...data, file })
      })
    }

    onMounted(() => {
      ws.on('connect', () => {
        startWS()
      })
    })

    return {
      url
    }
  }
}
</script>

<style lang="scss" scoped></style>
