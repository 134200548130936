<template>
  <div class="special-buttom flexRow">
    <span>开启海淘购物省钱之旅</span>
    <RButton href="/register" size="middle" type="primary">
      点击立即免费注册<IconFont size="20" name="arrow-right" color="#fff"
    /></RButton>
  </div>
</template>

<script>
import IconFont from '../../iconfont'
import RButton from '../revision/RButton'
export default {
  components: {
    IconFont,
    RButton
  },
  props: {
    isMobile: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="less">
.special-buttom {
  width: 418px;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  border-radius: 6px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  span {
    color: #e56131;
    font-weight: 600;
    flex: 1;
    text-align: center;
  }
  .r-button {
    font-weight: normal;
    .iconfont {
      margin-left: 10px;
      font-weight: normal;
    }
  }
}
@media screen and (max-width: 640px) {
  .special-buttom {
    width: 344px;
    margin: 0 auto;
    .r-button {
      width: 157px;
      font-size: 14px;
      font-weight: 500;
      padding: 0 10px;
      .iconfont {
        margin-left: 0;
      }
    }
  }
}
</style>
