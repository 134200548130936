<template>
  <div class="landing-wrapper">
    <LandingHeader />
    <nuxt class="landing-main" />
    <LandingFooter />
  </div>
</template>

<script>
import LandingHeader from '~/components/landing/LandingHeader'
import LandingFooter from '~/components/landing/LandingFooter'
export default {
  components: {
    LandingHeader,
    LandingFooter
  },
  head() {
    return {
      link: [
        {
          rel: 'canonical',
          href: 'https://www.ishopper.com' + this.$route.path
        }
      ]
    }
  }
}
</script>

<style lang="less">
.landing-wrapper {
  font-family: 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei',
    \\5fae\8f6f\96c5\9ed1, Arial, sans-serif;
  background: #f3f3f3;
  min-height: 100vh;
}
@media screen and (max-width: 640px) {
  .landing-wrapper {
    .container {
      width: 100%;
      padding: 13px;
    }
  }
}
</style>
