<template>
  <div class="index-adv-wrapper">
    <div
      class="i-adv-wrapper"
      :class="isMobile === false ? 'new-center-container' : ''"
    >
      <h3 class="new-title">我们的优势</h3>
      <div class="index-adv-content new-pb-60 flexRow">
        <div class="index-adv-item our">
          <h4>ISHOPPER</h4>
          <ul>
            <li class="flexRow">
              <span class="label">购物金额</span
              ><span class="content">金额限制低至£2</span>
            </li>
            <li class="flexRow">
              <span class="label">出入境</span
              ><span class="content">自消费90日内上传购物小票即可</span>
            </li>
            <li class="flexRow">
              <span class="label">退税比率</span
              ><span class="content">用户自选 最高可达20%</span>
            </li>
            <li class="flexRow">
              <span class="label">时间花费</span
              ><span class="content">在线退税 无需排队</span>
            </li>
            <li class="flexRow">
              <span class="label">退税手续费</span
              ><span class="content">无</span>
            </li>
            <li class="flexRow">
              <span class="label">货币兑换</span
              ><span class="content">实时汇率</span>
            </li>
            <li class="flexRow btn">
              <RButton href="/register" size="middle" type="primary"
                >立即免费注册</RButton
              >
            </li>
          </ul>
        </div>
        <div class="index-adv-item other">
          <h4>传统退税公司</h4>
          <ul>
            <li class="flexRow">
              <span class="label">购物金额</span
              ><span class="content">续介于£30~£250</span>
            </li>
            <li class="flexRow">
              <span class="label">出入境</span
              ><span class="content">必须出境日前90天之内发票</span>
            </li>
            <li class="flexRow sp">
              <span class="label">退税比率</span
              ><span class="content">£1000以上12%<br />£10000以上15%</span>
            </li>
            <li class="flexRow sp">
              <span class="label">时间花费</span>
              <span class="content"
                >在机场额外花费2-4小时<br />需现场查验，排队等待
              </span>
            </li>
            <li class="flexRow">
              <span class="label">退税手续费</span
              ><span class="content">每张小票收取£3-£7</span>
            </li>
            <li class="flexRow">
              <span class="label">货币兑换</span
              ><span class="content">退税汇率平均高于市场20%左右</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="index-adv-des">
      <div
        class="i-adv-des"
        :class="isMobile === false ? 'new-center-container' : ''"
      >
        <ul class="flexRow">
          <li>
            <h5>最快T+0</h5>
            <p>跨境支付立即到账</p>
          </li>
          <li>
            <h5>20%</h5>
            <p>退税金额高达</p>
          </li>
          <li>
            <h5>最快7天可退</h5>
            <p>定制化退税方案</p>
          </li>
          <li>
            <h5>3IN1</h5>
            <p>跨境支付 / 物流专家<br />退税法务团队</p>
          </li>
          <li>
            <h5>10种增值服务</h5>
            <p>海淘转运 / 大宗出口<br />海关事宜</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import RButton from '../../components/revision/RButton'
export default {
  components: {
    RButton
  },
  props: {
    isMobile: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="less">
.index-adv-wrapper {
  box-shadow: inset 0 -1px 0 0 rgba(229, 97, 49, 0.05);
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(251, 246, 244, 0.3)
  );
  padding-top: 100px;
  margin-bottom: 100px;
  .new-title {
    margin-bottom: 72px;
  }
  .index-adv-content {
    justify-content: center;
    .index-adv-item {
      width: 445px;
      padding: 40px 20px;
      border-radius: 6px;

      border: solid 1px rgba(0, 0, 0, 0.1);
      background-color: #fff;
      h4 {
        text-align: center;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      }
      ul {
        li {
          border-bottom: 1px solid rgba(0, 0, 0, 0.05);
          padding: 30px 0;
          justify-content: space-between;
          align-items: center;

          &.btn {
            border-bottom: 0;
            justify-content: center;
            a {
              width: 193px;
            }
          }
        }
      }
      &.our {
        margin-right: 30px;
        box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.1), inset 0 6px 0 0 #e56131;
        h4 {
          color: #e56131;
          font-size: 30px;
          font-weight: 600;
          padding-bottom: 20px;
        }
        ul {
          li {
            .label {
              color: #222;
              font-weight: 500;
            }
            .content {
              color: #e56131;
              font-weight: 500;
            }
          }
        }
      }
      &.other {
        box-sizing: border-box;
        box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.1), inset 0 6px 0 0 #222;
        margin-top: 72px;
        padding: 0 20px;
        height: 560px;
        overflow: hidden;
        h4 {
          padding: 30px 0 20px 0;
          font-weight: 600;
          color: #000;
        }
        ul {
          li {
            &.sp {
              padding: 20px 0;
            }
            .label {
              color: #999;
            }
            .content {
              color: #222;
            }
          }
        }
      }
    }
  }
  .index-adv-des {
    padding: 40px 0 60px 0;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    ul {
      justify-content: space-between;
      li {
        box-sizing: border-box;
        padding-left: 40px;
        width: 20%;
        &:not(:last-child) {
          border-right: 1px solid rgba(0, 0, 0, 0.05);
        }
        h5 {
          font-size: 18px;
          color: #e56131;
          margin-bottom: 10px;
        }
        p {
          font-size: 14px;
          color: #222;
        }
      }
    }
  }
}
@media screen and (max-width: 640px) {
  .index-adv-wrapper {
    padding-top: 40px;
    margin-bottom: 40px;
    .new-title {
      margin-bottom: 52px;
    }
    .index-adv-content {
      flex-direction: column;
      padding: 14px;
      .index-adv-item {
        width: 100%;
        margin: 0 auto;
        &.other {
          margin-top: 20px;
        }
      }
    }
    .index-adv-des {
      ul {
        flex-wrap: wrap;
        li {
          width: 50%;
          &:not(:last-child) {
            margin-bottom: 40px;
          }
        }
      }
    }
  }
}
</style>
