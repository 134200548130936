<template>
  <div class="address-picker-container">
    <slot :toggle="toggle">
      <Button type="primary" @click="toggle(true)">调用地址簿</Button>
    </slot>

    <Modal
      v-model="visible"
      :mask-closable="false"
      width="1000px"
      class="address-picker-dialog"
      title="地址簿"
    >
      <div class="actions">
        <div class="action-search">
          <Input
            v-model="keyword"
            size="small"
            style="width:240px;"
            search
            placeholder="搜索地址簿"
            @on-search="handleSearch"
          />
        </div>
        <div class="action-actions">
          <Button size="small" type="primary" @click="showEditor(null)">
            新增地址
          </Button>
        </div>
      </div>

      <DataTable
        ref="dataTable"
        :when-fetch="fetchAddressList"
        :columns="columns"
      />
    </Modal>

    <AddressEditorDialog
      v-model="editorVisible"
      :on-success="handleEditorSuccess"
      :model="editorModel"
    />
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'
import AddressEditorDialog from './AddressEditorDialog.vue'

const getModelTmp = (extra) => ({
  id: '',
  name: '',
  phone: null,
  cid: null,
  postcode: null,
  country: 'CN',
  province: null,
  city: null,
  county: null,
  street1: null,
  street2: null,
  street3: null,
  ...extra
})

export default {
  components: {
    AddressEditorDialog
  },
  props: {
    type: {
      type: String,
      default: 'receiver'
    },
    onSelect: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      keyword: '',
      visible: false,
      editorVisible: false,
      editorModel: getModelTmp({ ptype: this.type }),
      countries: []
    }
  },
  computed: {
    columns() {
      const columns = [
        {
          title: '姓名',
          key: 'name',
          width: 100
        },
        {
          title: '身份证号码',
          key: 'cid',
          width: 180,
          render: (_, { row }) => {
            return <span>{row.country === 'CN' ? row.cid || '-' : '-'}</span>
          }
        },
        {
          title: '手机号码',
          key: 'phone',
          width: 150
        },
        {
          title: '地址',
          render: (_, { row }) => {
            return <Address v-model={row} textMode />
          }
        },
        {
          title: '操作',
          width: 200,
          align: 'center',
          render: (_, { row }) => {
            return (
              <span>
                <Button
                  ghost
                  onClick={() => this.showEditor(row)}
                  type="primary"
                  size="small"
                >
                  编辑
                </Button>

                <Button
                  style={{ margin: '0 0 0 8px' }}
                  onClick={() => this.handleSelect(row)}
                  type="primary"
                  size="small"
                >
                  选择
                </Button>
              </span>
            )
          }
        }
      ]

      return columns
    }
  },
  async mounted() {
    const { data } = await this.$api.fetchCountries()
    this.countries = data
  },
  methods: {
    handleSelect(item) {
      this.onSelect(item)
      this.visible = false
    },
    showEditor(item) {
      this.editorModel = cloneDeep(item) || getModelTmp({ ptype: this.type })
      this.editorVisible = true
    },
    handleEditorSuccess() {
      this.$refs.dataTable.refresh()
    },
    getColByCN() {
      return [
        {
          title: '所在国家/地区',
          conditionRender(row) {
            return {
              text: () => row.country,
              form: {
                rules: {
                  required: true,
                  trigger: 'change',
                  message: '请选择所在国家/地区'
                },
                node: (
                  <Select
                    v-model={row.country}
                    onInput={() => this.handleCountry(row)}
                  >
                    {this.countries.map((item) => (
                      <Option value={item.code} key={item.code}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                )
              }
            }
          }
        }
      ]
    },
    getColByOther() {
      return [
        {
          title: '所在国家/地区',
          conditionRender(row) {
            return {
              text: () => row.country,
              form: {
                rules: {
                  required: true,
                  trigger: 'change',
                  message: '请选择所在国家/地区'
                },
                node: (
                  <Select
                    v-model={row.country}
                    onInput={() => this.handleCountry(row)}
                  >
                    {this.countries.map((item) => (
                      <Option value={item.code} key={item.code}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                )
              }
            }
          }
        }
      ]
    },
    handleCountry(row) {
      row.province = null
      row.city = null
      row.postcode = null
      row.county = null
      row.street1 = null
      row.street2 = null
      row.street3 = null
    },
    toggle(visible) {
      this.visible = visible
    },
    handleSearch() {
      this.$refs.dataTable.fetchData(1)
    },
    async fetchAddressList(page, perPage) {
      const data = await this.$api.fetchAddressList({
        q: this.keyword,
        ptype: this.type,
        page,
        perPage
      })

      return data
    }
  }
}
</script>

<style lang="less">
.address-picker-dialog {
  .ivu-modal-body {
    padding: 0;

    .pager {
      padding: 16px;
    }
  }

  .ivu-modal-footer {
    display: none;
  }

  .actions {
    display: flex;
    justify-content: space-between;
    padding: 12px 15px;
  }

  .ivu-form-item-label {
    display: none;
  }

  .ivu-form-item-content {
    margin: 0 !important;
  }

  .ivu-table-wrapper,
  .ivu-table,
  .ivu-table-cell {
    overflow: initial;
  }
}
</style>
