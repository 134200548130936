<template>
  <div />
</template>

<script>
import { onMounted } from '@vue/composition-api'
import { useVm } from '@/use'

export default {
  layout() {
    return 'new-login'
  },
  head() {
    return {
      title: '退出登录'
    }
  },
  setup() {
    const vm = useVm()

    onMounted(async () => {
      if (vm.$store.state.token) {
        await vm.$api.logout()
      }

      vm.$store.commit('SET_TOKEN', null)
      vm.$store.commit('SET_USER', null)
      this.$cookies.remove('token', { path: '/' })
      this.$cookies.remove('token', {
        path: '/',
        domain: location.hostname
          .split('.')
          .slice(-2)
          .join('.')
      })
      vm.$router.replace('/login')

      this.$sentry && this.$sentry.setUser(null)
      window.dataLayer.push({
        user_id: undefined
      })
    })

    return {}
  }
}
</script>

<style lang="scss" scoped></style>
