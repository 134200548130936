<template>
  <Select
    v-model="localValue"
    transfer-class-name="load-more-select"
    filterable
    placeholder="请选择"
  >
    <Option
      v-for="(item, index) of items"
      :key="item.id || index"
      :value="item.value"
      :label="item.value"
    >
      <span v-if="!rowOptions">{{ item.label }}</span>
      <ul v-else class="load-more-select-option-list">
        <li
          v-for="(rowItem, rowIndex) of rowOptions"
          :key="rowIndex"
          :style="`width:${rowItem.width ? rowItem.width + 'px' : 'auto'}`"
        >
          <div class="row-title">{{ rowItem.label }}</div>
          <NodeRender :value="rowItem.render(item)" />
        </li>
      </ul>
    </Option>
    <Option
      disabled
      value="_more"
      class="select-loadmore"
      :class="{ active: pageParams.hasNext }"
      @click.native="handleLoadMore"
    >
      {{
        loading ? '加载中..' : pageParams.hasNext ? '加载更多' : '没有更多了'
      }}
    </Option>
  </Select>
</template>

<script>
import { toRefs, ref } from '@vue/composition-api'
import { useSyncValue } from '@/use'
import NodeRender from '~/components/NodeRender'

export default {
  components: {
    NodeRender
  },
  props: {
    value: {
      type: null,
      required: true
    },
    rowOptions: {
      type: Array,
      default: null
    },
    onFetch: {
      type: Function,
      required: true
    }
  },
  setup(props) {
    const refProps = toRefs(props)
    const [localValue] = useSyncValue(refProps.value)
    const items = ref([])
    const loading = ref(false)
    const pageParams = {
      page: 1,
      perpage: 10,
      q: '',
      hasNext: true
    }

    const handleFetch = async (params) => {
      if (loading.value || !pageParams.hasNext) {
        return
      }

      loading.value = true
      try {
        Object.assign(pageParams, params)
        const { data, total } = await props.onFetch(pageParams)

        items.value.push(...data)
        pageParams.hasNext =
          Number(pageParams.page) * Number(pageParams.perpage) < total
      } catch (err) {
        console.error(err)
      }
      loading.value = false
    }

    const handleLoadMore = () => {
      handleFetch({ ...pageParams, page: pageParams.page + 1 })
    }

    const init = () => {
      handleFetch(pageParams)
    }

    init()

    return {
      pageParams,
      handleLoadMore,
      localValue,
      items,
      loading
    }
  }
}
</script>

<style lang="less">
.select-loadmore {
  cursor: default !important;
  text-align: center;
  transition: 0.3s;

  &:hover {
    opacity: 0.7;
  }

  &.active {
    color: #fb5120;
  }
}

.ivu-select-dropdown {
  max-height: 400px;
}

.load-more-select-option-list {
  display: flex;
  justify-content: space-between;
  align-items: center;

  li {
    min-width: 20px;
    text-align: right;
    padding: 0 10px;
    overflow: hidden;
    text-overflow: ellipsis;

    span {
      width: 100%;
    }

    &:first-child {
      border-right: 1px dashed #eee;
      text-align: left;
    }

    .row-title {
      font-size: 12px;
      height: 18px;
      color: #999;
      margin: 0 0 5px 0;
    }
  }
}
</style>
