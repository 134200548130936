<template>
  <div class="landing-header">
    <div class="landing-center container flexRow">
      <div class="landing-logo">
        <img src="../../assets/images/landing/logo_white.svg" />
      </div>
      <Button class="landing-header-back" shape="circle" to="/"
        >返回首页</Button
      >
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less">
.landing-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  .landing-center {
    height: 94px;
    align-items: center;
    justify-content: space-between;
    .landing-logo {
      img {
        width: 160px;
        height: 54px;
      }
    }
    .landing-header-back {
      background: #fff;
      border: 1px solid #000000;
      color: #000;
      width: 120px;
      border-radius: 6px;
      font-weight: bold;
      box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.08);

      &:hover {
        background: #000;
        color: #fff;
      }
    }
  }
}
@media screen and (max-width: 640px) {
  .landing-header {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  }
}
</style>
