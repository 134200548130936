<template>
  <Modal
    v-model="visible"
    width="550px"
    class-name="express-dialog-container"
    title="请输入发票物流单号"
    :mask-closable="false"
    @cancel="setVisible(false)"
  >
    <Form ref="formRef" :model="model">
      <Table class="table-blue" :columns="columns" :data="data">
        <template slot="action" slot-scope="{ index }">
          <div
            class="action"
            :class="{ 'copy-active': !!model.data[index].express_number }"
          >
            <Button
              v-clipboard:copy="model.data[index].express_number"
              v-clipboard:success="() => $Message.success(`已复制`)"
              class="copy-btn"
              type="primary"
              size="small"
            >
              复制
            </Button>
            <FormItem
              :prop="`data.${index}.express_number`"
              style="margin:0;"
              :rules="{
                required: true,
                trigger: 'blur',
                message: '请输入物流单号'
              }"
            >
              <Input
                v-model="model.data[index].express_number"
                size="small"
                placeholder="请输入物流单号"
              />
            </FormItem>
          </div>
        </template>
      </Table>
    </Form>
    <div slot="footer">
      <Button type="primary" :loading="loading" @click="handleSubmit">
        确认提交
      </Button>
    </div>
  </Modal>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import { toRefs, ref, watch } from '@vue/composition-api'
import { useSyncValue, useVm } from '@/use'

export default {
  props: {
    value: {
      type: Boolean,
      required: true
    },
    data: {
      type: Array,
      required: true
    },
    whenSuccess: {
      type: Function,
      default: () => {}
    }
  },
  setup(props) {
    const vm = useVm()
    const refProps = toRefs(props)
    const [visible, setVisible] = useSyncValue(refProps.value)
    const loading = ref(false)
    const model = ref({ data: cloneDeep(props.data) })
    const formRef = ref(null)

    watch(
      props,
      ({ data }) => {
        model.value = { data: cloneDeep(data) }
        formRef.value?.resetFields()
      },
      { immediate: true }
    )

    const columns = [
      {
        title: 'id',
        key: 'id'
      },
      {
        title: '票据号',
        key: 'invoice_number'
      },
      {
        width: 200,
        title: '票据物流单号',
        slot: 'action'
      }
    ]

    const handleSubmit = async () => {
      if (!(await formRef.value.validate())) {
        vm.$Message.error('请输入物流单号')
        return
      }

      loading.value = true
      try {
        const items = model.value.data.map((item) => ({
          purchase_id: item.id,
          express_number: item.express_number
        }))
        await vm.$api.updatePuchase({
          action: 'list_upload_express_number',
          items
        })
        vm.$Message.success('票据物流信息提交成功，请耐心等待！')
        setVisible(false)
        props.whenSuccess()
      } catch (err) {
        console.error(err)
      }
      loading.value = false
    }

    return {
      visible,
      setVisible,
      handleSubmit,
      loading,
      formRef,
      model,
      columns
    }
  }
}
</script>

<style lang="less">
.express-dialog-container {
  .action {
    position: relative;

    &.copy-active:hover {
      input {
        padding-right: 70px;
      }
      .copy-btn {
        display: block;
      }
    }

    .copy-btn {
      position: absolute;
      right: 0;
      top: 1px;
      z-index: 1;
      display: none;
    }
  }
}
</style>
