<template>
  <Form class="account-cards-detail-container">
    <div class="container overview-container">
      <ul>
        <li v-for="item of overview" :key="item.name">
          <div class="name">{{ item.name }}</div>
          <div class="value">{{ item.value }}</div>
        </li>
      </ul>
    </div>

    <div class="table-container bg-gray">
      <div class="container">
        <h1>
          <span>
            <nuxt-link to="/account/cards">
              <Button type="info">返 回</Button>
            </nuxt-link>
          </span>
          <span class="title">消费记录</span>
          <span>
            <!-- <nuxt-link
              v-if="cardInfo.status != 2"
              :to="'/account/cards/transfer?id=' + card_id"
            >
              <Button type="primary">转入消费款</Button>
            </nuxt-link> -->
          </span>
        </h1>
        <DataTable
          class="table-blue"
          :when-fetch="fetchCardRecord"
          :columns="columns"
        />
      </div>
    </div>
  </Form>
</template>

<script>
export default {
  data() {
    return {
      overview: [],
      columns: [
        {
          title: '时间',
          key: 'booking_datetime',
          render: (_, { row }) => {
            return (
              <span>
                {this.$dayjs(row.booking_datetime).format(
                  'YYYY/MM/DD HH:mm:ss'
                )}
              </span>
            )
          }
        },
        {
          title: '金额',
          key: 'amount',
          render: (_, { row }) => {
            if (row.record_type === 1) {
              return <Currency color="normal" value={row.amount} type="£" />
            } else {
              return <Currency color="normal" value={-row.amount} type="£" />
            }
          }
        },
        {
          title: '类型',
          width: 100,
          align: 'center',
          key: 'record_type',
          render: (_, { row }) => {
            return <span>{this.$ENUM.CARD_RECORD_TYPE[row.record_type]}</span>
          }
        },
        {
          title: '状态',
          width: 100,
          align: 'center',
          key: 'status',
          render: (_, { row }) => {
            return <span>{this.$ENUM.CARD_RECORD_STATUS[row.status]}</span>
          }
        },
        {
          title: '备注',
          key: 'notes'
        }
      ],
      card_id: 0,
      cardInfo: {}
    }
  },
  async asyncData({ $api, route }) {
    const { data: cards } = await $api.fetchCard({ card_id: route.query.id })
    const cardInfo = cards[0]
    return {
      cardInfo
    }
  },
  methods: {
    async fetchCardRecord(page, perPage) {
      const data = await this.$api.fetchCardRecord({
        page,
        perPage,
        card_id: this.$route.query.id
      })
      this.card_id = this.$route.query.id
      const cardInfo = data.data[0].card
      this.overview = [
        { name: '卡号', value: cardInfo.number },
        {
          name: '持卡人姓名',
          value: cardInfo.name
        },
        {
          name: '状态',
          value: this.$ENUM.CARD_STATUS[cardInfo.status]
        },
        {
          name: '有效期开始',
          value: cardInfo.vaild_from ? cardInfo.vaild_from : '-'
        },
        {
          name: '有效期结束',
          value: cardInfo.vaild_end ? cardInfo.vaild_end : '-'
        },
        {
          name: '创建时间',
          value: this.$dayjs(cardInfo.created_at).format('YYYY/MM/DD')
        }
      ]

      return data
    }
  }
}
</script>

<style lang="less">
.account-cards-detail-container {
  .overview-container {
    padding: 50px 10px 30px;

    ul {
      display: flex;
      padding: 0 10px;
      li {
        margin-right: 15px;
        flex: auto;

        .name {
          font-size: 16px;
          color: #a4a4a4;
          margin-bottom: 10px;
        }

        .value {
          font-size: 20px;
          color: #3b3b3b;
        }
      }
    }
  }
  .table-container {
    padding: 30px 0 80px;

    h1 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      .title {
        font-size: 24px;
        font-weight: normal;
        color: #3b3b3b;
      }
    }
  }
}
</style>
