<template>
  <Modal
    v-model="localVisible"
    :mask-closable="false"
    class="pay-of-transfer"
    title="英镑转账"
    ok-text="完成"
    cancel-text=""
    @on-ok="okButton"
  >
    <h3 class="">1.请登录您的银行账户</h3>
    <h3 class="">2.输入以下准确信息，进行银行转账</h3>

    <ul class="pay-info">
      <li v-for="item of info" :key="item.title">
        <div>
          <div class="item-title">{{ item.title }}</div>
          <div class="item-desc">{{ item.value }}</div>
        </div>
        <div>
          <Button
            v-clipboard:copy="item.value"
            v-clipboard:success="() => $Message.success(`已复制`)"
            type="primary"
            size="small"
          >
            COPY
          </Button>
        </div>
      </li>
    </ul>
  </Modal>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean
    },
    number: {
      type: String,
      required: true
    },
    amount: {
      type: [Number, String],
      required: true
    },
    clickCallBack: {
      // 定义一个外来方法
      type: Function, // 参数类型：函数
      required: true // 是否必填：是
    }
  },
  data() {
    return {}
  },

  computed: {
    info() {
      return [
        {
          title: 'Transfer to',
          value: 'Oriental Bu Trading Ltd'
        },
        {
          title: 'Sort code',
          value: '40-50-37'
        },
        {
          title: 'Account number',
          value: '90053849'
        },
        {
          title: 'Amount to transfer',
          value: '£' + this.amount
        },
        {
          title: 'Exact refernce number',
          value: this.number
        }
      ]
    },
    localVisible: {
      get() {
        return this.visible
      },
      set(value) {
        this.$emit('update:visible', value)
      }
    }
  },
  methods: {
    okButton() {
      this.$props.clickCallBack()
    }
  }
}
</script>

<style lang="less">
.pay-of-transfer {
  h3 {
    margin-bottom: 20px;
  }

  .pay-info {
    background: #f1f1f1;
    padding: 15px 20px;

    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      .item-title {
        color: #555;
      }
      .item-desc {
        color: #333;
        font-weight: bold;
        font-size: 16px;
      }
    }
  }
}
</style>
