<template>
  <div class="bussess-content">
    <BussinessStep1 />
    <BussinessStep2 />
    <BussinessStep3 />
    <BussinessStep4 />
    <BussinessStep5 />
    <BussinessStep6 />
  </div>
</template>

<script>
import BussinessStep1 from './components/BussinessStep1'
import BussinessStep2 from './components/BussinessStep2'
import BussinessStep3 from './components/BussinessStep3'
import BussinessStep4 from './components/BussinessStep4'
import BussinessStep5 from './components/BussinessStep5'
import BussinessStep6 from './components/BussinessStep6'
export default {
  layout() {
    return 'landing'
  },
  components: {
    BussinessStep1,
    BussinessStep2,
    BussinessStep3,
    BussinessStep4,
    BussinessStep5,
    BussinessStep6
  },
  mounted() {
    ;[...this.$el.querySelectorAll('a')].forEach((a) => {
      a.href = `${a.href}${location.search}`
    })
  }
}
</script>

<style lang="less">
.bussess-content {
  color: #000;
  .title {
    text-align: center;
    h3 {
      line-height: 42px;
      font-size: 30px;
      font-weight: 500;
    }
    .title-bottom-border {
      display: inline-block;
      width: 70px;
      height: 2px;
      background: #e56131;
      border-radius: 3px;
    }
  }
  .border-title {
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    text-align: center;
  }
}
</style>
