<template>
  <ul class="goods-info-items">
    <li>
      <h3>{{ purchaseListText }}</h3>
      <FlatSelect
        v-model="invoices.value"
        :options="invoices.options"
        @change="(id, isSelect) => fetchInvoiceDetail(id, isSelect)"
      >
        <template v-slot="{ data }">
          <PurchaseReview :data="data" />
        </template>
      </FlatSelect>
      <div class="btns">
        <Button
          :disabled="!invoices.options.length"
          type="primary"
          :loading="allInvoiceLoading"
          @click="handleAllInvoice(invoices.options)"
        >
          全部添加
        </Button>
      </div>
    </li>
    <li>
      <h3>{{ purchaseDetailText }}</h3>
      <div style="position:relative">
        <FlatSelect
          v-model="invoicesDetail.value"
          multiple
          :options="invoicesDetail.options"
        >
          <span class="i-icon-shopping-cart" />
        </FlatSelect>
        <Spin v-show="detailLoading" fix />
      </div>
      <div class="btns">
        <Button
          :disabled="!invoicesDetail.options.length"
          type="primary"
          @click="handleAllSelect"
        >
          全部添加
        </Button>
        <Button
          :disabled="!invoicesDetail.value.length"
          type="primary"
          @click="handleSelect(invoicesDetail.value)"
        >
          添加选中
        </Button>
      </div>
    </li>
    <li>
      <h3>{{ purchaseSelectedText }}</h3>
      <FlatSelect
        class="selected-items"
        :options="selectedItems.options"
        @change="handleRemove"
      >
        <span class="i-icon-shopping-cart" />
        <template #right>
          <Icon type="ios-trash" />
        </template>
      </FlatSelect>
      <div class="summary">
        <Currency :value="selectTotal" type="£" />
        <Poptip transfer confirm title="确定清空吗?" @on-ok="handleRemoveAll">
          <a href="#0">
            <Icon type="ios-trash" />
          </a>
        </Poptip>
      </div>
    </li>
  </ul>
</template>

<script>
import { ref, onMounted, computed } from '@vue/composition-api'
import { useVm } from '@/use'
import FlatSelect from '~/components/FlatSelect'
import PurchaseReview from '~/components/purchase/PurchaseReview'

export default {
  components: {
    FlatSelect,
    PurchaseReview
  },
  props: {
    purchaseListText: {
      type: String,
      default: '采购小票'
    },
    purchaseDetailText: {
      type: String,
      default: '采购详情'
    },
    purchaseSelectedText: {
      type: String,
      default: '发货商品'
    },
    query: {
      type: Object,
      default: () => ({})
    },
    selectedItems: {
      type: Object,
      required: true
    },
    updateSelectedItems: {
      type: Function,
      default: () => {}
    },
    updateInvoices: {
      type: Function,
      default: () => {}
    },
    filterInvoices: {
      type: Function,
      default: () => true
    }
  },
  setup(props) {
    const vm = useVm()
    const detailCache = {}
    const detailLoading = ref(false)
    const allInvoiceLoading = ref(false)
    const invoices = ref({
      options: [],
      value: []
    })
    const invoicesDetail = ref({
      options: [],
      value: []
    })

    const fetchInvoices = async () => {
      const { data } = await vm.$api.fetchPuchaseOrder({
        ...props.query
      })

      invoices.value.options = data
        .filter(props.filterInvoices)
        .map((item) => ({
          ...item,
          label: `${item.invoice_number} - £${item.amount}`,
          value: item.id
        }))

      props.updateInvoices(invoices.value)
    }

    const fetchInvoiceDetail = async (id, isSelect, callback = () => {}) => {
      if (!isSelect) {
        invoicesDetail.value.options = []
        invoicesDetail.value.value = []
        return
      }

      detailLoading.value = true
      const items = detailCache[id] || []
      try {
        if (!items.length) {
          const res = await vm.$api.fetchPuchaseOrderDetail({
            id,
            ...props.query
          })
          items.push(...res.items)
          detailCache[id] = res.items
        }

        invoicesDetail.value.options = items.map((item) => ({
          ...item,
          label: `${item.brand || '未知品牌'} - £${item.price}`,
          value: item.id
        }))
        callback()
      } catch (err) {
        console.error(err)
      }
      detailLoading.value = false
    }

    const handleAllInvoice = async (items) => {
      allInvoiceLoading.value = true
      try {
        await Promise.all(
          items.map((item) => {
            return fetchInvoiceDetail(item.id, true, handleAllSelect)
          })
        )
      } catch (err) {
        console.error(err)
      }

      invoicesDetail.value.options = []
      invoicesDetail.value.value = []
      invoices.value.value = null
      allInvoiceLoading.value = false
    }

    const handleSelect = (goodsIds) => {
      const fitlerIds = goodsIds.filter((id) =>
        props.selectedItems.options.every((item) => item.id !== id)
      )

      const options = invoicesDetail.value.options.filter((item) =>
        fitlerIds.includes(item.id)
      )

      props.selectedItems.options.push(...options)
      updateSelectedItems(props.selectedItems)
    }

    const handleAllSelect = () => {
      handleSelect(invoicesDetail.value.options.map((item) => item.value))
    }

    const updateSelectedItems = (newValue) => {
      props.updateSelectedItems(newValue)
    }

    const handleRemove = (id) => {
      props.selectedItems.options = props.selectedItems.options.filter(
        (item) => item.id !== id
      )
      updateSelectedItems(props.selectedItems)
    }

    const handleRemoveAll = () => {
      updateSelectedItems({
        value: null,
        options: []
      })
    }

    const selectTotal = computed(() => {
      return props.selectedItems.options.reduce((total, current) => {
        return total + Number(current.price)
      }, 0)
    })

    onMounted(() => {
      fetchInvoices()
    })

    return {
      handleSelect,
      handleAllSelect,
      invoices,
      selectTotal,
      handleRemoveAll,
      handleRemove,
      allInvoiceLoading,
      handleAllInvoice,
      detailLoading,
      fetchInvoiceDetail,
      invoicesDetail
    }
  }
}
</script>

<style lang="less"></style>
