import { render, staticRenderFns } from "./BussinessStep6.vue?vue&type=template&id=012d9aea&"
import script from "./BussinessStep6.vue?vue&type=script&lang=js&"
export * from "./BussinessStep6.vue?vue&type=script&lang=js&"
import style0 from "./BussinessStep6.vue?vue&type=style&index=0&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports