<template>
  <div class="login-wrapper reversion flexRow" :class="{ lite: lite }">
    <div class="login-box-wrapper">
      <div class="logo new-mb-40">
        <a href="/"><img src="../assets/images/index/logo.svg"/></a>
      </div>
      <div class="login-box">
        <div class="login-title flexRow new-mb-40">
          <h3>注册账号</h3>
          <span>已有账号? <a href="/login">直接登录</a></span>
        </div>
        <div class="login-account-box">
          <Form
            ref="form"
            hide-required-mark
            :model="model"
            :rules="rules"
            label-position="top"
          >
            <FormItem label="手机号码" prop="phone">
              <NewPhoneSelect v-model="model.phone" />
            </FormItem>
            <FormItem label="手机验证码" prop="code" class="code">
              <Input v-model="model.code" /><a
                class="code-btn"
                @click="handleSendVerifyCode"
                >{{ time === 60 ? '获取验证码' : `${time}s` }}</a
              >
            </FormItem>
            <FormItem v-if="inviteCode" label="邀请码" prop="inviteCode">
              <div style="margin-top:5px">{{ inviteCode }}</div>
            </FormItem>
            <FormItem>
              <Rbutton
                size="large"
                class="login-btn"
                :class="{ '!bg-gray-500 !border-gray-500': !vip }"
                type="primary"
                :disabled="!vip"
                @click="submit"
              >
                {{ fetching ? '注册中..' : '立即注册' }}
              </Rbutton>
            </FormItem>
          </Form>
        </div>
      </div>
    </div>

    <Modal
      v-model="notifyVisible"
      :mask-closable="false"
      :closable="false"
      title="温馨提示"
    >
      <div>
        <p class="leading-7">
          iSHOPPER正在进行服务升级中，暂时暂停新用户注册，专注提升现有客户服务质量。如果您已经有iSHOPPER账号，请直接登录账号操作。
        </p>
        <p class="text-right mt-5">---iShopper</p>
      </div>
      <div slot="footer">
        <div>
          <Button type="primary" size="small" @click="notifyVisible = false">
            好的
          </Button>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import Rbutton from '../components/revision/RButton'
import NewPhoneSelect from '../components/newPhoneSelect'
export default {
  components: { Rbutton, NewPhoneSelect },
  head({ documentTitle }) {
    return {
      title: documentTitle
    }
  },
  props: {
    documentTitle: {
      type: String,
      default: '注册'
    },
    utm: {
      type: String,
      default: ''
    },
    lite: {
      type: Boolean,
      default: false
    },
    // eslint-disable-next-line vue/require-default-prop
    onSuccess: {
      type: Function,
      required: false
    }
  },
  data() {
    return {
      notifyVisible: true,
      fetching: false,
      codeFetching: false,
      inviteCode: this.$route.query.aff_code || this.$cookies.get('aff_code'),
      model: {
        code: '',
        phone: ''
      },
      time: 60,
      rules: {
        phone: [
          {
            required: true,
            message: '请输入手机号',
            trigger: 'blur,change'
          }
        ],
        code: [
          {
            required: true,
            message: '请输入验证码',
            trigger: 'blur,change'
          }
        ]
      },
      vip: false
    }
  },
  mounted() {
    const affCode = this.$route.query.aff_code
    const source = this.$route.query.source
    const vip = this.$route.query.vip

    this.vip = vip
    if (vip) {
      this.notifyVisible = false
    }

    if (affCode) {
      this.$cookies.set('aff_code', affCode, {
        path: '/'
      })
    }
    if (source) {
      this.$cookies.set('source', source, {
        path: '/'
      })
    }
    this.model.inviteCode = this.$cookies.get('aff_code')
    this.model.source = this.$cookies.get('source')

    if (!this.utm && this.$store.state.user) {
      this.$router.replace('/account')
    }
  },
  methods: {
    timer() {
      const t = setInterval(() => {
        if (this.time > 1) {
          this.time--
        } else {
          clearInterval(t)
          this.time = 60
        }
      }, 1000)
    },
    async handleSendVerifyCode() {
      if (this.codeFetching || this.time !== 60) return
      try {
        this.codeFetching = true
        await this.$api.checkPhone({ phone: this.model.phone })
        await this.$api.sendVerifyCode({ phone: this.model.phone })
        this.timer()
      } catch (error) {
        console.log(error)
      } finally {
        this.codeFetching = false
      }
    },
    submit() {
      if (this.fetching) return
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return
        }
        this.fetching = true
        try {
          this.model.aff_code = this.inviteCode
          this.$cookies.remove('aff_code', {
            path: '/'
          })

          const {
            token,
            user,
            sessionid
          } = await this.$api.phoneLoginOrRegister({
            phone: this.model.phone,
            code: this.model.code,
            utm_source: this.utm
          })

          this.$cookies.set('token', token, {
            maxAge: 60 * 60 * 24 * 365 * 10,
            path: '/',
            domain: window.location.hostname
              .split('.')
              .slice(-2)
              .join('.')
          })
          this.$cookies.set('sessionid', sessionid, {
            maxAge: 60 * 60 * 24 * 365 * 10,
            path: '/'
          })

          this.$store.commit('SET_TOKEN', token)
          this.$store.commit('SET_USER', user)

          window.dataLayer.push({
            event: 'sign_up',
            method: 'ishopper'
          })
          window.dataLayer.push({
            event: 'ads',
            conversion_id: '389415407',
            conversion_label: 'prBTCJaApNMCEO-D2LkB'
          })

          if (this.onSuccess) {
            this.onSuccess(this.model)
          } else {
            this.$router.replace('/account')
          }
        } catch (error) {
          console.error(error)
          this.fetching = false
        }
      })
    }
  },
  layout() {
    return 'new-login'
  }
}
</script>

<style lang="less">
.login-wrapper {
  width: 100%;
  min-height: 100%;
  justify-content: center;
  align-items: center;

  &.lite {
    width: 540px;
    border-radius: 40px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.08);
    border: solid 1px rgba(0, 0, 0, 0.05);
    background-color: #fff;

    .login-box {
      box-shadow: none !important;
      border: none !important;
    }

    .logo,
    .login-title {
      display: none;
    }

    .login-btn {
      width: 350px;
      height: 50px;
      padding: 0 !important;
      border-radius: 25px;
      line-height: 1 !important;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1),
        inset 0 2px 0 0 rgba(255, 255, 255, 0.2),
        inset 0 -2px 0 0 rgba(0, 0, 0, 0.1);
      background-color: #e56131;
      font-size: 16px;
      font-weight: 500;
      color: #fff;
      margin-bottom: 0 !important;
    }
  }

  .login-box-wrapper {
    width: 540px;
    .logo {
      text-align: center;
    }
    .login-box {
      padding: 60px 95px;
      border-radius: 6px;
      box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.08);
      border: solid 1px rgba(0, 0, 0, 0.1);
      .login-title {
        justify-content: space-between;
        align-items: center;
        color: #000;
        h3 {
          font-size: 28px;
          font-weight: 600;
        }
        span {
          font-size: 14px;
          a {
            color: #e56131;
            text-decoration: underline;
          }
        }
      }
      .login-tab {
        a {
          width: 50%;
          height: 30px;
          position: relative;
          text-align: center;
          color: #666;
          &::after {
            content: '';
            height: 2px;
            width: 100%;
            background: #222;
            opacity: 0.1;
            position: absolute;
            bottom: 0px;
            left: 0;
          }

          &.active {
            color: #e56131;
            &::after {
              background: #e56131;
              opacity: 1;
            }
          }
        }
      }
      .login-account-box {
        .code {
          position: relative;
          .code-btn {
            position: absolute;
            right: 10px;
            top: 10px;
            font-size: 14px;
            color: #e56131;
          }
        }
        .login-btn {
          margin-top: 10px;
          width: 100%;
        }
        .login-tips {
          text-align: center;
          a {
            color: #666;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .new-actions {
    min-width: 100%;
    .new-action__main {
      .login-wrapper {
        align-items: flex-start;
        .login-box-wrapper {
          width: 100%;
          .logo {
            margin-top: 32px;
            margin-bottom: 60px;
          }
          .login-box {
            border: none;
            box-shadow: none;
            padding: 0 15px 0;
          }
        }
      }
    }
  }
}
</style>
