<template>
  <div class="new-wrapper">
    <LayoutHeader :is-mobile="isMobile" />
    <nuxt class="new-main" />
    <LayoutFooter :is-mobile="isMobile" />
    <div v-if="!isMobile && isShowTp" class="new-tp">
      <div class="new-tp-item tp-item-help" @click="customerService">
        <IconFont size="24" name="headset-help" />
        <div class="tp-left-img">
          <div class="left-img-box">
            在线客服
          </div>
        </div>
      </div>
      <div class="new-tp-item tp-item-wechat">
        <IconFont size="24" name="wechat" />
        <div class="tp-left-img">
          <div class="left-img-box">
            <img :src="require('../assets/images/index/xcx.jpg')" />
          </div>
        </div>
      </div>
      <div class="new-tp-item tp-item-top" @click="toTop">
        <IconFont size="24" name="arrow-up" />
        <div class="tp-left-img">
          <div class="left-img-box">
            返回顶部
          </div>
        </div>
      </div>
    </div>

    <CookiePolicy />
  </div>
</template>

<script>
import LayoutHeader from '../components/revision/LayoutHeader'
import LayoutFooter from '../components/revision/LayoutFooter'
import IconFont from '../iconfont'
import CookiePolicy from '~/components/CookiePolicy'
import { changeToPadViewPort } from '~/utils'
import { detect } from '~/utils/detect'
export default {
  components: {
    LayoutHeader,
    LayoutFooter,
    IconFont,
    CookiePolicy
  },
  head() {
    return {
      link: [
        {
          rel: 'canonical',
          href: 'https://www.ishopper.com' + this.$route.path
        }
      ]
    }
  },
  data() {
    return {
      isMobile: null,
      isShowTp: false
    }
  },
  watch: {
    $route() {
      if (detect(window.navigator.userAgent).isPad) {
        changeToPadViewPort()
      }
    }
  },
  mounted() {
    if (detect(window.navigator.userAgent).isPad) {
      changeToPadViewPort()
    }
    this.comMobile()
    if (!this.isMobile) {
      const scrollTop = document.documentElement.scrollTop
      this.isShowTp = scrollTop > 0
      window.addEventListener('scroll', this.scroll, false)
    }

    this.$Message.config({
      duration: 3
    })
  },
  destroyed() {
    window.removeEventListener('scroll', this.scroll, false)
  },
  methods: {
    toTop() {
      window.scrollTo(0, 0)
    },
    customerService() {
      const user = this.$store.state.user
      if (user) {
        window._MEIQIA('metadata', {
          id: user.id,
          email: user.email,
          username: user.username,
          phone: user.phone
        })
      }
      window._MEIQIA('showPanel')
    },
    scroll() {
      const scrollTop = document.documentElement.scrollTop
      this.isShowTp = scrollTop > 0
    },
    comMobile() {
      const width = document.body.clientWidth
      this.isMobile = width <= 640
    }
  }
}
</script>

<style lang="less">
.new-center-container {
  width: 1140px;
  margin: 0 auto;
  font-size: 14px;
  box-sizing: border-box;
  padding: 0 14px;
}
.new-pt-60 {
  padding-top: 60px;
}
.new-pb-60 {
  padding-bottom: 60px;
}
.new-ml-60 {
  margin-left: 60px;
}
.new-mr-60 {
  margin-right: 60p;
}
.new-mt-60 {
  margin-top: 60px;
}
.new-mb-60 {
  margin-bottom: 60px;
}
.new-mt-40 {
  margin-bottom: 40px;
}
.new-mb-40 {
  margin-top: 40px;
}
.new-pb-40 {
  padding-bottom: 40px;
}
.new-pt-40 {
  padding-top: 40px;
}

.new-wrapper {
  position: relative;
  .new-tp {
    width: 60px;
    height: 182px;
    box-sizing: border-box;
    border-radius: 6px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.08);
    border: solid 1px rgba(0, 0, 0, 0.05);
    background-color: #fff;
    position: fixed;
    right: 30px;
    bottom: 30px;

    .new-tp-item {
      cursor: pointer;
      width: 100%;
      height: 60px;
      line-height: 60px;
      text-align: center;
      color: #222;
      &:hover {
        color: #e56131;
      }
      &:not(:last-child) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      }
      &.tp-item-top,
      &.tp-item-help {
        position: relative;
        .tp-left-img {
          transition: all 0.3s ease-in;
          display: none;
          width: 114px;
          height: 40px;
          position: absolute;
          left: -114px;
          top: 12px;
          .left-img-box {
            width: 104px;
            height: 40px;
            text-align: center;
            line-height: 40px;
            border-radius: 6px;
            background: #222;
            box-sizing: border-box;
            position: relative;
            color: #fff;

            &::after {
              content: '';
              width: 0;
              height: 0;
              border-top: 5px solid transparent;
              border-left: 5px solid#222;
              border-bottom: 5px solid transparent;
              position: absolute;
              right: -5px;
              top: 15px;
            }
            img {
              width: 78px;
              height: 78px;
              border-radius: 6px;
            }
          }
        }
        &:hover {
          .tp-left-img {
            display: block;
          }
        }
      }
      &.tp-item-wechat {
        position: relative;

        .tp-left-img {
          transition: all 0.3s ease-in;
          display: none;
          width: 114px;
          height: 98px;
          position: absolute;
          left: -114px;
          top: -15px;
          .left-img-box {
            width: 104px;
            height: 98px;
            border-radius: 6px;
            background: #222;
            box-sizing: border-box;
            padding: 10px;
            position: relative;

            &::after {
              content: '';
              width: 0;
              height: 0;
              border-top: 5px solid transparent;
              border-left: 5px solid#222;
              border-bottom: 5px solid transparent;
              position: absolute;
              right: -5px;
              top: 45px;
            }
            img {
              width: 78px;
              height: 78px;
              border-radius: 6px;
            }
          }
        }

        &:hover {
          .tp-left-img {
            display: block;
          }
        }
      }
    }
  }
}

.new-wrapper {
  .new-main {
    min-height: 114px;
  }
  .new-title {
    text-align: center;
    font-size: 30px;
    height: 42px;
    line-height: 42px;
    font-weight: 500;
    color: #000;
    position: relative;
    &::after {
      content: '';
      width: 70px;
      height: 2px;
      border-radius: 2.5px;
      background-image: linear-gradient(
        to right,
        #e56131,
        rgba(229, 97, 49, 0)
      );
      position: absolute;
      left: 50%;
      margin-left: -35px;
      bottom: -10px;
    }
  }
}
.m-lr-14 {
  padding: 0 14px;
}
@media screen and (max-width: 640px) {
  .new-title {
    font-size: 24px !important;
  }
}
</style>
