<template>
  <div class="app en" :class="{ dev: isDev }">
    <CookiePolicy />

    <headroom>
      <div class="header">
        <div class="header-top">
          <div class="line" />
          <a href="/" class="logo">
            <img src="~/assets/images/common/logo.png"
          /></a>
          <div class="actions">
            <span v-if="!user || !user.id" class="unauth">
              <nuxt-link to="/login">Log In</nuxt-link>
              <nuxt-link to="/register">Sign up</nuxt-link>
            </span>
            <span v-else class="auth">
              <div class="icon">
                <img src="~/assets/images/common/icon-1.png" alt="" />
              </div>
              <div class="links">
                <nuxt-link to="/account">My Account</nuxt-link>
                <a href="#0" @click="logout(true)">Logout</a>
              </div>
            </span>
          </div>
        </div>
        <div class="menus flexRow">
          <a
            v-for="menuItem of menus"
            :key="menuItem.name"
            href="javascript:"
            @click="toMenu(menuItem.target)"
            >{{ menuItem.name }}</a
          >

          <div class="languages">
            <Nuxt-link to="/">中文</Nuxt-link>
            <Nuxt-link to="/en">EN</Nuxt-link>
          </div>
        </div>
      </div>
    </headroom>

    <nuxt class="main" />

    <div class="help-menus">
      <FooterMenu lang="en" />
    </div>

    <div class="footer">
      <div class="copyright">
        <nuxt-link to="/terms-conditions">TERMS AND CONDITIONS</nuxt-link>
        <nuxt-link to="/privacy-policy">PRIVACY POLICY</nuxt-link>
      </div>
    </div>
  </div>
</template>

<script>
import { headroom } from 'vue-headroom'
import bus from '~/utils/bus'
import CookiePolicy from '~/components/CookiePolicy'
import FooterMenu from '~/components/FooterMenu'

import '~/assets/index.less'

export default {
  components: {
    CookiePolicy,
    headroom,
    FooterMenu
  },
  head() {
    return {
      link: [
        {
          rel: 'canonical',
          href: 'https://www.ishopper.com' + this.$route.path
        }
      ]
    }
  },
  data() {
    return {
      menus: [
        { name: 'Home', target: 'home' },
        { name: 'About us', target: 'about-us' },
        { name: 'Customer review', target: 'customer-review' },
        { name: 'Our service', target: 'our-service' },
        { name: 'Cash Back', target: 'cash-back' },
        { name: 'Partners', target: 'partners' },
        { name: 'Contact us', target: 'contact-us' }
      ]
    }
  },
  computed: {
    isDev() {
      return process.env.NODE_ENV === 'development'
    },
    user() {
      return this.$store.state.user
    }
  },
  watch: {
    user: {
      deep: true,
      handler() {
        this.updateLogRocketInfo()
      }
    }
  },
  mounted() {
    bus.$on('logout', this.logout)
  },
  methods: {
    toMenu(id) {
      const target = document.querySelector(`#${id}`)

      target.scrollIntoView({
        behavior: 'smooth'
      })
    },
    updateLogRocketInfo() {
      const { user } = this
      if (!user) {
        return
      }

      this.$logRocket &&
        this.$logRocket.identify(user.username || '未设置用户名', {
          id: user.id,
          email: user.email,
          username: user.username,
          phone: user.phone
        })
    },
    async logout(report = false) {
      if (report && this.$store.state.token) {
        await this.$api.logout()
      }

      this.$store.commit('SET_TOKEN', null)
      this.$store.commit('SET_USER', null)
      this.$cookies.remove('token', { path: '/' })
      this.$cookies.remove('token', {
        path: '/',
        domain: location.hostname
          .split('.')
          .slice(-2)
          .join('.')
      })
      this.$router.replace('/')
    }
  }
}
</script>

<style lang="less">
.app.en {
  .headroom {
    background: #fff;
    border-bottom: 1px solid #ccc;
  }

  .header .menus {
    a + a {
      // margin-left: 40px;
      margin-right: 40px;
    }
    a {
      margin-right: 40px;
    }

    .languages {
      a {
        margin-right: 0;
      }
      a + a {
        margin-left: 10px;
      }
    }
  }

  .help-menus {
    max-width: 1200px;
    margin: 0 auto;
    background: #fb5020;
    display: flex;
    justify-content: center;
    padding: 30px 0 50px;
  }
}
</style>
