<template>
  <div class="index-banner-wrapper">
    <Carousel loop arrow="never" :autoplay-speed="5000">
      <CarouselItem>
        <div class="index-banner-item">
          <div
            class="banner-item-wrapper"
            :class="!isMobile ? 'new-center-container' : ''"
          >
            <h2>全英唯一线上海淘自助退税平台</h2>
            <h4 v-show="!isMobile">
              iShopper海淘直通车 · 十年金融税务备案 · 十万用户信誉保障
            </h4>
            <h4 v-show="isMobile">
              iShopper海淘直通车<br />
              十年金融税务备案 · 十万用户信誉保障
            </h4>
            <div class="banner-button-wrapper">
              <SpecialButton />
              <div class="banner-button-text">现在注册立享最高20%退税优惠</div>
            </div>
          </div>
        </div>
      </CarouselItem>
    </Carousel>
  </div>
</template>

<script>
import SpecialButton from './SpecialButton.vue'

export default {
  components: {
    SpecialButton
  },
  props: {
    isMobile: {
      type: Boolean,
      default: null
    }
  },
  data() {
    return {
      banners: [],
      bannerValue: 0
    }
  }
}
</script>

<style lang="less">
.index-banner-wrapper {
  height: 694px;
  margin-bottom: 100px;
  background-color: #fbf6f4;

  .ivu-carousel {
    height: 100%;
    .ivu-carousel-list {
      height: 100%;
      .ivu-carousel-track {
        height: 100%;

        .ivu-carousel-item {
          width: 100%;
          height: 100% !important;
          .index-banner-item {
            display: block;
            height: 100%;
            box-sizing: border-box;
            padding-top: 234px;
            background-color: #fbf6f4;
            .new-center-container {
              background: url('../../assets/images/index/banner-illustrations.svg')
                no-repeat right top;
            }
            h2 {
              color: #000;
              font-size: 42px;
              font-weight: 600;
              margin-bottom: 40px;
            }
            h4 {
              font-size: 18px;
              font-weight: normal;
              color: #000;
              margin-bottom: 80px;
            }
            .banner-button-wrapper {
              background: url('../../assets/images/index/arrows.svg') no-repeat
                340px 20px;
              .banner-button-text {
                color: #000000;
                width: 245px;
                height: 64px;
                line-height: 64px;
                text-align: center;
                margin-top: 40px;
                font-size: 14px;
                font-weight: 600;
                margin-left: 96px;
                background: url('../../assets/images/index/box.svg') no-repeat
                  center center;
              }
            }
          }
        }
      }
    }
    .ivu-carousel-dots {
      bottom: 30px;
      li {
        button {
          background-color: #000;
          opacity: 0.1;
          width: 10px;
        }
        &.ivu-carousel-active button {
          width: 30px;
          background-color: #e56131;
          opacity: 1;
        }
      }
    }
  }
}
@media screen and (max-width: 640px) {
  .index-banner-wrapper {
    height: 758px;
    margin-bottom: 40px;
    .ivu-carousel {
      .ivu-carousel-list {
        .ivu-carousel-track {
          .ivu-carousel-item {
            .index-banner-item {
              padding-top: 0;
              .banner-item-wrapper {
                height: 758px !important;
                background: url('../../assets/images/index/banner-illustrations.svg')
                  no-repeat center 100px;
                background-size: contain;
                padding-top: 350px !important;
              }
              h2 {
                width: 256px;
                margin: 0 auto;
                font-size: 32px;
                text-align: center;
              }
              h4 {
                margin: 20px 0;
                text-align: center;
                line-height: 30px;
              }
              .banner-button-wrapper {
                background: url('../../assets/images/index/m-arrows.svg')
                  no-repeat 290px 62px;
                .banner-button-text {
                  margin-left: auto;
                  margin-right: auto;
                }
              }
            }
          }
        }
      }
      .ivu-carousel-dots {
        bottom: 10px;
      }
    }
  }
}
</style>
