<template>
  <!-- eslint-disable vue/no-v-html -->
  <div class="new-qa-box new-center-container">
    <div class="title flexRow">
      <div class="new-title">常见问题</div>
      <div class="input-box flexRow" :class="{ focus: focus }">
        <IconFont size="24" name="magnifying-glass" />
        <input
          v-model="searchValue"
          placeholder="请输入您想要了解的问题关键词"
          @keyup.enter="search"
          @focus="handleFocus"
          @blur="handleBlur"
        />
      </div>
    </div>
    <div class="new-qa-tabs">
      <ul class="flexRow">
        <li
          v-for="item in categoryList"
          :key="item.id"
          :class="{ active: item.id === currentId }"
          @click="changeCategory(item.id)"
        >
          {{ item.name }}
        </li>
      </ul>
    </div>
    <div v-if="list.length > 0" class="new-qa-list">
      <div
        v-for="(item, i) in list"
        :key="i"
        class="qa-list-item"
        :class="[
          !item.name && i == 0 ? 'list-first-item' : '',
          !item.name ? 'none' : ''
        ]"
      >
        <div v-if="item.name" class="list-item-title">#{{ item.name }}</div>
        <div class="list-item-content">
          <div
            v-for="(qa, index) in item.list"
            :key="qa.id"
            class="list-content-item"
            :class="[
              { active: qa.active },
              item.name ? 'haveTitle' : 'noneTitle'
            ]"
          >
            <div
              class="list-content-title flexRow"
              @click="showText(i, index, qa.id, searchValue)"
            >
              <h4 v-html="qa.title" />
              <IconFont
                size="20"
                name="caret-down"
                :reverse="!qa.active ? 'left' : ''"
                color="#000000"
              />
            </div>

            <div
              v-if="qa.active"
              class="list-content-text"
              v-html="qa.content"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="list.length === 0" class="new-qa-empty flexRow">
      <IconFont name="smiley-sad" color="#e56131" size="40" />
      <div class="empty-content">
        很抱歉，没有你要找的内容。<br />
        可以换个关键词试试~
      </div>
    </div>
  </div>
  <!--eslint-enable-->
</template>

<script>
import IconFont from '../../iconfont'
export default {
  components: {
    IconFont
  },
  props: {
    categoryList: {
      type: Array,
      default: () => []
    },
    list: {
      type: Array,
      default: () => []
    },
    currentId: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      focus: false,
      searchValue: ''
    }
  },
  // watch: {
  //   searchValue(val) {
  //     console.log('watch val', val)
  //     this.$emit('search', val)
  //   }
  // },
  methods: {
    search() {
      this.$emit('search', this.searchValue)
    },
    changeCategory(id) {
      this.searchValue = ''
      this.$emit('getCategory', id)
    },
    showText(i, index, id, val) {
      this.$emit('showText', i, index, id, val)
    },
    handleFocus() {
      this.focus = true
    },
    handleBlur() {
      this.focus = false
    }
  }
}
</script>

<style lang="less">
.new-qa-box {
  padding-top: 100px;
  padding-bottom: 100px;
  width: 758px;
  .title {
    justify-content: space-between;
    align-items: center;
    padding-bottom: 40px;
    .new-title {
      &::after {
        margin-left: 0;
        left: 0;
      }
    }
    .input-box {
      box-sizing: border-box;
      height: 50px;
      width: 350px;
      border-radius: 25px;
      background-color: #f9f9f9;
      border: solid 1px #f9f9f9;
      padding: 20px;
      align-items: center;
      transition: all 0.2s ease-in-out;

      .iconfont {
        color: #999;
        margin-right: 10px;
      }
      input {
        flex: 1;
        border: none;
        outline: 0;
        height: 30px;
        line-height: 30px;
        font-size: 14px;
        color: #222;
        background-color: transparent;
        &::placeholder {
          color: #999;
        }
      }
      &.focus {
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.08);
        border: solid 1px rgba(0, 0, 0, 0.1);
        background-color: #fff;
        .iconfont {
          color: #222;
        }
        input {
          background-color: transparent;
          &::placeholder {
            color: #222;
          }
        }
      }
    }
  }
  .new-qa-tabs {
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    ul {
      li {
        color: #222;
        font-size: 14px;
        height: 30px;
        line-height: 30px;
        padding: 0 10px;
        border-radius: 6px;
        cursor: pointer;
        border: solid 1px #fff;
        margin-right: 30px;
        &:hover {
          color: #e56131;
        }
        &.active {
          border-color: #e56131;
          color: #e56131;
          font-weight: 600;
        }
      }
    }
  }
  .new-qa-list {
    .qa-list-item {
      &.none {
        margin-top: 30px;
      }
      &.list-first-item {
        margin-top: 60px;
      }

      .list-item-title {
        margin-top: 60px;
        color: #e56131;
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 30px;
      }
      .list-item-content {
        .list-content-item {
          box-sizing: border-box;
          border-radius: 6px;

          border: solid 1px rgba(0, 0, 0, 0.1);
          background-color: #fff;

          &:hover,
          &.active {
            box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.08);
          }

          &.haveTitle:not(:last-child) {
            margin-bottom: 30px;
          }
          &.noneTitle {
            margin-bottom: 30px;
          }
          .list-content-title {
            justify-content: space-between;
            align-items: center;
            padding: 20px;
            cursor: pointer;
            h4 {
              color: #000;
              font-size: 14px;
              font-weight: 600;

              .font-red {
                color: #ff0000;
                font-weight: 500;
              }
            }
          }
          .list-content-text {
            padding: 0 20px 20px;

            font-size: 14px;
            color: #444;
            .font-red {
              color: #ff0000 !important;
              font-weight: 500;
            }
            p {
              font-size: 14px;
              color: #444;
              line-height: 32px !important;
            }
          }
        }
      }
    }
  }
  .new-qa-empty {
    padding: 100px 0;
    align-items: center;
    justify-content: center;
    .iconfont {
      margin-right: 25px;
    }
    .empty-content {
      font-size: 14px;
      line-height: 28px;
      color: #222;
    }
  }
}
@media screen and (max-width: 640px) {
  .new-qa-box {
    padding-top: 40px;
    padding-bottom: 40px;
    .title {
      flex-direction: column;
      .new-title {
        margin-bottom: 40px;
        &::after {
          left: 50%;
          margin-left: -35px;
        }
      }
      .input-box {
        width: 100%;
      }
    }
    .new-qa-tabs {
      padding-bottom: 10px;
      ul {
        padding-bottom: 10px;
        overflow: auto;
        li {
          flex-shrink: 0;
        }
      }
    }
    .new-qa-list {
      .qa-list-item {
        margin-top: 40px;
        img {
          object-fit: contain;
        }
      }
    }
  }
}
</style>
