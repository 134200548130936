<template>
  <span>
    <a @click="visible = true">
      <Icon type="ios-download-outline" :size="22" />
      导出Excel
    </a>
    <Modal
      v-model="visible"
      ok-text="确定导出"
      cancel-text="关闭"
      :loading="loading"
      footer-hide
      @on-ok="handleSubmit"
    >
      <Form ref="formRef" class="mt-3" :model="model" :rules="rules">
        <FormItem label="请选择最大3个月范围内的数据" prop="dateRange">
          <DatePicker
            v-model="model.dateRange"
            format="yyyy/MM/dd"
            type="daterange"
            placement="bottom-end"
            placeholder="Select date"
            style="width: 300px"
          />
        </FormItem>
        <div class="text-right">
          <Button type="primary" @click="handleSubmit">确认导出</Button>
        </div>
      </Form>
    </Modal>
  </span>
</template>

<script>
import { ref } from '@vue/composition-api'
import { useVm } from '@/use'

export default {
  setup() {
    const vm = useVm()
    const loading = ref(false)
    const visible = ref(false)
    const model = ref({
      dateRange: [
        vm
          .$dayjs()
          .subtract(3, 'month')
          .format(),
        vm.$dayjs().format()
      ]
    })

    const rules = {
      dateRange: [
        {
          required: true,
          validator(rule, value, callback) {
            const [startDate, endDate] = value
            if (!startDate) {
              callback(new Error('请选择正确的时间'))
              return
            }

            if (
              vm
                .$dayjs(value[0])
                .add(3, 'month')
                .format() < vm.$dayjs(endDate).format()
            ) {
              callback(new Error('时间超出3个月范围'))
              return
            }

            callback()
          }
        }
      ]
    }

    const handleSubmit = () => {
      vm.$refs.formRef.validate(async (valid) => {
        if (!valid) {
          return
        }
        const [startDate, endDate] = model.value.dateRange

        const link = document.createElement('a')
        const res = await vm.$api.exportPurchase(
          {
            start_date: startDate,
            end_date: endDate
          },
          { responseType: 'arraybuffer' }
        )

        const blob = new Blob([res], {
          type: 'application/vnd.ms-excel;charset=utf-8'
        })
        const objectUrl = URL.createObjectURL(blob) // 创建URL
        link.href = objectUrl
        link.download = `采购数据_${vm
          .$dayjs(startDate)
          .format('YYYY-MM-DD')}_${vm.$dayjs(endDate).format('YYYY-MM-DD')}.xls`
        link.click() // 下载文件
        URL.revokeObjectURL(objectUrl) // 释放内存

        visible.value = false
      })
    }

    return {
      loading,
      visible,
      model,
      handleSubmit,
      rules
    }
  }
}
</script>

<style lang="scss" scoped></style>
