<template>
  <div class="bussiness-wrapper-step4">
    <div class="container">
      <div class="qr-box flexRow">
        <div class="qr-img">
          <img src="../../../assets/images/common/qrcode.png" />
        </div>
        <div class="qr-content">
          <p>仍然有问题想要做进一步了解？</p>
          <p>扫码添加我们的客服微信，或者拨打电话</p>
          <div class="flexRow">
            <img
              src="../../../assets/images/landing/yingguo.svg"
            />＋44.01869.320216
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less">
.bussiness-wrapper-step4 {
  background: #e56131;
  color: #fff;
  .container {
    position: relative;
    overflow: hidden;

    &::after,
    &:before {
      content: '';
      background: rgba(#fff, 0.1);
      position: absolute;
      left: 50%;

      transform: translate(-50%, 0);
      border-radius: 50%;
    }

    &::after {
      width: 560px;
      height: 560px;
      top: -465px;
    }

    &:before {
      width: 820px;
      height: 820px;
      top: -600px;
    }

    padding-top: 80px;
    padding-bottom: 80px;
    .qr-box {
      justify-content: center;
      .qr-img {
        width: 100px;
        height: 100px;
        padding: 5px;
        background: #fff;
        border-radius: 6px;
        margin-right: 30px;
      }
      .qr-content {
        p {
          margin-bottom: 20px;
          font-weight: 500;
          line-height: 20px;
          font-size: 14px;
        }
        .flexRow {
          font-size: 20px;
          line-height: 28px;
          font-weight: 600;
          align-items: center;
        }
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .bussiness-wrapper-step4 {
    .container {
      padding-top: 40px;
      padding-bottom: 40px;

      &:after {
        top: -470px;
      }
      &:before {
        top: -630px;
      }
      .qr-box {
        flex-direction: column-reverse;
        align-items: center;

        .qr-img {
          margin-top: 20px;
          margin-right: 0;
        }
        .qr-content {
          text-align: center;
          .flexRow {
            justify-content: center;
          }
        }
      }
    }
  }
}
</style>
