<template>
  <div class="user-verify-step4">
    <p v-if="userStatus == '10'">{{ disApproveReason }}</p>
    <p>{{ desc }}</p>
    <Button type="primary" @click="handleSubmit">{{
      status === 10 || status === 7 ? '修改资料' : '返回'
    }}</Button>
  </div>
</template>

<script>
const STATUS = {
  0: '您的资料已经提交，请等待管理审核。',
  5: '您的资料已经提交，请等待管理审核。',
  7: '您的护照信息已过期，请重新提交最新的护照信息',
  10: '您的审核没有通过，请修改资料后重新提交。',
  15: '您的账号已锁定。'
}

export default {
  inheritAttrs: false,
  props: {
    onSubmit: {
      type: Function,
      required: true
    }
  },
  data() {
    const userStatus = this.$store.state.user.status
    const disApproveReason = this.$store.state.user.dis_approve_reason
    return { userStatus, disApproveReason }
  },
  computed: {
    status() {
      return this.$store.state.user.status
    },
    desc() {
      return STATUS[this.status]
    }
  },
  methods: {
    async handleSubmit() {
      await this.$store.dispatch('nuxtServerInit')
      this.onSubmit()
    }
  }
}
</script>

<style lang="less">
.user-verify-step4 {
  text-align: center;
  margin: 80px 0;

  p {
    font-size: 22px;
    line-height: 30px;
    color: #3b3b3b;
    margin-bottom: 50px;
  }
}
</style>
