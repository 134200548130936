<script>
const ADDRESS_TYPE = {
  PROVINCE: 'PROVINCE',
  CITY: 'CITY',
  COUNTY: 'COUNTY'
}

export default {
  props: {
    value: {
      type: Object,
      required: true
    },
    isBillingAddress: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      data: []
    }
  },

  computed: {
    addressValue() {
      const value = this.value

      if (!this.isBillingAddress) {
        return value.province ? [value.province, value.city, value.county] : []
      } else {
        return value.billing_province
          ? [value.billing_province, value.billing_city, value.billing_county]
          : []
      }
    }
  },

  mounted() {
    this.fetchProvinces()
  },

  methods: {
    handleChange(_, value) {
      if (!value.length) {
        if (!this.isBillingAddress) {
          this.$emit('input', {
            ...this.value,
            province: null,
            city: null,
            county: null,
            postcode: null
          })
        } else {
          this.$emit('input', {
            ...this.value,
            billing_province: null,
            billing_city: null,
            billing_county: null,
            billing_postcode: null
          })
        }
        return
      }
      if (!this.isBillingAddress) {
        this.$emit('input', {
          ...this.value,
          province: value[0].value,
          city: value[1].value,
          county: value[2].value,
          postcode: value[2].postcode
        })
      } else {
        this.$emit('input', {
          ...this.value,
          billing_province: value[0].value,
          billing_city: value[1].value,
          billing_county: value[2].value,
          billing_postcode: value[2].postcode
        })
      }
    },
    createChildren(items, extra = {}) {
      return items.map((item) => ({
        ...item,
        value: item.name,
        label: item.name,
        children: [],
        loading: false,
        ...extra
      }))
    },

    async fetchProvinces() {
      const { data } = await this.$api.fetchProvinces()
      this.data = this.createChildren(data, {
        type: ADDRESS_TYPE.PROVINCE,
        children: []
      })
    },

    async loadData(item, callback) {
      if (item.loading) {
        callback()
        return
      }
      item.loading = true

      if (item.type === ADDRESS_TYPE.PROVINCE) {
        const { data } = await this.$api.fetchCities({ province: item.value })
        item.children = this.createChildren(data, {
          type: ADDRESS_TYPE.CITY
        })
      }

      if (item.type === ADDRESS_TYPE.CITY) {
        const { data } = await this.$api.fetchCounties({ city: item.value })
        item.children = data.map((item) => ({
          ...item,
          value: item.name,
          label: item.name
        }))
      }

      item.loading = false
      callback()
    }
  },

  render() {
    return (
      <Cascader
        transfer={true}
        class="address-cascader"
        value={this.addressValue}
        data={this.data}
        load-data={this.loadData}
        onOn-change={this.handleChange}
        filterable
      ></Cascader>
    )
  }
}
</script>

<style lang="less">
.address-cascader {
  .ivu-cascader-menu {
    height: 190px;
  }
}
</style>
