<template>
  <div class="bussiness-wrapper-step3">
    <div class="container">
      <div class="title">
        <h3>我们的受众群体</h3>
        <span class="title-bottom-border" />
      </div>
      <div class="ql-list">
        <ul class="flexRow">
          <li>
            <h5 class="flexRow">
              <img
                src="../../../assets/images/landing/circle-check.svg"
              />专业海淘买手
            </h5>
            <p>
              经常为国内客户采购商品和奢侈品并邮寄，需要频繁申请退税,我们提供简化流程，帮助客户节省时间，并享受最大的退税优惠
            </p>
          </li>
          <li class="li-center">
            <h5 class="flexRow">
              <img
                src="../../../assets/images/landing/circle-check.svg"
              />海外留学生
            </h5>
            <p>
              需要帮国内亲朋好友代购，不熟悉退税流程和繁琐的手续，想要通过便捷的一站式退税服务来省钱
            </p>
          </li>
          <li>
            <h5 class="flexRow">
              <img
                src="../../../assets/images/landing/circle-check.svg"
              />国内网购用户
            </h5>
            <p>
              喜爱国外优质商品，想要足不出户采购全球线上线下商品和奢侈品，并且以最优的价格买到绝对的正品并享受退税折扣
            </p>
          </li>
        </ul>
      </div>
      <div class="btns flexColumn">
        <LandingBussinessBtns />
      </div>
    </div>
  </div>
</template>

<script>
import LandingBussinessBtns from '../../../components/landing/LandingBussinessBtns'
export default {
  components: {
    LandingBussinessBtns
  }
}
</script>

<style lang="less">
.bussiness-wrapper-step3 {
  background: #fff;
  .container {
    padding-top: 80px;
    padding-bottom: 80px;
    .title {
      margin-bottom: 80px;
    }
    .ql-list {
      margin-bottom: 80px;
      ul {
        justify-content: space-between;
        li {
          width: 270px;
          padding: 0 60px;
          flex: 1;
          h5 {
            color: #e56131;
            margin-bottom: 20px;
            font-size: 22px;
            align-items: center;
            img {
              width: 24px;
              height: 24px;
              margin-right: 5px;
            }
          }
          &.li-center {
            border-right: 1px solid rgba(0, 0, 0, 0.1);
            border-left: 1px solid rgba(0, 0, 0, 0.1);
          }
        }
      }
    }
    .btns {
      align-items: center;
      .btns-two {
        margin-bottom: 30px;
      }
    }
  }
}
@media screen and (max-width: 640px) {
  .bussiness-wrapper-step3 {
    .container {
      padding-top: 40px;
      padding-bottom: 40px;

      .title {
        margin-bottom: 0px;
      }
      .ql-list {
        margin-bottom: 30px;
        ul {
          flex-direction: column;
          flex-wrap: wrap;

          li {
            width: 100%;
            padding: 40px 0 20px 0;
            text-align: center;

            &.li-center {
              border-left: 0;
              border-right: 0;
              border-top: 1px solid rgba(0, 0, 0, 0.1);
              border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            }
            h5 {
              justify-content: center;
              img {
                width: 18px;
                height: 18px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
