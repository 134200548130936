<script>
import { compressionFile } from '~/utils/compressionFile'
const maxSize = 15 * 1024

export default {
  inheritAttrs: false,
  computed: {
    localProps() {
      return {
        placeholder: '请选择文件',
        maxSize,
        headers: { Authorization: `JWT ${this.$store.state.token}` },
        beforeUpload: this.handleBeforeUpload,
        onExceededSize: this.handleMaxSize
      }
    },
    uploadList() {
      const uploadRef = this.$refs.upload
      return uploadRef ? uploadRef.fileList : []
    },
    realProps() {
      return { ...this.localProps, ...this.$attrs }
    }
  },
  methods: {
    handleMaxSize() {
      this.$Message.error(
        `请上传小于 ${this.localProps.maxSize / 1024}M 大小的文件`
      )
    },
    async handleBeforeUpload(file) {
      if (/image/.test(file.type)) {
        const newFile = await compressionFile(file)
        return newFile
      } else {
        return file
      }
    }
  },
  render() {
    return (
      <Upload ref="upload" props={this.realProps}>
        {this.$slots.default}
      </Upload>
    )
  }
}
</script>
