<template>
  <ul class="flat-select-container">
    <li
      v-for="(option, index) of options"
      :key="index"
      :class="{ active: isSelect(option.value) }"
      @click="handleSelect(option.value, !isSelect(option.value))"
    >
      <Tooltip
        :delay="300"
        :content="option.notes || option.desc"
        :disabled="!option.notes && !option.desc"
        placement="left"
      >
        <slot :data="option" />
        <span>{{ option.label }}</span>
        <span>
          <Icon type="ios-checkmark-circle" />
          <slot name="right" />
        </span>
      </Tooltip>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: true
    },
    multiple: {
      type: Boolean,
      default: false
    },
    value: {
      type: [String, Number, Array],
      default: ''
    }
  },
  methods: {
    isSelect(value) {
      const selectedValue = this.value
      if (Array.isArray(selectedValue)) {
        return selectedValue.includes(value)
      }

      return selectedValue === value
    },
    handleSelect(value, isSelect) {
      if (this.multiple) {
        this.$emit(
          'input',
          isSelect
            ? this.value.concat(value)
            : this.value.filter((id) => id !== value)
        )
      } else {
        this.$emit('input', isSelect ? value : undefined)
      }

      this.$emit('change', value, isSelect)
    }
  }
}
</script>

<style lang="less">
.flat-select-container {
  width: 350px;
  height: 180px;
  overflow: auto;
  padding: 8px 10px;
  background-color: #ffffff;
  border-radius: 10px;
  border: solid 1px #cccccc;

  li {
    font-size: 14px;
    padding: 3px 5px;
    display: flex;
    justify-content: space-between;

    .ivu-icon-ios-checkmark-circle {
      color: #fff;
      opacity: 0;
    }

    &:hover {
      background: #eee;
    }

    &.active {
      color: #fff;
      background: rgba(170, 197, 214);

      .ivu-icon-ios-checkmark-circle {
        opacity: 1;
      }
    }
  }
}
</style>
