<template>
  <div class="index-customer-wrapper">
    <h3 v-if="isMobile" class="new-title mobile-title">我们的受众群体你</h3>
    <div
      class="i-customer-content flexRow"
      :class="{ 'new-center-container': isMobile === false }"
    >
      <!-- <div class="new-center-container "> -->
      <div ref="advantage" class="index-customer-left border">
        <div class="avatar avatar-1 small" :class="{ active: arr.includes(1) }">
          <img v-lazy="require(`../../assets/images/index/avatar/1.png`)" />
        </div>
        <div class="avatar avatar-2 nomal" :class="{ active: arr.includes(2) }">
          <img v-lazy="require(`../../assets/images/index/avatar/7.png`)" />
        </div>
        <div class="avatar avatar-3 large" :class="{ active: arr.includes(3) }">
          <img v-lazy="require(`../../assets/images/index/avatar/6.png`)" />
        </div>
        <div class="around1 border">
          <div
            class="avatar avatar-7 nomal"
            :class="{ active: arr.includes(7) }"
          >
            <img v-lazy="require(`../../assets/images/index/avatar/4.png`)" />
          </div>
          <div
            class="avatar avatar-4 nomal"
            :class="{ active: arr.includes(4) }"
          >
            <img v-lazy="require(`../../assets/images/index/avatar/3.png`)" />
          </div>
          <div
            class="avatar avatar-5 nomal"
            :class="{ active: arr.includes(5) }"
          >
            <img v-lazy="require(`../../assets/images/index/avatar/2.png`)" />
          </div>
          <div
            class="avatar avatar-6 small"
            :class="{ active: arr.includes(6) }"
          >
            <img v-lazy="require(`../../assets/images/index/avatar/5.png`)" />
          </div>
          <div class="around2">
            <div class="around3">
              <img
                v-lazy="require(`../../assets/images/index/avatar/logo-a1.png`)"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="index-customer-right">
        <h3 v-if="isMobile === false" class="new-title">我们的受众群体</h3>
        <div class="index-customer-item new-pt-40">
          <h4 class="flexRow">
            <IconFont
              size="30"
              name="circle-check"
              color="#e56131"
            />专业海淘买手
          </h4>
          <p>
            经常为国内客户采购商品和奢侈品并邮寄，需要频繁申请退税,我们提供简化流程，帮助客户节省时间，并享受最大的退税优惠
          </p>
        </div>
        <div class="index-customer-item new-pt-40">
          <h4 class="flexRow">
            <IconFont size="30" name="circle-check" color="#e56131" />海外留学生
          </h4>
          <p>
            需要帮国内亲朋好友代购，不熟悉退税流程和繁琐的手续，想要通过便捷的一站式退税服务来省钱
          </p>
        </div>
        <div class="index-customer-item new-pt-40">
          <h4 class="flexRow">
            <IconFont
              size="30"
              name="circle-check"
              color="#e56131"
            />国内网购用户
          </h4>
          <p>
            喜爱国外优质商品，想要足不出户采购全球线上线下商品和奢侈品，并且以最优的价格买到绝对的正品并享受退税折扣
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconFont from '../../iconfont/index.vue'
import { detect } from '~/utils/detect'
export default {
  components: {
    IconFont
  },
  props: {
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      arr: []
    }
  },
  mounted() {
    this.resize()
    // setInterval(() => {
    //   this.random()
    // }, 2000)
  },
  methods: {
    resize() {
      const width = document.body.clientWidth
      if (width < 1200 && !detect(window.navigator.userAgent).isPad) {
        this.$refs.advantage.style = `zoom:${width / 580}`
      }
    },
    random() {
      const arr = []
      let add = 0
      while (add < 4) {
        const num = Math.floor(Math.random() * 7) + 1
        if (!arr.includes(num)) {
          arr.push(num)
          add++
        }
      }
      this.arr = arr
    }
  }
}
</script>

<style lang="less">
.index-customer-wrapper {
  padding: 100px 0;
  .i-customer-content {
    .border {
      border: 1px dashed rgba(0, 0, 0, 0.1);
    }
    .index-customer-left {
      width: 540px;
      height: 540px;
      margin-right: 125px;
      border-radius: 270px;
      position: relative;
      border: dashed 1px rgba(229, 97, 49, 0.2);
      .avatar {
        box-shadow: 0 2px 6px 0 rgba(229, 97, 49, 0.15);
        border: solid 4px #fff;
        background: #fff;
        box-sizing: border-box;
        position: absolute;
        &.small {
          width: 40px;
          height: 40px;
          border-radius: 20px;
          // animation-name: small-dh;
          // animation-duration: 2s;
          img {
            width: 32px;
            height: 32px;
            border-radius: 40px;
          }
        }
        &.nomal {
          width: 60px;
          height: 60px;
          border-radius: 30px;
          img {
            width: 52px;
            height: 52px;
            border-radius: 30px;
          }
        }
        &.large {
          width: 80px;
          height: 80px;
          border-radius: 40px;
          img {
            width: 72px;
            height: 72px;
            border-radius: 40px;
          }
        }
      }
      .avatar-1 {
        left: 40px;
        top: 80px;
        &.active {
          animation: small-dh 1s linear 0s infinite normal;
        }
      }
      .avatar-2 {
        top: 5px;
        right: 115px;
        &.active {
          animation: nomal-dh 1s linear 0s infinite normal;
        }
      }
      .avatar-3 {
        bottom: 5px;
        right: 84px;
        &.active {
          animation: large-dh 1s linear 0s infinite normal;
        }
      }
      .around1 {
        width: 350px;
        height: 350px;
        border-radius: 175px;
        margin: 95px 0 0 95px;
        border: dashed 1px rgba(229, 97, 49, 0.3);
        background-color: #fefcfb;

        position: relative;
        .avatar-7 {
          left: -28px;
          top: 170px;
          &.active {
            animation: nomal-dh 1s linear 0s infinite normal;
          }
        }
        .avatar-4 {
          right: -20px;
          top: 90px;
          &.active {
            animation: nomal-dh 1s linear 0s infinite normal;
          }
        }
        .avatar-5 {
          left: 75px;
          top: -15px;
          &.active {
            animation: nomal-dh 1s linear 0s infinite normal;
          }
        }
        .avatar-6 {
          bottom: -20px;
          right: 140px;
          &.active {
            animation: small-dh 1s linear 0s infinite normal;
          }
        }
      }
      .around2 {
        width: 160px;
        height: 160px;
        border-radius: 80px;
        margin: 95px 0 0 95px;
        border: dashed 1px rgba(229, 97, 49, 0.5);
        // background-image: radial-gradient(circle at 50% 50%, #fff, #fbf6f4 71%);
      }
      .around3 {
        width: 98px;
        height: 98px;
        border-radius: 49px;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.08);
        border: solid 1px rgba(229, 97, 49, 0.1);
        margin: 31px 0 0 31px;
        background: #fff;
        text-align: center;
        line-height: 98px;
        overflow: hidden;
      }
    }
    .index-customer-right {
      padding-right: 88px;
      flex: 1;
      .new-title {
        text-align: left;
        margin-bottom: 12px;
        &::after {
          left: 0;
          margin-left: 0;
        }
      }
      .index-customer-item {
        h4 {
          font-size: 22px;
          color: #e56131;
          margin-bottom: 20px;
          height: 20px;
          align-items: center;
          .iconfont {
            font-weight: normal;
            margin-right: 3px;
            position: relative;
            left: -5px;
          }
        }
        p {
          font-size: 14px;
          color: #666;
          line-height: 2;
        }
      }
    }
  }
}

@keyframes avatar1 {
  0% {
    left: 40px;
  }
  50% {
    left: 30px;
  }
  100% {
    left: 40px;
  }
}
@keyframes small-dh {
  0% {
    width: 40px;
    height: 40px;
    border-radius: 20px;
  }
  50% {
    width: 60px;
    height: 60px;
    border-radius: 30px;
  }
  100% {
    width: 40px;
    height: 40px;
    border-radius: 20px;
  }
}
@keyframes nomal-dh {
  0% {
    width: 60px;
    height: 60px;
    border-radius: 30px;
  }
  50% {
    width: 80px;
    height: 80px;
    border-radius: 40px;
  }
  100% {
    width: 60px;
    height: 60px;
    border-radius: 30px;
  }
}
@keyframes large-dh {
  0% {
    width: 80px;
    height: 80px;
    border-radius: 40px;
  }
  50% {
    width: 100px;
    height: 100px;
    border-radius: 50px;
  }
  100% {
    width: 80px;
    height: 80px;
    border-radius: 40px;
  }
}
@media screen and (max-width: 640px) {
  .index-customer-wrapper {
    padding: 40px 0;
    .mobile-title {
      margin-bottom: 50px;
    }
    .i-customer-content {
      flex-direction: column;
      .index-customer-left {
        width: 90%;
        margin: 0 auto;
      }
      .index-customer-right {
        padding-right: 0;
        padding: 0 16px;
      }
    }
  }
}
</style>
