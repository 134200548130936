<script>
import { isEqual } from 'lodash'
import AddressCascader from './AddressCascader'
import FormRender from '~/components/FormRender'

export default {
  props: {
    textMode: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      required: true
    },
    filter: {
      type: Function,
      default: (rows) => rows
    }
  },

  data() {
    return {
      countries: [],
      provinces: [],
      cities: [],
      counties: [],
      model: {
        postcode: null,
        country: null,
        province: null,
        city: null,
        county: null,
        street1: null,
        street2: null,
        street3: null
      },
      postcodeData: [],
      fetchPostcodeLoading: false,
      postcodeVisible: false
    }
  },

  watch: {
    model: {
      deep: true,
      handler(value) {
        this.$emit('input', { ...this.value, ...value })
      }
    },
    value: {
      deep: true,
      handler() {
        this.syncModel()
      }
    }
  },

  mounted() {
    this.fetchCountries()
    this.syncModel()
  },

  methods: {
    syncModel() {
      const value = this.value
      const model = {
        postcode: value.postcode || null,
        country: value.country || null,
        province: value.province || null,
        city: value.city || null,
        county: value.county || null,
        street1: value.street1 || null,
        street2: value.street2 || null,
        street3: value.street3 || null
      }

      if (isEqual(model, this.model)) {
        return
      }

      this.model = model
    },
    fetchUKPostcode() {
      const that = this
      that.fetchPostcodeLoading = true
      // Pass parameters via JSON
      const parameters = {
        key: process.env.FETCHIFY_KEY,
        response: 'data_formatted',
        postcode: this.model.postcode,
        sort: 'asc',
        lines: 3
      }
      const url = 'https://pcls1.craftyclicks.co.uk/json/rapidaddress'
      // or via GET parameters
      // var url = "http://pcls1.craftyclicks.co.uk/json/basicaddress?key=xxxxx-xxxxx-xxxxx-xxxxx&postcode=aa11aa&response=data_formatted";
      return new Promise((resolve, reject) => {
        const request = new XMLHttpRequest()
        request.open('POST', url)
        // Only needed for the JSON parameter pass
        request.setRequestHeader('Content-Type', 'application/json')
        // Wait for change and then either JSON parse response text or throw exception for HTTP error
        request.onreadystatechange = function() {
          if (this.readyState === 4) {
            if (this.status >= 200 && this.status < 400) {
              // Success!
              const data = JSON.parse(this.responseText)
              const postcodeData = data.delivery_points
                ? data.delivery_points.map((item) => {
                    return {
                      postcode: data.postcode,
                      city: data.town,
                      street1: item.line_1,
                      street2: item.line_2,
                      street3: item.line_3
                    }
                  })
                : []
              resolve(data)

              if (!postcodeData.length) {
                alert(
                  data.error_msg
                    ? `服务暂时不可用, 错误代码:${data.error_code}`
                    : '未查询到地址'
                )
              }

              that.postcodeData = postcodeData

              that.$nextTick(() => {
                that.fetchPostcodeLoading = false
                that.postcodeVisible = true
              })
            } else {
              reject(new Error('HTTP Request Error'))
              that.fetchPostcodeLoading = false
            }
          }
        }
        // Send request
        request.send(JSON.stringify(parameters))
      })
    },
    async fetchCountries() {
      const { data } = await this.$api.fetchCountries()
      this.countries = data
    },
    handleCountry() {
      this.model.province = null
      this.model.city = null
      this.model.postcode = null
      this.model.county = null
      this.model.street1 = null
      this.model.street2 = null
      this.model.street3 = null
    },
    renderByCN() {
      return [
        {
          col: 12,
          prop: 'country',
          label: '所在国家/地区',
          node: (
            <Select
              placeholder="所在国家/地区"
              v-model={this.model.country}
              onInput={this.handleCountry}
            >
              {this.countries.map((item) => (
                <Option value={item.code} key={item.code}>
                  {item.name}
                </Option>
              ))}
            </Select>
          ),
          rules: {
            required: true,
            trigger: 'change',
            message: '请选择所在国家/地区'
          }
        },
        {
          col: 12,
          prop: 'province',
          label: '所在省市区',
          node: (
            <AddressCascader placeholder="所在省市区" v-model={this.model} />
          ),
          rules: {
            required: true,
            trigger: 'change',
            message: '请选择所在省市区'
          }
        },
        {
          col: 24,
          label: '具体地址',
          prop: 'street1',
          node: (
            <Input
              placeholder="具体地址"
              v-model={this.model.street1}
              maxlength="60"
            />
          ),
          rules: {
            required: true,
            trigger: 'blur',
            message: '请输入具体地址'
          }
        }
      ]
    },
    renderPostNode() {
      const inputNode = (
        <Input
          style="width: 280px"
          placeholder="所在地邮编"
          autocomplete="new-password"
          type="text"
          v-model={this.model.postcode}
        />
      )

      if (this.model.country !== 'UK') {
        return inputNode
      }

      const isValidCode = /^[A-Z]{1,2}[0-9]{1,2}[A-Z]{0,1} ?[0-9][A-Z]{2}$|aa11aa/i.test(
        this.model.postcode
      )

      const handleSelectCode = (item) => {
        this.model.postcode = item.postcode
        this.model.street1 = item.street1
        this.model.street2 = item.street2
        this.model.street3 = item.street3
        this.model.city = item.city

        this.postcodeVisible = false
      }

      return (
        <Dropdown
          trigger="custom"
          visible={this.postcodeVisible}
          class="postcode-uk"
        >
          {inputNode}
          <Button
            loading={this.fetchPostcodeLoading}
            onClick={this.fetchUKPostcode}
            disabled={!isValidCode}
            type="primary"
            size="small"
          >
            查询地址
          </Button>
          {!!this.postcodeData.length && (
            <DropdownMenu slot="list">
              {this.postcodeData.map((item, index) => (
                <DropdownItem
                  key={index}
                  nativeOnClick={() => handleSelectCode(item)}
                >
                  {`${[
                    ...new Set([item.street1, item.street2, item.street3])
                  ].join(', ')}${item.city}`}
                </DropdownItem>
              ))}
            </DropdownMenu>
          )}
        </Dropdown>
      )
    },
    renderByOther() {
      return [
        {
          col: 7,
          prop: 'country',
          label: '所在国家/地区',
          node: (
            <Select
              placeholder="所在国家/地区"
              v-model={this.model.country}
              onInput={this.handleCountry}
            >
              {this.countries.map((item) => (
                <Option value={item.code} key={item.code}>
                  {item.name}
                </Option>
              ))}
            </Select>
          ),
          rules: {
            required: true,
            message: '请选择所在国家/地区'
          }
        },
        {
          col: 8,
          prop: 'postcode',
          label: '邮编',
          node: this.renderPostNode(),
          rules: {
            required: false,
            message: '请输入邮编'
          }
        },
        {
          col: 9,
          prop: 'city',
          label: '城市',
          node: (
            <Input
              placeholder="所在城市"
              v-model={this.model.city}
              maxlength="20"
            />
          ),
          rules: {
            required: true,
            trigger: 'blur',
            message: '请输入所在城市'
          }
        },
        {
          col: 7,
          prop: 'province',
          label: '所在省/州',
          node: (
            <Input
              placeholder="所在省/州"
              v-model={this.model.province}
              maxlength="20"
            />
          ),
          rules: {}
        },
        {
          col: 17,
          label: '所在地址',
          node: (
            <Row gutter={10} type="flex">
              <Col span="8">
                <FormItem prop="street1">
                  <Input
                    placeholder="地址第一行"
                    v-model={this.model.street1}
                    maxlength="50"
                  />
                </FormItem>
              </Col>
              <Col span="8">
                <FormItem prop="street2">
                  <Input
                    placeholder="地址第二行"
                    v-model={this.model.street2}
                    maxlength="50"
                  />
                </FormItem>
              </Col>
              <Col span="8">
                <FormItem prop="street3">
                  <Input
                    placeholder="地址第三行"
                    v-model={this.model.street3}
                    maxlength="50"
                  />
                </FormItem>
              </Col>
            </Row>
          ),
          rules: {
            required: true,
            trigger: 'blur',
            message: '请输入所在地址'
          }
        }
      ]
    }
  },

  render() {
    const { model } = this
    const rows =
      this.model.country === 'CN' ? this.renderByCN() : this.renderByOther()

    const matchCountry = this.countries.find(
      (item) => item.code === model.country
    )
    let fields = null

    if (model.country === 'CN') {
      fields = [
        matchCountry && matchCountry.name,
        model.province,
        model.city,
        model.county,
        model.street1
      ]
    } else {
      fields = [
        matchCountry && matchCountry.name,
        model.city,
        model.province,
        model.street1,
        model.street2,
        model.street3,
        `(${model.postcode})`
      ]
    }

    return this.textMode ? (
      <span>{fields.filter(Boolean).join(' ')}</span>
    ) : (
      <FormRender rows={this.filter(rows)} />
    )
  }
}
</script>

<style lang="less">
.postcode-uk {
  position: relative;

  button {
    position: absolute !important;
    right: 10px;
    top: 5px;
  }

  .ivu-select-dropdown {
    max-height: 300px !important;
    overflow: auto !important;
  }
}
</style>
