<template>
  <div class="account-home-container">
    <div class="overview container">
      <div class="avatar">
        <nuxt-link to="/user-info">
          <img src="~/assets/images/account/avatar.png" />
        </nuxt-link>
        <Username :user="user" :on-success="handleUsernameChange" />
        <a href="javascript:0;" @click.prevent="changePassword">修改密码</a>
        <a v-if="termsConditionsUrl" :href="termsConditionsUrl" target="_blank">
          <div><Icon :size="16" type="md-archive" />用户协议</div>
        </a>

        <a v-if="!!user.un_read_message_count" href="#news" class="msg-tips">
          <Button type="text" size="small">
            <span class="num">{{
              user.un_read_message_count > 99
                ? '99+'
                : user.un_read_message_count
            }}</span>

            <div class="tips">
              {{ user.un_read_message_count }}条未读重要站内信，请及时读取
            </div>
          </Button>
        </a>
        <a
          href="https://form.asana.com/?k=SATRLzvzK8u0F8UA5bUDkA&d=1183493859185320"
          class="msg-tips feedback"
          target="_blank"
        >
          <Button type="text" size="small">
            <div class="tips">
              欢迎提交您的宝贵意见或建议
            </div>
          </Button>
        </a>
      </div>

      <div class="overview-items">
        <div class="currency-account">
          <h3>预定金账户</h3>
          <BalanceDeposit :value="parseFloat(user.gbp_balance)" />
        </div>
        <div class="currency-account">
          <h3>所有消费卡总余额</h3>
          <Currency
            type="£"
            :value="parseFloat(user.card_balance_total || 0)"
          />
        </div>
        <div class="currency-account">
          <h3>返佣账户余额</h3>
          <Currency type="£" :value="user.commission_gbp_balance" />
          <Button
            class="mt-[-10px]"
            to="/account/rebate-detail"
            type="primary"
            size="small"
          >
            提现 / 详情
          </Button>
        </div>
      </div>
    </div>

    <AccountExtraTips />

    <div class="order-overview">
      <div class="container">
        <h1>发货订单总览</h1>
        <div>
          <div class="tax">
            <div class="sum">
              <div>完成的退税返佣</div>
              <Currency
                color="orange"
                :value="statis_dict.rebate_processed"
                type="£"
              />
              <Tooltip
                :content="
                  `含 VAT 金额为: £${statis_dict.rebate_processed_vaild}`
                "
                placement="top"
              >
                <Icon type="ios-help-circle-outline" />
              </Tooltip>
            </div>
            <div class="sum">
              <div>等待的退税返佣</div>
              <Currency
                color="green"
                :value="statis_dict.rebate_pending"
                type="£"
              />
              <Tooltip
                :content="
                  `预估含 VAT 金额为: £${statis_dict.rebate_pending_vaild}`
                "
                placement="top"
              >
                <Icon type="ios-help-circle-outline" />
              </Tooltip>
            </div>
          </div>
          <div class="stages">
            <div
              v-for="step of orderSteps"
              :key="step.name"
              :style="step.style"
              class="stage"
            >
              <div :style="step.style">{{ step.value }}</div>
              <div>{{ step.name }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="news" class="news">
      <div class="container">
        <div class="news-list">
          <div class="news-title">站内信息</div>
          <ul class="news-items">
            <li
              v-for="(newsItem, index) in messages.data"
              :key="index"
              :class="{ active: newsItem.is_read }"
            >
              <span class="news-time">{{
                newsItem.created_at.substring(0, 10)
              }}</span>
              <Nuxt-link :to="`/message/${newsItem.id}`" class="news-doc-title">
                <span class="news-type text-orange">
                  {{ newsItem.message.title }}
                </span>
              </Nuxt-link>
              <span v-if="newsItem.is_read" class="news-status text-orange"
                >已读</span
              >
              <span v-else class="news-status text-orange">未读</span>
            </li>
          </ul>
          <div class="news-more">
            <Page
              :total="messages.paginator && messages.paginator.count"
              :page-size="4"
              size="small"
              @on-change="(page) => fetchMessage(page)"
            />
          </div>
        </div>
        <div class="news-list">
          <div class="news-title">新闻中心</div>
          <ul class="news-items">
            <li v-for="(newsItem, index) in news.data" :key="index">
              <span class="news-time">{{
                newsItem.created_at.substring(0, 10)
              }}</span>
              <Nuxt-link :to="`/news/${newsItem.id}`" class="news-doc-title">
                <span class="news-type text-orange">
                  {{ newsItem.title }}
                </span>
              </Nuxt-link>
            </li>
          </ul>
          <div class="news-more">
            <Page
              :total="news.paginator && news.paginator.count"
              :page-size="4"
              size="small"
              @on-change="(page) => fetchNews(page)"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="bill-list">
      <div class="container">
        <div class="t-table w-full">
          <h3>等待上传票据的采购</h3>
          <Table
            border
            stripe
            class="table-blue"
            :columns="columns1"
            :data="phs_pending"
          />
        </div>
        <div class="t-table w-full">
          <h3>等待邮寄小票原件的采购</h3>
          <Table
            border
            stripe
            class="table-green"
            :columns="columns1"
            :data="phs_approved"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import { detect } from '~/utils/detect'
import AccountExtraTips from '~/components/AccountExtraTips.vue'
import Username from '~/components/account/Username.vue'
import { updateLoginInfo } from '~/pages/login.vue'
import BalanceDeposit from '~/components/BalanceDeposit.vue'

export default {
  components: {
    AccountExtraTips,
    Username,
    BalanceDeposit
  },
  scrollToTop: true,
  head() {
    return {
      title: '我的ishopper'
    }
  },
  meta: {
    private: true
  },
  data() {
    return {
      statis_dict: { rebate_pending: 0, rebate_processed: 0, created: 0 },

      columns1: [
        {
          title: '采购时间',
          key: 'purchase_time',
          render: (_, { row }) => {
            return (
              <span>
                {this.$dayjs(row.purchase_time).format('YYYY/MM/DD HH:mm:ss')}
              </span>
            )
          }
        },
        {
          title: '票据类型',
          key: 'get_invoice_type_display'
        },
        {
          title: '消费卡',
          key: 'card',
          render: (_, { row }) => {
            let number = ''
            if (row.card) {
              number = row.card.number
            }
            return <span>{number}</span>
          }
        },
        {
          title: '金额',
          key: 'amount'
        }
      ],
      messages: [],
      news: [],
      phs_pending: [],
      phs_approved: []
    }
  },
  computed: {
    ...mapState(['user']),
    termsConditionsUrl() {
      return this.user.terms_conditions
        ? `${process.env.IMG_CDN_URL}/${this.user.terms_conditions}`
        : null
    },
    orderSteps() {
      return [
        {
          name: '已创建',
          style: 'border-color: #f7b09c',
          value: this.created
        },
        {
          name: '发货中',
          value: this.shipping,
          style: 'border-color: #f5b654'
        },
        {
          name: '返佣中',
          style: 'border-color: #89c0e2',
          value: this.count_down
        },
        {
          name: '返佣完成',
          style: 'border-color: #a3d57e',
          value: this.processed
        }
      ]
    }
  },
  async asyncData({ $api }) {
    const [phsPending, phsApproved, statisDict] = await Promise.all([
      $api.fetchPuchase({ status: 1 }),
      $api.fetchPuchase({ status: 7 }),
      $api.fetchOrderStatis()
    ])

    const statisDictData = statisDict.data

    return {
      phs_pending: phsPending.data, // 待退税
      phs_approved: phsApproved.data, // 已经退税
      statis_dict: statisDictData,
      created: statisDictData.created, // 已创建
      shipping: statisDictData.shipping, // 发货中
      count_down: statisDictData.count_down, // 待返佣
      processed: statisDictData.processed // 已经返佣
    }
  },
  mounted() {
    this.fetchMessage()
    this.fetchNews()
    this.showMobileTips()
  },
  methods: {
    handleUsernameChange({ token, user, sessionid }) {
      updateLoginInfo(this, { token, user, sessionid })
    },
    showMobileTips() {
      const MOBILE_TIPS = 'MOBILE_TIPS'
      if (
        this.$cookies.get('MOBILE_TIPS') ||
        detect(window.navigator.userAgent).isPc
      ) {
        return
      }

      this.$Modal.warning({
        title: '提示',
        width: '600px',
        content: `尊敬的用户，您现在正在使用移动端设备访问 iShopper 网站控制台，浏览体验会受到些许影响。目前我们正在抓紧开发基于微信小程序的iShopper控制台用以优化您的手机浏览体验。上线后我们会及时通知，欢迎届时试用及反馈。`,
        okText: '知道了',
        onOk: () => {
          this.$cookies.set(MOBILE_TIPS, true, {
            maxAge: 1440, // 1day
            path: '/'
          })
        }
      })
    },
    changePassword() {
      this.$portal({
        context: this,
        component: () => import('~/components/ChangePassword')
      })
    },

    async fetchMessage(page = 1, perpage = 4) {
      const messages = await this.$api.fetchMessage({
        perpage,
        page
      })
      this.messages = messages
    },
    async fetchNews(page = 1, perpage = 4) {
      const news = await this.$api.fetchNews({
        perpage,
        page
      })
      this.news = news
    },
    showReferralLink() {
      this.$portal({
        context: this,
        component: () => import('~/components/account/ReferralLink'),
        props: {}
      })
    },
    async goto_s2c() {
      const url = await this.$api.fetchRedirectUrl({ next_url: '/' })
      window.open(url.redirect_url, '_blank')
    }
  }
}
</script>

<style lang="less">
.account-home-container {
  position: relative;

  .overview {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 0;

    .avatar {
      display: flex;
      flex-flow: column wrap;
      align-items: center;
      position: relative;

      .msg-tips {
        width: 35px;
        height: 35px;
        line-height: 30px;
        text-align: center;
        padding: 0;
        position: absolute;
        right: -50px;
        top: 0;
        background: #f6f6f6;
        border-radius: 50%;

        & + .msg-tips {
          top: 50px;
        }

        &.feedback {
          &:after {
            width: 40px;
            height: 41px;
            left: 8px;
            top: 8px;
            background: url('~@/assets/images/account/意见反馈-min.png')
              no-repeat;
            background-size: 45%;
          }
        }

        &:hover {
          .tips {
            display: block;
            z-index: 2;
          }
        }

        .num {
          width: 16px;
          height: 16px;
          line-height: 16px;
          color: #fff;
          font-size: 10px;
          display: inline-block;
          border-radius: 50%;
          background: #e7532f;
          position: absolute;
          top: 0;
          right: 0;
          margin: -6px;
        }

        &::after {
          content: '';
          width: 61px;
          height: 45px;
          background: url(~@/assets/images/account/icon-envelope.png) no-repeat
            0 0;
          background-size: 35%;
          display: block;
          position: absolute;
          top: 9px;
          left: 7px;
        }

        .tips {
          position: absolute;
          bottom: -28px;
          left: 0;
          line-height: 12px;
          font-size: 12px;
          color: #fff;
          padding: 3px 8px;
          border-radius: 2px;
          background: #e7532f;
          display: none;

          &:after,
          &:before {
            bottom: 100%;
            left: 17px;
            border: solid transparent;
            content: '';
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
          }

          &:after {
            border-color: rgba(231, 83, 47, 0);
            border-bottom-color: #e7532f;
            border-width: 3px;
            margin-left: -3px;
          }
          &:before {
            border-color: rgba(231, 83, 47, 0);
            border-bottom-color: #e7532f;
            border-width: 5px;
            margin-left: -5px;
          }
        }
      }

      a {
        display: flex;
        flex-flow: column;
        align-items: center;
      }

      + div {
        display: flex;
        align-items: center;

        .currency-account {
          margin: 0 60px;
          position: relative;
          flex: none;

          .referral-link {
            white-space: nowrap;
            display: block;
            position: relative;
            top: 5px;
          }
        }
      }

      img {
        width: 130px;
        height: 130px;
        background-size: cover;
        border-radius: 50%;
        margin-bottom: 10px;
      }

      span {
        font-size: 16px;
        color: #3b3b3b;
      }
    }
  }

  .overview-items {
    width: 880px;
    position: relative;
  }

  .order-overview {
    padding: 60px 0;
    background: #f8f8f8;

    h1 {
      text-align: center;
      font-size: 24px;
      color: #3b3b3b;
      font-weight: normal;
      margin-bottom: 40px;

      ~ div {
        display: flex;
      }
    }

    .tax {
      margin-left: 80px;

      .sum + .sum {
        margin-top: 20px;
      }

      .sum > div {
        font-size: 16px;
        color: #3b3b3b;
      }
    }

    .stages {
      display: flex;
      margin-left: 100px;
      margin-top: 10px;

      .stage {
        width: 142px;
        height: 142px;
        border: 1px solid;
        border-radius: 50%;
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
        align-items: center;
        padding: 0 20px;
        position: relative;

        & + .stage {
          margin-left: 75px;

          &:after {
            content: '';
            width: 49px;
            height: 5px;
            background: url('@~assets/images/account-home/account-home-1.png')
              no-repeat 0 0;
            position: absolute;
            top: 83px;
            left: -62px;
          }
        }

        div:nth-child(1) {
          width: 100%;
          line-height: 1;
          text-align: center;
          font-size: 56px;
          border-bottom: 1px solid;
          padding-bottom: 8px;
          margin-bottom: 5px;
        }
        div:nth-child(2) {
          font-size: 18px;
        }
      }
    }
  }

  .news {
    padding: 50px 0;

    .news-title {
      font-size: 24px;
      color: #3b3b3b;
      border-bottom: 1px solid #f4f4f4;
      padding: 0 0 10px 140px;
      margin-bottom: 10px;
    }

    .container {
      display: flex;
      justify-content: center;

      .news-list {
        width: 460px;

        & + .news-list {
          margin-left: 100px;
        }
      }
    }

    .news-items {
      border-bottom: 1px solid #f4f4f4;
      margin-top: 15px;
      margin-bottom: 15px;
      min-height: 175px;

      li {
        margin-bottom: 25px;
        display: flex;

        &.active {
          .news-type,
          .news-status {
            color: #999999 !important;
          }
        }

        &:last-child {
          margin-bottom: 15px;
        }

        .news-time {
          font-size: 14px;
          color: #999999;
          margin-right: 20px;
        }
        .news-doc-title {
          width: 214px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-right: 20px;
          color: #3b3b3b;
          flex: 1;
        }
        .news-status {
          margin-left: auto;
          font-size: 14px;
          color: #999999;
        }
      }
    }

    .news-more {
      display: flex;
      justify-content: center;
    }
  }

  .bill-list {
    padding: 70px 0 100px;
    background: #f8f8f8;

    .t-table {
      & + .t-table {
        margin-top: 70px;
      }
      h3 {
        font-size: 24px;
        color: #3b3b3b;
        text-align: center;
        font-weight: normal;
        margin-bottom: 30px;
      }
    }
  }

  .pop-tip {
    .ivu-poptip-body-content {
      padding: 5px 5px;
      text-align: right;

      p {
        font-size: 14px;

        i {
          font-size: 18px;
          color: #84cbd8;
        }
      }

      button {
        margin: 10px 0 0;
        line-height: 1;
        font-size: 12px;
        display: inline-block;
        padding: 3px 8px;
        line-height: 1 !important;
      }
    }
  }
}
</style>
