<template>
  <ul class="comp-service-list">
    <li v-for="item of services" :key="item.name">
      <img :src="item.icon" />
      <div>
        <p>{{ item.name }}</p>
        <span :style="`background:${item.color}`" />
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  data() {
    return {
      services: [
        {
          icon: require('~/assets/images/home/p-s-0.png'),
          name: '购物折扣',
          color: '#f78869'
        },
        {
          icon: require('~/assets/images/home/p-s-1.png'),
          name: '一站式退税',
          color: '#f7c982'
        },
        {
          icon: require('~/assets/images/home/p-s-2.png'),
          name: '直达物流',
          color: '#c0e2a7'
        }
      ]
    }
  }
}
</script>

<style lang="less">
.comp-service-list {
  display: flex;
  justify-content: space-between;
  padding: 0 140px 100px;
  position: relative;

  &::after {
    content: '';
    width: 99%;
    height: 2px;
    position: absolute;
    top: 100px;
    left: 50%;
    transform: translate(-50%, 0);
    background: #f2f2f2;
  }

  li {
    flex-shrink: 0;
    width: 200px;
    text-align: center;
    font-size: 20px;
    position: relative;
    z-index: 2;
    background: #fff;
    padding: 0 5px;

    & > div {
      text-align: center;
    }

    p {
      font-size: 26px;
      color: #3b3b3b;
      margin: 15px 0 0 0;

      & + span {
        width: 30px;
        height: 5px;
        display: inline-block;
      }
    }
  }
}
</style>
