<script>
import { useVm } from '~/use'

export default {
  props: {
    data: {
      type: Object,
      required: true
    },
    whenSubmitExpress: {
      type: Function,
      default: () => {}
    }
  },
  setup(props) {
    const vm = useVm()

    return () => {
      const data = props.data

      const statusMap = [
        {
          conditions: [vm.$ENUM.PURCHASE_STATUS.UPLOAD],
          render: () => (
            <span class="text-[orange]">
              <Icon class="animate-spin" type="ios-sync" />{' '}
              {vm.$ENUM.PURCHASE_TYPE[data.status]}
            </span>
          )
        },
        {
          conditions: [
            vm.$ENUM.PURCHASE_STATUS.DEPRECATED,
            vm.$ENUM.PURCHASE_STATUS.PENDING
          ],
          render: () => {
            const url =
              data.purchase_type === 0
                ? `/account/purchase/upload?id=${data.id}`
                : `/account/purchase/refund?id=${data.id}`
            const text = data.purchase_type === 0 ? '待上传' : '待核销'

            return (
              <Tooltip
                transfer
                max-width="250"
                placement="top"
                content="点击处理"
              >
                <Nuxt-link to={url}>
                  <Icon class="animate-pulse" type="md-cloud-upload" /> {text}
                </Nuxt-link>
              </Tooltip>
            )
          }
        },
        {
          conditions: [vm.$ENUM.PURCHASE_STATUS.UPLOAD],
          render: () => (
            <span class="text-[orange]">
              <Icon class="animate-spin" type="ios-sync" />{' '}
              {vm.$ENUM.PURCHASE_TYPE[data.status]}
            </span>
          )
        },
        {
          conditions: [
            vm.$ENUM.PURCHASE_STATUS.DSIAPPROVED,
            vm.$ENUM.PURCHASE_STATUS.RECONFAIL
          ],
          render: () => (
            <Poptip
              title={`未通过原因`}
              trigger="hover"
              width="150"
              transfer
              word-wrap
              content={data.reject_reason || '暂无原因'}
            >
              <Nuxt-link
                class="text-[#fb5120]"
                to={`/account/purchase/upload?id=${data.id}`}
              >
                <span>
                  <Icon class="animate-bounce" type="ios-information-circle" />{' '}
                  {vm.$ENUM.PURCHASE_TYPE[data.status]}
                </span>
              </Nuxt-link>
            </Poptip>
          )
        },
        {
          conditions: [vm.$ENUM.PURCHASE_STATUS.APPROVE],
          render: () => {
            return (
              <Tooltip
                transfer
                max-width="250"
                placement="top"
                content="点击处理"
              >
                <a onClick={() => props.whenSubmitExpress(data)}>
                  <Icon class="animate-pulse" type="md-cloud-upload" />{' '}
                  {vm.$ENUM.PURCHASE_TYPE[data.status]}
                </a>
              </Tooltip>
            )
          }
        },
        {
          conditions: [vm.$ENUM.PURCHASE_STATUS.RECONCILED],
          render: () => {
            // 异常采购
            if (data.stock > 0 && !!data.trouble && data.purchase_type === 0) {
              return (
                <Poptip
                  title={`商品未全部核销`}
                  trigger="hover"
                  transfer
                  word-wrap
                  content={`有 ${data.stock} 件商品未附加发货核销`}
                >
                  <Nuxt-link class="text-[orange]" to={`/account/order/create`}>
                    <span>
                      <Icon class="animate-pulse" type="ios-checkmark-circle" />{' '}
                      {vm.$ENUM.PURCHASE_TYPE[data.status]}
                    </span>
                  </Nuxt-link>
                </Poptip>
              )
            }

            return (
              <span class="text-green-600">
                <Icon type="ios-checkmark-circle" />{' '}
                {vm.$ENUM.PURCHASE_TYPE[data.status]}
              </span>
            )
          }
        }
      ]

      const matchStatus = statusMap.find((item) =>
        typeof item.conditions === 'function'
          ? item.conditions?.()
          : item.conditions.includes(data.status)
      )

      return matchStatus?.render()
    }
  }
}
</script>

<style lang="scss" scoped></style>
