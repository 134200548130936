<template>
  <div>
    <div
      v-show="!isMobile"
      class="new-wrapper__header"
      :class="{ active: isActive }"
    >
      <div class="new-center-container new-header__box flexRow">
        <div class="new-header__logo">
          <a href="/">
            <img src="../../assets/images/index/logo.svg" />
          </a>
        </div>
        <div class="new-header__menu flexRow">
          <ul class="header-menu__slide flexRow">
            <li><nuxt-link to="/">首页</nuxt-link></li>
            <li><nuxt-link to="/about">关于我们</nuxt-link></li>
            <li><nuxt-link to="/news">折扣资讯</nuxt-link></li>
            <li><nuxt-link to="/contact">联系我们</nuxt-link></li>
            <li><nuxt-link to="/aff-program">合作伙伴计划</nuxt-link></li>
          </ul>
          <div class="header-menu__actions flexRow">
            <div v-if="!user || !user.id" class="unlogin">
              <RButton href="/login">登录</RButton>
              <RButton href="/register" type="primary">注册</RButton>
            </div>
            <div
              v-else
              class="logined"
              :class="{ active: active }"
              @mouseenter="showDrop"
              @mouseleave="hideDrop"
            >
              <RButton type="primary" href="/account">我的账户</RButton>
              <div class="drop__options">
                <ul>
                  <li><a href="/account">我的账户</a></li>
                  <li><a @click="logout(true)">退出登录</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-show="isMobile"
      class="new-mobile__header m-lr-14 flexRow"
      :class="{ active: isActive || showMenus }"
    >
      <div class="new-header-__logo">
        <img src="../../assets/images/index/logo.svg" />
      </div>
      <div class="new-mobile-btns" @click="show">
        <Iconfont :name="showMenus ? 'X' : 'menu'" size="24" color="#000" />
      </div>
    </div>
    <div v-if="showMenus" class="new-mobile__menu">
      <div class="new-mobile-menu__content new-pt-40">
        <ul>
          <li @click="hide"><nuxt-link to="/">首页</nuxt-link></li>
          <li @click="hide"><nuxt-link to="/about">关于我们</nuxt-link></li>
          <li @click="hide"><nuxt-link to="/news">折扣资讯</nuxt-link></li>
          <li @click="hide"><nuxt-link to="/contact">联系我们</nuxt-link></li>
          <li @click="hide">
            <nuxt-link to="/aff-program">合作伙伴计划</nuxt-link>
          </li>
        </ul>
        <div v-if="!user || !user.id" class="btns flexRow m-lr-14">
          <RButton href="/login">登录</RButton>
          <RButton href="/register" type="primary">注册</RButton>
        </div>
        <div v-else class="btns flexRow m-lr-14">
          <RButton href="/register">注册</RButton>
          <RButton type="rdefault" @click="logout(true)">退出登录</RButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Iconfont from '../../iconfont'
import RButton from './RButton'
import bus from '~/utils/bus'
export default {
  components: {
    Iconfont,
    RButton
  },
  props: {
    isMobile: {
      type: Boolean,
      default: null
    }
  },
  data() {
    return {
      isActive: false,
      showMenus: false,
      active: false
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    }
  },
  mounted() {
    window.onscroll = () => {
      this.isActive = !!(document.documentElement.scrollTop > 10)
    }
    bus.$on('logout', this.logout)
  },
  destroyed() {
    window.onscroll = () => {}
  },
  methods: {
    show() {
      this.showMenus = !this.showMenus
    },
    hide() {
      console.log('hide')
      this.showMenus = false
    },
    showDrop() {
      this.active = true
    },
    hideDrop() {
      this.active = false
    },
    async logout(report = false) {
      if (report && this.$store.state.token) {
        await this.$api.logout()
      }

      this.$store.commit('SET_TOKEN', null)
      this.$store.commit('SET_USER', null)
      this.$cookies.remove('token', { path: '/' })
      this.$cookies.remove('token', {
        path: '/',
        domain: location.hostname
          .split('.')
          .slice(-2)
          .join('.')
      })
      this.$router.replace('/login')

      window.dataLayer.push({
        user_id: undefined
      })
    }
  }
}
</script>

<style lang="less">
.new-wrapper__header {
  width: 100%;
  min-width: 1140px;
  height: 114px;
  position: fixed;
  top: 0;
  z-index: 1000;
  &.active {
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
    box-shadow: 0 1px 9px 0 rgba(0, 0, 0, 0.05);
    background-color: hsla(0, 0%, 100%, 0.6);
  }

  .new-header__box {
    height: 114px;
    align-items: center;
    justify-content: space-between;

    .new-header__logo {
      width: 160px;
      height: 54px;

      img {
        width: 100%;
        height: 100%;
      }
    }
    .new-header__menu {
      .header-menu__slide li,
      .header-menu__actions .unlogin a,
      .header-menu__actions .logined {
        margin-left: 30px;
      }
      .header-menu__slide {
        align-items: center;
        li {
          a {
            color: #000;
            font-weight: 600;
            position: relative;
            &:hover {
              color: #e56131;
            }
            &.nuxt-link-exact-active {
              &::after {
                content: '';
                width: 100%;
                height: 2px;
                border-radius: 1px;
                background: #e56131;
                position: absolute;
                left: 0;
                bottom: -10px;
              }
            }
          }
        }
      }
      .header-menu__actions {
        .logined {
          position: relative;
          .drop__options {
            display: none;
            width: 100%;
            padding-top: 15px;
            box-sizing: border-box;
            position: absolute;
            left: 0;
            top: 32px;
            ul {
              position: relative;
              border-radius: 4px;
              box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.08);
              border: solid 1px rgba(0, 0, 0, 0.1);
              background-color: #fff;
              padding: 0 10px;
              &::before {
                content: '';
                display: block;
                position: absolute;
                top: -10px;
                left: 38px;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-bottom: 10px solid #d9d9d9;
              }
              &::after {
                content: '';
                display: block;
                position: absolute;
                top: -9px;
                left: 38px;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-bottom: 10px solid #fff;
              }
              li {
                text-align: center;
                height: 40px;
                line-height: 40px;
                &:not(:last-child) {
                  border-bottom: 1px solid #eee;
                }
                a {
                  color: #222;
                }
              }
            }
          }

          &.active {
            .drop__options {
              display: block;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .new-mobile__header {
    position: fixed;
    top: 0;
    height: 75px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    z-index: 2000;
    .new-header-__logo {
      width: 104px;
      height: 35px;
    }

    &.active {
      -webkit-backdrop-filter: blur(6px);
      backdrop-filter: blur(6px);
      box-shadow: 0 1px 9px 0 rgba(0, 0, 0, 0.05);
      background-color: rgba(255, 255, 255, 0.9);
    }
  }
  .new-mobile__menu {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 75px;
    background: rgba(0, 0, 0, 0.3);
    z-index: 1000;

    .new-mobile-menu__content {
      background: #fff;
      ul {
        border-bottom: 1px dashed rgba(0, 0, 0, 0.3);
        li {
          text-align: center;
          padding-bottom: 40px;
          a {
            font-size: 14px;
            font-weight: 600;
            color: #000;
            position: relative;

            &:hover {
              color: #e56131;
            }

            &.nuxt-link-exact-active {
              color: #e56131;
              &::after {
                content: '';
                width: 100%;
                height: 2px;
                border-radius: 1px;
                background: #e56131;
                position: absolute;
                bottom: -10px;
                left: 0;
              }
            }
          }
        }
      }
      .btns {
        justify-content: center;
        padding-top: 40px;
        padding-bottom: 40px;
        a {
          width: 109px;
          &:first-child {
            margin-right: 10px;
          }
        }
      }
    }
  }
}
</style>
