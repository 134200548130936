<template>
  <!-- eslint-disable-next-line vue/no-v-html -->
  <div class="rich-text-container" v-html="data" />
</template>

<script>
export default {
  props: {
    data: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="less">
.rich-text-container {
  width: 900px;
  margin: 0 auto;
  padding: 30px 0 50px;
  position: relative;
  overflow: auto;
  font-family: 'lucida Grande', Verdana, Helvetica, 'Microsoft Yahei', arial,
    sans-serif;
  font-size: 14px;
  line-height: 1.5;
  color: #000;
  word-wrap: break-word;
  white-space: normal;
  font-size: 14px;
  p {
    margin: 1em 0;
  }
  a {
    text-decoration: underline;
    cursor: pointer;
    outline: none;
  }
  h1 {
    margin: 0.67em 0;
    font-size: 2em;
    font-weight: bold;
  }
  h2 {
    margin: 0.83em 0;
    font-size: 1.5em;
    font-weight: bold;
  }
  h3 {
    margin: 1.17em 0;
    font-size: 1.17em;
    font-weight: bold;
  }
  h4 {
    margin: 1.33em 0;
    font-weight: bold;
  }
  h5 {
    margin: 2.33em 0;
    font-size: 0.67em;
    font-weight: bold;
  }
  ul,
  ol {
    padding: 15px;
    margin: 0;
  }
  ul {
    list-style-type: disc;
  }
  ol {
    list-style-type: decimal;
  }
  ol[type='a'] {
    list-style-type: lower-alpha;
  }
  ol[type='i'] {
    list-style-type: lower-roman;
  }
  ul li {
    list-style-position: inside;
    list-style-type: inherit;
  }
  ol li {
    list-style-position: inside;
    list-style-type: inherit;
  }
  td {
    line-height: normal;
  }
  pre {
    width: 95%;
    word-wrap: break-word;
    white-space: pre-wrap;
    height: unset !important;
  }
  img {
    max-width: 100%;
    vertical-align: middle;
    border: 0;
  }
  address,
  cite,
  dfn,
  em,
  var {
    font-style: italic;
  }
  blockquote {
    padding-left: 8px;
    margin: 16px 4px;
    border-left: 1px solid #ccc;
  }
  table {
    word-break: initial;
    border-collapse: separate;
  }
  .MsoNormal {
    margin: 0;
  }
  sup {
    vertical-align: super;
  }
  sub {
    vertical-align: sub;
  }
  sup,
  sub {
    font-size: smaller;
    position: static;
    top: 0;
    line-height: unset;
  }
  hr {
    border-style: inset;
    border-width: 1px;
  }
}
</style>
