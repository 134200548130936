<template>
  <div class="index-advert-wrapper">
    <div
      class="i-advert-wrapper flexColumn"
      :class="isMobile === false ? 'new-center-container' : ''"
    >
      <h2>全英唯一线上海淘自助退税平台</h2>
      <div class="button-wrapper flexColumn">
        <div class="button-text">现在注册立享最高20%退税优惠</div>
        <SpecialButton />
      </div>
    </div>
  </div>
</template>

<script>
import SpecialButton from './SpecialButton.vue'
export default {
  components: {
    SpecialButton
  },
  props: {
    isMobile: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="less">
.index-advert-wrapper {
  box-sizing: border-box;
  height: 350px;
  color: #fff;
  background: url('../../assets/images/index/cat-bg.png') no-repeat center
    center;
  .i-advert-wrapper {
    align-items: center;
    justify-content: center;

    h2 {
      font-size: 32px;
      font-weight: 600;
      padding: 60px 0 40px;
      text-align: center;
    }
    .button-wrapper {
      width: 510px;
      align-items: center;
      background: url('../../assets/images/index/arrows-white.svg') no-repeat
        right 30px;
      .button-text {
        width: 245px;
        height: 63px;
        text-align: center;
        line-height: 63px;
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 40px;
        background: url('../../assets/images/index/box-white.svg') no-repeat
          center center;
      }
    }
  }
}
@media screen and (max-width: 640px) {
  .index-advert-wrapper {
    background-size: cover;
    .i-advert-wrapper {
      h2 {
        font-size: 24px;
      }
      .button-wrapper {
        width: auto;
        margin-bottom: 30px;
        background: url('../../assets/images/index/m-arrows-white.svg')
          no-repeat 275px 17px;
      }
    }
  }
}
</style>
