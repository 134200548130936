<template>
  <div class="user-verify-container">
    <ul class="steps container">
      <li
        v-for="(step, index) of steps"
        :key="step.title"
        class="step-item"
        :class="{
          current: curStepIndex === index,
          active: index <= curStepIndex
        }"
      >
        <span>第{{ step.index }}步</span>
        <p>{{ step.title }}</p>
      </li>
    </ul>

    <div class="contents">
      <component
        :is="curStep.component"
        :key="curStep.title"
        v-bind="curStep.props"
      />
    </div>
  </div>
</template>

<script>
export default {
  layout: 'login',
  head() {
    return {
      title: '用户信息完善'
    }
  },
  meta: {
    private: true
  },
  data() {
    const isResult = !!this.$route.query.result
    return {
      curStepIndex: isResult ? 3 : 0,
      steps: [
        {
          index: '一',
          title: '用户信息完善',
          component: () => import('~/components/user-verify/step1'),
          props: {
            onSubmit: () => {
              this.curStepIndex = 1
            }
          }
        },
        {
          index: '二',
          title: '材料上传',
          component: () => import('~/components/user-verify/step2'),
          props: {
            onSubmit: () => {
              this.curStepIndex = 2
            }
          }
        },
        {
          index: '三',
          title: '条款签字',
          component: () => import('~/components/user-verify/step3'),
          props: {
            onSubmit: () => {
              this.curStepIndex = 3
            }
          }
        },
        {
          index: '四',
          title: '审核状态',
          component: () => import('~/components/user-verify/step4'),
          props: {
            onSubmit: () => {
              if (
                this.status === this.$ENUM.USER_STATUS.REJECT ||
                this.status === this.$ENUM.USER_STATUS.EXPIRED
              ) {
                this.curStepIndex = 0
                return
              }

              this.$router.replace('/account')

              window.dataLayer.push({
                event: 'user_conversion',
                conversion_action: 'real_name_authentication'
              })
            }
          }
        }
      ]
    }
  },
  computed: {
    status() {
      return this.$store.state.user.status
    },
    curStep() {
      return this.steps[this.curStepIndex]
    }
  },
  mounted() {
    if (this.status === this.$ENUM.USER_STATUS.WAITING) {
      this.curStepIndex = 3
    }
  }
}
</script>

<style lang="less">
.user-verify-container {
  .steps {
    display: flex;
    justify-content: center;
    margin-top: -120px;
    color: #fff;
    padding-bottom: 41px;

    .step-item {
      padding-top: 4px;
      text-align: center;
      height: 80px;
      background: url('~@/assets/images/common/step-1.png') no-repeat center;
      opacity: 0.6;
      transition: 0.7s;
      margin-right: 100px;
      position: relative;

      &:last-child {
        margin-right: 0;
      }

      &:not(:last-child)::after {
        content: '';
        width: 80px;
        height: 5px;
        margin-left: 10px;
        display: block;
        background: url('~@/assets/images/common/step-2.png') no-repeat 0 0;
        position: absolute;
        left: 100%;
        top: 50%;
        transform: translate(0, -50%);
      }

      &.current:before {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 5px 5px 5px;
        border-color: transparent transparent #f4f4f4 transparent;
        position: absolute;
        bottom: -41px;
        left: 50%;
        transform: translate(-50%, 0);
      }

      &.active {
        opacity: 1;
      }

      span {
        font-size: 12px;
        font-weight: bold;
        position: relative;
        top: 3px;
      }

      p {
        font-size: 22px;
        font-weight: normal;
        color: #ffffff;
      }
    }
  }
}
</style>
