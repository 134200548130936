<template>
  <div class="bussiness-wrapper-step5">
    <div class="container">
      <div class="title">
        <h3>我们的优势</h3>
        <span class="title-bottom-border" />
      </div>
      <div class="ys-logo">
        <img src="../../../assets/images/landing/logo_black.svg" />
      </div>
      <div class="ys-list">
        <ul class="flexRow">
          <li class="flexColumn">
            <h3>最快T+0</h3>
            <span class="ys-item-border one" />
            <p>跨境支付立即到账</p>
          </li>
          <li class="flexColumn">
            <h3>20%</h3>
            <span class="ys-item-border two" />
            <p>退税金额高达</p>
          </li>
          <li class="flexColumn">
            <h3>最快7天可退</h3>
            <span class="ys-item-border three" />
            <p>定制化退税方案</p>
          </li>
          <li class="flexColumn">
            <h3>3IN1</h3>
            <span class="ys-item-border four" />
            <p>跨境支付 / 物流专家 / 退税法务团队</p>
          </li>
          <li class="flexColumn">
            <h3>10种增值服务</h3>
            <span class="ys-item-border five" />
            <p>海淘转运 / 大宗出口 / 海关事宜</p>
          </li>
        </ul>
      </div>
      <div class="is-list-pc">
        <div class="is-list-two">
          <ul>
            <li class="flexRow">
              <span class="fw" /><span class="fw">传统退税公司</span>
            </li>
            <li class="flexRow">
              <span class="fw">购物金额</span><span>续介于£30~£250</span>
            </li>
            <li class="flexRow">
              <span class="fw">出入境</span
              ><span>必须出境日前90天之内发票</span>
            </li>
            <li class="flexRow">
              <span class="fw">退税比率</span
              ><span>£1000以上12%<br />£10000以上15%</span>
            </li>
            <li class="flexRow">
              <span class="fw">时间花费</span>
              <span>在机场额外花费2-4小时<br />需现场查验，排队等待</span>
            </li>
            <li class="flexRow">
              <span class="fw">退税手续费</span><span>每张小票收取£3-£7</span>
            </li>
            <li class="flexRow">
              <span class="fw">货币兑换</span
              ><span>退税汇率平均高于市场20%左右</span>
            </li>
          </ul>
        </div>
        <div class="is-list-one">
          <h3>ISHOPPER</h3>
          <ul>
            <li>金额限制低至£2</li>
            <li>自消费90日内上传购物小票即可</li>
            <li>用户自选 最高可达20%</li>
            <li>在线退税 无需排队</li>
            <li>无</li>
            <li>实时汇率</li>
          </ul>
        </div>
      </div>
      <div class="is-list-wap">
        <ul class="is-list-wap-one">
          <li class="flexRow">
            <span class="first-title">ISHOPPER</span>
          </li>
          <li class="flexRow">
            <span class="fw">购物金额</span
            ><span class="fd">金额限制低至£2</span>
          </li>
          <li class="flexRow">
            <span class="fw">出入境</span
            ><span class="fd">自消费90日内上传购物小票即可</span>
          </li>
          <li class="flexRow">
            <span class="fw">退税比率</span
            ><span class="fd">用户自选 最高可达20%</span>
          </li>
          <li class="flexRow">
            <span class="fw">时间花费</span>
            <span class="fd">在线退税 无需排队</span>
          </li>
          <li class="flexRow">
            <span class="fw">退税手续费</span><span class="fd">无</span>
          </li>
          <li class="flexRow">
            <span class="fw">货币兑换</span><span class="fd">实时汇率</span>
          </li>
        </ul>
        <ul>
          <li class="flexRow">
            <span class="first">传统退税公司</span>
          </li>
          <li class="flexRow">
            <span class="fw">购物金额</span
            ><span class="ft">续介于£30~£250</span>
          </li>
          <li class="flexRow">
            <span class="fw">出入境</span
            ><span class="ft">必须出境日前90天之内发票</span>
          </li>
          <li class="flexRow">
            <span class="fw">退税比率</span
            ><span class="ft">£1000以上12%<br />£10000以上15%</span>
          </li>
          <li class="flexRow">
            <span class="fw">时间花费</span>
            <span class="ft"
              >在机场额外花费2-4小时<br />需现场查验，排队等待</span
            >
          </li>
          <li class="flexRow">
            <span class="fw">退税手续费</span
            ><span class="ft">每张小票收取£3-£7</span>
          </li>
          <li class="flexRow">
            <span class="fw">货币兑换</span
            ><span class="ft">退税汇率平均高于市场20%左右</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less">
.bussiness-wrapper-step5 {
  background: #ebedf0;
  .container {
    padding-top: 80px;
    padding-bottom: 80px;
    max-width: 1440px;

    background: #ebedf0 url('./triangle.svg') no-repeat 0 0;
    background-size: 100% calc(100% + 12px);
    .ys-logo {
      text-align: center;
      padding-top: 80px;
    }
    .ys-list {
      ul {
        justify-content: center;
        flex-wrap: wrap;
        li {
          width: 270px;
          height: 130px;
          margin: 70px 30px 0;
          justify-content: center;
          align-items: center;
          background: #ffffff;
          box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.08);
          border-radius: 6px;
          border: 1px solid rgba(0, 0, 0, 0.1);
          h3 {
            font-size: 30px;
            font-weight: 500;
            line-height: 42px;
          }
          .ys-item-border {
            display: inline;
            width: 60px;
            height: 1px;
            border-radius: 1px;
            margin: 10px 0;

            &.one {
              background: #0a7aff;
            }
            &.two {
              background: #e56131;
            }
            &.three {
              background: #34c759;
            }
            &.four {
              background: #ff3b30;
            }
            &.five {
              background: #af52de;
            }
          }
        }
      }
    }
    .is-list-wap {
      display: none;
      ul {
        background: #fff;
        box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.08);
        border-radius: 6px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        margin-top: 40px;
        li {
          justify-content: space-between;
          height: 80px;
          align-items: center;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);

          &:last-child {
            border-bottom: none;
          }
          span {
            width: 70%;
            text-align: center;
            &.fw {
              width: 30%;
              font-size: 14px;
              font-weight: 500;
              text-align: right;
            }
            &.ft {
              text-align: left;
              padding: 0 30px 0 30px;
            }
            &.first {
              width: 100%;
              font-size: 14px;
              font-weight: 500;
            }
          }
          // font-weight: 500;
          // font-size: 14px;
          // height: 80px;
          // line-height: 80px;
          // border-top: 1px solid rgba(0, 0, 0, 0.1);
        }

        &.is-list-wap-one {
          margin-top: 40px;
          li {
            span {
              &.first-title {
                font-size: 30px;
                font-weight: 600;
                width: 100%;
                color: #e56131;
              }
              &.fd {
                color: #e56131;
                font-weight: 600;
                text-align: left;
                padding: 0 30px 0 40px;
              }
            }
            &:first-child {
              height: 120px;
              line-height: 120px;
            }
          }
        }
      }
    }
    .is-list-pc {
      // margin-top: 120px;
      margin: 120px auto 0;
      width: 858px;
      background: #ffffff;
      box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.08);
      border-radius: 6px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      position: relative;
      .is-list-two {
        li {
          justify-content: space-between;
          height: 80px;
          align-items: center;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);

          &:last-child {
            border-bottom: none;
          }
          span {
            display: inline-block;
            text-align: center;
            width: 30%;
            &.fw {
              font-size: 14px;
              font-weight: 500;
            }
          }
        }
      }
      .is-list-one {
        width: 270px;
        height: 640px;
        background: #fff;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.08);
        border-radius: 6px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        position: absolute;
        left: 270px;
        top: -42px;
        color: #e56131;
        text-align: center;
        h3 {
          height: 120px;
          line-height: 120px;
          font-size: 30px;
          font-weight: 600;
        }
        ul {
          li {
            font-weight: 500;
            font-size: 14px;
            height: 80px;
            line-height: 80px;
            border-top: 1px solid rgba(0, 0, 0, 0.1);
          }
        }
      }
    }
  }
}
@media screen and (max-width: 640px) {
  .bussiness-wrapper-step5 {
    .container {
      padding-top: 40px;
      padding-bottom: 40px;
      background: #fafafa url('./triangle-light.svg') no-repeat center -10px;
      background-size: calc(100% + 5px) calc(100% + 35px);
      overflow: hidden;
      .ys-logo {
        padding-top: 40px;
      }
      .ys-list {
        ul {
          li {
            margin: 40px 0 0;
            width: 90%;
          }
        }
      }
      .is-list-pc {
        display: none;
      }
      .is-list-wap {
        display: block;
      }
    }
  }
}
</style>
