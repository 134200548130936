<template>
  <div class="new-aff-target new-center-container flexRow">
    <img
      v-lazy="require(`../../assets/images/new-aff/target.png`)"
      class="new-aff-img"
    />
    <div class="aff-target-content">
      <h3 class="new-title">推荐目标人群</h3>
      <div class="aff-target-item new-pt-40">
        <h4 class="flexRow">
          <IconFont size="30" name="circle-check" color="#e56131" />专业海淘买手
        </h4>
        <p>
          经常为国内客户采购商品和奢侈品并邮寄，需要频繁申请退税,我们提供简化流程，帮助客户节省时间，并享受最大的退税优惠
        </p>
      </div>
      <div class="aff-target-item new-pt-40">
        <h4 class="flexRow">
          <IconFont size="30" name="circle-check" color="#e56131" />海外留学生
        </h4>
        <p>
          需要帮国内亲朋好友代购，不熟悉退税流程和繁琐的手续，想要通过便捷的一站式退税服务来省钱
        </p>
      </div>
      <div class="aff-target-item new-pt-40">
        <h4 class="flexRow">
          <IconFont size="30" name="circle-check" color="#e56131" />国内网购用户
        </h4>
        <p>
          喜爱国外优质商品，想要足不出户采购全球线上线下商品和奢侈品，并且以最优的价格买到绝对的正品并享受退税折扣
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import IconFont from '../../iconfont'
export default {
  components: {
    IconFont
  }
}
</script>

<style lang="less">
.new-aff-target {
  justify-content: space-between;
  padding-bottom: 100px;
  .new-aff-img {
    margin-top: 43px;
    width: 540px;
    height: 447px;
  }
  .aff-target-content {
    margin-right: 88px;
    flex-shrink: 0;
    width: 350px;
    .new-title {
      text-align: left;
      margin-bottom: 12px;
      &::after {
        left: 0;
        margin-left: 0;
      }
    }
    .aff-target-item {
      h4 {
        font-size: 22px;
        color: #e56131;
        margin-bottom: 20px;
        height: 20px;
        align-items: center;
        .iconfont {
          font-weight: normal;
          margin-right: 3px;
          position: relative;
          left: -5px;
        }
      }
      p {
        font-size: 14px;
        color: #666;
        line-height: 2;
      }
    }
  }
}
@media screen and (max-width: 640px) {
  .new-aff-target {
    padding-bottom: 40px;
    flex-direction: column;
    padding-top: 85px;
    margin-top: 0;
    position: relative;
    .new-aff-img {
      margin-top: 0;
      width: 100%;
      height: auto;
    }
    .aff-target-content {
      margin-right: 0;
      width: 100%;
      padding-top: 10px;
      .new-title {
        position: absolute;
        top: 0;
        width: 100%;
        text-align: center;

        &::after {
          left: 50%;
          margin-left: -35px;
        }
      }
    }
  }
}
</style>
