var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login-wrapper reversion flexRow"},[_c('div',{staticClass:"login-box-wrapper"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"login-box"},[_vm._m(1),_vm._v(" "),_c('div',{staticClass:"login-tab flexRow new-mb-40"},[_c('a',{class:{
            active: _vm.model.loginMethod === _vm.LOGIN_METHOD.phone
          },on:{"click":function($event){return _vm.changeTab(_vm.LOGIN_METHOD.phone)}}},[_vm._v("手机号登录")]),_vm._v(" "),_c('a',{class:{ active: _vm.model.loginMethod === _vm.LOGIN_METHOD.code },on:{"click":function($event){return _vm.changeTab(_vm.LOGIN_METHOD.code)}}},[_vm._v("验证码登录")]),_vm._v(" "),_c('a',{class:{
            active: _vm.model.loginMethod === _vm.LOGIN_METHOD.username
          },on:{"click":function($event){return _vm.changeTab(_vm.LOGIN_METHOD.username)}}},[_vm._v("用户名登录")])]),_vm._v(" "),_c('div',{staticClass:"login-account-box"},[_c('Form',{ref:"form",attrs:{"hide-required-mark":"","model":_vm.model,"rules":_vm.rules,"label-position":"top"}},[(
              [_vm.LOGIN_METHOD.phone, _vm.LOGIN_METHOD.code].includes(
                _vm.model.loginMethod
              )
            )?_c('FormItem',{attrs:{"label":"手机号码","prop":"phone"}},[_c('NewPhoneSelect',{model:{value:(_vm.model.phone),callback:function ($$v) {_vm.$set(_vm.model, "phone", $$v)},expression:"model.phone"}})],1):_vm._e(),_vm._v(" "),(_vm.model.loginMethod === _vm.LOGIN_METHOD.username)?_c('FormItem',{attrs:{"label":"用户名","prop":"username"}},[_c('Input',{attrs:{"name":"username","placeholder":"请输入您的用户名"},model:{value:(_vm.model.username),callback:function ($$v) {_vm.$set(_vm.model, "username", $$v)},expression:"model.username"}})],1):_vm._e(),_vm._v(" "),(
              [_vm.LOGIN_METHOD.phone, _vm.LOGIN_METHOD.username].includes(
                _vm.model.loginMethod
              )
            )?_c('FormItem',{attrs:{"label":"密码","prop":"password"}},[_c('Input',{attrs:{"type":"password","placeholder":"请输入您的密码"},model:{value:(_vm.model.password),callback:function ($$v) {_vm.$set(_vm.model, "password", $$v)},expression:"model.password"}})],1):_c('FormItem',{staticClass:"code",attrs:{"label":"验证码","prop":"code"}},[_c('Input',{attrs:{"type":"text"},model:{value:(_vm.model.code),callback:function ($$v) {_vm.$set(_vm.model, "code", $$v)},expression:"model.code"}}),_vm._v(" "),_c('a',{staticClass:"code-btn",on:{"click":_vm.handleSendVerifyCode}},[_vm._v("\n              "+_vm._s(_vm.time === 60 ? '获取验证码' : (_vm.time + "s"))+"\n            ")])],1),_vm._v(" "),_c('FormItem',[_c('Rbutton',{staticClass:"login-btn",attrs:{"size":"large","type":"primary"},on:{"click":_vm.submit}},[_vm._v("\n              "+_vm._s(_vm.fetching ? '登录中...' : '登录')+"\n            ")]),_vm._v(" "),_c('button',{staticStyle:{"display":"none"},attrs:{"type":"submit"},on:{"click":_vm.submit}},[_vm._v("\n              submit\n            ")])],1)],1),_vm._v(" "),_vm._m(2)],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"logo new-mb-40"},[_c('a',{attrs:{"href":"/"}},[_c('img',{attrs:{"src":require("../assets/images/index/logo.svg")}})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login-title flexRow new-mb-40"},[_c('h3',[_vm._v("用户登录")]),_vm._v(" "),_c('span',[_vm._v("还没有账户? "),_c('a',{attrs:{"href":"/register"}},[_vm._v("请点击注册")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login-tips"},[_c('a',{attrs:{"href":"/forget"}},[_vm._v("忘记密码？")])])}]

export { render, staticRenderFns }