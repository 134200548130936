<template>
  <div class="article-container">
    <div :style="`background:${color}`" class="article-header-container">
      <div class="container">
        <h1 :style="`background:${color}`" class="article-header-title">
          <span :style="`background:${color}`">{{ title }}</span>
        </h1>
      </div>
    </div>

    <div class="main-container container">
      <RichText v-if="data" :data="data" />
      <div v-else class="rich-text-container">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import RichText from '~/components/RichText'

export default {
  components: {
    RichText
  },
  props: {
    title: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: '#ea6935'
    },
    data: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="less">
.article-container {
  background: #f8f8f8;

  .rich-text-container {
    padding: 0;
    width: 100%;
  }

  .article-header-container {
    height: 100px;
    color: #fff;
    background: #ea6935;
    position: relative;

    &::before {
      content: '';
      width: 50%;
      height: 2px;
      position: absolute;
      top: 64px;
      left: 0;
      background: #fff;
      opacity: 0.3;
    }

    &::after {
      content: '';
      width: 50%;
      height: 2px;
      position: absolute;
      top: 43px;
      right: 0;
      background: #fff;
      opacity: 0.3;
    }

    .article-header-title {
      font-size: 30px;
      font-style: italic;
      letter-spacing: 2px;
      color: #ffffff;
      font-weight: normal;
      position: relative;
      top: 28px;
      position: relative;
      background: #ea6935;
      margin-right: 25px;

      &::before {
        content: '';
        width: 100%;
        height: 2px;
        position: absolute;
        top: 36px;
        left: 0;
        background: #fff;
        opacity: 0.3;
      }

      span {
        background: #ea6935;
        display: inline-block;
        position: relative;
        z-index: 2;

        &::before {
          content: '';
          width: 20px;
          height: 3px;
          background: #fff;
          display: block;
          position: absolute;
          top: -3px;
          left: 5px;
          border-radius: 5px;
        }
      }
    }

    .container {
      text-align: right;
      position: relative;
      height: 100%;
      z-index: 1;
    }
  }

  .container {
    width: 940px;
  }

  .main-container {
    background: #fff;
    min-height: 300px;
    border-radius: 10px;
    position: relative;
    margin-top: -20px;
    padding: 80px;
    z-index: 3;
  }
}
</style>
