<template>
  <div class="m-notice">
    <img src="~/assets/images/m/img-1.png" />

    <div class="text">
      <p>
        iShopper服务页面包含功能较多，暂时不支持移动端浏览。请登录PC端进行浏览和操作，以获得更佳的体验。
      </p>
      <p>
        我们的网址是:
        <a href="/"><span class="text-orange">ishopper.com</span></a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  layout: 'empty',
  head() {
    return {
      title: '提示'
    }
  },
  setup() {
    return {}
  }
}
</script>

<style lang="less">
.m-notice {
  display: flex;
  flex-flow: column wrap;
  padding: 50px 20px;

  img {
    width: 70%;
    margin: 0 auto 5px;
  }

  .text {
    padding: 10px 15px;
    background: #f8f8f8;
    border-radius: 5px;
    color: #888;

    p {
      line-height: 1.8;
      font-size: 14px;
    }

    .text-orange {
      font-weight: bold;
    }
  }
}
</style>
