<template>
  <div class="black5-container">
    <section class="s1">
      <div class="container">
        <header>
          <div class="logo">
            <nuxt-link to="/">
              <img src="./images/img-13.png" alt="" />
            </nuxt-link>
          </div>
          <div class="actions">
            <nuxt-link to="/login">登录</nuxt-link>
            <a href="#register">注册</a>
          </div>
        </header>

        <div class="s1-main">
          <ul>
            <li class="logo">
              <img src="./images/img-2.png" />
              <img src="./images/img-12.png" />
            </li>
            <li class="text1">
              <p>联手提供史上黑五最高折扣</p>
            </li>
            <li class="text2">
              <div>
                高达
                <span><img src="./images/img-4.png"/></span>
                退税
              </div>
              <div>
                运费85折
              </div>
            </li>
            <li class="text3 text-underline">
              活动时间：2021年11月22日-2021年12月31日
            </li>
          </ul>

          <div class="s1-banner">
            <img :src="banner" />
          </div>
        </div>
      </div>
    </section>

    <section class="s2">
      <div class="cross-bg">
        <img :src="require('./images/img-8.png')" alt="" />
      </div>

      <div class="container">
        <div class="s2-title">
          <h2 class="text-underline">
            <img :src="require('./images/img-19.png')" alt="ISHOPPER" />
          </h2>
          <h2>
            <img
              :src="require('./images/img-14.png')"
              alt="全英唯一线上退税平台"
            />
          </h2>
        </div>

        <ul>
          <li>
            <ColorIconFont size="30" name="receipt-disscount" />
            <span>高达16%的退税税率</span>
          </li>
          <li>
            <ColorIconFont size="30" name="security-time" />
            <span>快至1周到账</span>
          </li>
          <li>
            <ColorIconFont size="30" name="user-octagon" />
            <span>全程一对一客服服务</span>
          </li>
        </ul>
      </div>
    </section>

    <section class="s3">
      <ul>
        <li><IconFont size="30" name="number-1" />注册</li>
        <li><IconFont size="30" name="number-2" />申请购物卡</li>
        <li><IconFont size="30" name="number-3" />使用购物卡支付</li>
        <li><IconFont size="30" name="number-4" />上传商品票据</li>
        <li><IconFont size="30" name="number-5" />提交物流信息</li>
        <li><IconFont size="30" name="number-6" />等待退税</li>
      </ul>
    </section>

    <section class="s4">
      <div class="cross-bg">
        <img
          v-lazy="require('./images/img-11.png')"
          :width="`${1142 / 2}px`"
          :height="`${1138 / 2}px`"
          alt=""
        />
      </div>

      <div class="cross-bg2">
        <div />
        <div />
      </div>

      <div class="container">
        <div class="info">
          <h2>
            <img
              v-lazy="require('./images/img-15.png')"
              :width="`${386 / 2}px`"
              :height="`${56 / 2}px`"
              alt=""
            />
          </h2>
          <h2 class="text-underline">
            <img
              v-lazy="require('./images/img-16.png')"
              :width="`${682 / 2}px`"
              :height="`${56 / 2}px`"
              alt=""
            />
          </h2>
          <ul>
            <li>活动期间用户独享：</li>
            <li>16%退税60天到账 或 10%退税7天到账</li>
            <li>活动结束后的退税税率和到账周期以官网展示为准</li>
          </ul>
        </div>

        <div id="register">
          <Register
            utm="2021_black5"
            lite
            :on-success="handleRegisterSuccess"
            document-title="Black Friday Sale"
          />
        </div>
      </div>
    </section>

    <section class="s5">
      <ul>
        <li>
          <img
            v-lazy="require('./images/img-17.png')"
            :width="`${564 / 2}px`"
            :height="`${56 / 2}px`"
            alt=""
          />
        </li>
        <li>
          <p>
            <span>ISHOPPER英国本地线下采买服务</span
            ><span>(不可叠加16%的退税服务)</span>
          </p>
        </li>
        <li class="pc-only">
          <img
            v-lazy="require('./images/img-18.png')"
            :width="`${978 / 2}px`"
            :height="`${56 / 2}px`"
            alt=""
          />
        </li>
        <li class="m-only">
          <div>
            帮你用更底价
          </div>
          <div>
            实体店采买英国爆款
          </div>
        </li>
        <li>
          <a href="javascript:;" class="btn-black" @click="showCustomer">
            详情咨询客服
          </a>
        </li>
      </ul>
    </section>

    <div class="s6">
      <h3>注意</h3>
      <p><span>英国0税率商品无法参与退税</span></p>
      <p><span>英国实体店采买服务无法叠加退税</span></p>
    </div>

    <footer>
      <div class="container">
        <div>© iSHOPPER 2022</div>
        <div>
          <Nuxt-link to="/terms-conditions">TERMS AND CONDITIONS</Nuxt-link>
          /
          <Nuxt-link to="/privacy-policy">PRIVACY POLICY</Nuxt-link>
        </div>
      </div>
    </footer>

    <Modal
      v-model="nextDialogVisible"
      :closable="false"
      :mask-closable="false"
      class-name="register-success-dialog"
    >
      <h3>恭喜您注册成功</h3>
      <p>请添加您的专属客服开启您的英国退税之旅</p>

      <div class="qrcode">
        <img
          v-lazy="require('./images/img-9.png')"
          :width="`${248 / 2}px`"
          :height="`${248 / 2}px`"
          alt=""
        />
      </div>
      <p>客服二维码</p>

      <Nuxt-link to="/">进入官网</Nuxt-link>
    </Modal>
  </div>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
import ColorIconFont from '../../iconfont/color'
import { useShowCustomer } from '~/use'
import { changeToPadViewPort } from '~/utils'

import Register from '~/pages/register.vue'

// eslint-disable-next-line import/no-webpack-loader-syntax
const banner = require('!url-loader?limit=100000!./images/img-1.png').default

export default {
  layout: 'empty',
  head() {
    return { title: 'Black Friday Sale' }
  },
  components: {
    ColorIconFont,
    Register
  },
  setup() {
    const showCustomer = useShowCustomer()
    const nextDialogVisible = ref(false)

    const handleRegisterSuccess = () => {
      nextDialogVisible.value = true
    }

    onMounted(() => {
      const screenWidth = window.screen.width
      if (screenWidth > 640 && screenWidth < 1200) {
        changeToPadViewPort(1440)
      }
    })

    return {
      banner,
      showCustomer,
      handleRegisterSuccess,
      nextDialogVisible
    }
  }
}
</script>

<style lang="less">
@import './black5.pc.less';
@import './black5.m.less';
</style>
