<template>
  <div class="bussiness-wrapper-step1">
    <div class="container">
      <h1>全英唯一线上海淘自助退税平台</h1>
      <h3>iShopper海淘直通车</h3>
      <p class="pp">
        <span class="p-pc">iShopper海淘直通车 ·</span> 十年金融税务备案 ·
        十万用户信誉保障
      </p>

      <LandingBussinessBtns />
    </div>
  </div>
</template>

<script>
import LandingBussinessBtns from '../../../components/landing/LandingBussinessBtns'
export default {
  components: {
    LandingBussinessBtns
  }
}
</script>

<style lang="less">
.bussiness-wrapper-step1 {
  background: #fff;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.3);
  .container {
    // padding: 120px 0 147px 0;
    padding-top: 120px;
    padding-bottom: 147px;
    background: url('../../../assets/images/landing/step1_bg.svg') right 70px
      no-repeat;
    h1 {
      font-size: 42px;
      font-weight: 600;
      border-bottom: 2px solid #000;
      display: inline-block;
      padding-bottom: 20px;
      margin-bottom: 20px;
    }
    h3 {
      display: none;
      font-size: 16px;
      font-weight: 600;
    }
    .pp {
      font-size: 18px;
      line-height: 25px;
      margin-bottom: 40px;
    }
    .btns-two,
    .btns-one {
      margin-bottom: 30px;
    }
  }
}
@media screen and (max-width: 640px) {
  .bussiness-wrapper-step1 {
    padding-bottom: 40px;
    .container {
      text-align: center;
      padding-bottom: 300px;
      padding-top: 30px;
      background-position: center bottom;
      background-size: contain;
      h1 {
        font-size: 32px;
        padding: 0 30px 10px;
        line-height: 60px;
      }
      h3 {
        display: block;
        margin-bottom: 20px;
      }
      .pp {
        margin-bottom: 20px;
        .p-pc {
          display: none;
        }
      }
    }
  }
}
</style>
