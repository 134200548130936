<template>
  <div
    class="new-about-banner"
    :style="{
      backgroundColor: bgColor
    }"
  >
    <div
      class="new-center-container about-banner-center"
      :style="{
        backgroundImage: `url(${bgImgae})`
      }"
    >
      <div class="box">
        <h3><slot name="title" /></h3>
        <h4><slot name="subtitle" /></h4>
        <p class="banner-btn">
          <RButton size="middle" type="primary" @click="btnClick"
            ><slot name="btn"
          /></RButton>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import RButton from '../revision/RButton'
export default {
  components: {
    RButton
  },
  props: {
    bgColor: {
      type: String,
      default: ''
    },
    bgImgae: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isMobile: null
    }
  },
  mounted() {
    this.comMobile()
  },
  methods: {
    btnClick() {
      this.$emit('click')
    },
    comMobile() {
      const width = document.body.clientWidth
      this.isMobile = width <= 640
    }
  }
}
</script>

<style lang="less">
.new-about-banner {
  color: #222;
  .about-banner-center {
    height: 515px;
    position: relative;
    background-position: right 120px;
    background-repeat: no-repeat;
    background-size: 43%;
    // background: url('../../assets/images/new-about/banner.png') no-repeat right
    //   130px;
    .box {
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 2;
    }
    &::after {
      content: '';
      z-index: 1;
      width: 709px;
      height: 515px;
      background: url('../../assets/images/reversion/banner-bg.svg') no-repeat
        center center;
      position: absolute;
      top: 0;
      right: -75px;
    }
    h3 {
      padding-top: 205px;
      font-size: 42px;
      font-weight: 600;
    }
    h4 {
      text-align: center;
      margin: 30px 0;
      line-height: 45px;
      padding: 0 10px;
      background: #fff;
      font-size: 18px;
      border-radius: 6px;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
      display: inline-block;
      font-weight: normal;
    }
    .banner-btn {
      .r-button {
      }
      // a {
      //   display: inline-flex;
      //   height: 42px;
      //   align-items: center;
      //   justify-content: center;
      //   padding: 0 10px;
      //   border-radius: 6px;
      //   box-shadow: 0 2px 2px 0 rgba(229, 97, 49, 0.08);
      //   background: #e56131;
      //   color: #fff;
      //   font-size: 16px;
      //   font-weight: 500;
      // }
    }
  }
}
@media screen and (max-width: 640px) {
  .new-about-banner {
    .new-center-container {
      width: auto;
    }
    .about-banner-center {
      height: 760px;
      background-size: 90%;
      background-position: center 164px;
      overflow: hidden;
      &::after {
        left: 60px;
        height: 760px;
        background-position: center 0px;
      }
      h3 {
        padding: 436px 60px 0;
        font-size: 32px;
        text-align: center;
      }
      h4 {
        font-size: 14px;
        width: 100%;
        font-weight: normal;
        p {
          font-size: 18px;
          font-weight: 600;
        }
      }
      .banner-btn {
        .r-button {
          width: 100%;
        }
      }
    }
  }
}
</style>
