<template>
  <ClientOnly>
    <div class="CommissionWithdarw w-[480px] mx-auto">
      <Form ref="form" :model="model" :label-width="130">
        <FormRender :rows="fields" />
      </Form>
      <div class="text-right">
        <Button :loading="loading" type="primary" @click="handleSubmit">
          确认提现
        </Button>
      </div>
    </div>
  </ClientOnly>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
import { useVm } from '@/use'
import AddressPickerForForm from '~/components/AddressPickerForForm'
import FormRender from '~/components/FormRender'

export default {
  components: {
    FormRender
  },
  props: {
    onSubmit: {
      type: Function,
      required: true
    }
  },
  computed: {
    subTotal() {
      const matchOrders = this.orderItems.filter((item) =>
        this.model.order_ids.includes(item.id)
      )

      return matchOrders
        .reduce((acc, cur) => {
          acc += Number(cur.rebate)
          return acc
        }, 0)
        .toFixed(2)
    },
    total() {
      const subTotal = Number(this.subTotal)
      return (subTotal + (subTotal * this.model.vat) / 100).toFixed(2)
    },
    fields() {
      const model = this.model
      const user = this.$store.state.user

      const bankVisible =
        model.receiving_type === this.$ENUM.RECEIVING_TYPE_MAP.bank
      const internationalTypeVisible =
        bankVisible && !!model.is_internaitonal_bank
      const vatVisible = !!user.company_name

      const handleSelectAll = () => {
        model.order_ids = this.orderItems.map((item) => item.id)
      }

      const fields = [
        {
          label: '返佣订单',
          prop: 'order_ids',
          node: (
            <Select
              multiple
              filterable
              clearable
              v-model={model.order_ids}
              not-found-text="没有可提现的订单"
            >
              {this.orderItems.map((item) => (
                <Option value={item.id} label={item.number}>
                  <div>
                    <span>{item.number}</span>
                    <Divider type="vertical" />
                    <span>
                      {this.$dayjs(item.created_at).format('YYYY/MM/DD HH:mm')}
                    </span>
                    <Divider type="vertical" />
                    <span>
                      <Currency value={item.rebate} size="mini"></Currency>
                    </span>
                  </div>
                </Option>
              ))}
              {!!this.orderItems.length && (
                <Option disabled value={''}>
                  <a onClick={handleSelectAll} class="text-right block">
                    选择全部
                  </a>
                </Option>
              )}
            </Select>
          ),
          rules: [
            {
              required: true,
              type: 'array',
              message: '请选择提现订单',
              trigger: 'change'
            }
          ]
        },
        {
          label: '提现到',
          prop: 'receiving_type',
          node: (
            <RadioGroup v-model={model.receiving_type}>
              {[
                {
                  // eslint-disable-next-line standard/computed-property-even-spacing
                  label: this.$ENUM.RECEIVING_TYPE[
                    this.$ENUM.RECEIVING_TYPE_MAP.balance
                  ],
                  value: this.$ENUM.RECEIVING_TYPE_MAP.balance
                },
                {
                  // eslint-disable-next-line standard/computed-property-even-spacing
                  label: this.$ENUM.RECEIVING_TYPE[
                    this.$ENUM.RECEIVING_TYPE_MAP.bank
                  ],
                  value: this.$ENUM.RECEIVING_TYPE_MAP.bank
                }
              ].map((item) => (
                <Radio key={item.value} label={item.value}>
                  {item.label}
                </Radio>
              ))}
            </RadioGroup>
          )
        },
        {
          render: () => (
            <Alert
              show-icon
              type="warning"
              class="mt-[-20px] ml-[135px] !mb-[20px] !pr-[20px]"
            >
              <Icon slot="icon" type="ios-bulb-outline"></Icon>
              {bankVisible
                ? '提现到个人银行卡过程中由银行收取的手续费需由您自行承担'
                : '无手续费，您随时可以将预定金账户中的金额充值到iShopper消费卡'}
            </Alert>
          )
        },
        {
          visible: vatVisible,
          label: 'VAT',
          prop: 'vat',
          node: (
            <RadioGroup v-model={model.vat}>
              <Radio label={0}>0%</Radio>
              <Radio
                disabled={
                  model.receiving_type === this.$ENUM.RECEIVING_TYPE_MAP.balance
                }
                label={20}
              >
                20%
              </Radio>
            </RadioGroup>
          )
        },
        {
          visible: bankVisible,
          label: '银行卡类型',
          prop: 'is_internaitonal_bank',
          node: (
            <RadioGroup v-model={model.is_internaitonal_bank}>
              <Radio label={0}>英国银行卡</Radio>
              <Radio label={1}>非英国银行卡</Radio>
            </RadioGroup>
          )
        },
        {
          visible: bankVisible,
          label: '收款人姓名',
          prop: 'bank_user',
          node: (
            <Input
              v-model={model.bank_user}
              placeholder="收款人姓名"
              maxlength="30"
            />
          ),
          rules: [
            {
              required: true,
              message: '请输入银行名称',
              trigger: 'blur'
            }
          ]
        },
        {
          visible: bankVisible,
          label: '银行名称',
          prop: 'bank_name',
          node: (
            <Input
              v-model={model.bank_name}
              placeholder="银行名称"
              maxlength="30"
            />
          ),
          rules: [
            {
              required: true,
              message: '请输入银行名称',
              trigger: 'blur'
            }
          ]
        },
        {
          visible: internationalTypeVisible,
          label: '银行所在国家',
          prop: 'bank_country',
          node: (
            <Input
              v-model={model.bank_country}
              placeholder="银行所在国家"
              maxlength="30"
            />
          ),
          rules: [
            {
              required: true,
              message: '请输入银行名称',
              trigger: 'blur'
            }
          ]
        },
        {
          visible: internationalTypeVisible,
          label: '开户支行',
          prop: 'bank_opening_branch',
          node: (
            <Input v-model={model.bank_opening_branch} placeholder="开户支行" />
          ),
          rules: [
            {
              required: true,
              message: '请输入银行开户支行',
              trigger: 'blur'
            }
          ]
        },
        {
          visible: bankVisible,
          label: '银行账号',
          prop: 'bank_account',
          node: (
            <Input
              v-model={model.bank_account}
              placeholder="银行账号"
              maxlength="30"
            />
          ),
          rules: [
            {
              required: true,
              message: '请输入银行账号',
              trigger: 'blur'
            }
          ]
        },
        {
          visible: bankVisible,
          label: 'Bank Code/SWIFT Code/Sort Code',
          prop: 'bank_code',
          node: (
            <Input
              v-model={model.bank_code}
              placeholder="Bank Code / SWIFT Code / Sort Code"
              maxlength="30"
            />
          ),
          rules: [
            {
              required: true,
              message: '请输入银行代码',
              trigger: 'blur'
            }
          ]
        },
        {
          render: () => (
            <div class="mt-[-20px]">
              <Divider />
            </div>
          )
        },
        {
          render: () => (
            <FormItem
              prop="shipping_address"
              required
              rules={[
                {
                  required: true,
                  type: 'number',
                  message: '请选择地址',
                  trigger: 'change'
                }
              ]}
              label="收款账单地址"
            >
              <AddressPickerForForm v-model={model.shipping_address} />
            </FormItem>
          )
        },
        {
          render: () => (
            <div class="mt-[-20px] flex flex-col text-right">
              {vatVisible && (
                <span>
                  <span class="text-gray-500">返佣金额:</span>
                  <span class="w-[80px] inline-block">£{this.subTotal}</span>
                </span>
              )}

              {vatVisible && (
                <span>
                  <span class="text-gray-500">VAT:</span>
                  <span class="w-[80px] inline-block">{model.vat}%</span>
                </span>
              )}
              <span>
                <span class="text-gray-500">提现金额合计:</span>
                <span class="w-[80px] inline-block text-red-500">
                  £{this.total}
                </span>
              </span>
              <Divider />
            </div>
          )
        }
      ]

      return fields.filter((fieldItem) =>
        [undefined, true].includes(fieldItem.visible)
      )
    }
  },
  setup({ onSubmit }) {
    const vm = useVm()
    const model = ref({
      receiving_type: vm.$ENUM.RECEIVING_TYPE_MAP.balance,
      is_internaitonal_bank: 0,
      bank_country: '',
      vat: 0,
      bank_user: '',
      bank_name: '',
      bank_account: '',
      bank_code: '',
      bank_opening_branch: '',
      shipping_address: '',
      order_ids: []
    })
    const orderItems = ref([])
    const loading = ref(false)

    const updateOrderItems = async () => {
      const { data } = await vm.$api.fetchOrderList({
        size: 99999,
        withdrawable: true
      })

      orderItems.value = data
    }

    const handleSubmit = () => {
      vm.$refs.form.validate(async (valid) => {
        if (!valid) {
          return
        }

        loading.value = true
        try {
          await onSubmit(model.value)
          vm.$refs.form.resetFields()
          updateOrderItems()
        } catch (err) {
          console.error(err)
        }
        loading.value = false
      })
    }

    onMounted(() => {
      updateOrderItems()
    })

    return {
      model,
      orderItems,
      loading,
      handleSubmit
    }
  }
}
</script>

<style lang="scss"></style>
