<template>
  <Form :label-width="140" hide-required-mark label-colon>
    <div class="account-order-detail-container">
      <div class="order-info">
        <div class="container">
          <h3 class="title-line"><span>订单号</span></h3>
          <h1>
            <span>用户名: {{ user.username }}</span>
            <span>{{ detail.number }}</span>
            <span>
              创建日期:{{
                this.$dayjs(detail.created_at).format('YYYY/MM/DD  HH:mm:ss')
              }}
            </span>
          </h1>
          <Row class="remark">
            <i-col span="12">
              <div class="remark-item border">
                <h3>用户备注:</h3>
                <p>{{ detail.note_user }}</p>
              </div>
            </i-col>
            <i-col span="12">
              <div class="remark-item">
                <h3>管理员备注:</h3>
                <p>{{ detail.note_admin }}</p>
              </div>
            </i-col>
          </Row>
          <ul class="info-list">
            <li>
              <h5>订单金额:</h5>
              <Currency :value="detail.value_total" type="£" />
            </li>
            <li>
              <h5>总VAT金额:</h5>
              <Currency :value="detail.vat_total" type="£" />
            </li>
            <li>
              <h5>实际VAT金额:</h5>
              <Currency :value="detail.vat_vaild" type="£" />
            </li>
            <li>
              <h5>退税方案:</h5>
              {{ detail.rebate_plan_title }}
            </li>
            <li>
              <h5>有效返佣金额:</h5>
              <Currency :value="detail.rebate_vaild" type="£" />
            </li>
          </ul>
        </div>
      </div>

      <div class="order-detail bg-gray">
        <div class="container">
          <h2>订单明细</h2>
          <Data-table
            class="table-blue"
            hide-page
            :when-fetch="
              () => ({
                data: detail.purchases
              })
            "
            :columns="columns"
          />
        </div>
      </div>

      <div v-if="detail.pay_retain > 0" class="pay-detail container">
        <ul class="info-list">
          <li>
            <h5>尾款金额:</h5>
            <Currency :value="detail.pay_retain" type="£" />
          </li>
          <li>
            <h5>尾款状态:</h5>
            <span class="text-orange">
              {{ this.$ENUM.ORDER_RETAIN_STATUS[detail.pay_status_retain] }}
            </span>
          </li>
          <li>
            <h5>尾款支付方式:</h5>
            <span>{{ this.$ENUM.PAY_METHOD[detail.pay_method_retain] }}</span>
          </li>
          <li>
            <h5>尾款二维码:</h5>
            <!-- <img :src="retainQR" alt="qrcode" title="" /> -->
            <div v-if="detail.qr_code_retain">
              <qrcode
                :value="detail.qr_code_retain"
                :options="{ width: 100 }"
              />
            </div>
          </li>
          <li>
            <h5>尾款链接:</h5>
            <a
              v-clipboard:copy="detail.qr_code_retain"
              v-clipboard:success="
                () => $Message.success(`内容已复制：${detail.qr_code_retain}`)
              "
              href="#0"
              >点击拷贝</a
            >
          </li>
        </ul>
        <ul class="info-list">
          <li>
            <h5>尾款支付号:</h5>
            <span>{{ detail.billing_retain_number }}</span>
          </li>
          <li style="width:80%;">
            <h5>尾款支付备注:</h5>
            <span>{{ detail.pay_note_retain }}</span>
          </li>
        </ul>
      </div>

      <div class="receiver-info" :class="{ 'gb-gray': detail.pay_retain > 0 }">
        <div class="container">
          <Row :gutter="50">
            <i-col span="14">
              <h3>收件人信息</h3>
              <div class="receiver-info-container">
                <Row>
                  <i-col span="6">
                    <div class="title">收货人姓名</div>
                    <span>{{ detail.recipient_name }}</span>
                  </i-col>
                  <i-col span="12">
                    <div class="title">身份证号码</div>
                    <span>{{ detail.recipient_cid }}</span>
                  </i-col>
                  <i-col span="6">
                    <div class="title">手机号码</div>
                    <span>{{ detail.recipient_phone }}</span>
                  </i-col>
                  <i-col span="6">
                    <div class="title">收货人国家/地区</div>
                    <span>{{
                      this.$ENUM.COUNTRY[detail.recipient_country]
                    }}</span>
                  </i-col>
                  <i-col span="6">
                    <div class="title">所在省</div>
                    <span>{{ detail.recipient_province }}</span>
                  </i-col>
                  <i-col span="6">
                    <div class="title">所在市</div>
                    <span>{{ detail.recipient_city }}</span>
                  </i-col>
                  <i-col span="6">
                    <div class="title">所在区</div>
                    <span>{{ detail.recipient_county }}</span>
                  </i-col>
                  <i-col span="24">
                    <div class="title">详细地址</div>
                    <span>{{ detail.recipient_street }}</span>
                  </i-col>
                  <i-col span="6">
                    <div class="title">快递公司</div>
                    <span>{{ detail.express_name || detail.express_cmp }}</span>
                  </i-col>
                  <i-col span="18">
                    <div class="title">快递单号</div>
                    <span>{{ detail.tracking_number }}</span>
                  </i-col>
                </Row>
              </div>
            </i-col>
            <i-col class="file-info" span="10">
              <h3>文件信息</h3>
              <Row>
                <i-col v-if="detail.invoice_url" span="12">
                  <div class="icon">
                    <a :href="detail.invoice_url" target="_blank">
                      <Icon type="ios-cloud-download" size="45" />
                    </a>
                  </div>
                  <span>商业发票</span>
                </i-col>
                <i-col v-if="detail.express_cmp" span="12">
                  <div class="icon">
                    <a
                      v-if="detail.express_cmp === 'other'"
                      :href="detail.other_label"
                      target="_blank"
                    >
                      <Icon type="md-attach" size="45" />
                    </a>
                    <a v-else :href="detail.sd_label" target="_blank">
                      <Icon type="md-attach" size="45" />
                    </a>
                  </div>
                  <span>快递面单</span>
                </i-col>
                <i-col v-if="detail.screenshot" span="12">
                  <div class="icon">
                    <a :href="detail.screenshot" target="_blank">
                      <Icon type="ios-reverse-camera" size="45" />
                    </a>
                  </div>
                  <span>妥投截图</span>
                </i-col>
              </Row>
            </i-col>
          </Row>
        </div>
      </div>

      <div class="actions">
        <div class="container text-center">
          <Button type="primary" @click="$router.go(-1)">返回</Button>
        </div>
      </div>
    </div>
  </Form>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {
      detail: {},
      retainQR: '',
      columns: [
        {
          title: '发票号',
          key: 'purchase.invoice_number',
          render: (_, { row }) => {
            return <span>{row.purchase.invoice_number}</span>
          }
        },
        {
          title: '采购时间',
          key: 'purchase.created_at',
          render: (_, { row }) => {
            return (
              <span>
                {this.$dayjs(row.purchase.purchase_time).format(
                  'YYYY/MM/DD HH:mm:ss'
                )}
              </span>
            )
          }
        },
        {
          title: '票据类型',
          key: 'invoice_type',
          render: (_, { row }) => {
            return (
              <span>
                {this.$ENUM.INVOICE_TYPE[row.purchase.invoice_type]}

                {!!row.is_trouble && (
                  <div class="inline-block text-muted">(异常采购)</div>
                )}
              </span>
            )
          }
        },
        {
          title: '品牌',
          key: 'brand'
        },
        {
          title: '描述',
          key: 'desc'
        },
        {
          title: '金额(£)',
          key: 'value_total',
          render: (_, { row }) => (
            <Currency color="normal" value={row.value_total} type="£" />
          )
        },
        {
          title: '状态',
          render: (_, { row }) => {
            return (
              <div>
                <span>{this.$ENUM.PURCHASE_TYPE[row.purchase.status]}</span>
              </div>
            )
          }
        },
        {
          title: '采购详情',
          key: 'action',
          render: (_, { row }) => {
            return (
              <div>
                <Nuxt-link
                  to={`/account/purchase/detail?id=${row.purchase.id}`}
                >
                  点击查看
                </Nuxt-link>
              </div>
            )
          }
        }
      ]
    }
  },
  computed: {
    ...mapState(['user'])
  },
  async asyncData({ $api, route }) {
    let detail
    const number = route.query.id
    if (number.startsWith('OBT') || number.startsWith('ISP')) {
      detail = await $api.fetchOrderDetailByNumber({ number })
    } else {
      detail = await $api.fetchOrderDetail(route.query.id, {})
    }
    const retainQR =
      'https://api.qrserver.com/v1/create-qr-code/?data=' +
      detail.qr_code_retain +
      '&size=80x80'
    return {
      detail,
      retainQR
    }
  }
}
</script>

<style lang="less">
.account-order-detail-container {
  padding: 50px 0 0;

  .order-info {
    .title-line {
      font-size: 20px;
      font-weight: normal;
      color: #3b3b3b;
      margin-bottom: 20px;

      & + h1 {
        font-size: 24px;
        font-weight: normal;
        color: #78b74a;
        border-bottom: 1px solid rgb(231, 231, 231);
        padding-bottom: 10px;
        margin-bottom: 30px;
        display: flex;
        justify-content: space-between;

        span:nth-child(1),
        span:nth-child(3) {
          font-size: 16px;
          line-height: 36px;
          color: #a4a4a4;
        }
      }
    }

    .remark {
      border-bottom: 1px solid rgb(231, 231, 231);
      padding: 0 0 25px;
      margin-bottom: 20px;

      .ivu-col-span-12:nth-child(1) {
        border-right: 1px solid rgb(231, 231, 231);
      }

      .remark-item {
        width: 330px;
        margin: 0 auto;
        min-height: 200px;
        padding: 10px 0 0;

        h3 {
          font-weight: normal;
          font-size: 18px;
          color: #3b3b3b;
          margin-bottom: 20px;
          text-align: center;
        }

        p {
          font-size: 16px;
          color: #a7a7a7;
        }
      }
    }
  }

  .info-list {
    display: flex;
    margin-bottom: 20px;
    padding: 0 50px;
    li {
      width: 20%;

      h5 {
        font-size: 16px;
        font-weight: normal;
        color: #a4a4a4;
        margin-bottom: 10px;
      }

      font-size: 22px;

      .currency-int {
        font-size: 22px;
      }

      .currency-decimal {
        font-size: 16px;
      }
    }
  }

  .order-detail {
    padding: 40px 0;
    .container {
      width: 920px;
    }

    h2 {
      font-size: 18px;
      font-weight: normal;
      color: #3b3b3b;
      text-align: center;
      margin-bottom: 20px;
    }
  }

  .pay-detail {
    padding: 30px 20px 10px;
  }

  .receiver-info {
    padding: 50px 0;

    .container {
      width: 1000px;
    }

    .receiver-info-container {
      font-size: 16px;
      .title {
        font-size: 16px;
        color: #a4a4a4;
        margin-bottom: 10px;
      }
      .ivu-col {
        margin-bottom: 30px;
      }
    }

    h3 {
      font-size: 18px;
      font-weight: normal;
      color: #3b3b3b;
      text-align: center;
      border-bottom: 1px solid rgb(231, 231, 231);
      margin-bottom: 40px;
      padding: 0 0 20px;
    }

    .file-info {
      text-align: center;
      margin-bottom: -50px;
      .icon {
        margin-bottom: 10px;
        & + span {
          font-size: 16px;
          color: #a4a4a4;
          margin-bottom: 50px;
          display: inline-block;
        }
      }
    }
  }

  .actions {
    padding: 40px 0 100px;
  }
}
</style>
