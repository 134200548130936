<template>
  <div class="rebate-detail-container container">
    <h1 class="text-center mb-10">返佣订单提现</h1>
    <Alert
      v-if="!!purchaseRefund.amount"
      class="w-[490px] mx-auto !mb-[30px] mt-[-15px]"
      show-icon
    >
      您有待核销退款
      <template slot="desc">
        您有{{ purchaseRefund.total }}笔退款未核销，累计退款金额为£{{
          purchaseRefund.amount.toFixed(2)
        }}。我们将从您的可提现佣金中减去未核销退款产生的佣金£{{
          purchaseRefund.vat.toFixed(2)
        }}，所以您的当前账号返佣可提现额度为<strong
          >£{{ realCommissionBalance }}</strong
        >。请立刻<nuxt-link to="/account/purchase">核销退款</nuxt-link
        >恢复您的返佣可提现额度£{{ commission_gbp_balance }}
      </template>
    </Alert>
    <CommissionWithdarw :on-submit="handleSubmit" />

    <h1 class="main-title text-center mt-[100px]">返佣订单</h1>
    <DataTable
      ref="detailTableRef"
      class="table-blue"
      :when-fetch="
        (page, perPage) =>
          detailTable.api({
            page,
            perPage
          })
      "
      :columns="detailTable.cols"
    />

    <h1 class="main-title text-center mt-[100px]">返佣明细</h1>
    <DataTable
      ref="balanceTableRef"
      class="table-blue"
      :when-fetch="
        (page, perPage) =>
          balanceTable.api({
            page,
            perPage
          })
      "
      :columns="balanceTable.cols"
    />
  </div>
</template>

<script>
import { ref, onMounted, computed } from '@vue/composition-api'
import CommissionWithdarw from '~/components/CommissionWithdarw.vue'

import { useVm } from '~/use'

export default {
  components: {
    CommissionWithdarw
  },
  meta: {
    private: true
  },
  head() {
    return {
      title: '返佣明细'
    }
  },
  setup() {
    const vm = useVm()
    const amount = ref(null)
    const loading = ref(false)
    const detailTableRef = ref(null)
    const balanceTableRef = ref(null)
    const purchaseRefund = ref({ amount: 0, vat: 0 })
    const { ORDER_PROCESS_STATUS } = vm.$ENUM

    const detailTable = {
      api: (params) => {
        return vm.$api.fetchOrderList({ ...params, status: 100 })
      },
      cols: [
        {
          title: '订单号',
          align: 'center',
          width: 140,
          render: (_, { row }) => {
            return (
              <nuxt-link key={row.id} to={`/account/order/detail?id=${row.id}`}>
                {row.number}
              </nuxt-link>
            )
          }
        },
        {
          title: '返佣方案',
          key: 'rebate_plan_title',
          align: 'center'
        },
        {
          title: '返佣金额',
          align: 'center',
          render(_, { row }) {
            return <Currency size="mini" value={row.rebate} type="£" />
          }
        },
        {
          title: '返佣状态',
          align: 'center',
          render: (_, { row }) => {
            if (row.status !== 4) {
              if ((row.note_outside && row.status === 1) || row.status === 6) {
                return (
                  <div>
                    <span>{ORDER_PROCESS_STATUS[row.status]}</span>
                    <br />
                    <span>
                      <Poptip
                        title="退回原因"
                        trigger="hover"
                        width="150"
                        transfer
                        word-wrap
                        content={row.note_outside || '暂无原因'}
                      >
                        <span class="status_active">申请被退回</span>
                      </Poptip>
                    </span>
                  </div>
                )
              } else {
                return (
                  <div>
                    <span>{ORDER_PROCESS_STATUS[row.status]}</span>
                  </div>
                )
              }
            } else {
              return (
                <div>
                  <span>{ORDER_PROCESS_STATUS[row.status]}</span>
                  <br />
                  <span>{row.rebate_remaining_days}天</span>
                </div>
              )
            }
          }
        },
        {
          title: '订单时间',
          align: 'center',
          render(_, { row }) {
            return (
              <span>
                {vm.$dayjs(row.created_at).format('YYYY/MM/DD HH:mm')}
              </span>
            )
          }
        }
      ]
    }

    const balanceTable = {
      api: (params) => {
        return vm.$api.fetchBalance({
          ...params,
          account: 2 // 2 = 返佣账户
        })
      },
      cols: [
        {
          title: 'ID',
          key: 'id',
          align: 'center',
          width: 80
        },
        {
          title: '金额',
          align: 'center',
          key: 'amount',
          render: (_, { row }) => {
            let tp = ''
            if (row.in_gbp) {
              tp = '£'
            } else if (row.in_cny) {
              tp = '￥'
            }
            return <Currency color="normal" value={row.amount} type={tp} />
          }
        },
        {
          title: '返佣余额',
          align: 'center',
          key: 'balance',
          render: (_, { row }) => {
            let tp = ''
            if (row.in_gbp) {
              tp = '£'
            } else if (row.in_cny) {
              tp = '￥'
            }
            return <Currency color="normal" value={row.balance} type={tp} />
          }
        },
        {
          title: '方式',
          align: 'center',
          key: '',
          render: (_, { row }) => {
            let tp = ''
            if (row.amount < 0) {
              tp = '转出'
            } else {
              tp = '转入'
            }
            return <span>{tp}</span>
          }
        },
        {
          title: '备注',
          align: 'center',
          key: 'notes'
        },
        {
          title: '日期',
          key: 'created_at',
          align: 'center',
          width: 165,
          render: (_, { row }) => {
            return (
              <span>
                {vm.$dayjs(row.created_at).format('YYYY/MM/DD HH:mm')}
              </span>
            )
          }
        }
      ]
    }

    const handleSubmit = (model) => {
      return new Promise((resolve, reject) => {
        vm.$Modal.confirm({
          title: '提示',
          content:
            '确认提交，即代表您同意 iShopper 根据您填充的资料和在 iShopper 保存的信息进行返佣提现操作，并且自动生成您签名的发票。您是否确认提交此次返佣提现申请？',
          okText: '确认提交',
          cancelText: '不同意',
          onOk: async () => {
            loading.value = true
            try {
              await vm.$api.commissionWithdraw(model)
              detailTableRef.value.refresh()
              balanceTableRef.value.refresh()

              resolve(true)
              vm.$router.push(`/account/success?type=${model.receiving_type}`)
            } catch (err) {
              console.error(err)
              reject(err)
            }
            loading.value = false
          },
          onCancel: () => reject(new Error('cancel'))
        })
      })
    }

    const realCommissionBalance = computed(() => {
      return (
        vm.$store.state?.user?.commission_gbp_balance - purchaseRefund.value.vat
      ).toFixed(2)
    })

    onMounted(() => {
      vm.$store.dispatch('updateUserDetail')

      vm.$api.fetchPurchaseRefundAmount().then((res) => {
        purchaseRefund.value = res
      })
    })

    return {
      balanceTableRef,
      detailTableRef,
      state: vm.$store.state,
      handleSubmit,
      loading,
      detailTable,
      balanceTable,
      purchaseRefund,
      realCommissionBalance,
      commission_gbp_balance: vm.$store.state?.user?.commission_gbp_balance,
      amount
    }
  }
}
</script>

<style lang="less">
.rebate-detail-container {
  padding: 30px 0;

  .main-title {
    margin-bottom: 20px;
  }

  .currency-container {
    margin-top: 4px;
  }
}
</style>
