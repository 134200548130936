<template>
  <div class="new-contact-wrapper reversion">
    <RBanner
      bg-color="#f6fbfd"
      :bg-imgae="require('../assets/images/new-contact/banner.png')"
      @click="customerService"
    >
      <template v-slot:title>全英唯一线上海淘自助退税平台</template>
      <template v-slot:subtitle>
        如有任何请求及商业合作相关疑问，欢迎联系我们
      </template>
      <template v-slot:btn
        >联系客服支持
        <IconFont
          class="banner-arrow-right"
          size="28"
          color="#fff"
          name="arrow-right"
      /></template>
    </RBanner>
    <div class="new-contact-content new-center-container flexRow">
      <div class="map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2461.9193398503066!2d-1.1506243838330832!3d51.89893607970141!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4876e77b506c0127%3A0xeb40fa941dab4e85!2sMinton%20Place%2C%20Victoria%20Rd%2C%20Bicester%20OX26%206QB%E8%8B%B1%E5%9B%BD!5e0!3m2!1szh-CN!2sru!4v1634035566293!5m2!1szh-CN!2sru"
          width="100%"
          height="450"
          style="border:0;"
          allowfullscreen=""
          loading="lazy"
        />
      </div>
      <div class="content">
        <div class="new-title">商业合作</div>
        <div class="fs-list flexRow">
          <div class="fs-item">
            <p>电话：</p>
            <h4 class="flexRow">
              <IconFont size="26" name="phone" color="#222" />
              ＋44 01869 320216
            </h4>
          </div>
          <div class="fs-item">
            <p>邮件：</p>
            <h4 class="flexRow">
              <IconFont size="26" name="mail" color="#222" />
              info@ishopper.com
            </h4>
          </div>
          <div class="fs-item">
            <p>地址：</p>
            <h4 class="flexRow">
              <IconFont size="26" name="mappin" color="#222" />
              Unit 6 Minton Place, Victoria Road, Bicester OX26 6QB
            </h4>
          </div>
        </div>
        <div class="bottom">
          <p class="desc">
            关注iShopper<br />
            随时获取第一手资讯及打折信息！
          </p>
          <ul class="list flexRow">
            <li>
              <div class="img-box">
                <img v-lazy="require(`../assets/images/index/xcx.jpg`)" />
              </div>
              <p>小程序</p>
            </li>
            <li>
              <div class="img-box">
                <img v-lazy="require(`../assets/images/index/qrcode.png`)" />
              </div>
              <p>公众号</p>
            </li>
          </ul>
        </div>
        <div class="btns">
          <RButton size="middle" type="primary" @click="customerService"
            >联系客服支持<IconFont size="30" name="arrow-right"
          /></RButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RBanner from '../components/revision/RBanner'
import IconFont from '../iconfont'
import RButton from '../components/revision/RButton'
export default {
  components: {
    RBanner,
    IconFont,
    RButton
  },
  layout() {
    return 'new-default'
  },
  data() {
    return {
      isMobile: null
    }
  },
  head() {
    return {
      title: '联系我们'
    }
  },
  mounted() {
    this.comMobile()
  },
  methods: {
    customerService() {
      const user = this.$store.state.user
      if (user) {
        window._MEIQIA('metadata', {
          id: user.id,
          email: user.email,
          username: user.username,
          phone: user.phone
        })
      }
      window._MEIQIA('showPanel')
    },
    comMobile() {
      const width = document.body.clientWidth
      this.isMobile = width <= 640
    }
  }
}
</script>

<style lang="less">
.new-contact-wrapper {
  .new-contact-content {
    padding-top: 100px;
    padding-bottom: 100px;
    justify-content: space-between;

    .map {
      width: 540px;
      height: 450px;
    }
    .content {
      width: 445px;
      box-sizing: border-box;
      .new-title {
        margin-bottom: 70px;
        text-align: left;
        &::after {
          left: 0;
          margin-left: 0;
        }
      }
      .fs-list {
        flex-wrap: wrap;
        .fs-item {
          margin-bottom: 40px;
          &:not(:last-child) {
            width: 50%;
          }

          p {
            font-size: 16px;
            color: #666;
          }
          h4 {
            align-items: center;
            font-weight: 500;
            font-size: 16px;
            color: #222;
            .iconfont {
              margin-right: 8px;
              font-weight: normal;
            }
          }
        }
      }
      .bottom {
        border-top: 1px dashed rgba(0, 0, 0, 0.1);
        padding-top: 40px;
        padding-bottom: 60px;
        .desc {
          line-height: 30px;
          font-size: 16px;
          color: #222;
          font: 1em sans-serif;
          padding-bottom: 30px;
        }
        .list {
          li {
            width: 88px;
            margin-right: 40px;
            .img-box {
              height: 88px;
              box-sizing: border-box;
              padding: 5px;
              border-radius: 6px;
              box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.08);
              border: solid 1px rgba(0, 0, 0, 0.1);
              background-color: #fff;
              margin-bottom: 10px;
              img {
                width: 100%;
                height: 100%;
              }
            }
            p {
              font-size: 12px;
              color: #222;
              text-align: center;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 640px) {
  .new-center-container {
    width: auto;
  }
  .new-contact-wrapper {
    .new-about-banner {
      .about-banner-center {
        background-position: center 145px;
        h3 {
          padding: 450px 60px 0;
        }
        h4 {
          font-weight: 600;
        }
        .special {
          line-height: 20px;
          padding: 10px 0;
        }
      }
    }
    .new-contact-content {
      padding-bottom: 40px;
      padding-top: 135px;
      flex-direction: column;
      position: relative;
      .map {
        width: 100%;
      }
      .content {
        width: 100%;
        padding-top: 40px;
        .new-title {
          width: 100%;
          position: absolute;
          left: 0;
          top: 40px;
          text-align: center;
          &::after {
            left: 50%;
            margin-left: -35px;
          }
        }
        .fs-list {
          .fs-item {
            margin-bottom: 20px;
            &:not(:last-child) {
              width: 100%;
            }
            &:last-child {
              h4 {
                align-items: flex-start;
                padding-top: 5px;
                .iconfont {
                  position: relative;
                  top: -5px;
                }
              }
            }
          }
        }
        .bottom {
          padding-bottom: 40px;
          .list {
            justify-content: space-between;
            li {
              margin-right: 0;
              img {
                width: 76px;
                height: 76px;
              }
            }
          }
        }
        .btns {
          text-align: center;
        }
      }
    }
  }
}
</style>
