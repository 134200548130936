<template>
  <Form
    ref="form"
    :model="modelForRules"
    class="account-purchase-upload-container"
  >
    <uploadSection v-model="model" :detail="detail" :purchase-id="purchaseId" />

    <goodsSection
      v-model="goodsItems"
      api-type="refund"
      check-mode
      :check-disabled="
        (field) => ['brand', 'barcode', 'desc', 'quantity'].includes(field)
      "
      title="待核销商品明细"
      :exclude-amount.sync="model.exclude_amount"
    >
      <template #head>
        <Row>
          <i-col span="5" offset="2">
            <FormItem label="待核销票据编号：">
              <LoadMoreSelect
                ref="invocieSelectRef"
                v-model="curInvoice"
                :row-options="loadMoreOptions"
                :on-fetch="handleFetchInvoice"
              />
            </FormItem>
          </i-col>
        </Row>
      </template>
    </goodsSection>
    <Spin v-show="invoiceLoading" size="large" fix />

    <div class="actions">
      <Button
        :loading="loading"
        :disabled="!canSubmit"
        type="primary"
        @click="handleSubmit"
      >
        确 认
      </Button>
    </div>
  </Form>
</template>

<script>
import dayjs from 'dayjs'
import uploadSection from '~/components/purchase/uploadSection'
import goodsSection from '~/components/purchase/goodsSection'
import LoadMoreSelect from '~/components/LoadMoreSelect'

export default {
  components: {
    uploadSection,
    goodsSection,
    LoadMoreSelect
  },
  inheritAttrs: false,
  props: {},
  data() {
    return {
      goodsItems: [],
      detail: {},
      model: {},
      loading: false,
      total: 0,
      curInvoice: null,
      invoiceLoading: false
    }
  },
  computed: {
    purchaseId() {
      return this.$route.query.id
    },
    modelForRules() {
      return {
        goodsItems: this.goodsItems
      }
    },
    canSubmit() {
      return this.curInvoice && this.selectedGoodsItems.length
    },
    selectedGoodsItems() {
      return this.goodsItems
        .filter((item) => item._check)
        .map((item) => ({ ...item, refund_id: item.id }))
    },
    loadMoreOptions() {
      return [
        {
          label: '票据编号',
          render: (row) => <span>{row.label}</span>
        },
        {
          width: 70,
          label: 'id',
          render: (row) => <span>{row.id}</span>
        },
        {
          width: 100,
          label: '采购时间',
          render: (row) => (
            <span>{this.$dayjs(row.purchase_time).format('MM/DD HH:mm')}</span>
          )
        },
        {
          width: 80,
          label: '状态',
          render: (row) => <span>{this.$ENUM.PURCHASE_TYPE[row.status]}</span>
        },
        {
          width: 140,
          label: '卡号',
          render: (row) => <span>{row.card.number}</span>
        },
        {
          label: '金额',
          width: 100,
          render: (row) => (
            <Currency size="tiny" color="orange" type="£" value={row.amount} />
          )
        },
        {
          width: 150,
          label: '备注',
          render: (row) => (
            <span title={row.notes || '-'}>{row.notes || '-'}</span>
          )
        }
      ]
    }
  },
  watch: {
    async curInvoice(val) {
      const curInvoice = this.$refs.invocieSelectRef?.items.find(
        (item) => item.invoice_number === val
      )

      if (!curInvoice) {
        return
      }
      this.invoiceLoading = true
      try {
        const detail = await this.$api.fetchPuchaseDetail({ id: curInvoice.id })
        this.goodsItems = detail.items.map((item) => ({
          ...item,
          is_inc_tax: item.is_inc_tax || '1'
        }))
      } catch (err) {
        console.error(err)
      }
      this.invoiceLoading = false
    }
  },
  async asyncData({ $api, route }) {
    const detail = await $api.fetchPuchaseDetail({ id: route.query.id })
    const model = {
      purchase_id: route.query.id,
      action: `create`,
      invoice_number:
        detail.invoice_number || 'PUR-REFUND-' + dayjs().format('YYYYMMDDHHmm'),
      invoice_type: detail.invoice_type,
      exclude_amount: detail.exclude_amount,
      items: []
    }
    return {
      detail,
      model
    }
  },
  methods: {
    async handleFetchInvoice({ page, perpage, q }) {
      const { data, paginator } = await this.$api.fetchRefundPuchase({
        page,
        perpage,
        invoice_number: q
      })

      return {
        data: data.map((item) => ({
          ...item,
          label: item.invoice_number,
          value: item.invoice_number
        })),
        total: paginator.count
      }
    },

    beforeSubmit() {
      return new Promise((resolve, reject) => {
        const formData = {
          purchase_id: this.purchaseId,
          invoice_type: this.model.invoice_type,
          invoice_number: this.model.invoice_number,
          exclude_amount: this.model.exclude_amount,
          items: this.selectedGoodsItems
        }

        this.$refs.form.validate((isPass) => {
          if (!isPass) {
            this.loading = false
            reject(new Error('invaid'))
            return
          }

          resolve(formData)
        })
      })
    },
    async handleSubmit() {
      try {
        const params = await this.beforeSubmit()

        this.loading = true
        await this.$api.updateRefundPuchase({
          ...params,
          action: 'create'
        })
        this.$Message.success('提交成功，请耐心等待！')
        this.$router.go(-1)
      } catch (err) {
        console.error(err)
      }
      this.loading = false
    }
  }
}
</script>

<style lang="less">
.drag .account-purchase-upload-container {
  .ivu-upload-drag {
    position: fixed;
    top: 0;
    left: 0;
    background: #000;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.7;
    z-index: 2;
  }

  .upload-list {
    pointer-events: none;
    padding-top: 7px;
  }
}

.account-purchase-upload-container {
  .currency-container {
    .currency-type {
      font-size: 20px;
    }
    .currency-int {
      font-size: 22px;
    }
    .currency-decimal {
      font-size: 16px;
    }
  }

  .actions {
    text-align: center;
    padding: 60px 0 100px;
  }

  .upload-list {
    display: inline-block;
    width: 80px;
    height: 80px;
    text-align: center;
    line-height: 80px;
    border: 1px solid transparent;
    border-radius: 4px;
    overflow: hidden;
    background: #fff;
    position: relative;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    border: 1px solid #ddd;
    margin-right: 12px;
  }
  .upload-list img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .upload-list-cover {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.6);
  }
  .upload-list:hover .upload-list-cover {
    display: block;
  }
  .upload-list-cover i {
    color: #fff;
    font-size: 20px;
    cursor: pointer;
    margin: 0 2px;
  }

  .ivu-upload {
    width: 80px;
    height: 80px;
    display: inline-flex;
    padding-top: 4px;
    vertical-align: top;
    margin-top: -1px;
    .upload-list {
      margin: 0;
      border: none;
    }
  }
}

.preview-invoice .ivu-modal-footer {
  display: none;
}
</style>
