<script>
import { useVm } from '~/use'

export default {
  props: {
    data: {
      type: Object,
      required: true
    },
    whenShowRebate: {
      type: Function,
      required: true
    }
  },
  setup(props) {
    const vm = useVm()

    return () => {
      const data = props.data
      const statusText = vm.$ENUM.ORDER_PROCESS_STATUS[data.status]

      const statusMap = [
        {
          conditions: [vm.$ENUM.ORDER_PROCESS_TYPE.PROCESSED],
          render: () => (
            <span class="text-green-600">
              <Icon type="ios-checkmark-circle" /> {statusText}
            </span>
          )
        },
        {
          conditions: [vm.$ENUM.ORDER_PROCESS_TYPE.COUNT_DOWN],
          render: () => (
            <span class="text-[orange]">
              <Icon class="animate-bounce" type="ios-time" /> {statusText}
              <div>{data.rebate_remaining_days}天</div>
            </span>
          )
        },
        {
          conditions: () =>
            !!data.note_outside ||
            data.status === vm.$ENUM.ORDER_PROCESS_TYPE.PROCESSED_NO_REBATE,
          render: () => {
            return (
              <Poptip
                title={`详情`}
                trigger="hover"
                width="250"
                transfer
                word-wrap
              >
                <div slot="content">
                  <div class="break-all">{data.note_outside || '暂无原因'}</div>
                  {data.status !==
                    vm.$ENUM.ORDER_PROCESS_TYPE.PROCESSED_NO_REBATE && (
                    <div>
                      <div class="border-b my-2 border-muted"></div>
                      <div class="text-right">
                        <Nuxt-link to={`/account/order/delivery?id=${data.id}`}>
                          更新发货 |{' '}
                        </Nuxt-link>
                        <a onClick={() => props.whenShowRebate(data)}>
                          更新退税
                        </a>
                      </div>
                    </div>
                  )}
                </div>
                {data.status ===
                vm.$ENUM.ORDER_PROCESS_TYPE.PROCESSED_NO_REBATE ? (
                  <span class="text-[orange]">
                    <Icon type="ios-checkmark-circle" /> 订单已完成
                  </span>
                ) : (
                  <div class="text-[#fb5120]">
                    <span>
                      <Icon
                        class="animate-bounce"
                        type="ios-information-circle"
                      />{' '}
                      审核未通过
                    </span>
                  </div>
                )}
              </Poptip>
            )
          }
        },
        {
          conditions: [vm.$ENUM.ORDER_PROCESS_TYPE.CREATED],
          render: () => {
            const url = `/account/order/delivery?id=${data.id}`
            return (
              <Tooltip
                transfer
                max-width="250"
                placement="top"
                content="点击发货"
              >
                <Nuxt-link to={url}>
                  <Icon class="animate-pulse" type="ios-cube" /> {statusText}
                </Nuxt-link>
              </Tooltip>
            )
          }
        },
        {
          conditions: [vm.$ENUM.ORDER_PROCESS_TYPE.SHIPING],
          render: () => {
            const { user } = vm.$store.state

            if (user.status !== vm.$ENUM.USER_STATUS.PASS) {
              return (
                <Tooltip
                  transfer
                  max-width="250"
                  placement="top"
                  content="请先进行实名验证以便享受iShopper提供的优惠退税服务"
                >
                  <nuxt-link class="text-muted" to="/user-verify">
                    <Icon class="animate-pulse" type="ios-cube" /> {statusText}
                  </nuxt-link>
                </Tooltip>
              )
            }

            return (
              <Tooltip
                transfer
                max-width="250"
                placement="top"
                content="点击退税"
              >
                <a onClick={() => props.whenShowRebate(data)}>
                  <Icon class="animate-pulse" type="ios-cube" /> {statusText}
                </a>
              </Tooltip>
            )
          }
        },
        {
          conditions: [
            vm.$ENUM.ORDER_PROCESS_TYPE.CHECK,
            vm.$ENUM.ORDER_PROCESS_TYPE.AUDIT
          ],
          render: () => (
            <span class="text-[orange]">
              <Icon class="animate-spin" type="ios-sync" /> {statusText}
            </span>
          )
        }
      ]

      const matchStatus = statusMap.find((item) =>
        typeof item.conditions === 'function'
          ? item.conditions?.()
          : item.conditions.includes(data.status)
      )

      return matchStatus?.render()
    }
  }
}
</script>

<style lang="scss" scoped></style>
