<template>
  <div class="account-exchange-container">
    <Form ref="formValidate" :model="formValidate" :rules="ruleValidate">
      <div class="container form">
        <div>
          <span class="label">当前结算汇率:</span>
          <span style="font-size:20px"> {{ balanceData.rate }} </span>
        </div>
        <div>
          <span class="label">当前人民币账户余额:</span>
          <currency type="￥" :value="balanceData.rmb_balance" />
        </div>
        <div>
          <FormItem prop="cny">
            <span class="label">兑换英镑的人民币余额:</span>
            <span>
              <Input
                v-model="formValidate.cny"
                type="text"
                style="width: 200px"
                placeholder="请输入数额"
                number
              />
            </span>
          </FormItem>
          <span>
            <span>=</span>
            <span class="text-orange">{{
              parseFloat(formValidate.cny / balanceData.rate, 2).toFixed(2)
            }}</span>
          </span>
        </div>
      </div>

      <div class="paymethod-container bg-gray">
        <FormItem>
          <div class="container">
            <div class="actions">
              <nuxt-link to="/account/balance">
                <Button type="info">返 回</Button>
              </nuxt-link>
              <Button type="primary" @click="handleSubmit('formValidate')"
                >确认提交</Button
              >
            </div>
          </div>
        </FormItem>
      </div>
    </Form>
  </div>
</template>

<script>
export default {
  data() {
    const validateCNY = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('金额不能为空'))
      }
      // 模拟异步验证效果
      setTimeout(() => {
        console.log(value)
        console.log(Number.isInteger(value))
        if (!Number.isInteger(value)) {
          callback(new Error('Please enter a numeric value'))
        } else if (value < 0) {
          callback(new Error('输入金额必须大于0'))
        } else if (value > this.balanceData.rmb_balance) {
          callback(new Error('输入金额错误'))
        } else {
          callback()
        }
      }, 0)
    }
    return {
      formValidate: {
        cny: ''
      },
      ruleValidate: {
        cny: [{ validator: validateCNY, trigger: 'blur' }]
      }
    }
  },
  async asyncData({ $api }) {
    const balanceData = await $api.fetchBalance()
    return {
      balanceData
    }
  },
  methods: {
    ok() {
      this.$Message.info('Clicked ok')
    },
    cancel() {
      this.$Message.info('Clicked cancel')
    },
    handleSubmit(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.$Message.success('Success!')
          this.pop_window()
        } else {
          this.$Message.error('Fail!')
        }
      })
    },
    async pop_window() {
      await this.$api.exchange({
        value: this.formValidate.cny
      })
      console.log('start to redirect')
      this.$router.replace('/account/balance/')
      // return { title: res.data.title }    }
    }
  }
}
</script>

<style lang="less">
.account-exchange-container {
  .form {
    width: 500px;
    display: flex;
    flex-flow: column wrap;
    padding: 70px 0 20px;

    > div {
      display: flex;
      align-items: baseline;
      margin-bottom: 20px;

      .label {
        width: 150px;
        text-align: right;
      }

      &:first-child {
        .label {
          margin-top: 10px;
        }
      }

      .label {
        margin-right: 15px;
      }

      .ivu-input-type-number {
        padding: 0 10px 10px 0;

        & ~ div {
          text-align: center;
          margin-left: -15px;
        }
      }
    }
  }

  .paymethod-container {
    padding: 40px 0 200px;

    .container {
      display: flex;
      flex-flow: column wrap;
      align-items: center;
    }

    .actions {
      width: 500px;
      text-align: center;

      a {
        margin-right: 50px;
      }
    }
  }
}
</style>
