<template>
  <div v-if="initd" class="bind-s2c-account">
    <Button v-if="!isBinded" type="primary" @click="showBind"
      >绑定Send2China账号</Button
    >
    <div v-else>
      <Button disabled type="primary">已绑定Send2China账号</Button>
      <i-button type="info" @click="confirm">解绑</i-button>
    </div>
    <Modal
      v-model="visible"
      width="620px"
      title="绑定s2c账号"
      @on-ok="handleSubmit"
    >
      <Form
        ref="form"
        class="bind-account-dialog"
        :model="model"
        :label-width="200"
      >
        <FormRender :rows="rows" />
      </Form>
      <div slot="footer">
        <Button type="primary" :loading="loading" @click="handleSubmit">
          开始绑定
        </Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import FormRender from '~/components/FormRender'

export default {
  components: {
    FormRender
  },

  data() {
    return {
      initd: false,
      visible: false,
      isBinded: false,
      loading: false,
      model: {
        username: '',
        password: ''
      }
    }
  },

  computed: {
    rows() {
      const model = this.model
      return [
        {
          col: 20,
          prop: 'username',
          label: 'Send2China用户名',
          node: <Input v-model={model.username} />,
          rules: {
            required: true,
            message: '请输入用户名'
          }
        },
        {
          col: 20,
          prop: 'password',
          label: 'Send2China密码',
          node: <Input type="password" v-model={model.password} />,
          rules: {
            required: true,
            message: '请输入密码'
          }
        }
      ]
    }
  },
  async mounted() {
    const isBinded = await this.checkBind()
    this.isBinded = isBinded
    this.initd = true
  },

  methods: {
    async checkBind() {
      const user = await this.$api.fetchUserDetail()
      if (user.username_s2c) {
        return true
      } else {
        return false
      }
    },
    showBind() {
      this.visible = true
    },
    async handleSubmit() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
        }
      })
      this.loading = true
      console.log(this.model)
      console.log('start binding')
      try {
        await this.$api.bindingS2cUser({
          username: this.model.username,
          password: this.model.password
        })
        setTimeout(() => {
          this.loading = false
          this.isBinded = true
          this.visible = false
        }, 1000)
        this.$Message.success('绑定成功')
      } catch (err) {
        setTimeout(() => {
          this.loading = false
          this.isBinded = false
          this.visible = false
        }, 1000)
        this.model.username = ''
        this.model.password = ''
        console.log(err)
      }
    },
    confirm() {
      this.$Modal.confirm({
        title: '是否解绑',
        content: '<p>确认解绑该用户</p>',
        onOk: () => {
          try {
            this.gobutton()
            this.$Message.info('解绑成功')
            this.isBinded = false
          } catch (err) {
            console.log(err)
            this.$Message.info('解绑失败')
          }
        },
        onCancel: () => {}
      })
    },
    async gobutton() {
      const res = await this.$api.disBindingS2cUser()
      return res
    }
  }
}
</script>

<style lang="less">
.bind-account-dialog {
  margin: 20px auto 0;
  position: relative;
  left: 30px;
}
</style>
