<script>
import dayjs from 'dayjs'
import ExpressDialog from '@/components/purchase/ExpressDialog.vue'
import ExportDialog from '@/components/purchase/ExportDialog.vue'
import PurchaseReview from '@/components/purchase/PurchaseReview'
import PurchaseStatus from '@/components/purchase/PurchaseStatus'

export default {
  data() {
    const PURCHASE_STATUS = this.$ENUM.PURCHASE_STATUS
    const tabUploadStatusFilter = [
      {
        name: '全部',
        value: [
          PURCHASE_STATUS.UPLOAD,
          PURCHASE_STATUS.DSIAPPROVED,
          PURCHASE_STATUS.RECONFAIL
        ].join(',')
      },
      {
        name: '等待审核',
        value: PURCHASE_STATUS.UPLOAD
      },
      {
        name: '审核失败',
        value: PURCHASE_STATUS.DSIAPPROVED
      },
      {
        name: '票据无效',
        value: PURCHASE_STATUS.RECONFAIL
      }
    ]

    return {
      curTabIndex: 0,
      filter: {
        dateRange: '',
        order_by: ''
      },
      dateOptions: {
        shortcuts: [
          {
            text: '所有',
            value() {
              return []
            }
          },
          {
            text: '今天',
            value() {
              return [
                dayjs()
                  .startOf('day')
                  .toDate(),
                dayjs()
                  .endOf('day')
                  .toDate()
              ]
            }
          },
          {
            text: '本周',
            value() {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              return [start, end]
            }
          },
          {
            text: '本月',
            value() {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              return [start, end]
            }
          },
          {
            text: '今年',
            value() {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              return [start, end]
            }
          }
        ]
      },
      tabs: [
        {
          order_by: '-purchase_type,expiration_data',
          name: () => (
            <Tooltip
              content="请提交消费记录对应的采购票据图片和商品明细"
              placement="top"
              transfer
              max-width="150px"
            >
              <div>
                等待上传
                <span class="text-orange">
                  ({this.orderStatusCount.pending_count || ''})
                </span>
              </div>
            </Tooltip>
          ),
          head: () => (
            <div class="flex items-center justify-between">
              <strong class="text-orange">
                <Icon type="ios-information-circle" />
                请在采购时间的30天以内及时上传采购票据
              </strong>
              <div>
                <ExportDialog />
              </div>
            </div>
          ),
          status: PURCHASE_STATUS.PENDING,
          columns: this.getColById(PURCHASE_STATUS.PENDING)
        },
        {
          order_by: '-updated_at',
          name: () => (
            <Tooltip
              content="查看等待ishopper审核的已提交票据，以及对审核失败的票据重新提交"
              placement="top"
              transfer
              max-width="150px"
            >
              <div>
                等待审核
                <span class="text-orange">
                  (
                  {this.orderStatusCount.upload_count +
                    this.orderStatusCount.disapproved_count +
                    this.orderStatusCount.reconfail_count ||
                    '' ||
                    0}
                  )
                </span>
              </div>
            </Tooltip>
          ),
          // 等待审核、审核失败、票据无效
          status: [
            PURCHASE_STATUS.UPLOAD,
            PURCHASE_STATUS.DSIAPPROVED,
            PURCHASE_STATUS.RECONFAIL
          ].join(','),
          head: () => {
            return (
              <Select
                onOn-change={this.handleUploadStatusFilter}
                v-model={this.tabs[1].status}
                style="width:220px"
              >
                {tabUploadStatusFilter.map((statusItem) => (
                  <Option value={statusItem.value}>{statusItem.name}</Option>
                ))}
              </Select>
            )
          },
          columns: this.getColById(PURCHASE_STATUS.UPLOAD)
        },
        {
          order_by: '-updated_at',
          name: () => (
            <Tooltip
              content="等待送达ishopper审核的实体票据"
              placement="top"
              transfer
              max-width="150px"
            >
              待审实体票据
              <span class="text-orange">
                ({this.orderStatusCount.approved_count})
              </span>
            </Tooltip>
          ),
          status: PURCHASE_STATUS.APPROVE,
          columns: this.getColById(PURCHASE_STATUS.APPROVE)
        },
        {
          order_by: '-updated_at',
          name: () => (
            <Tooltip
              content="已提交的采购票据，以及实体票据全部审核成功"
              placement="top"
              transfer
              max-width="150px"
            >
              <div>
                核对成功
                <span class="text-orange">
                  ({this.orderStatusCount.reconciled_count})
                </span>
              </div>
            </Tooltip>
          ),
          status: PURCHASE_STATUS.RECONCILED,
          columns: this.getColById(PURCHASE_STATUS.RECONCILED)
        },
        {
          order_by: '-updated_at',
          name: () => (
            <Tooltip
              content="异常采购"
              placement="top"
              transfer
              max-width="150px"
            >
              <div>
                异常采购
                <span class="text-orange">
                  ({this.orderStatusCount.trouble_count})
                </span>
              </div>
            </Tooltip>
          ),
          head: () => (
            <div class="flex items-center justify-between">
              <strong class="text-orange">
                <Icon type="ios-information-circle" />
                请上传对应单据完善以下采购信息，如果您的异常采购数量超过5笔，您的账号将会被冻结。
                <a href="https://www.ishopper.com/news/41" target="_blank">
                  【如何放弃以下异常采购的退税申请？】
                </a>
              </strong>
            </div>
          ),
          status: PURCHASE_STATUS.TROUBLE,
          columns: this.getColById(PURCHASE_STATUS.TROUBLE)
        }
      ],
      orderStatusCount: {},
      expDialogVisible: false,
      listStatus: 0,
      curRows: []
    }
  },
  computed: {
    curDataTableRef() {
      return this.$refs.dataTable[this.curTabIndex]
    }
  },
  watch: {
    filter: {
      handler() {
        this.refresh()
      },
      deep: true
    },
    curTabIndex(value) {
      if (value === -1) {
        return
      }

      this.$router.push({
        query: {
          ...this.$route.query,
          order_by: this.tabs[value].order_by,
          page: this.$refs.dataTable[this.curTabIndex]?.page,
          status: this.tabs[value]?.status,
          curTabIndex: value
        }
      })
    }
  },
  mounted() {
    const reFetch = (query) => {
      if (!query.curTabIndex) {
        return
      }

      this.filter.dateRange = query.dateRange
      this.filter.order_by = query.order_by || ''
      this.curTabIndex = Number(query.curTabIndex)
      return this.$refs.dataTable[this.curTabIndex].fetchData(query.page)
    }

    // 恢复查询状态
    const query = this.$route.query
    setTimeout(async () => {
      if (query.curTabIndex !== undefined) {
        await reFetch(query)
      }

      this.$watch('$route', (newValue) => {
        if (window.event?.target === window) {
          reFetch(newValue.query)
        }
      })

      this.refresh()
    }, 0)

    this.$store.dispatch('updateUserDetail')
  },
  methods: {
    async refresh() {
      await Promise.all(
        this.$refs.dataTable.map((compRef) => compRef.refresh())
      )
      return this.fetchPuchaseStatus()
    },
    async handleGiveupUpload(row) {
      await this.$api.updatePuchase({
        action: 'give_up',
        purchase_id: row.id
      })
      this.refresh()
    },
    async handleRecoverUpload(row) {
      await this.$api.updatePuchase({
        action: 'recover',
        purchase_id: row.id
      })
      this.refresh()
    },
    async fetchPuchase(params, tabIndex) {
      if (tabIndex === this.curTabIndex) {
        this.$router.push({
          query: {
            ...params,
            curTabIndex: this.curTabIndex
          }
        })
      }

      params.order_by =
        params.order_by || this.filter.order_by || this.tabs[tabIndex].order_by

      const res = await this.$api.fetchPuchase(params)
      if (res.data?.length) {
        window.dataLayer.push({
          event: 'user_conversion',
          conversion_action: 'had_purchased'
        })
      }

      return res
    },
    handleSortChange({ key, order }) {
      this.filter.order_by = `${order === 'desc' ? '-' : ''}${key}`
    },
    async fetchPuchaseStatus() {
      const orderStatusCount = await this.$api.fetchPuchaseStatus({
        dateRange: this.filter.dateRange,
        order_by: this.filter.order_by
      })
      this.orderStatusCount = orderStatusCount

      // 避免布局错误
      const curTabIndex = this.curTabIndex
      this.curTabIndex = -1
      this.$nextTick(() => {
        this.curTabIndex = curTabIndex
      })
    },
    handleUploadStatusFilter(status) {
      this.curTabIndex = 1
      this.tabs[1].status = status
      this.$nextTick(() => {
        this.$refs.dataTable[1].fetchData(1)
      })
    },
    handleSubmitExp(item) {
      const rows = this.curDataTableRef.$refs.tableRef.getSelection()
      if (!rows.some((row) => row.id === item.id)) {
        rows.push(item)
      }

      this.curRows = rows
      this.expDialogVisible = true
    },
    getColById(id) {
      const PURCHASE_STATUS = this.$ENUM.PURCHASE_STATUS
      const checkboxRow = [
        {
          title: 'id',
          width: 70,
          key: 'id'
        },
        {
          title: '采购时间',
          key: 'purchase_time',
          sortable: 'custom',
          render: (_, { row }) => {
            return (
              <span>
                {this.$dayjs(row.purchase_time).format('YYYY/MM/DD HH:mm')}
              </span>
            )
          }
        },
        {
          title: '票据类型',
          key: 'invoice_type',
          width: 120,
          render: (_, { row }) => {
            return (
              <span>
                <PurchaseReview data={row} key={row.id} />
                {this.$ENUM.INVOICE_TYPE[row.invoice_type]}
              </span>
            )
          }
        },
        {
          title: '持卡人',
          key: 'card.name',
          width: 100,
          render: (_, { row }) => {
            return <span>{row.card && row.card.name}</span>
          }
        },
        {
          title: '消费卡',
          key: 'card.number',
          render: (_, { row }) => {
            if (row.card && row.card.number) {
              return <span>{row.card.number}</span>
            } else {
              return <span>-</span>
            }
          }
        },
        {
          title: '金额',
          width: 110,
          key: 'amount',
          sortable: 'custom',
          render: (_, { row }) => {
            if (row.purchase_type === this.$ENUM.PURCHASE_STATUS.PENDING) {
              return (
                <span class="text-orange" style="">
                  <span class="rebate-icon">退</span>
                  <Currency
                    size="tiny"
                    color="orange"
                    type="£"
                    value={row.amount}
                  />
                </span>
              )
            } else {
              return <Currency size="tiny" type="£" value={row.amount} />
            }
          }
        },
        {
          title: '备注',
          key: 'notes'
        },
        {
          title: '状态',
          key: 'status',
          width: 120,
          render: (_, { row }) => {
            return (
              <PurchaseStatus
                data={row}
                whenSubmitExpress={() => this.handleSubmitExp(row)}
              />
            )
          }
        },
        {
          title: '操作',
          key: 'action',
          align: 'center',
          width: 100,
          render: (_, { row }) => {
            return (
              <Nuxt-link to={`/account/purchase/detail?id=${row.id}`}>
                详情
              </Nuxt-link>
            )
          }
        }
      ]

      // 增加过期提示
      if (id === PURCHASE_STATUS.PENDING) {
        const targetIndex = checkboxRow.findIndex(
          (item) => item.key === 'notes'
        )

        checkboxRow.splice(targetIndex + 1, 0, {
          title: '到期',
          width: 100,
          align: 'center',
          render: (_, { row }) => {
            const BASE = 30
            const expires = row.expiration_data
            const days = expires ? dayjs(expires).diff(dayjs(), 'day') : 0
            const percent = 100 - (days / BASE) * 100

            // 退款类型无需显示
            if (
              row.purchase_type === this.$ENUM.PURCHASE_TRANSACTION_TYPE.REFUND
            ) {
              return <span></span>
            }

            const color =
              days >= 20 ? '#2e8cf0' : days >= 10 ? 'orange' : '#ff5500'

            return (
              <Tooltip transfer placement="top-end" max-width="350">
                <div slot="content">
                  <p>
                    请在{days}天内
                    <Nuxt-link to={`/account/purchase/refund?id=${row.id}`}>
                      上传交易小票和商业发票
                    </Nuxt-link>
                    ，避免交易过期
                  </p>
                  <br />
                  <p>若您的过期交易累计数量超过5笔，您的消费卡将会被冻结</p>
                </div>

                <i-circle
                  percent={percent}
                  stroke-color={color}
                  size={36}
                  class="mt-[5px]"
                >
                  <span class="text-[11px]" style={{ color }}>
                    {days}天
                  </span>
                </i-circle>
              </Tooltip>
            )
          }
        })
      }

      // 增加多选邮寄小票
      if (id === PURCHASE_STATUS.APPROVE) {
        checkboxRow.unshift({
          type: 'selection',
          width: 60,
          align: 'center'
        })
      }

      return checkboxRow
    }
  },
  render() {
    const PURCHASE_STATUS = this.$ENUM.PURCHASE_STATUS

    return (
      <div class="account-purchase-container">
        <div class="bg-gray tabs-container">
          <div class="container">
            <div class="date-filter">
              <DatePicker
                v-model={this.filter.dateRange}
                type="daterange"
                placement="bottom-end"
                placeholder="筛选日期"
                style="width: 220px"
                options={this.dateOptions}
              ></DatePicker>
            </div>

            <Tabs v-model={this.curTabIndex}>
              <div class="extra-info" slot="extra">
                <span>
                  <a
                    herf="###"
                    onClick={() =>
                      this.handleUploadStatusFilter(PURCHASE_STATUS.DSIAPPROVED)
                    }
                  >
                    审核失败
                    <span class="text-orange">
                      ({this.orderStatusCount.disapproved_count})
                    </span>
                  </a>
                </span>
                <span>
                  <span class="text-muted" style="margin: 0 5px;">
                    |
                  </span>
                  <a
                    herf="###"
                    onClick={() =>
                      this.handleUploadStatusFilter(PURCHASE_STATUS.RECONFAIL)
                    }
                  >
                    票据无效
                    <span class="text-orange">
                      ({this.orderStatusCount.reconfail_count})
                    </span>
                  </a>
                </span>
              </div>

              {this.tabs.map((tab, index) => {
                const status = tab.status
                return (
                  <TabPane key={index} label={tab.name}>
                    {tab.head ? (
                      <div style="margin: -15px 0 20px">{tab.head()}</div>
                    ) : null}
                    <DataTable
                      auto={false}
                      refInFor
                      ref="dataTable"
                      class="table-blue"
                      sortChange={this.handleSortChange}
                      whenFetch={(page) =>
                        this.fetchPuchase(
                          { page, status, ...this.filter },
                          index
                        )
                      }
                      columns={tab.columns}
                    />
                  </TabPane>
                )
              })}
            </Tabs>

            <ExpressDialog
              v-model={this.expDialogVisible}
              data={this.curRows}
              whenSuccess={() => this.curDataTableRef.refresh()}
            />
          </div>
        </div>
      </div>
    )
  }
}
</script>

<style lang="less">
.account-purchase-container {
  margin-top: 160px;

  .container {
    position: relative;

    .ivu-tabs {
      position: relative;
      top: -130px;
      margin-bottom: -40px;
    }

    .ivu-tabs-nav-wrap {
      margin-left: 60px;
    }
    .date-filter {
      position: absolute;
      top: -130px;
      left: 0;
      z-index: 1000;
    }

    .ivu-tabs-bar {
      border-color: #f4f4f4;
      margin-bottom: 40px;
    }

    .extra-info {
      margin-top: 8px;
    }
  }

  .link-btn {
    color: #2d8cf0;
    cursor: pointer;

    &.link-divide {
      border-left: 1px solid #ccc;
      margin-left: 5px;
      padding-left: 5px;
    }
  }

  .rebate-icon {
    width: 14px;
    height: 14px;
    line-height: 14px;
    font-size: 10px;
    text-align: center;
    color: #fff;
    border-radius: 50%;
    background: #e25e3a;
    display: inline-block;
    margin-right: 3px;
  }
}
</style>
