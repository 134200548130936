<template>
  <div class="app" :class="{ dev: isDev }">
    <CookiePolicy />

    <div class="header">
      <div class="header-top">
        <div class="line" />
        <a href="/" class="logo">
          <img src="~/assets/images/common/logo.png"
        /></a>
        <div v-if="isMobile === false" class="actions">
          <span v-if="!user || !user.id" class="unauth">
            <nuxt-link to="/login">登录</nuxt-link>
            <nuxt-link to="/register">注册</nuxt-link>
          </span>
          <span v-else class="auth">
            <div class="icon">
              <img src="~/assets/images/common/icon-1.png" alt="" />
            </div>
            <div class="links">
              <nuxt-link to="/account">我的账户</nuxt-link>
              <a href="#0" @click="logout(true)">退出登录</a>
            </div>
          </span>
        </div>
        <div v-if="isMobile === true" class="mobile-actions" @click="action">
          <div v-if="!openMenu" class="open">
            <svg
              width="28"
              height="29"
              viewBox="0 0 28 29"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M27 24.4128H1C0.734784 24.4128 0.48043 24.5182 0.292893 24.7057C0.105357 24.8933 0 25.1476 0 25.4128L0 27.4128C0 27.678 0.105357 27.9324 0.292893 28.1199C0.48043 28.3075 0.734784 28.4128 1 28.4128H27C27.2652 28.4128 27.5196 28.3075 27.7071 28.1199C27.8946 27.9324 28 27.678 28 27.4128V25.4128C28 25.1476 27.8946 24.8933 27.7071 24.7057C27.5196 24.5182 27.2652 24.4128 27 24.4128ZM27 16.4128H1C0.734784 16.4128 0.48043 16.5182 0.292893 16.7057C0.105357 16.8933 0 17.1476 0 17.4128L0 19.4128C0 19.678 0.105357 19.9324 0.292893 20.1199C0.48043 20.3075 0.734784 20.4128 1 20.4128H27C27.2652 20.4128 27.5196 20.3075 27.7071 20.1199C27.8946 19.9324 28 19.678 28 19.4128V17.4128C28 17.1476 27.8946 16.8933 27.7071 16.7057C27.5196 16.5182 27.2652 16.4128 27 16.4128ZM27 8.41283H1C0.734784 8.41283 0.48043 8.51818 0.292893 8.70572C0.105357 8.89326 0 9.14761 0 9.41283L0 11.4128C0 11.678 0.105357 11.9324 0.292893 12.1199C0.48043 12.3075 0.734784 12.4128 1 12.4128H27C27.2652 12.4128 27.5196 12.3075 27.7071 12.1199C27.8946 11.9324 28 11.678 28 11.4128V9.41283C28 9.14761 27.8946 8.89326 27.7071 8.70572C27.5196 8.51818 27.2652 8.41283 27 8.41283ZM27 0.412827H1C0.734784 0.412827 0.48043 0.518183 0.292893 0.70572C0.105357 0.893256 0 1.14761 0 1.41283L0 3.41283C0 3.67804 0.105357 3.9324 0.292893 4.11993C0.48043 4.30747 0.734784 4.41283 1 4.41283H27C27.2652 4.41283 27.5196 4.30747 27.7071 4.11993C27.8946 3.9324 28 3.67804 28 3.41283V1.41283C28 1.14761 27.8946 0.893256 27.7071 0.70572C27.5196 0.518183 27.2652 0.412827 27 0.412827Z"
                fill="white"
              />
            </svg>
          </div>
          <div v-else class="close">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M23.0832 3.14916L20.8507 0.916664L11.9998 9.7675L3.149 0.916664L0.916504 3.14916L9.76734 12L0.916504 20.8508L3.149 23.0833L11.9998 14.2325L20.8507 23.0833L23.0832 20.8508L14.2323 12L23.0832 3.14916Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
      </div>
      <div v-if="!isMobile || (isMobile && openMenu)" class="menus flexRow">
        <nuxt-link
          v-for="menuItem of !isMobile ? menus : mobileMenus"
          :key="menuItem.name"
          :to="menuItem.url"
        >
          {{ menuItem.name }}
        </nuxt-link>

        <div class="languages">
          <Nuxt-link class="nuxt-link-exact-active nuxt-link-active" to="/"
            >中文</Nuxt-link
          >
          <Nuxt-link to="/en">EN</Nuxt-link>
        </div>
      </div>
    </div>

    <nuxt class="main" />

    <div class="footer">
      <div class="nav">
        <div class="qrcode-list">
          <div>
            <div class="qrcode">
              <img src="~/assets/images/common/qrcode.png" alt="" />
            </div>
          </div>
          <div>
            <p>关注iShopper公众号</p>
            <p>随时获取第一手资讯及打折信息！</p>
          </div>
          <!-- <div>
            <div class="qrcode">
              <img src="~/assets/images/common/cs-qrcode.png" alt="" />
            </div>
            <p>客服二维码</p>
          </div> -->
        </div>

        <FooterMenu />
      </div>

      <div class="copyright">
        <nuxt-link to="/terms-conditions">TERMS AND CONDITIONS</nuxt-link>
        <nuxt-link to="/privacy-policy">PRIVACY POLICY</nuxt-link>
      </div>
    </div>
  </div>
</template>

<script>
import bus from '~/utils/bus'
import CookiePolicy from '~/components/CookiePolicy'
import FooterMenu from '~/components/FooterMenu'
import { eventService } from '~/services/event.service'

import '~/assets/index.less'

export default {
  components: {
    CookiePolicy,
    FooterMenu
  },
  head() {
    return {
      link: [
        {
          rel: 'canonical',
          href: 'https://www.ishopper.com' + this.$route.path
        }
      ]
    }
  },
  data() {
    return {
      menus: [
        { name: '首页', url: '/' },
        { name: '关于我们', url: '/about' },
        { name: '我们的服务', url: '/service' },
        { name: '常见问题', url: '/qa' },
        { name: '折扣资讯', url: '/news' },
        { name: '联系我们', url: '/contact' },
        { name: '合作伙伴计划', url: '/aff-program' }
      ],
      mobileMenus: [
        { name: '首页', url: '/' },
        { name: '登录', url: '/login' },
        { name: '注册', url: '/register' },
        { name: '关于我们', url: '/about' },
        { name: '我们的服务', url: '/service' },
        { name: '常见问题', url: '/qa' },
        { name: '折扣资讯', url: '/news' },
        { name: '联系我们', url: '/contact' },
        { name: '合作伙伴计划', url: '/aff-program' }
      ],
      isMobile: null,
      openMenu: false
    }
  },
  computed: {
    isDev() {
      return process.env.NODE_ENV === 'development'
    },
    user() {
      return this.$store.state.user
    }
  },
  watch: {
    user: {
      deep: true,
      handler() {
        this.updateUserInfo()
      }
    }
  },
  mounted() {
    eventService.on('EVENT_GQL_ERROR', (err) => {
      this.$Message.error({
        background: true,
        content: err.message || err,
        duration: 5
      })
    })

    this.updateUserInfo()
    this.comMobile()
    bus.$on('logout', this.logout)
    this.$Message.config({
      duration: 3
    })
  },
  methods: {
    action() {
      this.openMenu = !this.openMenu
    },
    comMobile() {
      const width = document.body.clientWidth
      this.isMobile = width <= 640
    },
    updateUserInfo() {
      const { user } = this

      if (!user) {
        return
      }

      window.dataLayer.push({
        user_id: user.id,
        email: user.email,
        username: user.username,
        phone: user.phone
      })

      this.$logRocket &&
        this.$logRocket.identify(user.username || '未设置用户名', {
          id: user.id,
          email: user.email,
          username: user.username,
          phone: user.phone
        })
    },
    async logout(report = false) {
      if (report && this.$store.state.token) {
        await this.$api.logout()
      }

      this.$store.commit('SET_TOKEN', null)
      this.$store.commit('SET_USER', null)
      this.$cookies.remove('token', { path: '/' })
      this.$cookies.remove('token', {
        path: '/',
        domain: location.hostname
          .split('.')
          .slice(-2)
          .join('.')
      })

      if (this.$router.currentRoute.path !== '/login') {
        this.$router.replace('/login')
      }

      window.dataLayer.push({
        user_id: undefined
      })
    }
  }
}
</script>

<style lang="less"></style>
