<template>
  <!-- eslint-disable vue/no-v-html -->
  <div class="new-detail-wrapper reversion">
    <RBanner
      bg-color="#fff7eb"
      :bg-imgae="require('../../assets/images/new-news/banner.png')"
      @click="customerService"
    >
      <template v-slot:title>全英唯一线上海淘自助退税平台</template>
      <template v-slot:subtitle>
        获取ishopper第一手最新资讯及折扣相关信息
      </template>
      <template v-slot:btn
        >联系客服了解
        <IconFont
          class="banner-arrow-right"
          size="28"
          color="#fff"
          name="arrow-right"
      /></template>
    </RBanner>
    <div class="new-news-detail new-center-container">
      <div class="news-detail-content">
        <h3 class="title">{{ detail.title }}</h3>
        <p class="date">{{ $dayjs(detail.created_at).format('YYYY-MM-DD') }}</p>
        <div class="content" v-html="detail.content" />
      </div>
      <div class="news-detail-bottom flexRow">
        <ul class="list flexRow">
          <li>
            <div class="img-box">
              <img v-lazy="require(`../../assets/images/index/xcx.jpg`)" />
            </div>
            <p>小程序</p>
          </li>
          <li>
            <div class="img-box">
              <img v-lazy="require(`../../assets/images/index/qrcode.png`)" />
            </div>
            <p>公众号</p>
          </li>
        </ul>
        <div class="desc">
          <p>
            您可以微信扫码进入小程序购买，<br />
            关注我们随时获取第一手资讯及打折信息！
          </p>
        </div>
      </div>
      <div class="btns flexRow">
        <RButton :href="`/news/${previous_id}`" size="middle" type="rdefault">
          <IconFont
            reverse="x"
            size="30"
            name="arrow-right"
          />返回上一页</RButton
        >
        <RButton :href="`/news/${next_id}`" size="middle" type="rdefault"
          >浏览下一页 <IconFont reverse="y" size="30" name="arrow-right"
        /></RButton>
      </div>
    </div>
  </div>
  <!--eslint-enable-->
</template>

<script>
import IconFont from '../../iconfont'
import RBanner from '../../components/revision/RBanner'
import RButton from '../../components/revision/RButton'
export default {
  components: {
    RBanner,
    IconFont,
    RButton
  },
  head() {
    return {
      title: '折扣资讯'
    }
  },
  layout() {
    return 'new-default'
  },
  data() {
    return {
      isMobile: null
    }
  },
  async asyncData({ route, $api, app }) {
    const res = await $api.fetchNewsById({ id: route.params.id })
    const { data, next_id, previous_id } = res
    app.head.title = data.title
    console.log(res)
    return {
      detail: data,
      next_id,
      previous_id
    }
  },
  methods: {
    customerService() {
      const user = this.$store.state.user
      if (user) {
        window._MEIQIA('metadata', {
          id: user.id,
          email: user.email,
          username: user.username,
          phone: user.phone
        })
      }
      window._MEIQIA('showPanel')
    }
  }
}
</script>

<style lang="less">
.new-detail-wrapper {
  .new-news-detail {
    padding: 100px 0;
    width: 730px;
    .news-detail-content {
      .title {
        line-height: 45px;
        font-size: 32px;
        font-weight: 600;
        color: #222;
      }
      .date {
        padding-top: 10px;
        padding-bottom: 60px;
        color: #999;
        font-size: 18px;
      }
      .content {
        line-height: 1.78;
        font-size: 18px;
        text-align: justify;
        color: #222;
        p {
          padding-bottom: 30px;
        }
      }
    }
    .news-detail-bottom {
      align-items: center;
      .list {
        li {
          width: 88px;
          margin-right: 40px;
          .img-box {
            height: 88px;
            box-sizing: border-box;
            padding: 5px;
            border-radius: 6px;
            box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.08);
            border: solid 1px rgba(0, 0, 0, 0.1);
            background-color: #fff;
            margin-bottom: 10px;
            img {
              display: block;
              width: 78px;
              // height: 78px;
            }
          }
          p {
            font-size: 12px;
            color: #222;
            text-align: center;
          }
        }
      }
      .desc {
        line-height: 1.88;
        font-size: 16px;
        font-weight: 600;
        color: #e56131;
        margin-top: -28px;
      }
    }
    .btns {
      margin-top: 60px;
      position: relative;
      padding-top: 40px;
      justify-content: space-between;
      &::after {
        position: absolute;
        width: 100%;
        content: '';
        height: 1px;
        opacity: 0.1;
        border-bottom: solid 1px #222;
        left: 0;
        top: 0;
      }
      a {
        width: 194px;
        .iconfont {
          font-weight: normal;
          color: #000;
        }
        &:hover {
          .iconfont {
            color: #e56131;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 640px) {
  .new-detail-wrapper {
    width: auto;
    .new-news-detail {
      width: auto;
      padding: 40px 14px;
      .news-detail-content {
        .title {
          font-size: 24px;
          color: #222;
        }
        .date {
          padding-bottom: 40px;
        }
      }
      .news-detail-bottom {
        flex-direction: column-reverse;
        justify-content: center;
        .desc {
          margin-bottom: 20px;
          margin-top: 0;
        }
        .list {
          width: 100%;
          justify-content: space-between;
          li {
            margin-right: 0;
          }
        }
      }
      .btns {
        margin-top: 40px;
        justify-content: space-between;
        a {
          width: 168px;
        }
      }
    }
  }
}
</style>
