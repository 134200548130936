<script>
export default {
  head() {
    return {
      title: '推广明细'
    }
  },
  data() {
    return {
      curTab: 2,
      withdrawLoading: false
    }
  },
  methods: {
    async getCash() {
      try {
        this.withdrawLoading = true
        await this.$api.applyAffiliateCash()
        this.$Message.success('提现成功')
      } catch (err) {
        console.error(err)
      }
      this.withdrawLoading = false
    }
  },

  render() {
    const commissionOfNumberDetail = {
      api: this.$api.fetchAffiliateRecommend,
      cols: [
        {
          title: 'ID',
          key: 'number'
        },
        {
          title: '新客户',
          width: 150,
          key: 'user.username',
          render: (_, { row }) => {
            return <span>{row.user.username}</span>
          }
        },
        {
          title: '推荐人',
          width: 150,
          key: 'referrer.username',
          render: (_, { row }) => {
            return <span>{row.referrer.username}</span>
          }
        },
        {
          title: '新客累计消费额',
          width: 150,
          key: 'cost_total',
          render: (_, { row }) => {
            return <Currency color="normal" value={row.cost_total} type="£" />
          }
        },
        {
          title: '新客消费达标额',
          width: 150,
          key: 'min_cost',
          render: (_, { row }) => {
            return <Currency color="normal" value={row.min_cost} type="£" />
          }
        },
        {
          title: '新客户奖金',
          width: 110,
          key: 'new_guy_commission',
          render: (_, { row }) => {
            return (
              <Currency
                color="normal"
                value={row.new_guy_commission}
                type="£"
              />
            )
          }
        },
        {
          title: '推荐人奖金',
          key: 'broker_commission',
          render: (_, { row }) => {
            return (
              <Currency color="normal" value={row.broker_commission} type="£" />
            )
          }
        },
        {
          title: '返佣状态',
          key: 'status',
          width: 95,
          render: (_, { row }) => {
            return (
              <span>{this.$ENUM.AFFILIATE_RECOMMEND_STATUS[row.status]}</span>
            )
          }
        },
        {
          title: '消费达标时间',
          width: 125,
          key: 'done_time',
          render: (_, { row }) => {
            return (
              <span>
                {row.done_time &&
                  this.$dayjs(row.done_time).format('YYYY/MM/DD')}
              </span>
            )
          }
        }
      ]
    }

    const commissionOfConsumptionDetail = {
      api: this.$api.fetchAffiliateLog,
      cols: [
        {
          title: 'ID',
          key: 'number',
          width: 110
        },
        {
          title: '新客户',
          key: 'user.username',
          render: (_, { row }) => {
            return <span>{row.user.username}</span>
          }
        },
        {
          title: '推荐人',
          key: 'referrer.username',
          render: (_, { row }) => {
            return <span>{row.referrer.username}</span>
          }
        },
        {
          title: '新客消费卡号',
          key: 'card_number',
          width: 145
        },
        {
          title: '新客消费金额',
          key: 'card_record.amount',
          render: (_, { row }) => {
            return (
              <Currency
                color="normal"
                value={row.card_record.amount}
                type="£"
              />
            )
          }
        },
        {
          title: '推荐人提成比例',
          key: 'card_record.card.commission',
          render: (_, { row }) => {
            return <span>{row.card_record.card.commission}</span>
          }
        },
        {
          title: '推荐人提成金额',
          key: 'commission',
          render: (_, { row }) => {
            return <span>£ {row.commission}</span>
          }
        },
        {
          title: '返佣状态',
          width: 100,
          key: 'status_show'
        },
        {
          title: '新客消费时间',
          key: 'card_record.booking_datetime',
          width: 110,
          render: (_, { row }) => {
            return (
              <span>
                {this.$dayjs(row.card_record.booking_datetime).format(
                  'YYYY/MM/DD HH:mm:ss'
                )}
              </span>
            )
          }
        },
        {
          title: '推荐人提成有效期(月)',
          key: 'card_record.card.rebate_month',
          width: 80,
          render: (_, { row }) => {
            return <span>{row.card_record.card.rebate_month}</span>
          }
        },
        {
          title: '提成截止时间',
          key: 'card_record.card.rebate_end_date',
          width: 110,
          render: (_, { row }) => {
            return (
              <span>
                {row.card_record.card.rebate_end_date &&
                  this.$dayjs(row.card_record.card.rebate_end_date).format(
                    'YYYY/MM/DD HH:mm:ss'
                  )}
              </span>
            )
          }
        }
      ]
    }

    const bonusDetail = {
      api: this.$api.fetchAffiliateCommission,
      cols: [
        {
          title: '佣金来源',
          key: 'commission_source'
        },
        {
          title: '奖金',
          key: 'commission',
          render: (_, { row }) => {
            return <Currency color="normal" value={row.commission} type="£" />
          }
        },
        {
          title: '返佣状态',
          key: 'status'
        },
        {
          title: '奖金所属ID',
          key: 'number'
        },
        {
          title: '备注',
          key: 'note'
        },
        {
          title: '返佣生效时间',
          key: 'eff_time',
          render: (_, { row }) => {
            return (
              <span>
                {row.eff_time && this.$dayjs(row.eff_time).format('YYYY/MM/DD')}
              </span>
            )
          }
        }
      ]
    }

    const tabs = [
      {
        name: '新客达标奖励',
        content: (
          <DataTable
            ref="dataTable"
            class="table-blue"
            when-fetch={(page, perPage) =>
              commissionOfNumberDetail.api({
                page,
                perPage
              })
            }
            columns={commissionOfNumberDetail.cols}
          />
        )
      },
      {
        name: '新客消费提成',
        content: (
          <DataTable
            ref="dataTable"
            class="table-blue"
            when-fetch={(page, perPage) =>
              commissionOfConsumptionDetail.api({
                page,
                perPage
              })
            }
            columns={commissionOfConsumptionDetail.cols}
          />
        )
      },
      {
        name: '奖金明细',
        content: (
          <div>
            <div class="text-right">
              <Button
                loading={this.withdrawLoading}
                type="primary"
                onClick={() => this.getCash()}
              >
                提现至余额
              </Button>
            </div>
            <br />
            <DataTable
              ref="dataTable"
              class="table-blue"
              when-fetch={(page, perPage) =>
                bonusDetail.api({
                  page,
                  perPage
                })
              }
              columns={bonusDetail.cols}
            />
          </div>
        )
      }
    ]

    return (
      <div class="affiliate-detail tabs-container">
        <Tabs v-model={this.curTab}>
          {tabs.map((tab) => (
            <TabPane class="tab-balance" label={tab.name}>
              <div class="container">{tab.content}</div>
            </TabPane>
          ))}
        </Tabs>
      </div>
    )
  }
}
</script>

<style lang="less">
.affiliate-detail {
}
</style>
