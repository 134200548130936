<template>
  <Modal
    v-model="visible"
    class="drawback-container"
    width="750px"
    title="申请退税返佣"
    @on-ok="handleSubmit"
  >
    <DataTable
      class="table-blue"
      :when-fetch="fetchBill"
      hide-page
      :columns="columns"
    />
    <div class="upload-container">
      <h3>如包裹{{ info.tracking_number }}已经妥投，请上传妥投截图</h3>
      <Upload
        ref="upload"
        :headers="headers"
        type="drag"
        :before-upload="handleBeforeUpload"
        :on-remove="handleUploadRemove"
        :on-success="handleUploadSuccess"
        :disabled="!!uploadList.length"
        name="attachment"
        :data="{ pk: id }"
        :action="`${$API_URL}/api/order/rebate-upload/${id}/`"
      >
        <div v-if="curFile" class="pic">
          <img :src="curFile" />
        </div>
        <div style="padding: 20px 0">
          <Icon
            v-if="uploadList.length"
            type="ios-checkmark-circle"
            size="52"
            style="color:#19be6b"
          />
          <Icon
            v-else
            type="ios-cloud-upload"
            size="52"
            style="color: #3399ff"
          />
          <p>
            {{ uploadList.length ? '已选择图片' : '点击或者拖拽到这里上传' }}
          </p>
        </div>
      </Upload>
    </div>

    <div class="action text-center">
      <Button
        :disabled="uploading"
        type="primary"
        :loading="loading"
        @click="handleSubmit"
      >
        确定提交
      </Button>
    </div>
  </Modal>
</template>

<script>
export default {
  props: {
    id: {
      type: Number,
      required: true
    },
    onSubmit: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      curFile: null,
      loading: false,
      uploading: false,
      info: {},
      visible: true,
      uploadList: [],
      can_be_submit: false
    }
  },
  computed: {
    headers() {
      return {
        Authorization: `JWT ${this.$store.state.token}`
      }
    },
    columns() {
      return [
        {
          title: '票据号',
          render: (_, { row }) => {
            return <div>{row.invoice_number}</div>
          }
        },
        {
          title: '票据邮寄单号',
          key: 'express_number',
          render: (_, { row, index }) => {
            const handleCopy = (text) => {
              this.$copyText(text)
              this.$Message.success(`已复制 ${text}`)
            }

            return (
              <div
                class={{
                  'express-number': true,
                  active: !!row.purchase_express_number
                }}
              >
                <Input
                  value={row.purchase_express_number}
                  onInput={(value) =>
                    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                    (this.info.purchases[index].purchase_express_number = value)
                  }
                />
                <Button
                  onClick={() => handleCopy(row.purchase_express_number)}
                  type="primary"
                  size="small"
                >
                  复制
                </Button>
              </div>
            )
          }
        },
        {
          title: '系统提示',
          render: (_, { row }) => {
            return <div>{row.purchase_status_display}</div>
          }
        }
      ]
    }
  },
  mounted() {
    this.uploadList = this.$refs.upload.fileList
  },
  methods: {
    handleUploadSuccess() {
      this.uploading = false
      this.can_be_submit = true
    },
    handleUploadRemove() {
      this.curFile = null
      this.uploading = false
    },
    handleBeforeUpload(file) {
      this.uploading = true
      this.curFile = window.URL.createObjectURL(file)
    },
    async fetchBill() {
      const data = await this.$api.fetchDrawbackById(this.id)

      this.info = data
      return { data: data.purchases }
    },
    async handleSubmit() {
      this.loading = true
      if (this.can_be_submit === false) {
        this.$Message.error('请上传妥投截图')
      } else {
        try {
          const model = this.info.purchases
          await this.$api.saveDrawbackById(this.id, model)
          this.onSubmit()
          this.visible = false

          window.dataLayer.push({
            event: 'user_conversion',
            conversion_action: 'had_refund'
          })
        } catch (err) {
          console.error(err)
        }
      }
      this.loading = false
    }
  }
}
</script>

<style lang="less">
.drawback-container {
  .ivu-modal-footer {
    display: none;
  }

  .upload-container {
    margin: 30px 0;

    h3 {
      text-align: center;
      margin-bottom: 20px;
    }

    .ivu-upload {
      width: 71%;
      margin: 0 auto;
    }

    .ivu-upload-drag {
      position: relative;
      .pic {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #fff;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }

  .action {
    margin: 0 0 10px;
  }

  .express-number {
    padding: 15px 0;
    position: relative;

    &.active {
      button {
        display: block;
      }

      &:hover button {
        opacity: 1;
      }
    }
    button {
      position: absolute;
      right: 10px;
      top: 20px;
      opacity: 0;
      transition: 0.3s;
      display: none;
    }
  }
}
</style>
