<template>
  <div
    class="currency-container"
    :style="{ fontWeight: bold ? 'bold' : 'normal' }"
    :class="[`color-${color}`, `size-${size}`, { new: 'new' }]"
  >
    <span class="currency-type" :class="[`currency-type-${type}`]">
      {{ type }}
    </span>
    <span class="currency-int">{{ int }}</span>
    <span class="currency-decimal">.{{ decimal }}</span>
  </div>
</template>

<script>
export default {
  props: {
    new: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: '£'
    },
    value: {
      type: [Number, String],
      required: true
    },
    color: {
      type: String,
      default: 'default'
    },
    size: {
      type: String,
      default: 'default'
    },
    bold: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    int() {
      return Number(
        Number(this.value)
          .toFixed(2)
          .split('.')[0]
      ).toLocaleString()
    },
    decimal() {
      return Number(this.value)
        .toFixed(2)
        .split('.')[1]
    }
  }
}
</script>

<style lang="less">
.currency-container {
  display: inline-flex;
  align-items: baseline;
  white-space: nowrap;

  &.color-orange {
    .currency-type,
    .currency-int,
    .currency-decimal {
      color: #ea5d35 !important;
    }
  }
  &.color-green {
    .currency-type,
    .currency-int,
    .currency-decimal {
      color: #78b74a !important;
    }
  }
  &.color-normal {
    .currency-type,
    .currency-int,
    .currency-decimal {
      font-size: 14px !important;
      font-family: inherit;
    }
  }

  &.size-mini {
    .currency-type,
    .currency-int,
    .currency-decimal {
      font-size: 14px !important;
      font-family: inherit;
    }
  }

  &.size-tiny {
    .currency-type,
    .currency-int,
    .currency-decimal {
      font-size: 12px !important;
      font-family: inherit;
    }
  }
  .currency-type {
    font-size: 22px;
    color: #e77084;
    margin-right: 2px;

    &.currency-type-£ {
      color: #e6aa41;
    }
    &.currency-type-¥ {
      color: #e77084;
    }
  }

  .currency-int {
    font-size: 40px;
    color: #3b3b3b;
    font-family: PingFangSC-Thin;
  }

  .currency-decimal {
    font-size: 26px;
    color: #3b3b3b;
    font-family: PingFangSC-Thin;
  }

  &.new {
    .currency-int,
    .currency-decimal {
      font-size: 22px;
      font-weight: 700;
    }

    .currency-type {
      font-size: 16px;
      font-weight: 500;
    }
  }
}
</style>
