<template>
  <div class="new-about-introduce">
    <div class="new-center-container">
      <div class="flexRow about-introduce__top">
        <img
          v-lazy="require(`../../assets/images/new-about/3.png`)"
          class="about-introduce__img"
        />
        <div class="content">
          <h4 class="new-title">关于我们</h4>
          <p>
            iShopper十多年来服务位于欧洲和世界各地的客户，提供包含支付、采购、物流、退税等全面购物解决方案。
          </p>
          <p>
            iShopper标准化的一站式购物流程，为全球用户带来了持续高满意度的服务体验。
          </p>
        </div>
      </div>
      <div class="about-iintroduce__bottom">
        <ul class="flexRow">
          <li>
            <a class="flexColumn">
              <div class="img__box flexRow">
                <ColorIconFont size="32" name="card-tick" />
              </div>
              <h6>金融支付</h6>
              <p>多渠道跨境支付·即时到账</p>
            </a>
          </li>
          <li>
            <a class="flexColumn">
              <div class="img__box flexRow">
                <ColorIconFont size="32" name="receipt" />
              </div>
              <h6>一站式退税</h6>
              <p>最高20%海淘退税</p>
            </a>
          </li>
          <li>
            <a class="flexColumn">
              <div class="img__box flexRow">
                <ColorIconFont size="32" name="truck1" />
              </div>
              <h6>专业跨国快递物流</h6>
              <p>一站式转运·出口·报关</p>
            </a>
          </li>
          <li>
            <a class="flexColumn">
              <div class="img__box flexRow">
                <ColorIconFont size="32" name="judge" />
              </div>
              <h6>法律保障</h6>
              <p>全面法律支持保障</p>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import ColorIconFont from '../../iconfont/color'
export default {
  components: {
    ColorIconFont
  }
}
</script>

<style lang="less">
.new-about-introduce {
  padding-top: 100px;
  .about-introduce__top {
    justify-content: space-between;
    .about-introduce__img {
      width: auto;
      height: 447px;
    }
    .content {
      padding-top: 70px;
      width: 445px;
      .new-title {
        margin-bottom: 70px;
        text-align: left;
        &::after {
          left: 0;
          margin-left: 0;
        }
      }
      p {
        line-height: 40px;
        font-size: 16px;
        color: #222;
      }
    }
  }
  .about-iintroduce__bottom {
    margin-top: 100px;
    ul {
      // padding: 0 30px;
      justify-content: space-around;
      li {
        width: 160px;
        a {
          align-items: center;
          justify-content: center;
          color: #222;
          .img__box {
            content: '';
            width: 58px;
            height: 58px;
            align-items: center;
            justify-content: center;
            box-sizing: border-box;
            border-radius: 6px;
            border: 1px solid #222;
            position: relative;
            background: #fff;

            &::after {
              content: '';
              position: absolute;
              right: -5px;
              top: 3px;
              z-index: -2;
              width: 58px;
              height: 58px;
              border-radius: 6px;
              border: solid 1px #222;
              background-color: rgba(229, 97, 49, 0.1);
            }
          }
          h6 {
            padding: 20px 0 10px 0;
            font-size: 16px;
            font-weight: 600;
          }
          p {
            font-size: 14px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 640px) {
  .new-about-introduce {
    padding-top: 170px;
    .about-introduce__top {
      flex-direction: column;
      position: relative;
      .about-introduce__img {
        height: auto;
      }
      .content {
        width: auto;
        padding-top: 50px;
        .new-title {
          width: 100%;
          position: absolute;
          top: -90px;
          text-align: center;
          &::after {
            left: 50%;
            margin-left: -35px;
          }
        }
      }
    }
    .about-iintroduce__bottom {
      margin-top: 10px;
      ul {
        flex-wrap: wrap;
        li {
          padding-top: 40px;
          a {
            h6 {
              font-size: 14px;
            }
            p {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
</style>
