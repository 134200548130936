<template>
  <div class="new-aff-apply new-center-container">
    <h4 class="new-title">申请成为合作伙伴</h4>
    <div class="aff-top-list flexRow">
      <div class="aff-top-item flexColumn">
        <div class="img__box flexRow">
          <ColorIconFont size="40" name="handshake" />
        </div>
        <h3>申请成为推广合作伙伴</h3>
        <p>获得专属推广链接</p>
      </div>
      <div class="aff-top-item flexColumn">
        <div class="img__box flexRow">
          <ColorIconFont size="40" name="user-circle-plus" />
        </div>
        <h3>推荐新用户扫码注册</h3>
        <p>获得推荐奖励</p>
      </div>
      <div class="aff-top-item flexColumn">
        <div class="img__box flexRow">
          <ColorIconFont size="40" name="medal" />
        </div>
        <h3>登录账户，随时查看</h3>
        <p>推荐奖励到账明细</p>
      </div>
    </div>
  </div>
</template>

<script>
import ColorIconFont from '../../iconfont/color'
export default {
  components: {
    ColorIconFont
  }
}
</script>

<style lang="less">
.new-aff-apply {
  padding-top: 100px;
  padding-bottom: 100px;
  .new-title {
    margin-bottom: 70px;
  }
  .aff-top-list {
    justify-content: space-between;
    .aff-top-item {
      width: 350px;
      height: 264px;
      box-sizing: border-box;
      border-radius: 6px;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.08);
      border: solid 1px rgba(0, 0, 0, 0.1);
      background-color: #fff;
      background: url('../../assets/images/new-aff/aff-program-bg1.svg')
        no-repeat center center;
      align-items: center;
      justify-content: center;
      position: relative;
      overflow: hidden;
      &::after {
        box-sizing: border-box;
        font-family: Futura;
        padding-top: 102px;
        padding-left: 70px;
        background-size: contain;
        content: '1';
        height: 216px;
        width: 216px;
        border-radius: 108px;
        // border: 1px solid rgba(2, 2, 2, 0.3);
        position: absolute;
        top: -108px;
        right: -108px;
        opacity: 0.15;
        font-size: 50px;
        font-weight: 500;
        color: #666;
      }
      &:nth-child(2) {
        background: url('../../assets/images/new-aff/aff-program-bg2.svg')
          no-repeat center center;
        &::after {
          content: '2';
        }
      }
      &:nth-child(3)::after {
        content: '3';
      }

      .img__box {
        content: '';
        width: 58px;
        height: 58px;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        border-radius: 6px;
        border: 1px solid #222;
        position: relative;
        background: #fff;

        &::after {
          content: '';
          position: absolute;
          right: -4px;
          top: 4px;
          z-index: -2;
          width: 58px;
          height: 58px;
          border-radius: 6px;
          border: solid 1px #222;
          background-color: rgba(229, 97, 49, 0.1);
        }
      }
      h3 {
        padding-top: 34px;
        color: #222;
        font-size: 16px;
        font-weight: 600;
      }
      p {
        padding-top: 10px;
        font-size: 14px;
        color: #e56131;
      }
    }
  }
}
@media screen and (max-width: 640px) {
  .new-aff-apply {
    padding-top: 40px;
    padding-bottom: 40px;
    .new-title {
      margin-bottom: 50px;
    }
    .aff-top-list {
      flex-direction: column;
      .aff-top-item {
        width: 100%;
        background-size: 100%;
        background-position-y: -2px;
        margin-bottom: 20px;
      }
    }
  }
}
</style>
