<template>
  <Modal
    :value="value"
    :mask="false"
    :mask-closable="false"
    width="1100px"
    :title="`${localModel.id ? '编辑' : '新增'}地址`"
    @on-visible-change="toggleVisible"
    @on-ok="handleSubmit"
  >
    <Form
      ref="form"
      label-position="right"
      :model="localModel"
      :label-width="130"
      label-colon
    >
      <FormRender :rows="rows" />
    </Form>

    <template #footer>
      <Button type="text" @click="toggleVisible(false)">取消</Button>
      <Button :loading="loading" type="primary" @click="handleSubmit">
        保存
      </Button>
    </template>
  </Modal>
</template>

<script>
import { cloneDeep } from 'lodash'
import FormRender from '~/components/FormRender'

export default {
  components: {
    FormRender
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    model: {
      type: Object,
      required: true
    },
    onSuccess: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      localModel: cloneDeep(this.model),
      loading: false
    }
  },
  computed: {
    rows() {
      const rows = [
        {
          col: 8,
          prop: 'name',
          label: '收件人姓名',
          node: (
            <Input
              v-model={this.localModel.name}
              placeholder="姓名"
              maxlength="30"
            />
          ),
          rules: {
            required: true,
            trigger: 'blur',
            message: '请输入收件人姓名'
          }
        },
        {
          col: 8,
          prop: 'cid',
          label: '身份证号码',
          node: (
            <Input
              v-model={this.localModel.cid}
              maxlength="18"
              placeholder="身份证号码"
            />
          ),
          rules: [
            {
              trigger: 'blur',
              message: '请输入身份证号码'
            },
            {
              trigger: 'blur',
              validator(rule, value, callback) {
                if (!value) {
                  callback()
                  return
                }

                const isIdNumber = /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}$)/.test(
                  value
                )

                callback(
                  isIdNumber ? undefined : new Error('请输入正确的身份证')
                )
              }
            }
          ]
        },
        {
          col: 8,
          prop: 'phone',
          label: '手机号码',
          node: (
            <Input placeholder="手机号码" v-model={this.localModel.phone} />
          ),
          rules: {
            required: true,
            trigger: 'blur',
            message: '请输入手机号码'
          }
        },
        {
          render: <Address v-model={this.localModel} />
        }
      ]

      if (this.localModel.country !== 'CN') {
        rows.splice(1, 1)
      }

      return rows
    }
  },
  watch: {
    model: {
      deep: true,
      handler(value) {
        this.$refs.form.resetFields()
        this.localModel = cloneDeep(value)
      }
    }
  },
  methods: {
    toggleVisible(value) {
      this.$emit('input', value)
    },
    handleSubmit() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return
        }

        this.loading = true
        try {
          await this.$api.updateAddress(this.localModel)
          this.onSuccess(this.localModel)
          this.toggleVisible(false)
        } catch (err) {
          console.error(err)
        }
        this.loading = false
      })
    }
  }
}
</script>

<style lang="less"></style>
