<template>
  <Modal
    v-model="visible"
    class="forget-container"
    width="610px"
    title="请设置新密码"
  >
    <Form ref="form" :model="model" hide-required-mark :label-width="120">
      <FormRender :rows="rows" />
    </Form>

    <div class="action text-right">
      <div class="divide" style="margin-bottom:15px;" />
      <Button type="primary" :loading="loading" @click="handleSubmit">
        确定提交
      </Button>
    </div>
  </Modal>
</template>

<script>
import FormRender from '~/components/FormRender'

export default {
  components: {
    FormRender
  },
  data() {
    return {
      visible: true,
      loading: false,
      model: {
        username: '',
        password: '',
        password2: ''
      }
    }
  },
  computed: {
    rows() {
      const model = this.model
      return [
        {
          col: 24,
          prop: 'username',
          label: '用户名',
          node: <Input disabled v-model={model.username} />
        },
        {
          col: 24,
          prop: 'password',
          label: '新密码',
          node: <Input type="password" password v-model={model.password} />,
          rules: {
            required: true,
            trigger: 'blur',
            pattern: /^(?=.*?[A-Za-z#?!@$%^&*-])(?=.*?[0-9]).{6,16}$/g,
            message: '密码至少6-16位，包含数字和字母'
          }
        },
        {
          col: 24,
          prop: 'password2',
          label: '确认新密码',
          node: <Input type="password" password v-model={model.password2} />,
          rules: [
            {
              validator: (rule, value, calllback) => {
                if (value === '') {
                  calllback(new Error('请输入确认密码'))
                } else if (value !== this.model.password) {
                  calllback(new Error('两次输入密码不一致'))
                } else {
                  calllback()
                }
              },
              trigger: 'blur'
            }
          ]
          //   rules: {
          //     required: true,
          //     trigger: 'blur',
          //     pattern: /^(?=.*?[A-Za-z#?!@$%^&*-])(?=.*?[0-9]).{6,16}$/g,
          //     message: '密码至少6-16位，包含数字和字母'
          //   }
        }
      ]
    }
  },
  created() {
    const { username, email } = this.$store.state.user
    // console.log(this.$store.state.user.username)
    this.model.username = username || email
    // console.log(this.$store.state.user)
  },
  methods: {
    handleSubmit() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return
        }
        // console.log(valid)
        this.loading = true
        try {
          const res = await this.$api.changePwd(this.model)
          this.$cookies.set('token', res.token, {
            maxAge: 60 * 60 * 24 * 365 * 10,
            path: '/',
            domain: window.location.hostname
              .split('.')
              .slice(-2)
              .join('.')
          })
          this.$store.commit('SET_TOKEN', res.token)
          this.$Message.success('密码修改成功')
          this.visible = false
        } catch (err) {
          console.error(err)
        }
        this.loading = false
      })
    },
    handleTelInput(value, { number }) {
      this.model.phone = number.international || value
    }
  }
}
</script>

<style lang="less">
.forget-container {
  .ivu-modal-footer {
    display: none;
  }

  form {
    width: 80%;
    margin: auto;
  }
}
</style>
