<template>
  <div class="app layout-login">
    <div class="to-home">
      <nuxt-link class="arrow_box" to="/">返回首页</nuxt-link>
    </div>
    <div class="logo-container">
      <nuxt-link to="/"
        ><img src="~/assets/images/common/logo.png" alt=""
      /></nuxt-link>
    </div>

    <nuxt class="main" />
  </div>
</template>

<script>
import { changeToMobileViewport } from '~/utils/'

export default {
  head() {
    return {
      link: [
        {
          rel: 'canonical',
          href: 'https://www.ishopper.com' + this.$route.path
        }
      ]
    }
  },
  mounted() {
    changeToMobileViewport(this)
  }
}
</script>

<style lang="less">
.layout-login {
  min-height: 100vh;
  padding: 0 0 0 0;
  background: #f4f4f4;

  .to-home {
    background: #fff;
    text-align: center;
    padding: 45px 0 30px;

    a {
      font-size: 15px;
      color: #3b3b3b;
      position: relative;

      &.arrow_box:after,
      &.arrow_box:before {
        left: -18px;
        top: 48%;
        border: solid transparent;
        content: ' ';
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
      }

      &.arrow_box:after {
        border-color: rgba(234, 93, 53, 0);
        border-right-color: #ea5d35;
        border-width: 5px;
        margin-top: -5px;
      }
    }
  }

  a {
    color: #79a0c7;
  }

  .logo-container {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    background: #fff;

    &::after {
      content: '';
      width: 100%;
      height: 160px;
      background: #fb5120;
      margin-top: 35px;
    }

    img {
      width: 310px;
      height: 62px;
    }
  }
}
</style>
