<template>
  <div class="partner-wrapper new-mb-40">
    <div
      class="i-partner-content"
      :class="{ 'new-center-container': isMobile === false }"
    >
      <h3 class="new-title">合作伙伴</h3>
      <img
        style="display:none"
        :src="require('../../assets/images/index/color/fusionpay.png')"
      />
      <img
        style="display:none"
        :src="require('../../assets/images/index/color/suning.png')"
      />
      <img
        style="display:none"
        :src="require('../../assets/images/index/color/bankofchina.png')"
      />
      <img
        style="display:none"
        :src="require('../../assets/images/index/color/grantthornton.png')"
      />
      <img
        style="display:none"
        :src="require('../../assets/images/index/color/denninglegal.png')"
      />

      <img
        style="display:none"
        :src="require('../../assets/images/index/color/send2china.png')"
      />

      <img
        style="display:none"
        :src="require('../../assets/images/index/color/alipay.png')"
      />
      <img
        style="display:none"
        :src="require('../../assets/images/index/color/wechatpay.png')"
      />
      <img
        style="display:none"
        :src="require('../../assets/images/index/color/lookfantastic.png')"
      />
      <img
        style="display:none"
        :src="require('../../assets/images/index/color/elemis.png')"
      />
      <img
        style="display:none"
        :src="require('../../assets/images/index/color/nyr.png')"
      />
      <img
        style="display:none"
        :src="require('../../assets/images/index/color/omoro.png')"
      />
      <ul class="flexRow">
        <li class="i-1" />
        <li class="i-2" />
        <li class="i-3" />
        <li class="i-4" />
        <li class="i-5" />
        <li class="i-6" />
        <li class="i-7" />
        <li class="i-8" />
        <li class="i-9" />
        <li class="i-10" />
        <li class="i-11" />
        <li class="i-12" />
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isMobile: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="less" scoped>
.partner-wrapper {
  ul {
    flex-wrap: wrap;
    margin-top: 72px;
    padding-bottom: 20px;
    li {
      width: 190px;
      height: 60px;
      border-radius: 6px;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.08);
      background-color: #fff;
      border: solid 1px rgba(0, 0, 0, 0.1);
      overflow: hidden;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      cursor: pointer;
      &:not(:nth-child(5n)) {
        margin-right: 40px;
        margin-bottom: 40px;
      }

      &.i-1 {
        background-image: url('../../assets/images/index/default/fusionpay.png');
        &:hover {
          background-image: url('../../assets/images/index/color/fusionpay.png');
        }
      }
      &.i-2 {
        background-image: url('../../assets/images/index/default/suning.png');
        &:hover {
          background-image: url('../../assets/images/index/color/suning.png');
        }
      }
      &.i-3 {
        background-image: url('../../assets/images/index/default/bankofchina.png');
        &:hover {
          background-image: url('../../assets/images/index/color/bankofchina.png');
        }
      }
      &.i-4 {
        background-image: url('../../assets/images/index/default/grantthornton.png');
        &:hover {
          background-image: url('../../assets/images/index/color/grantthornton.png');
        }
      }
      &.i-5 {
        background-image: url('../../assets/images/index/default/denninglegal.png');
        &:hover {
          background-image: url('../../assets/images/index/color/denninglegal.png');
        }
      }
      &.i-6 {
        background-image: url('../../assets/images/index/default/send2china.png');
        &:hover {
          background-image: url('../../assets/images/index/color/send2china.png');
        }
      }
      &.i-7 {
        background-image: url('../../assets/images/index/default/alipay.png');
        &:hover {
          background-image: url('../../assets/images/index/color/alipay.png');
        }
      }
      &.i-8 {
        background-image: url('../../assets/images/index/default/wechatpay.png');
        &:hover {
          background-image: url('../../assets/images/index/color/wechatpay.png');
        }
      }
      &.i-9 {
        background-image: url('../../assets/images/index/default/lookfantastic.png');
        &:hover {
          background-image: url('../../assets/images/index/color/lookfantastic.png');
        }
      }
      &.i-10 {
        background-image: url('../../assets/images/index/default/elemis.png');
        &:hover {
          background-image: url('../../assets/images/index/color/elemis.png');
        }
      }
      &.i-11 {
        background-image: url('../../assets/images/index/default/nyr.png');
        &:hover {
          background-image: url('../../assets/images/index/color/nyr.png');
        }
      }
      &.i-12 {
        background-image: url('../../assets/images/index/default/omoro.png');
        &:hover {
          background-image: url('../../assets/images/index/color/omoro.png');
        }
      }
    }
  }
}
@media screen and (max-width: 640px) {
  .partner-wrapper {
    ul {
      margin-top: 52px;
      padding-left: 16px;
      padding-right: 16px;
      li {
        width: 104px;
        height: 33px;
        &:not(:nth-child(5n)) {
          margin-right: 15px;
          margin-bottom: 20px;
        }
        &:not(:nth-child(3n)) {
          margin-right: 15px;
          margin-bottom: 20px;
        }
        &:nth-child(3n) {
          margin-right: 0;
        }
      }
    }
  }
}
</style>
