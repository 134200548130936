<template>
  <div>
    <!-- eslint-disable-next-line vue/html-self-closing -->
    <Input
      v-model="phone"
      class="new-phone-selection"
      placeholder="请输入您的手机号码"
      name="phone"
      @input="change"
    >
      <Select
        slot="prepend"
        v-model="area"
        style="width: 80px"
        @input="handleSelect"
      >
        <Option
          v-for="(item, i) in list"
          :key="i"
          :value="item[2]"
          :label="item[2]"
        >
          {{ item[0] }}<span>{{ item[2] }}</span>
        </Option>
      </Select>
    </Input>
  </div>
</template>

<script>
export default {
  data() {
    return {
      phone: '',
      area: '+44',
      list: [
        ['China (中国)', 'cn', '+86'],
        ['United Kingdom', 'gb', '+44'],
        ['Germany (Deutschland)', 'de', '+49'],
        ['Hong Kong (香港)', 'hk', '+852'],
        ['Taiwan (台灣)', 'tw', '+886'],
        ['Italy (Italia)', 'it', '+39'],
        ['France', 'fr', '33'],
        ['Spain (España)', 'es', '+34'],
        ['United States', 'us', '+1'],
        ['Canada', 'ca', '+1'],
        ['Vietnam (Việt Nam)', 'vn', '+84']
      ]
    }
  },
  methods: {
    handleSelect(value) {
      this.area = value
      this.change(this.phone)
    },
    change(value) {
      if (value === '') {
        this.$emit('input', '')
        this.$emit('change', '')
      } else {
        this.$emit('input', this.area + value)
        this.$emit('change', this.area + value)
      }
    }
  }
}
</script>

<style></style>
