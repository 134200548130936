<script>
import { isEqual } from 'lodash'
import AddressCascader from './AddressCascader'
import FormRender from '~/components/FormRender'

export default {
  props: {
    textMode: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      required: true
    },
    filter: {
      type: Function,
      default: (rows) => rows
    }
  },

  data() {
    return {
      countries: [],
      provinces: [],
      cities: [],
      counties: [],
      model: {
        billing_postcode: null,
        billing_country: null,
        billing_province: null,
        billing_city: null,
        billing_county: null,
        billing_street1: null,
        billing_street2: null,
        billing_street3: null
      },
      postcodeData: [],
      fetchPostcodeLoading: false,
      postcodeVisible: false
    }
  },

  watch: {
    model: {
      deep: true,
      handler(value) {
        this.$emit('input', { ...this.value, ...value })
      }
    },
    value() {
      this.syncModel()
    }
  },

  mounted() {
    this.fetchCountries()
    this.syncModel()
  },

  methods: {
    syncModel() {
      const value = this.value
      const model = {
        billing_postcode: value.billing_postcode || null,
        billing_country: value.billing_country || null,
        billing_province: value.billing_province || null,
        billing_city: value.billing_city || null,
        billing_county: value.billing_county || null,
        billing_street1: value.billing_street1 || null,
        billing_street2: value.billing_street2 || null,
        billing_street3: value.billing_street3 || null
      }
      // console.log(isEqual(model, this.model))
      if (isEqual(model, this.model)) {
        return
      }

      this.model = model
    },
    fetchUKPostcode() {
      const that = this
      that.fetchPostcodeLoading = true
      // Pass parameters via JSON
      const parameters = {
        key: process.env.FETCHIFY_KEY,
        response: 'data_formatted',
        postcode: !this.isBillingAddress
          ? this.model.postcode
          : this.model.billing_postcode,
        sort: 'asc',
        lines: 3
      }
      const url = 'https://pcls1.craftyclicks.co.uk/json/rapidaddress'
      // or via GET parameters
      // var url = "http://pcls1.craftyclicks.co.uk/json/basicaddress?key=xxxxx-xxxxx-xxxxx-xxxxx&postcode=aa11aa&response=data_formatted";
      return new Promise((resolve, reject) => {
        const request = new XMLHttpRequest()
        request.open('POST', url)
        // Only needed for the JSON parameter pass
        request.setRequestHeader('Content-Type', 'application/json')
        // Wait for change and then either JSON parse response text or throw exception for HTTP error
        request.onreadystatechange = function() {
          if (this.readyState === 4) {
            if (this.status >= 200 && this.status < 400) {
              // Success!
              const data = JSON.parse(this.responseText)
              const postcodeData = data.delivery_points
                ? data.delivery_points.map((item) => {
                    return {
                      postcode: data.postcode,
                      city: data.town,
                      street1: item.line_1,
                      street2: item.line_2,
                      street3: item.line_3z
                    }
                  })
                : []
              resolve(data)

              if (!postcodeData.length) {
                alert('未查询到地址')
              }

              that.postcodeData = postcodeData

              that.$nextTick(() => {
                that.fetchPostcodeLoading = false
                that.postcodeVisible = true
              })
            } else {
              reject(new Error('HTTP Request Error'))
              that.fetchPostcodeLoading = false
            }
          }
        }
        // Send request
        request.send(JSON.stringify(parameters))
      })
    },
    async fetchCountries() {
      const { data } = await this.$api.fetchCountries()
      this.countries = data
    },
    handleCountry() {
      this.model.billing_province = null
      this.model.billing_city = null
      this.model.billing_postcode = null
      this.model.billing_county = null
      this.model.billing_street1 = null
      this.model.billing_street2 = null
      this.model.billing_street3 = null
    },
    getAddressCascaderData(data) {
      this.model.billing_province = data.province
      this.model.billing_city = data.city
      this.model.billing_county = data.county
      this.model.billing_postcode = data.postcode
    },
    renderByCN() {
      return [
        {
          col: 12,
          prop: 'billing_country',
          label: '所在国家/地区',
          node: (
            <Select
              placeholder="所在国家/地区"
              v-model={this.model.billing_country}
              onInput={this.handleCountry}
            >
              {this.countries.map((item) => (
                <Option value={item.code} key={item.code}>
                  {item.name}
                </Option>
              ))}
            </Select>
          ),
          rules: {
            required: true,
            trigger: 'change',
            message: '请选择所在国家/地区'
          }
        },
        {
          col: 12,
          prop: 'billing_province',
          label: '所在省市区',
          node: (
            <AddressCascader
              isBillingAddress={true}
              placeholder="所在省市区"
              v-model={this.model}
            />
          ),
          rules: {
            required: true,
            trigger: 'change',
            message: '请选择所在省市区'
          }
        },
        {
          col: 24,
          label: '具体地址',
          prop: 'billing_street1',
          node: (
            <Input
              placeholder="具体地址"
              v-model={this.model.billing_street1}
              maxlength="60"
            />
          ),
          rules: {
            required: true,
            trigger: 'blur',
            message: '请输入具体地址'
          }
        }
      ]
    },
    renderPostNode() {
      const inputNode = (
        <Input
          style="width: 280px"
          placeholder="所在地邮编"
          autocomplete="new-password"
          type="text"
          v-model={this.model.billing_postcode}
        />
      )

      if (this.model.country !== 'UK') {
        return inputNode
      }

      const isValidCode = /^[A-Z]{1,2}[0-9]{1,2}[A-Z]{0,1} ?[0-9][A-Z]{2}$|aa11aa/i.test(
        this.model.billing_postcode
      )

      const handleSelectCode = (item) => {
        this.model.billing_postcode = item.postcode
        this.model.billing_street1 = item.street1
        this.model.billing_street2 = item.street2
        this.model.billing_street3 = item.street3
        this.model.billing_city = item.city

        this.postcodeVisible = false
      }

      return (
        <Dropdown
          trigger="custom"
          visible={this.postcodeVisible}
          class="postcode-uk"
        >
          {inputNode}
          <Button
            loading={this.fetchPostcodeLoading}
            onClick={this.fetchUKPostcode}
            disabled={!isValidCode}
            type="primary"
            size="small"
          >
            查询地址
          </Button>
          {!!this.postcodeData.length && (
            <DropdownMenu slot="list">
              {this.postcodeData.map((item, index) => (
                <DropdownItem
                  key={index}
                  nativeOnClick={() => handleSelectCode(item)}
                >
                  {`${[
                    ...new Set([item.street1, item.street2, item.street3])
                  ].join(', ')}${item.city}`}
                </DropdownItem>
              ))}
            </DropdownMenu>
          )}
        </Dropdown>
      )
    },
    renderByOther() {
      return [
        {
          col: 7,
          prop: 'billing_country',
          label: '所在国家/地区',
          node: (
            <Select
              placeholder="所在国家/地区"
              v-model={this.model.billing_country}
              onInput={this.handleCountry}
            >
              {this.countries.map((item) => (
                <Option value={item.code} key={item.code}>
                  {item.name}
                </Option>
              ))}
            </Select>
          ),
          rules: {
            required: true,
            message: '请选择所在国家/地区'
          }
        },
        {
          col: 8,
          prop: 'billing_postcode',
          label: '邮编',
          node: this.renderPostNode(),
          rules: {
            required: false,
            message: '请输入邮编'
          }
        },
        {
          col: 9,
          prop: 'billing_city',
          label: '城市',
          node: (
            <Input
              placeholder="所在城市"
              v-model={this.model.billing_city}
              maxlength="20"
            />
          ),
          rules: {
            required: true,
            trigger: 'blur',
            message: '请输入所在城市'
          }
        },
        {
          col: 7,
          prop: 'billing_province',
          label: '所在省/州',
          node: (
            <Input
              placeholder="所在省/州"
              v-model={this.model.billing_province}
              maxlength="20"
            />
          ),
          rules: {}
        },
        {
          col: 17,
          label: '所在地址',
          node: (
            <Row gutter={10} type="flex">
              <Col span="8">
                <FormItem prop={'billing_street1'}>
                  <Input
                    placeholder="地址第一行"
                    v-model={this.model.billing_street1}
                    maxlength="50"
                  />
                </FormItem>
              </Col>
              <Col span="8">
                <FormItem prop={'billing_street2'}>
                  <Input
                    placeholder="地址第二行"
                    v-model={this.model.billing_street2}
                    maxlength="50"
                  />
                </FormItem>
              </Col>
              <Col span="8">
                <FormItem prop={'billing_street3'}>
                  <Input
                    placeholder="地址第三行"
                    v-model={this.model.billing_street3}
                    maxlength="50"
                  />
                </FormItem>
              </Col>
            </Row>
          ),
          rules: {
            required: true,
            trigger: 'blur',
            message: '请输入所在地址'
          }
        }
      ]
    }
  },

  render() {
    const { model } = this
    const country = model.billing_country
    const rows = country === 'CN' ? this.renderByCN() : this.renderByOther()

    const matchCountry = this.countries.find((item) => item.code === country)
    let fields = null

    if (country === 'CN') {
      fields = [
        matchCountry && matchCountry.name,
        model.billing_province,
        model.billing_city,
        model.billing_county,
        model.billing_street1
      ]
    } else {
      fields = [
        matchCountry && matchCountry.name,
        model.billing_province,
        model.billing_city,
        model.billing_street1,
        model.billing_street2,
        model.billing_street3,
        `(${model.billing_postcode})`
      ]
    }

    return this.textMode ? (
      <span>{fields.filter(Boolean).join(' ')}</span>
    ) : (
      <FormRender rows={this.filter(rows)} />
    )
  }
}
</script>

<style lang="less">
.postcode-uk {
  position: relative;

  button {
    position: absolute !important;
    right: 10px;
    top: 5px;
  }

  .ivu-select-dropdown {
    max-height: 300px !important;
    overflow: auto !important;
  }
}
</style>
