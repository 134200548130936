<script>
import { useStore } from '~/use'
import ENUM from '~/core/enum'

export default {
  setup() {
    return () => {
      const { state: userInfo } = useStore()
      const isCardInactive =
        userInfo.cards.length === 1 &&
        [0, 1, 4, 5].includes(userInfo.cards[0].status)

      const isTransferTips =
        !userInfo.isNewUser &&
        !isCardInactive &&
        userInfo.user?.gbp_balance >= 1 &&
        userInfo.user?.card_balance_total <= 0

      const tips = [
        {
          conditon: userInfo.isNewUser && userInfo.user?.gbp_balance < 1,
          content: (
            <div class="extra-tips">
              恭喜您成功注册iShopper账户！下一步请为您的英镑余额账户
              <nuxt-link to="/account/balance">【支付货款1英镑】</nuxt-link>
              ，以便申请我们提供的消费卡，用以采购您心仪的商品
            </div>
          )
        },
        {
          // 卡激活中
          conditon: isCardInactive,
          content: (
            <div class="extra-tips">
              您申请的消费卡在审批中，可点击
              <nuxt-link to="/account/cards">【我的消费卡】</nuxt-link>
              查看卡申请状态
            </div>
          )
        },
        {
          // 有消费卡，有余额, 卡无预存
          conditon: isTransferTips,
          content: (
            <div class="extra-tips">
              使用消费卡进行采购需确保余额充足，请预先将余额从英镑账户中
              <nuxt-link to="/account/cards">【转入消费款至消费卡】</nuxt-link>
            </div>
          )
        },
        {
          // 有消费卡，但无余额
          conditon:
            !isTransferTips &&
            !userInfo.isNewUser &&
            userInfo.user?.card_balance_total <= 0,
          content: (
            <div class="extra-tips">
              使用消费卡进行采购需确保余额充足，请首先
              <nuxt-link to="/account/balance">【支付货款】</nuxt-link>
              ，然后将余额从英镑账户中
              <nuxt-link to="/account/cards">【转入消费款至消费卡】</nuxt-link>
            </div>
          )
        },
        {
          // 无消费卡，有余额
          conditon: userInfo.isNewUser && userInfo.user?.gbp_balance >= 1,
          content: (
            <div class="extra-tips">
              需要采购商品并申请退税，必须使用我们提供的消费卡，请点击
              <nuxt-link to="/account/cards/register">【申请消费卡】</nuxt-link>
            </div>
          )
        },
        // {
        //   // 退税提醒
        //   conditon: userInfo.user?.refund_purchase_sum,
        //   content: (
        //     <div class="extra-tips" style="white-space: nowrap;">
        //       您目前尚有总计金额{' '}
        //       <Currency
        //         type="£"
        //         size="mini"
        //         value={userInfo.user?.refund_purchase_sum}
        //       />{' '}
        //       的退款采购尚未提交退款明细，将影响您的提现额度，请及时
        //       <nuxt-link to="/account/purchase">
        //         【上传并核销退款票据】
        //       </nuxt-link>
        //     </div>
        //   )
        // },
        {
          // 未实名
          conditon:
            userInfo.user?.first_card_record &&
            userInfo.user?.status !== ENUM.USER_STATUS.PASS,
          content: (
            <div class="extra-tips">
              如果需要使用退税服务，请预先进行
              <nuxt-link to="/user-verify">【实名验证】</nuxt-link>
            </div>
          )
        }
      ]
        .filter((item) => item.conditon)
        .map((item) => item.content)

      return <div class="extra-tips-container container">{...tips}</div>
    }
  }
}
</script>

<style lang="less">
.extra-tips-container {
  padding-left: 375px;
  margin: -40px auto 20px;

  .extra-tips {
    &:last-child {
      margin-bottom: 15px;
    }
    & + .extra-tips {
      margin-top: 10px;
    }

    &::before {
      content: '';
      width: 5px;
      height: 5px;
      vertical-align: middle;
      margin-right: 5px;
      border-radius: 50%;
      background: #e7532f;
      display: inline-block;
    }
  }
}
</style>
