<template>
  <div class="items-editor">
    <div
      v-for="(item, index) of items"
      :key="item[rowkey] || index"
      class="edit-item"
    >
      <slot :item="item" :index="index" />
      <span class="edit-actions">
        <Icon
          v-show="removeBtnVisible"
          type="ios-remove-circle-outline"
          @click="remove(index)"
        />
        <Icon
          v-show="addBtnVisible && isSingleAddBtn(index)"
          type="ios-add-circle"
          @click="() => add(index)"
        />
      </span>
    </div>
  </div>
</template>

<script>
import { cloneDeep, isEqual } from 'lodash'

export default {
  props: {
    rowkey: {
      type: [String, Number],
      default: 'id'
    },
    value: {
      type: Array,
      required: true
    },
    itemTemplate: {
      type: [Object, Function],
      required: true
    },
    maxLength: {
      type: Number,
      default: 0
    },
    minLength: {
      type: Number,
      default: 1
    },
    singeAddBtn: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      items: this.value
    }
  },
  computed: {
    addBtnVisible() {
      if (!this.maxLength) {
        return true
      }

      return this.items.length < this.maxLength
    },
    removeBtnVisible() {
      return this.items.length > this.minLength
    }
  },
  watch: {
    items: {
      deep: true,
      handler(value) {
        this.$emit('input', value)
      }
    },
    value(value) {
      if (isEqual(value, this.items)) {
        return
      }

      this.items = cloneDeep(value)
    }
  },
  methods: {
    isSingleAddBtn(index) {
      const itemsLen = this.items.length
      // 只有一条、未启用single选项，并且当前index不是最后一条时就显示按钮
      return itemsLen <= 1 || !this.singeAddBtn || itemsLen - 1 === index
    },
    add(index) {
      const { itemTemplate } = this

      if (typeof itemTemplate === 'function') {
        itemTemplate(index)
        return
      }

      this.items.push(cloneDeep(itemTemplate))
    },
    remove(index) {
      this.items.splice(index, 1)
    }
  }
}
</script>

<style lang="less">
.items-editor {
  .edit-item {
    display: flex;
    align-items: center;
    position: relative;

    .edit-actions {
      width: 85px;
      position: absolute;
      right: 0;
      display: flex;
    }
  }
}
</style>
