<template>
  <div>
    <div class="new-wrapper__footer">
      <div
        class="i-footer-wrapper"
        :class="isMobile === false ? 'new-center-container' : ''"
      >
        <div v-if="isMobile === false" class="new-footer__content new-pt-60">
          <div class="footer-content__menu flexRow">
            <div class="footer-menu__logo">
              <img
                v-lazy="require(`../../assets/images/index/logo-white.svg`)"
              />
            </div>
            <ul class="footer-menu__slide flexRow">
              <li class="new-ml-60">
                <a class="flexColumn" @click="customerService">
                  <div class="iconfont-wrapper">
                    <IconFont size="28" name="message" />
                  </div>
                  <p>在线客服</p>
                </a>
              </li>
              <li class="new-ml-60">
                <a class="flexColumn" @click="feedBack">
                  <div class="iconfont-wrapper">
                    <IconFont size="28" name="pencil" />
                  </div>
                  <p>意见反馈</p>
                </a>
              </li>
              <li class="new-ml-60">
                <a class="flexColumn" @click="faq">
                  <div class="iconfont-wrapper">
                    <IconFont size="28" name="help" />
                  </div>
                  <p>常见问题</p>
                </a>
              </li>
              <li class="new-ml-60">
                <a class="flexColumn" @click="contactUs">
                  <div class="iconfont-wrapper">
                    <IconFont size="28" name="phone" />
                  </div>
                  <p>联系我们</p>
                </a>
              </li>
            </ul>
          </div>
          <div class="footer-content__info new-pb-60 new-pt-60 flexRow">
            <div class="footer-info__imgs flexRow">
              <div class="footer-imgs_box">
                <img v-lazy="require(`../../assets/images/index/xcx.jpg`)" />
              </div>
              <div class="footer-imgs_box">
                <img v-lazy="require(`../../assets/images/index/qrcode.png`)" />
              </div>
              <div class="footer-info__desc">
                ←关注iShopper<br />
                随时获取第一手资讯及打折信息！
              </div>
            </div>
            <div class="footer-info__company">
              <p class="an">商业合作</p>
              <p><span class="an">电话</span> ＋44 01869 320216</p>
              <p><span class="an">邮件</span> info@ishopper.com</p>
            </div>
          </div>
        </div>
        <div v-if="isMobile" class="new-footer__content">
          <div class="footer-content__menu flexRow">
            <div class="footer-menu__logo">
              <img
                v-lazy="require(`../../assets/images/index/logo-white.svg`)"
              />
            </div>
            <div class="footer-info__company">
              <p class="an">商业合作</p>
              <p><span class="an">电话</span> ＋44 01869 320216</p>
              <p><span class="an">邮件</span> info@ishopper.com</p>
            </div>
          </div>
          <div class="footer-content__info flexColumn">
            <ul class="footer-menu__slide flexRow">
              <li class="new-ml-60">
                <a class="flexColumn" @click="customerService">
                  <div class="iconfont-wrapper">
                    <IconFont size="28" name="message" />
                  </div>
                  <p>在线客服</p>
                </a>
              </li>
              <li class="new-ml-60">
                <a class="flexColumn" @click="feedBack">
                  <div class="iconfont-wrapper">
                    <IconFont size="28" name="pencil" />
                  </div>
                  <p>意见反馈</p>
                </a>
              </li>
              <li class="new-ml-60">
                <a class="flexColumn" @click="faq">
                  <div class="iconfont-wrapper">
                    <IconFont size="28" name="help" />
                  </div>
                  <p>常见问题</p>
                </a>
              </li>
              <li class="new-ml-60">
                <a class="flexColumn" @click="contactUs">
                  <div class="iconfont-wrapper">
                    <IconFont size="28" name="phone" />
                  </div>
                  <p>联系我们</p>
                </a>
              </li>
            </ul>
            <div class="footer-info__imgs flexColumn">
              <div class="flexRow imgs">
                <div class="footer-imgs_box">
                  <img v-lazy="require(`../../assets/images/index/xcx.jpg`)" />
                </div>
                <div class="footer-imgs_box">
                  <img
                    v-lazy="require(`../../assets/images/index/qrcode.png`)"
                  />
                </div>
              </div>
              <div class="footer-info__desc">
                ↑关注iShopper<br />
                随时获取第一手资讯及打折信息！
              </div>
            </div>
          </div>
        </div>
        <div class="new-footer__bottom flexRow">
          <div class="copyright">© 2024 Copyright. All rights reserved.</div>
          <ul class="flexRow">
            <li class="first">
              <a href="/terms-conditions">Terms and conditions</a>
            </li>
            <li><a href="/privacy-policy">Privacy policy</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconFont from '../../iconfont/index.vue'
export default {
  components: {
    IconFont
  },
  props: {
    isMobile: {
      type: Boolean,
      default: null
    }
  },
  mounted() {},
  methods: {
    customerService() {
      const user = this.$store.state.user
      if (user) {
        window._MEIQIA('metadata', {
          id: user.id,
          email: user.email,
          username: user.username,
          phone: user.phone
        })
      }
      window._MEIQIA('showPanel')
    },
    feedBack() {
      window.open(
        'https://form.asana.com/?k=SATRLzvzK8u0F8UA5bUDkA&d=1183493859185320'
      )
    },
    faq() {
      this.$router.push('/qa')
    },
    contactUs() {
      this.$router.push('/contact')
    }
  }
}
</script>

<style lang="less">
.new-wrapper__footer {
  background: #000;
  color: #fff;
  font-size: 12px;
  .footer-content__menu {
    justify-content: space-between;
  }
  .footer-menu__logo {
    width: 160px;
    height: 54px;
    img {
      width: 160px;
      height: 54px;
    }
  }
  .footer-menu__slide {
    li {
      a {
        align-items: center;
        color: #fff;
        .iconfont-wrapper {
          width: 40px;
          height: 40px;
          box-sizing: border-box;
          border-radius: 6px;
          box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.08);
          border: solid 1px #fff;
          text-align: center;
          line-height: 40px;
        }

        &:hover {
          .iconfont-wrapper {
            background: #fff;
            .iconfont {
              color: #000;
            }
          }
        }
        p {
          line-height: 18px;
          padding-top: 10px;
        }
      }
    }
  }
  .footer-content__info {
    justify-content: space-between;
    border-bottom: 1px solid #5e5e5e;
  }
  .footer-info__imgs {
    align-items: center;
    .footer-imgs_box {
      background: #fff;
      border-radius: 6px;
      width: 88px;
      height: 88px;
      margin-right: 30px;
      padding: 5px;
    }
    .footer-info__desc {
      height: 60px;
      line-height: 30px;
    }
  }
  .footer-info__company {
    text-align: right;
    p {
      height: 30px;
      span {
        padding-right: 10px;
      }
    }
    .an {
      color: #5e5e5e;
    }
  }
  .new-footer__bottom {
    padding: 40px 0;
    justify-content: space-between;
    color: #5e5e5e;

    ul {
      align-items: center;
      a {
        color: #5e5e5e;
      }
      .first {
        position: relative;
        margin-right: 40px;
        &::after {
          content: '';
          height: 12px;
          width: 1px;
          background: #5e5e5e;
          position: absolute;
          top: 5px;
          right: -20px;
        }
      }
    }
  }
}
@media screen and (max-width: 640px) {
  .new-wrapper__footer {
    .footer-menu__logo {
      width: 104px;
      height: 35px;
      img {
        width: 104px;
        height: 35px;
      }
    }
    .new-footer__content {
      .footer-content__menu {
        align-items: center;
        border-bottom: 1px solid #5e5e5e;
        padding: 20px 16px 40px;
        .footer-info__company {
          text-align: left;
        }
      }
      .footer-content__info {
        padding: 40px 16px;
        border-bottom: 1px solid #5e5e5e;
        .footer-menu__slide {
          justify-content: space-between;
          margin-bottom: 40px;
          li {
            margin-left: 0;
          }
        }
        .footer-info__imgs {
          .imgs {
            justify-content: center;
            margin-bottom: 20px;
            .footer-imgs_box {
              margin-right: 20px;
              img {
                width: 78px;
                height: 78px;
              }
              &:last-child {
                margin-right: 0;
              }
            }
          }
          .footer-info__desc {
            text-align: center;
          }
        }
      }
    }
    .new-footer__bottom {
      padding-top: 20px;
      flex-direction: column;
      align-items: center;
      .copyright {
        padding-bottom: 20px;
      }
    }
  }
}
</style>
