<template>
  <ul class="footer-menu">
    <li v-for="item of menus" :key="item.name" @click="item.onClick">
      <div class="pic">
        <img :src="item.url" />
      </div>
      <div>{{ lang === 'cn' ? item.name : item.enName }}</div>
    </li>
  </ul>
</template>

<script>
import { useVm } from '~/use'

export default {
  props: {
    lang: {
      type: String,
      default: 'cn'
    }
  },

  setup() {
    const vm = useVm()

    const menus = [
      {
        name: '在线客服',
        enName: 'Customer Service',
        url: require('./images/客服.png'),
        onClick: () => {
          const user = vm.$store.state.user
          if (user) {
            window._MEIQIA('metadata', {
              id: user.id,
              email: user.email,
              username: user.username,
              phone: user.phone
            })
          }

          window._MEIQIA('showPanel')
        }
      },
      {
        name: '意见反馈',
        enName: 'Feedback',
        url: require('./images/意见反馈.png'),
        onClick: () =>
          window.open(
            'https://form.asana.com/?k=SATRLzvzK8u0F8UA5bUDkA&d=1183493859185320'
          )
      },
      {
        name: '常见问题',
        enName: 'FAQS',
        url: require('./images/问题.png'),
        onClick: () => vm.$router.push('/qa')
      },
      {
        name: '联系我们',
        enName: 'Contact Us',
        url: require('./images/联系.png'),
        onClick: () => vm.$router.push('/contact')
      }
    ]

    return {
      menus
    }
  }
}
</script>

<style lang="less">
.footer-menu {
  width: 40%;
  display: inline-flex;
  color: #fff;
  text-align: center;
  cursor: pointer;
  justify-content: space-between;

  li {
    transition: 0.3s;
    display: flex;
    flex-flow: column wrap;
    align-items: center;

    & + li {
      // margin-left: 70px;
    }

    &:hover {
      opacity: 0.7;
    }

    .pic {
      width: 38px;
      height: 38px;
      margin-bottom: 10px;
      img {
        object-fit: contain;
      }
    }
  }
}
</style>
