<template>
  <div class="account-order-container">
    <div class="bg-gray">
      <div class="container">
        <Tabs v-model="curTab">
          <TabPane
            v-for="(tab, index) of tabs"
            :key="tab.name"
            :label="tab.name"
            :name="String(index)"
          >
            <div class="head">
              <Input
                v-model="tab.model.keyword"
                search
                placeholder="搜索"
                style="width: 250px"
                @on-search="handleSearch(index)"
                @keyup.enter.native="handleSearch(index)"
              />
              <div class="action">
                <Button to="/account/order/create" icon="md-add" type="primary">
                  创建发货订单
                </Button>
              </div>
            </div>
            <DataTable
              ref="dataTable"
              class="table-blue"
              :when-fetch="
                (page, perPage) =>
                  fetchList({
                    page,
                    perPage,
                    status: tab.id,
                    key: tab.model.keyword
                  })
              "
              :columns="tab.columns"
            />
          </TabPane>
        </Tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import OrderStatus from '~/components/order/OrderStatus'

export default {
  scrollToTop: true,
  data() {
    const ORDER_STATUS = this.$ENUM.ORDER_STATUS
    return {
      delOrderId: '',
      delLoading: false,
      curTab: this.$route.query.curTab || '0',
      tabs: [
        {
          name: '已创建',
          id: ORDER_STATUS.CREATED,
          model: {
            keyword: ''
          },
          columns: this.getColById(ORDER_STATUS.CREATED)
        },
        {
          name: '发货中',
          id: ORDER_STATUS.SHIPING,
          model: {
            keyword: ''
          },
          columns: this.getColById(ORDER_STATUS.SHIPING)
        },
        {
          name: '返佣中',
          id: ORDER_STATUS.COUNT_DOWN,
          model: {
            keyword: ''
          },
          columns: this.getColById(ORDER_STATUS.COUNT_DOWN)
        },
        {
          name: '返佣完成',
          id: ORDER_STATUS.PROCESSED,
          model: {
            keyword: ''
          },
          columns: this.getColById(ORDER_STATUS.PROCESSED)
        }
      ]
    }
  },
  computed: {
    ...mapState(['user']),
    curDataTable() {
      return this.$refs.dataTable[this.curTab]
    }
  },
  watch: {
    curTab(value) {
      this.$router.push({
        query: {
          ...this.$route.query,
          curTab: value
        }
      })
    }
  },
  mounted() {
    const reFetch = (query) => {
      if (!query.curTab) {
        return
      }
      this.curTab = String(query.curTab)
      this.curDataTable.fetchData(query.page)
    }

    // 恢复查询状态
    const query = this.$route.query
    setTimeout(() => {
      if (query.curTab !== undefined) {
        reFetch(query)
      }

      this.$watch('$route', (newValue) => {
        if (window.event?.target === window) {
          reFetch(newValue.query)
        }
      })
    }, 0)
  },
  methods: {
    confirmSumbit() {
      this.handleDelOrder(this.delOrderId)
    },
    showConfirm(delOrderId) {
      this.delOrderId = delOrderId
      this.$Modal.confirm({
        title: '温馨提示',
        content: '<p>确认删除吗？</p>',
        okText: '继续',
        cancelText: '返回',
        onOk: () => {
          this.confirmSumbit()
        }
      })
    },
    showDrawback(row) {
      this.$portal({
        context: this,
        component: () => import('~/components/account/order/Drawback'),
        props: {
          id: row.id,
          onSubmit: () => {
            this.curDataTable.refresh()
          }
        }
      })
    },
    async handleDelOrder(id) {
      try {
        this.delLoading = true
        await this.$api.delOrder(id)
        this.curDataTable.refresh()
      } catch (err) {
        console.error(err)
      }
      this.delLoading = false
    },
    handleSearch(index) {
      this.$refs.dataTable[index].fetchData(1)
    },
    fetchList(params) {
      this.$router.push({
        query: {
          ...params,
          curTab: this.curTab
        }
      })

      return this.$api.fetchOrderList(params)
    },
    renderPurchaseList(purchaseItems = [], mapFn) {
      if (!purchaseItems.length) {
        return <span>-</span>
      }

      const listNodes = purchaseItems.slice(0, 3).map(mapFn)
      return (
        <div>
          <Poptip width="300" padding="0" transfer trigger="hover">
            {listNodes}共{purchaseItems.length}条
            <template slot="content">
              <Table
                stripe
                height="200"
                columns={[
                  {
                    title: '发票',
                    render: (_, { row: item }) => (
                      <nuxt-link
                        key={item.purchase_id}
                        to={`/account/purchase/detail?id=${item.purchase_id}`}
                      >
                        {item.invoice_number}(
                        {this.$ENUM.PURCHASE_ALL[item.is_all]})
                      </nuxt-link>
                    )
                  },
                  {
                    title: '金额',
                    render: (_, { row: item }) => (
                      <Currency
                        size="mini"
                        key={item.id}
                        value={item.purchase_item_amount}
                        type="£"
                      />
                    )
                  }
                ]}
                data={purchaseItems}
              />
            </template>
          </Poptip>
        </div>
      )
    },
    getColById() {
      const { EXPRESS_NAME, ORDER_PROCESS_TYPE } = this.$ENUM

      return [
        {
          title: '订单号',
          align: 'center',
          render: (_, { row }) => {
            return (
              <nuxt-link key={row.id} to={`/account/order/detail?id=${row.id}`}>
                {row.number}
              </nuxt-link>
            )
          }
        },
        {
          title: '采购发票和金额',
          align: 'center',
          minWidth: 150,
          render: (_, { row }) => {
            const renderItem = (item) => {
              return (
                <div style="white-space: nowrap;">
                  <nuxt-link
                    key={item.purchase_id}
                    to={`/account/purchase/detail?id=${item.purchase_id}`}
                  >
                    {item.invoice_number}({this.$ENUM.PURCHASE_ALL[item.is_all]}
                    )
                  </nuxt-link>{' '}
                  |{' '}
                  <Currency
                    size="mini"
                    key={item.id}
                    value={item.purchase_item_amount}
                    type="£"
                  />
                </div>
              )
            }

            return this.renderPurchaseList(row.purchase_distinct, renderItem)
          }
        },
        {
          title: '尾款信息',
          align: 'center',
          render: (_, { row }) => {
            if (row.pay_retain <= 0) {
              return <span>-</span>
            }

            const payStatus =
              row.get_pay_status_retain_display === 'PENDING' ? (
                <a class="text-orange" href="#0">
                  等待支付
                </a>
              ) : (
                <div>
                  <div class="text-green">
                    {this.$ENUM.PAY_STATUS_RETAIN[row.pay_status_retain]}
                  </div>
                  {row.pay_status_retain > 0 ? (
                    <div>
                      <div>流水号:</div> <div>{row.billing_retain_number}</div>
                    </div>
                  ) : null}
                </div>
              )
            let payRetain = 0
            if (row.pay_retain) {
              payRetain = row.pay_retain
            }
            return (
              <div>
                <Currency size="mini" value={payRetain} type="£" />
                <div>{payStatus}</div>
              </div>
            )
          }
        },
        {
          title: '收件人',
          align: 'center',
          render: (_, { row }) => {
            return (
              <div style="padding:8px;">
                <div>{row.recipient_name}</div>
                <div>
                  {row.recipient_province}
                  {row.recipient_city}
                </div>
                <div>{this.$ENUM.COUNTRY[row.recipient_country]}</div>
                <div>{row.recipient_phone}</div>
              </div>
            )
          }
        },
        {
          title: '返佣方案',
          key: 'rebate_plan_title',
          align: 'center'
        },
        {
          title: '快递信息',
          align: 'center',
          render: (_, { row }) => {
            if (!row.tracking_number) {
              return <span>-</span>
            }

            return (
              <div style="padding:8px;">
                <div>{EXPRESS_NAME[row.express_cmp]}</div>
                <div>{row.service_title}</div>
                {row.weight > 0 ? `${row.weight}kg` : null}
                <div>{row.tracking_number}</div>
              </div>
            )
          }
        },
        {
          title: '订单状态',
          align: 'center',
          width: 130,
          render: (_, { row }) => (
            <OrderStatus whenShowRebate={this.showDrawback} data={row} />
          )
        },
        {
          title: '操作',
          align: 'center',
          render: (_, { row }) => {
            return (
              <div>
                {![
                  ORDER_PROCESS_TYPE.COUNT_DOWN,
                  ORDER_PROCESS_TYPE.PROCESSED,
                  ORDER_PROCESS_TYPE.PROCESSED_NO_REBATE,
                  ORDER_PROCESS_TYPE.CANCELED
                ].includes(row.status) && (
                  <a onClick={() => this.showConfirm(row.id)}>删除 | </a>
                )}
                <nuxt-link
                  key={row.id}
                  to={`/account/order/detail?id=${row.id}`}
                >
                  详情
                </nuxt-link>
              </div>
            )
          }
        }
      ]
    }
  }
}
</script>

<style lang="less">
.account-order-container {
  .bg-gray {
    padding: 0 0 100px;
    margin: 210px 0 -180px;

    .container {
      position: relative;
      top: -180px;
    }
  }

  .ivu-tabs-bar {
    display: flex;
    justify-content: center;
    border-color: #f4f4f4;
  }

  .head {
    display: flex;
    justify-content: space-between;
    padding: 10px 0 22px;
  }
}
</style>
