<template>
  <div class="new-about-logistics">
    <div class="new-center-container flexRow about-logistics__box">
      <img
        v-lazy="require(`../../assets/images/new-about/logistics.png`)"
        class="logistics-img"
      />
      <div class="content">
        <h3 class="new-title">直达物流</h3>
        <div class="content-img__box">
          <img
            v-lazy="require(`../../assets/images/new-about/sudi2China.png`)"
          />
        </div>
        <p>
          Send2China是全英领先的跨境物流高科技公司，公司于2013年由美国硅谷投资，成立于英国，专注为海内外客户提供安全，快捷，方便的物流解决方案。业务范围遍布欧洲，包括英国，德国，爱尔兰，意大利，法国和西班牙等，不仅有布局各大重点城市的线下体验店，还拥有自营取件车队，
          自动化运营中心，数千个合作方投递揽收网点。
        </p>
        <p>
          Send2China已服务英国及欧洲的数百万客户，每年承运包裹数百万件，总货物运输量超万吨，在
          欧洲出口运输企业中排名前列。与国际巨头英国皇家邮政ParcelForce, Royal
          Mail，比利时邮政Bpost，UPS，DHL合作多年。并在2017年，成为阿里巴巴旗下菜鸟物流的英国唯一官方指定合作
          直邮物流商，为各类淘宝天猫海外跨境商家提供助力！
        </p>
        <p>
          Send2China速递中国为您提供最稳定，最负责，最安心的服务。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less">
.new-about-logistics {
  padding-top: 100px;
  .about-logistics__box {
    justify-content: space-between;
    .logistics-img {
      width: 540px;
      height: 515px;
      margin-top: 70px;
    }
    .content {
      width: 445px;
      .new-title {
        text-align: left;
        margin-bottom: 70px;
        &::after {
          left: 0;
          margin-left: 0;
        }
      }
      img {
        width: 150px;
        height: 40px;
        margin-bottom: 30px;
      }
      p {
        line-height: 38px;
        color: #222;
        font-size: 16px;
      }
    }
  }
}
@media screen and (max-width: 640px) {
  .new-about-logistics {
    padding-top: 126px;
    .about-logistics__box {
      flex-direction: column;
      position: relative;
      .logistics-img {
        width: auto;
        height: auto;
        margin: 0;
      }
      .content {
        padding-top: 40px;
        width: auto;
        .new-title {
          width: 100%;
          position: absolute;
          left: 0;
          top: -90px;
          text-align: center;
          &::after {
            left: 50%;
            margin-left: -35px;
          }
        }
        .content-img__box {
          text-align: center;
        }
      }
    }
  }
}
</style>
