<script>
import { ref } from '@vue/composition-api'
import iconPDF from '~/assets/images/common/pdf-icon.png'

export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const url = props.data.invoices?.[0]?.url
    const previewLoaded = ref(true)
    const visible = ref(false)

    if (!url) {
      return () => <span />
    }

    const isPDF = /^pdf/i.test(url.split('.').pop())

    const handlePoptip = (value) => {
      if (!value) {
        return
      }

      visible.value = true
    }

    return () => (
      <span class="purchase-review">
        <Poptip
          transfer
          placement="right-start"
          trigger="hover"
          title={props.data.invoice_number}
          onInput={handlePoptip}
        >
          <template slot="content">
            {!!visible.value &&
              (isPDF ? (
                <iframe
                  class="purchase-preview-pdf"
                  src={process.env.STATIC_CDN_URL + url}
                ></iframe>
              ) : (
                <span class="py-2">
                  {previewLoaded.value && (
                    <Icon type="ios-loading" class="animate-spin text-[16px]" />
                  )}
                  <x-img
                    class="purchase-preview-img"
                    process="resize,w_800"
                    src={url}
                    whenLoad={() => {
                      previewLoaded.value = false
                    }}
                  />
                </span>
              ))}
          </template>

          <span>
            {isPDF ? (
              <img class="thumbnail" src={iconPDF} />
            ) : (
              <x-img class="thumbnail" src={url} process="resize,w_120" />
            )}
          </span>
        </Poptip>
      </span>
    )
  }
}
</script>

<style lang="less">
.purchase-review {
  display: inline-block;
  vertical-align: middle;

  .thumbnail {
    width: 20px;
    height: 20px;
    object-fit: cover;
    margin-right: 3px;
    border: 1px solid #eee;
  }
}

.purchase-preview-img {
  max-width: 800px;
  max-height: 800px;
  object-fit: contain;
}

.purchase-preview-pdf {
  border: none;
  width: 800px;
  height: 800px;
}
</style>
