<template>
  <div>
    <Form ref="form" :model="model" :label-width="150" label-colon>
      <div class="account-order-create-container">
        <div class="goods-info">
          <div class="container">
            <h1 class="title-line"><span>发货商品</span></h1>
            <PurchaseSelect
              :query="{ order: orderId }"
              :selected-items="purchaseConfig"
              :update-selected-items="(newValue) => (purchaseConfig = newValue)"
              :filter-invoices="(item) => !item.trouble"
            />
          </div>
        </div>

        <div v-show="troublePurchaseVisible" class="goods-info">
          <div class="container">
            <h1 class="title-line">
              <span>
                附加异常采购
                <Tooltip transfer placement="top" :max-width="320">
                  <div slot="content">
                    <div class="whitespace-normal">
                      以下是您放弃退税或者无法退税的商品明细。<br />在同一个发货包裹内，您选择的异常采购的商品数量【必须小于】您正常发货商品的数量。<a
                        href="https://www.ishopper.com/news/41"
                        target="_blank"
                      >
                        【还有疑问？】
                      </a>
                    </div>
                  </div>
                  <Icon type="ios-help-circle-outline" /> </Tooltip
              ></span>
            </h1>
            <PurchaseSelect
              :purchase-selected-text="
                `核销商品(最多可选 ${troublePurchaseConfig.options.length}/${purchaseConfig.options.length} 件)`
              "
              :query="{ is_trouble: true, order: orderId }"
              :selected-items="troublePurchaseConfig"
              :update-selected-items="
                (newValue) => (troublePurchaseConfig = newValue)
              "
              :update-invoices="handleUpdateTroubleInvoices"
              :filter-invoices="(item) => !!item.trouble"
            />
          </div>
        </div>

        <div class="receiver-info bg-gray">
          <div class="container">
            <h1>
              <span>请填写收件人信息</span>
              <AddressPicker :on-select="handleAddressSelect" />
            </h1>
            <FormRender :rows="receiverRows" />
            <div class="receiver-info-check">
              <Checkbox v-model="isSameAddress">账单邮寄至相同地址</Checkbox>
            </div>
            <FormRender
              v-if="!isSameAddress"
              is-billing-address
              :rows="billingRows"
            />
          </div>
        </div>

        <div class="receiver-info tax-refund-plan-info">
          <div class="container">
            <FormItem style="width:400px; margin:0 auto;" prop="rebate_plan">
              <template #label>
                <Tooltip content="退税比率按商品税前价格计算" placement="top">
                  选择退税方案: <Icon type="ios-help-circle-outline" />
                </Tooltip>
              </template>
              <Select v-model="model.rebate_plan">
                <Option
                  v-for="item of taxRefundPlans"
                  :key="item.id"
                  :value="item.id"
                  >{{ item.title }}</Option
                >
                <Option
                  v-if="is20percentRebateVisible"
                  key="20%"
                  disabled
                  value="null"
                >
                  20% - 180工作日(仅对高级会员开放)
                </Option>
              </Select>
            </FormItem>
            <div v-if="!taxRefundPlans.length" class="ml-[465px] mt-3">
              <Alert class="inline-block" type="warning" show-icon
                >您暂无可用退税方案，请咨询<a @click="customerService">客服</a
                >开通
              </Alert>
            </div>
          </div>
        </div>

        <div class="sender-info bg-gray">
          <div class="container">
            <div class="panel">
              <h3>请填写尾款信息</h3>
              <Row :gutter="10">
                <i-col span="12">
                  <FormItem label="尾款金额" prop="pay_retain">
                    <Input
                      v-model="model.pay_retain"
                      placeholder="£"
                      type="number"
                    />
                  </FormItem>
                </i-col>
                <i-col span="12">
                  <FormItem label="支付方式" prop="pay_method_retain">
                    <Select v-model="model.pay_method_retain">
                      <Option value="50">微信</Option>
                      <Option value="40">支付宝</Option>
                    </Select>
                  </FormItem>
                </i-col>
                <i-col span="24">
                  <div class="pay-note-title">
                    尾款支付备注:
                  </div>
                </i-col>
                <i-col span="24">
                  <FormItem class="pay-note-form-item" prop="pay_note_retain">
                    <Input v-model="model.pay_note_retain" type="text" />
                  </FormItem>
                </i-col>
              </Row>
            </div>
            <div class="panel">
              <h3>请填写订单备注</h3>
              <FormItem style="margin-left:-120px;">
                <Input
                  v-model="model.order_note"
                  type="textarea"
                  :autosize="{ minRows: 6, maxRows: 10 }"
                />
              </FormItem>
            </div>
          </div>
        </div>

        <div class="actions">
          <div class="container text-center">
            <Button type="info" @click="$router.go(-1)">返回</Button>
            <Button :loading="loading" type="primary" @click="handleSubmit"
              >确认提交</Button
            >
          </div>
        </div>
      </div>
    </Form>
  </div>
</template>

<script>
import { pick } from 'lodash'

import FormRender from '~/components/FormRender'
import AddressPicker from '~/components/AddressPicker'
import BillingAddress from '~/components/BillingAddress'
import PurchaseSelect from '~/components/order/PurchaseSelect'

export default {
  components: {
    FormRender,
    AddressPicker,
    PurchaseSelect
  },
  data() {
    return {
      isSameAddress: true,
      showConfirmDialog: false,
      loading: false,
      taxRefundPlans: [],
      model: {
        rebate_plan: null,
        pay_retain: null,
        pay_method_retain: null,
        pay_note_retain: '',
        order_note: '',
        purchase_items: '',
        recipient_name: '',
        recipient_phone: null,
        recipient_cid: null,
        postcode: null,
        country: 'CN',
        province: null,
        city: null,
        county: null,
        street1: null,
        street2: null,
        street3: null,
        billing_recipient_name: '',
        billing_recipient_phone: null,
        billing_postcode: null,
        billing_country: 'CN',
        billing_province: null,
        billing_city: null,
        billing_county: null,
        billing_street1: null,
        billing_street2: null,
        billing_street3: null
      },
      detailLoading: false,
      invoices: {
        options: [],
        value: ''
      },
      invoicesDetail: {
        options: [],
        value: []
      },
      invoicesCache: {},
      allInvoiceLoading: false,
      purchaseConfig: {
        options: [],
        value: []
      },
      troublePurchaseConfig: {
        options: [],
        value: []
      },
      troublePurchaseVisible: false
    }
  },
  computed: {
    addressModel() {
      const { model } = this
      return pick(model, [
        'addressId',
        'recipient_name',
        'recipient_phone',
        'recipient_cid',
        'postcode',
        'country',
        'province',
        'city',
        'county',
        'street1',
        'street2',
        'street3'
      ])
    },
    is20percentRebateVisible() {
      return (
        !!this.taxRefundPlans?.length &&
        !this.taxRefundPlans.find((item) => item.title.startsWith('20%'))
      )
    },
    orderId() {
      return this.$route.query.id
    },
    receiverRows() {
      const isOtherCountry = this.model.country !== 'CN'

      const rows = [
        {
          col: 8,
          prop: 'recipient_name',
          label: '收件人姓名',
          node: (
            <Input
              v-model={this.model.recipient_name}
              placeholder="姓名"
              maxlength="30"
            />
          ),
          rules: {
            required: true,
            trigger: 'blur',
            message: '请输入收件人姓名'
          }
        },
        {
          col: 8,
          prop: 'recipient_cid',
          label: '身份证号码',
          node: (
            <Input
              v-model={this.model.recipient_cid}
              maxlength="18"
              placeholder="身份证号码"
            />
          ),
          rules: [
            {
              trigger: 'blur',
              validator(rule, value, callback) {
                if (!value) {
                  callback()
                  return
                }

                const isIdNumber = /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}$)/.test(
                  value
                )

                callback(
                  isIdNumber ? undefined : new Error('请输入正确的身份证')
                )
              }
            }
          ]
        },
        {
          col: 8,
          prop: 'recipient_phone',
          label: '手机号码',
          node: (
            <Input
              placeholder="手机号码"
              v-model={this.model.recipient_phone}
            />
          ),
          rules: {
            required: true,
            trigger: 'blur',
            message: '请输入手机号码'
          }
        },
        {
          render: <Address v-model={this.model} />
        }
      ]

      if (isOtherCountry) {
        rows.splice(1, 1)
      }

      return rows
    },
    billingRows() {
      const rows = [
        {
          col: 8,
          prop: 'billing_recipient_name',
          label: '账单收件人姓名',
          node: (
            <Input
              v-model={this.model.billing_recipient_name}
              placeholder="姓名"
              maxlength="30"
            />
          ),
          rules: {
            required: true,
            trigger: 'blur',
            message: '请输入收件人姓名'
          }
        },
        {
          col: 8,
          prop: 'billing_recipient_phone',
          label: '手机号码',
          node: (
            <Input
              placeholder="手机号码"
              v-model={this.model.billing_recipient_phone}
            />
          ),
          rules: {
            required: true,
            trigger: 'blur',
            message: '请输入手机号码'
          }
        },
        {
          render: <BillingAddress v-model={this.model} />
        }
      ]

      // if (isOtherCountry) {
      //   rows.splice(1, 1)
      // }

      return rows
    }
  },
  async mounted() {
    if (this.orderId) {
      this.initById()
    }

    const plans = await this.fetchTaxRefundPlan()
    // 默认一个返佣方案
    if (plans?.length) {
      this.model.rebate_plan = plans[plans.length - 1].id
    }
  },
  methods: {
    handleUpdateTroubleInvoices(data) {
      if (data.options?.length) {
        this.troublePurchaseVisible = true
      }
    },
    customerService() {
      const user = this.$store.state.user
      if (user) {
        window._MEIQIA('metadata', {
          id: user.id,
          email: user.email,
          username: user.username,
          phone: user.phone
        })
      }
      window._MEIQIA('showPanel')
    },
    handleAddressSelect(addressItem) {
      this.$set(this, 'model', {
        addressId: addressItem.id,
        recipient_name: addressItem.name,
        recipient_phone: addressItem.phone,
        recipient_cid: addressItem.cid,
        postcode: addressItem.postcode,
        country: addressItem.country,
        province: addressItem.province,
        city: addressItem.city,
        county: addressItem.county,
        street1: addressItem.street1,
        street2: addressItem.street2,
        street3: addressItem.street3,
        rebate_plan: this.model.rebate_plan
      })
    },
    async initById() {
      const data = await this.$api.fetchOrderById(this.orderId)

      const resolvePurchaseItems = (filterFn = () => true) => {
        return data.purchases_items_list.filter(filterFn).map((item) => ({
          ...item,
          label: `${item.brand} - £${item.price}`,
          value: item.id
        }))
      }

      this.purchaseConfig.options = resolvePurchaseItems(
        (item) => item.is_trouble === false
      )

      // 显示异常小票
      this.troublePurchaseConfig.options = resolvePurchaseItems(
        (item) => item.is_trouble === true
      )
      if (this.troublePurchaseConfig.options.length) {
        this.troublePurchaseVisible = true
      }

      this.model = {
        ...data,
        recipient_name: '',
        recipient_phone: '',
        recipient_cid: '',
        postcode: '',
        country: '',
        state: '',
        city: '',
        district: '',
        street1: '',
        street2: '',
        street3: '',
        billing_recipient_name: '',
        billing_recipient_phone: '',
        billing_postcode: '',
        billing_country: '',
        billing_state: '',
        billing_city: '',
        billing_district: '',
        billing_street1: '',
        billing_street2: '',
        billing_street3: ''
      }

      this.isSameAddress = true
    },
    async fetchTaxRefundPlan() {
      const data = await this.$api.fetchTaxRefundPlan()
      this.taxRefundPlans = data

      return data
    },
    confrimSaveAddress() {
      const { addressModel } = this

      return new Promise((resolve) => {
        this.$Modal.confirm({
          title: '发现新的收件地址',
          content: '<p>这是一个新的收件人，要保存到地址簿吗?</p>',
          onOk: () => {
            resolve()
            this.$api.updateAddress({
              ...addressModel,
              ptype: 'receiver',
              name: addressModel.recipient_name,
              phone: addressModel.recipient_phone,
              cid: addressModel.recipient_cid
            })
          },
          onCancel: resolve
        })
      })
    },
    async confirmSumbit() {
      this.loading = true

      const model = {
        ...this.model,
        purchase_items: [
          ...this.purchaseConfig.options,
          ...this.troublePurchaseConfig.options
        ]
          .map((item) => item.value)
          .join(',')
      }
      try {
        if (!this.addressModel.addressId) {
          await this.confrimSaveAddress()
        }
        if (this.orderId) {
          await this.$api.createOrUpdateOrder(this.orderId, model)
          this.$Message.success('订单已更新')
          this.$router.go(-1)
        } else {
          const data = await this.$api.createOrUpdateOrder(0, model)
          this.$Message.success('订单已创建')
          this.orderId = data.id

          this.$Modal.confirm({
            title: '提示',
            content: '现在发货吗？',
            onOk: () => {
              this.$router.replace(`/account/order/delivery?id=${this.orderId}`)
            },
            onCancel: () => {
              this.$router.go(-1)
            }
          })
        }
      } catch (err) {
        console.error(err)
      }
      this.loading = false
    },
    handleSubmit() {
      if (
        this.troublePurchaseConfig.options.length >
        this.purchaseConfig.options.length
      ) {
        this.$Message.error('附加商品数量不能大于发货商品数量')
        return
      }

      this.$refs.form.validate((valid) => {
        if (!valid) {
          return
        }

        if (this.isSameAddress) {
          this.model = {
            ...this.model,
            ...{
              billing_recipient_name: this.model.recipient_name,
              billing_recipient_phone: this.model.recipient_phone,
              billing_postcode: this.model.postcode,
              billing_country: this.model.country,
              billing_province: this.model.province,
              billing_city: this.model.city,
              billing_county: this.model.country,
              billing_street1: this.model.street1,
              billing_street2: this.model.street2,
              billing_street3: this.model.street3
            }
          }
        }

        // 存在未审核通过小票提示
        const isShowConfirm = this.purchaseConfig.options.some(
          (item) => item.purchase.status !== 10
        )

        if (isShowConfirm) {
          this.$Modal.confirm({
            title: '温馨提示',
            content:
              '<p>监测到有未通过审核的小票,请尽快提交否则可能影响退税</p>',
            okText: '继续',
            cancelText: '返回',
            onOk: () => {
              this.confirmSumbit()
            }
          })
        } else {
          this.confirmSumbit()
        }
        // this.showConfirmDialog = true
      })
    }
  }
}
</script>

<style lang="less">
.account-order-create-container {
  padding: 50px 0 0;

  .address-picker-container {
    position: relative;
    top: -40px;
  }

  .goods-info {
    margin-bottom: 60px;

    .goods-info-items {
      display: flex;
      justify-content: space-between;
      align-items: center;

      > li {
        flex: none;

        .btns {
          margin-top: 10px;
          margin-bottom: -40px;
          display: flex;
          justify-content: flex-end;

          button + button {
            margin-left: 10px;
          }
        }

        h3 {
          font-size: 18px;
          font-weight: normal;
          color: #3b3b3b;
          text-align: center;
          margin-bottom: 10px;
        }
      }

      .selected-items {
        li {
          .ivu-icon {
            display: none;
          }

          &:hover {
            .ivu-icon {
              display: inline-block;
            }
          }
        }
      }

      .summary {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;
        margin-bottom: -40px;

        .currency-container {
          zoom: 0.6;
        }

        a {
          font-size: 24px;
        }
      }
    }
  }

  .receiver-info {
    padding: 40px 0 30px;
    .receiver-info-check {
      padding-bottom: 20px;
    }

    h1 {
      text-align: center;
      border-bottom: 1px solid #ececec;
      font-weight: normal;
      margin-bottom: 30px;
      padding-bottom: 20px;

      button {
        float: right;
      }
    }

    .ivu-col {
      margin-bottom: 10px;
    }
  }

  .sender-info {
    padding: 5px 0;
    .container {
      display: flex;
      justify-content: center;
    }
    .panel {
      padding: 30px 0 30px;

      &:first-child {
        width: 620px;
        border-right: 1px solid #f6f6f6;
        padding-right: 50px;
        margin-right: 50px;
      }
      &:last-child {
        width: 410px;
      }

      h3 {
        text-align: center;
        font-weight: normal;
        font-size: 24px;
        font-weight: normal;
        color: #3b3b3b;
        margin-bottom: 30px;
      }

      .pay-note-title {
        width: 120px;
        text-align: right;
        font-size: 16px;
        padding: 10px 12px 10px 0;
        margin-bottom: 5px;
      }

      .pay-note-form-item {
        .ivu-form-item-content {
          margin-left: 0 !important;
        }
      }
    }
  }

  .actions {
    padding: 30px 0 100px;

    button {
      margin-left: 30px;
    }
  }
}
</style>
