<template>
  <div>
    <Form class="account-cards-transfer-container">
      <h1 class="title">转入消费款</h1>
      <div class="container form-container">
        <div class="item">
          <span class="label">预订金余额:</span>
          <BalanceDeposit :value="user.gbp_balance" />
        </div>
        <div class="my-card">
          <div class="item">
            <span class="label">消费卡:</span>
            <span style="font-size:20px">{{ cardInfo.number }}</span>
          </div>
          <div class="item">
            <span class="label">消费卡余额:</span>
            <Currency :value="cardInfo.gbp_balance" type="£" />
          </div>
        </div>
        <div class="item transfer">
          <span class="label">转入金额(英镑):</span>
          <InputNumber
            v-model="charge_amount"
            type="number"
            :max="30000"
            :min="0"
            size="large"
            @on-blur="checkChargeAmount"
          />
          <span class="ml-2">
            最大可转入<span
              class="text-[#fb5120] font-bold underline cursor-pointer"
              @click="setMaxAmount"
            >
              £{{
                user.current_credit_balance >= 0
                  ? user.current_credit_balance.toFixed(2)
                  : 0
              }}
            </span>
          </span>
        </div>
      </div>

      <!-- <div class="actions-container bg-gray">
        <div class="container">
          <nuxt-link to="/account/cards">
            <Button type="info">返 回</Button>
          </nuxt-link>
          <Button type="primary" :loading="loading" @click="handleSubmit"
            >确认提交</Button
          >
        </div>
      </div> -->
    </Form>
  </div>
</template>

<script>
import BalanceDeposit from '~/components/BalanceDeposit.vue'

export default {
  components: {
    BalanceDeposit
  },
  data() {
    return {
      cardInfo: {},
      user: {},
      loading: false,
      charge_amount: 10
    }
  },
  computed: {
    maxAmount() {
      return this.cardInfo.gbp_balance
    }
  },
  async asyncData({ $api, route }) {
    const userDetail = await $api.fetchUserDetail()
    const { data: cards } = await $api.fetchCard({ card_id: route.query.id })
    const cardInfo = cards[0]
    const user = userDetail
    return {
      cardInfo,
      user
    }
  },
  methods: {
    setMaxAmount() {
      if (this.user.current_credit_balance <= 0) {
        return
      }
      this.charge_amount = Number(this.user.current_credit_balance)
    },
    async confirmSumbit() {
      this.loading = true
      const model = {
        action: 'recharge',
        card_id: this.cardInfo.id,
        amount: 0
      }
      model.amount = this.charge_amount
      try {
        await this.$api.cardCharge(model)
        this.$Message.success('支付货款申请已提交，请耐心等待！')
        this.$router.replace('/account/cards')
      } catch (err) {
        console.error(err)
      }
      this.loading = false
    },
    handleSubmit() {
      this.$Modal.confirm({
        title: '温馨提示',
        content: '<p>确认提交吗？</p>',
        okText: '继续',
        cancelText: '返回',
        onOk: () => {
          this.confirmSumbit()
        }
      })
    },
    checkChargeAmount() {
      if (this.charge_amount < 10) {
        this.$Message.error('支付货款金额必须大于10磅')
      }
      if (this.charge_amount >= 30000) {
        this.$Message.error('支付货款金额不能高于30000磅')
        this.charge_amount = 30000
      }
    }
  }
}
</script>

<style lang="less">
.account-cards-transfer-container {
  h1 {
    text-align: center;
    margin: 40px 0 0;
    font-weight: normal;
  }

  .form-container {
    width: 390px;
    min-width: 390px;
    margin: 0 auto;
    padding: 30px 0 10px;

    .item {
      display: flex;
      align-items: baseline;
      margin-bottom: 10px;
      border-bottom: 1px solid #f4f4f4;
      padding-bottom: 10px;
      margin-bottom: 20px;

      &.transfer {
        border: none;
      }
    }

    .my-card {
      border-bottom: 1px solid #f4f4f4;
      padding-bottom: 10px;
      margin-bottom: 30px;

      .item {
        border-bottom: none;
        margin-bottom: 0;
      }
    }

    .label {
      width: 120px;
      text-align: right;
      font-size: 16px;
      color: #3b3b3b;
      margin-right: 15px;
      flex: none;
    }
  }

  .actions-container {
    padding: 35px 0 80px;
    text-align: center;
    a {
      margin-right: 25px;
    }
  }
}
</style>
