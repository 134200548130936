<template>
  <div class="account-cards-container">
    <input type="password" hidden />
    <div class="container table-list">
      <h1>
        <div class="toolbar">
          <Input
            v-model="keyword"
            placeholder="搜索卡号、公司、持卡人、状态等"
            type="text"
            style="width:280px"
            prefix="ios-search"
          />
        </div>
        <span class="text-center">消费卡列表</span>
        <span class="text-right">
          <Tooltip
            :disabled="canRegister"
            content="账户余额须至少1英镑才可申请新卡"
            placement="top"
          >
            <nuxt-link to="/account/cards/register">
              <Button type="primary" :disabled="!canRegister">申请新卡</Button>
            </nuxt-link>
          </Tooltip>
        </span>
      </h1>
      <Table
        border
        stripe
        :loading="loading"
        class="table-blue"
        :columns="columns"
        :data="filterCards"
        @on-sort-change="tableOnSortChange"
      />
      <Modal v-model="model_1" title="请输入登录密码" @on-ok="submitPwd">
        <Input
          v-model="loginPwd"
          type="password"
          placeholder="请输入登录密码"
        />
      </Modal>

      <Modal v-model="model_pwd" title="CVV">
        <div>卡片CVV为：{{ cardCvv }}</div>
      </Modal>
    </div>

    <div class="desc-container bg-gray">
      <div class="pic">
        <img src="~/assets/images/account/common-5.png" />
      </div>
      <div class="intro">
        <h2>注意事项:</h2>
        <ul>
          <li style="width:390px">
            账户余额需至少1英镑才可申请新卡，在新用户推广期间，当余额少于20英镑时，iShopper会为您垫付10英镑的工本费和10英镑消费卡预存金额中不足的部分，方便您无门槛体验我们提供的优质采购和退税服务。
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      keyword: '',
      deleteCardId: '',
      columns: [
        {
          title: '发卡公司',
          key: 'issuer',
          align: 'center',
          render: (_, { row }) => {
            if (row.issuer) {
              return <span>{row.issuer.name}</span>
            } else {
              return <span>-</span>
            }
          }
        },
        {
          title: '卡号',
          align: 'center',
          sortable: 'custom',
          key: 'number',
          render: (_, { row }) => {
            if (row.number) {
              if (row.vaild_from && row.vaild_end) {
                return (
                  <div style="padding:10px 0; text-align: center;">
                    <div>卡号：{row.number}</div>
                    <div>持卡人：{row.name}</div>
                    <div>
                      有效期：
                      {row.vaild_from &&
                        this.$dayjs(row.vaild_from).format('MM/YYYY')}
                      -
                      {row.vaild_end &&
                        this.$dayjs(row.vaild_end).format('MM/YYYY')}
                    </div>
                    <div>
                      <a
                        href="#0"
                        onClick={() => {
                          this.handleSeeCvv(row.id)
                        }}
                      >
                        查看cvv
                      </a>
                    </div>
                  </div>
                )
              } else {
                return (
                  <div style="padding:10px 0; text-align: center;">
                    <div>卡号：{row.number}</div>
                    <div>持卡人：{row.name}</div>
                  </div>
                )
              }
            } else {
              return (
                <span>
                  {row.apply_log.name ? `持卡人:${row.apply_log.name}` : ''}
                </span>
              )
            }
          }
        },
        {
          title: '创建时间',
          width: 120,
          align: 'center',
          sortable: 'custom',
          key: 'created_at',
          render: (_, { row }) => {
            return (
              <span>{this.$dayjs(row.created_at).format('YYYY/MM/DD')}</span>
            )
          }
        },
        {
          title: '状态',
          width: 110,
          align: 'center',
          sortable: 'custom',
          key: 'status',
          render: (_, { row }) => {
            return (
              <span class={row.status === 2 ? 'status_active' : ''}>
                {this.$ENUM.CARD_STATUS[row.status]}
              </span>
            )
          }
        },
        {
          title: '当前余额',
          key: 'balance',
          sortable: 'custom',
          align: 'center',
          render: (_, { row }) => {
            return <Currency color="normal" value={row.gbp_balance} type="£" />
          }
        },
        {
          title: '消费记录',
          key: 'log',
          align: 'center',
          render(_, { row }) {
            if (row.number) {
              if (row.status >= 2) {
                return (
                  <div class="log-actions text-center">
                    <div>
                      <nuxt-link to={`/account/cards/detail?id=${row.id}`}>
                        <Button type="info" size="small">
                          查看
                        </Button>
                      </nuxt-link>
                    </div>
                  </div>
                )
              } else {
                return <span>-</span>
              }
            } else {
              return <span>-</span>
            }
          }
        },
        {
          title: '管理',
          key: 'actions',
          width: 150,
          align: 'center',
          render: (_, { row }) => {
            if (row.status === 0) {
              return (
                <div>
                  <a
                    class="mr-2 run-btn"
                    onClick={() => {
                      this.removeCard(row.id)
                    }}
                  >
                    删除
                  </a>
                  <p>
                    <nuxt-link
                      to={`/account/cards/card-detail?id=${row.apply_log.id}&showEdit=1`}
                      class="mr-2 run-btn"
                    >
                      查看
                    </nuxt-link>
                  </p>
                </div>
              )
            } else if (row.status === 1) {
              return (
                <p>
                  <nuxt-link
                    to={`/account/cards/card-detail?id=${row.apply_log.id}&showEdit=1`}
                    class="mr-2 run-btn"
                  >
                    查看
                  </nuxt-link>
                </p>
              )
            } else if (row.status === 3) {
              return (
                <div class="log-actions text-center">
                  <div>
                    {/* <nuxt-link to={`/account/cards/transfer?id=${row.id}`}>
                      <a class="run-btn">转入消费款</a>
                    </nuxt-link> */}
                    <Poptip transfer title="请输入用户密码">
                      <div slot="content">
                        <span>
                          <Input
                            style="width:120px;text-align:center;margin-right:10px;"
                            type="password"
                            size="small"
                            onOn-change={(e) => (this.pin_pwd = e.target.value)}
                            placeholder="请输入用户密码"
                          ></Input>
                          <Button
                            type="primary"
                            size="small"
                            onClick={() =>
                              this.showEnterPinPwd(
                                this.pin_pwd,
                                row.id,
                                row.number
                              )
                            }
                          >
                            查看
                          </Button>
                        </span>
                      </div>
                      <a class="run-btn">查看pin码</a>
                    </Poptip>
                  </div>
                </div>
              )
            } else if (row.status === 4) {
              let cardNum = ''

              return (
                <div>
                  <Poptip transfer title="请输入卡号后两位激活">
                    <div slot="content">
                      <span>
                        <Input
                          style="width:120px;text-align:center;margin-right:10px;"
                          type="text"
                          size="small"
                          onOn-change={(e) => (cardNum = e.target.value)}
                          placeholder="输入卡号后两位"
                        ></Input>
                        <Button
                          type="primary"
                          size="small"
                          onClick={() => this.activeCard(cardNum, row.id)}
                        >
                          激活
                        </Button>
                      </span>
                    </div>
                    <a class="run-btn">申请激活</a>
                  </Poptip>
                  <Poptip transfer title="请输入用户密码">
                    <div slot="content">
                      <span>
                        <Input
                          style="width:120px;text-align:center;margin-right:10px;"
                          type="password"
                          size="small"
                          onOn-change={(e) => (this.pin_pwd = e.target.value)}
                          placeholder="请输入用户密码"
                        ></Input>
                        <Button
                          type="primary"
                          size="small"
                          onClick={() =>
                            this.showEnterPinPwd(
                              this.pin_pwd,
                              row.id,
                              row.number
                            )
                          }
                        >
                          查看
                        </Button>
                      </span>
                    </div>
                    <a class="run-btn">查看pin码</a>
                  </Poptip>
                </div>
              )
            } else if (row.apply_log) {
              return (
                <nuxt-link
                  to={'/account/cards/card-detail?id=' + row.apply_log.id}
                  class="mr-2 run-btn"
                >
                  查看
                </nuxt-link>
              )
            } else {
              return <span>-</span>
            }
          }
        }
      ],
      cards: [],
      selectCardId: 0,
      loginPwd: '',
      model_1: false,
      cardCvv: '',
      model_pwd: false,
      loading: false,
      pin_pwd: ''
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.user
    },
    canRegister() {
      return this.userInfo.gbp_balance >= 1
    },
    filterCards() {
      const filterCards = this.cards.filter((item) => {
        const q = new RegExp(this.keyword, 'i')
        return q.test(
          JSON.stringify({
            ...item,
            status: this.$ENUM.CARD_STATUS[item.status]
          })
        )
      })

      return filterCards
    }
  },
  mounted() {
    this.fetchCard()
    this.$store.dispatch('updateUserDetail')
  },
  methods: {
    tableOnSortChange({ key, order }) {
      this.fetchCard({
        order_by: `${order === 'desc' ? '' : '-'}${
          key === 'balance' ? 'gbp_balance' : key
        }`
      })
    },
    async showEnterPinPwd(pwd, card_id, cardNum) {
      if (!pwd) {
        this.$Message.error('请输入用户密码!')
        return
      }

      try {
        const res = await this.$api.cardCharge({
          action: 'view_pin',
          card_id,
          pwd
        })
        document.body.click()
        this.pin_pwd = ''
        this.$Modal.success({
          content: `<div style="font-size:18px"><p>卡号:${cardNum}</p><p>pin:${res.pin}</p></div>`
        })
      } catch (error) {
        console.log(error.desc)
      }
    },
    async fetchCard(query) {
      this.loading = true
      try {
        const { data: cards } = await this.$api.fetchCard(query || {})
        this.cards = cards
      } catch (err) {
        console.error(err)
      }
      this.loading = false
    },
    handleSeeCvv(id) {
      this.selectCardId = id
      this.model_1 = true
    },
    async submitPwd() {
      if (this.loginPwd) {
        const a = await this.$api.cardCharge({
          action: 'view_cvv',
          card_id: this.selectCardId,
          pwd: this.loginPwd
        })
        this.cardCvv = a.cvv
        this.model_pwd = true
        console.log(a)
      } else {
        this.$Message.error('请输入密码!')
      }
    },
    async confirmSumbit() {
      await this.$api.cardCharge({
        action: 'remove',
        card_id: this.deleteCardId
      })
      this.$Message.success('删除成功')
      this.fetchCard()
    },
    removeCard(cardId) {
      this.deleteCardId = cardId
      this.$Modal.confirm({
        title: '温馨提示',
        content: '<p>确认删除该条数据吗？</p>',
        okText: '继续',
        cancelText: '返回',
        onOk: () => {
          this.confirmSumbit()
        }
      })
    },
    async activeCard(cardNum, cardId) {
      if (cardNum) {
        try {
          await this.$api.cardCharge({
            action: 'active',
            card_id: cardId,
            card_num: cardNum
          })
          this.$Message.success('申请成功')
          this.fetchCard()
        } catch (err) {
          console.error(err)
        }
      } else {
        this.$Message.error('请输入卡号最后两位!')
      }
    }
  }
}
</script>

<style lang="less">
.account-cards-container {
  .table-list {
    padding: 40px 0;

    h1 {
      font-weight: normal;
      font-size: 24px;
      color: #3b3b3b;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      & > span {
        width: 33.3333%;

        &:first-child {
          margin-left: 33.333%;
        }
      }
    }
  }

  .desc-container {
    display: flex;
    justify-content: center;
    padding: 50px 0;

    .pic {
      margin-right: 60px;
    }

    .intro {
      padding-top: 10px;
      h2 {
        font-size: 20px;
        font-weight: normal;
        color: #ea5d35;
        margin-bottom: 10px;
      }

      ul {
        li {
          margin-bottom: 5px;
          &::before {
            width: 10px;
            content: '●';
            color: #fb5120;
            display: inline-block;
            margin-right: 10px;
          }
        }
      }
    }
  }
  .log-actions {
    padding: 10px;

    > div {
      & + div {
        margin-top: 10px;
      }
    }
    button {
      width: 110px;
    }
  }
  .run-btn {
    color: #fb5120;
    background: none;
    text-decoration: underline;
    &:hover {
      background: none;
    }
    &:focus {
      box-shadow: none;
    }
  }
}
</style>
