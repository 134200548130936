<template>
  <div class="w-full p-[32px] px-[32px] bg-[#fbfbfb] min-h-[100vh]">
    <h1
      v-if="!isDone"
      class="text-[24px] mt-[32px] mb-[12px] font-bold text-[#333]"
    >
      绑定账户
    </h1>

    <div v-if="!userInfo.user">
      <div class="mb-[32px]">
        请登录或者注册账号后再进行绑定
      </div>
      <div class="flex">
        <NuxtLink class="w-[50%]" to="/register">
          <Button class="w-full" type="default">注册</Button>
        </NuxtLink>

        <NuxtLink class="w-[50%] ml-[20px]" :to="loginUrl">
          <Button type="primary" class="w-full">登录</Button>
        </NuxtLink>
      </div>
    </div>

    <div v-if="!isDone && userInfo.user">
      <div class="mb-[32px]">
        您将绑定的账号是：{{ userInfo.user.username }}
      </div>
      <div class="flex justify-center">
        <Button
          :loading="loading"
          class="w-full"
          type="primary"
          dashed
          @click="handleBind"
        >
          确认绑定
        </Button>
      </div>
    </div>

    <div v-if="isDone" class="text-center">
      <Icon
        class="text-[64px] mb-[8px] text-[#52c41a]"
        type="ios-checkmark-circle"
      />
      <p class="text-[#333] text-[16px]">
        您已成功绑定到账号：{{ userInfo.user.username }}
      </p>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from '@vue/composition-api'
import { useVm, useStore } from '~/use'

export default {
  layout: 'empty',
  head() {
    return {
      title: '绑定微信'
    }
  },
  setup() {
    const vm = useVm()
    const query = vm.$route.query
    const { state: userInfo } = useStore()

    const loginUrl = ref('/login')
    const loading = ref(false)

    const isDone = computed(() => !!userInfo.user?.mp_openid)

    const handleBind = async () => {
      loading.value = true

      try {
        await vm.$api.wechatBindUser({ openid: query.openid })
      } catch (err) {
        console.error(err)
      }
      vm.$store.dispatch('updateUserDetail')
      loading.value = false
    }

    onMounted(() => {
      loginUrl.value = `/login?callbackUrl=${encodeURIComponent(
        window.location.href
      )}`
    })

    return {
      loading,
      handleBind,
      loginUrl,
      isDone,
      userInfo
    }
  }
}
</script>

<style lang="less"></style>
