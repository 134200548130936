<template>
  <div>
    <Form
      ref="form"
      :model="modelForRules"
      class="account-purchase-upload-container"
    >
      <uploadSection
        v-model="model"
        :detail="detail"
        show-admin-input
        :purchase-id="purchaseId"
      />

      <goodsSection
        v-model="goodsItems"
        :hide-goods="model.is_admin_input"
        :exclude-amount.sync="model.exclude_amount"
      />

      <div class="actions">
        <Button
          v-if="detail.status === 1"
          :loading="loading"
          type="primary"
          @click="showConfirm(1)"
        >
          暂存
        </Button>
        <Button :loading="loading" type="primary" @click="showConfirm(2)">
          确 认
        </Button>
      </div>
    </Form>
  </div>
</template>

<script>
import uploadSection from '~/components/purchase/uploadSection'
import goodsSection from '~/components/purchase/goodsSection'

export default {
  components: {
    uploadSection,
    goodsSection
  },
  inheritAttrs: false,
  props: {},
  data() {
    return {
      confirmType: 1,
      goodsItems: [
        {
          brand: '',
          barcode: '',
          desc: '',
          quantity: 1,
          hscode: '',
          price: '',
          vat: '',
          total: '',
          is_inc_tax: '1'
        }
      ],
      detail: {},
      model: {},
      loading: false,
      total: 0
    }
  },
  computed: {
    purchaseId() {
      return this.$route.query.id
    },
    modelForRules() {
      return {
        goodsItems: this.goodsItems
      }
    }
  },
  async asyncData({ $api, route }) {
    const detail = await $api.fetchPuchaseDetail({ id: route.query.id })
    const goodsItems = []
    const model = {
      purchase_id: route.query.id,
      action: `create`,
      invoice_number: detail.invoice_number,
      invoice_type: detail.invoice_type,
      exclude_amount: detail.exclude_amount,
      user_remark: detail.user_remark,
      items: [],
      is_admin_input: false,
      trouble: detail.trouble
    }
    const total = Number(detail.amount)
    detail.items.map((oneItem) => {
      const itmeObj = {
        brand: oneItem.brand,
        desc: oneItem.desc,
        price: oneItem.price,
        barcode: oneItem.barcode,
        quantity: oneItem.quantity,
        vat: oneItem.vat,
        is_inc_tax: '1',
        total: oneItem.total,
        hscode: oneItem.hscode
      }
      goodsItems.push(itmeObj)
    })
    if (goodsItems.length === 0) {
      const oneObj = {
        brand: '',
        barcode: '',
        desc: '',
        quantity: 1,
        hscode: '',
        price: '',
        vat: '',
        total: '',
        is_inc_tax: '1'
      }
      goodsItems.push(oneObj)
    }
    return {
      detail,
      goodsItems,
      model,
      total
    }
  },
  methods: {
    async handleSubmit() {
      this.loading = true
      let ac = 'create'
      if (this.detail.status === 4) {
        ac = 'update'
      }
      const formData = {
        purchase_id: this.purchaseId,
        action: ac,
        invoice_type: this.model.invoice_type,
        invoice_number: this.model.invoice_number,
        exclude_amount: this.model.exclude_amount,
        user_remark: this.model.user_remark,
        is_admin_input: String(this.model.is_admin_input),
        trouble: this.model.trouble
      }
      const items = []
      this.goodsItems.map((oneItem) => {
        const itmeObj = {
          brand: oneItem.brand,
          hscode: oneItem.hscode,
          barcode: oneItem.barcode,
          qty: oneItem.quantity,
          desc: oneItem.desc,
          price: oneItem.price,
          is_inc_tax: oneItem.is_inc_tax
        }
        items.push(itmeObj)
      })
      formData.items = items
      try {
        await this.$api.updatePuchase(formData)
        this.$Message.success('票据提交成功，请耐心等待！')
        this.$router.go(-1)

        window.dataLayer.push({
          event: 'user_conversion',
          conversion_action: 'uploaded_bill'
        })
      } catch (err) {
        console.error(err)
      }
      this.loading = false
      // })
    },
    showConfirm(type) {
      this.confirmType = type
      if (this.model.is_admin_input) {
        if (this.confirmType === 1) {
          this.$Message.error('人工填单的采购，无法暂存，请直接提交')
          return
        }
        this.confirmSumbit()
        return
      }

      this.$refs.form.validate((isPass) => {
        if (!isPass) {
          this.loading = false
          return
        }
        this.$Modal.confirm({
          title: '温馨提示',
          content: `<p>确认${this.confirmType === 1 ? '暂存' : '提交'}吗</p>`,
          okText: '继续',
          cancelText: '返回',
          onOk: () => {
            this.confirmSumbit()
          }
        })
      })

      // 1代表暂存，2代表确认
    },
    confirmSumbit() {
      if (this.confirmType === 1) {
        this.handleSave()
      } else {
        this.handleSubmit()
      }
    },
    async handleSave() {
      this.loading = true
      const ac = 'save'
      const formData = {
        purchase_id: this.purchaseId,
        action: ac,
        invoice_type: this.model.invoice_type,
        invoice_number: this.model.invoice_number,
        exclude_amount: this.model.exclude_amount,
        user_remark: this.model.user_remark,
        is_admin_input: String(this.model.is_admin_input)
      }
      const items = []
      this.goodsItems.map((oneItem) => {
        const itmeObj = {
          brand: oneItem.brand,
          barcode: oneItem.barcode,
          qty: oneItem.quantity,
          hscode: oneItem.hscode,
          desc: oneItem.desc,
          price: oneItem.price,
          is_inc_tax: oneItem.is_inc_tax
        }
        items.push(itmeObj)
      })
      formData.items = items

      // this.$refs.form.validate(async (isPass) => {
      //   if (!isPass) {
      //     this.loading = false
      //     return
      //   }

      try {
        await this.$api.updatePuchase(formData)
        this.$Message.success('票据暂存成功')
        this.$router.replace('/account/purchase/')
        // this.$router.back(-1)
      } catch (err) {
        console.error(err)
      }
      this.loading = false
      // })
    }
  }
}
</script>

<style lang="less">
.drag .account-purchase-upload-container {
  .ivu-upload-drag {
    position: fixed;
    top: 0;
    left: 0;
    background: #000;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.7;
    z-index: 2;
  }

  .upload-list {
    pointer-events: none;
    padding-top: 7px;
  }
}

.account-purchase-upload-container {
  .currency-container {
    .currency-type {
      font-size: 20px;
    }
    .currency-int {
      font-size: 22px;
    }
    .currency-decimal {
      font-size: 16px;
    }
  }

  .actions {
    text-align: center;
    padding: 60px 0 100px;
  }

  .upload-list {
    display: inline-block;
    width: 80px;
    height: 80px;
    text-align: center;
    line-height: 80px;
    border: 1px solid transparent;
    border-radius: 4px;
    overflow: hidden;
    background: #fff;
    position: relative;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    border: 1px solid #ddd;
    margin-right: 12px;
  }
  .upload-list img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .upload-list-cover {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.6);
  }
  .upload-list:hover .upload-list-cover {
    display: block;
  }
  .upload-list-cover i {
    color: #fff;
    font-size: 20px;
    cursor: pointer;
    margin: 0 2px;
  }

  .ivu-upload {
    width: 80px;
    height: 80px;
    display: inline-flex;
    padding-top: 4px;
    vertical-align: top;
    margin-top: -1px;
    .upload-list {
      margin: 0;
      border: none;
    }
  }
}

.preview-invoice .ivu-modal-footer {
  display: none;
}
</style>
