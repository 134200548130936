<template>
  <ArticleLayout color="#ea6935" title="新手入门" :data="textData" />
</template>

<script>
import ArticleLayout from '~/components/ArticleLayout'
export default {
  layout: 'default',
  head() {
    return {
      title: '新手入门'
    }
  },
  components: {
    ArticleLayout
  },
  asyncData() {
    return {
      textData: '暂无内容'
    }
  }
}
</script>

<style lang="less"></style>
