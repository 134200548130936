<template>
  <div>
    <div
      v-for="(item, index) in uploadList"
      :key="index"
      class="upload-list-wrapper"
    >
      <div class="upload-list">
        <template v-if="item.status === 'finished'">
          <img
            v-if="item.url.indexOf('.pdf') !== -1"
            src="~/assets/images/common/pdf-icon.png"
          />
          <x-img v-else :src="item.url" process="resize,w_600" />
          <div class="upload-list-cover">
            <Icon type="ios-eye-outline" @click.native="handleReview(item)" />
            <Poptip
              transfer
              confirm
              title="确定要删除吗?"
              @on-ok="handleRemove(item)"
            >
              <Icon type="ios-trash-outline" />
            </Poptip>
          </div>
        </template>
        <template v-else>
          <Progress
            v-if="item.showProgress"
            :percent="item.percentage"
            hide-info
          />
        </template>
      </div>
      <slot name="item" :data="item" />
    </div>
    <ExtUpload
      ref="uploadRef"
      type="drag"
      :format="['jpg', 'jpeg', 'png', 'gif', 'pdf']"
      :on-format-error="() => $Message.error('请选择图片文件')"
      :default-file-list="defaultList"
      :show-upload-list="false"
      :on-success="handleSuccess"
      :data="data"
      :name="fieldName"
      :action="actionApi"
    >
      <div title="点击或者拖拽上传" class="upload-list" @click="onUploadClick">
        <Icon type="ios-camera" size="35" />
      </div>
    </ExtUpload>
    <slot />
    <Modal
      v-model="previewVisible"
      class="preview-invoice"
      width="80%"
      title="查看"
    >
      <div v-if="previewVisible">
        <iframe
          v-if="curItem.url.indexOf('.pdf') !== -1"
          style="width: 100%;height: 600px;border: none;"
          :src="`${STATIC_CDN_URL}${curItem.url}`"
        />
        <x-img v-else :src="curItem.url" style="width: 100%" />
      </div>
    </Modal>
  </div>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
import { useVm } from '@/use'

export default {
  props: {
    actionApi: {
      type: String,
      required: true
    },
    defaultList: {
      type: Array,
      default: () => []
    },
    fieldName: {
      type: String,
      default: 'file'
    },
    data: {
      type: Object,
      default: () => ({})
    },
    onRemove: {
      type: Function,
      default: () => ({})
    },
    onSuccess: {
      type: Function,
      required: true
    },
    onUploadClick: {
      type: Function,
      default: () => ({})
    }
  },
  setup(props) {
    const vm = useVm()
    const curItem = ref(null)
    const previewVisible = ref(false)
    const uploadRef = ref(null)
    const uploadList = ref([])

    const handleReview = (item) => {
      previewVisible.value = true
      curItem.value = item
    }

    const handleSuccess = ({ data }, file) => {
      props.onSuccess(data, file)
    }

    const handleRemove = async (item) => {
      const loading = vm.$Message.loading({
        content: '正在删除...',
        duration: 0
      })

      try {
        await props.onRemove(item)
        const index = uploadList.value.findIndex(
          (fileItem) => fileItem.id === item.id
        )
        uploadList.value.splice(index, 1)
      } catch (err) {
        console.error(err)
      }
      loading()
    }

    const isAllReady = () =>
      uploadList.value.every((item) => item.status === 'finished')

    onMounted(() => {
      uploadList.value = uploadRef.value.uploadList
    })

    return {
      isAllReady,
      uploadList,
      handleSuccess,
      handleReview,
      previewVisible,
      uploadRef,
      handleRemove,
      curItem,
      STATIC_CDN_URL: process.env.STATIC_CDN_URL
    }
  }
}
</script>

<style lang="less">
.upload-list-wrapper {
  display: inline-block;
  vertical-align: top;
}
.upload-list {
  display: inline-block;
  width: 80px;
  height: 80px;
  text-align: center;
  line-height: 80px;
  border: 1px solid transparent;
  border-radius: 4px;
  overflow: hidden;
  background: #fff;
  position: relative;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  border: 1px solid #ddd;
  margin-right: 12px;
  vertical-align: top;
  margin-bottom: 10px;
}
.ivu-upload {
  margin-right: 5px;
  margin-left: -1px;
}

.upload-list img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.upload-list-cover {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
}
.upload-list:hover .upload-list-cover {
  display: block;
}
.upload-list-cover i {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  margin: 0 2px;
}
</style>
