<template>
  <!-- eslint-disable vue/no-v-html -->
  <div class="goods-info bg-gray" :class="{ 'check-mode': checkMode }">
    <div class="container">
      <slot name="head" />

      <h1 v-if="!hideGoods">{{ title || '商品明细' }}</h1>
      <div v-if="goodsItems.length && !hideGoods" class="form">
        <div class="item head">
          <span>
            <Poptip trigger="hover">
              <span>商品货号<Icon type="ios-help-circle-outline"/></span>
              <template #content>
                <img
                  width="320px"
                  src="~/assets/images/common/sample-1.png"
                  alt=""
                />
              </template>
            </Poptip>
            <span class="text-red-500">*</span></span
          >
          <span>英文描述<span class="text-red-500">*</span></span>
          <span>品牌</span>
          <span
            >物品分类
            <Tooltip
              max-width="240"
              content="物品分类有可能会影响本件商品的进出口流程，请尽量准确填写"
              placement="top"
            >
              <Icon type="ios-help-circle-outline" />
            </Tooltip>
          </span>
          <span>是否含税</span>
          <span>小票单价</span>
          <span>数量</span>
          <span>总价</span>
        </div>
        <ItemsEditor
          v-model="goodsItems"
          v-slot:default="{ item, index }"
          :item-template="getGoodsTemplate()"
        >
          <div class="item">
            <span>
              <FormItem
                :prop="`goodsItems[${index}].barcode`"
                :rules="{
                  required: true,
                  message: '请填写商品货号',
                  trigger: 'blur'
                }"
              >
                <AutoComplete
                  v-model="item.barcode"
                  v-tab="'barcode'"
                  :disabled="checkDisabled('barcode')"
                  @on-change="handleSearchBarcode"
                  @on-select="
                    (value) =>
                      handleSelectGoods(
                        searchBarcodeResult.find((item) => item.id === value),
                        index
                      )
                  "
                >
                  <Option
                    v-for="optionItem in searchBarcodeResult"
                    :key="optionItem.id"
                    :value="optionItem.id"
                  >
                    <span
                      v-html="highlightSelectedGoods(item.barcode, optionItem)"
                  /></Option>
                </AutoComplete>
              </FormItem>
            </span>
            <span>
              <FormItem
                :prop="`goodsItems[${index}].desc`"
                :rules="{
                  required: true,
                  pattern: /^[,.'()-_+=$%\w\s]+$/gi,
                  message: '请正确填写描述',
                  trigger: 'blur'
                }"
              >
                <AutoComplete
                  v-model="item.desc"
                  v-tab="'desc'"
                  :disabled="checkDisabled('desc')"
                  @on-change="handleSearchGoods"
                  @on-select="
                    (value) =>
                      handleSelectGoods(
                        searchGoodsResult.find((item) => item.id === value),
                        index
                      )
                  "
                >
                  <Option
                    v-for="optionItem in searchGoodsResult"
                    :key="optionItem.id"
                    :value="optionItem.id"
                  >
                    <span v-html="highlightSelectedGoods(item.desc, optionItem)"
                  /></Option>
                </AutoComplete>
              </FormItem>
            </span>
            <span>
              <AutoComplete
                v-model="item.brand"
                v-tab="'brand'"
                :disabled="checkDisabled('brand')"
                @on-search="handleSearchBrand"
              >
                <Option
                  v-for="name in realtimeBrands"
                  :key="name"
                  :value="name"
                >
                  <span v-html="highlightSelectedBrand(item.brand, name)"
                /></Option>
              </AutoComplete>
            </span>
            <span :title="item.value">
              <FormItem
                :prop="`goodsItems[${index}].hscode`"
                :rules="{
                  required: true,
                  message: '请选择分类',
                  trigger: 'change'
                }"
              >
                <Select v-model="item.hscode" filterable>
                  <Option
                    v-for="hscodeItem in hscodeData"
                    :key="hscodeItem.value"
                    :value="hscodeItem.value"
                    >{{ hscodeItem.label }}</Option
                  >
                </Select>
              </FormItem>
            </span>
            <span>
              <Select
                v-model="item.is_inc_tax"
                :disabled="checkDisabled('is_inc_tax')"
                @on-change="update_row_total(index)"
              >
                <Option value="1" selected>含税</Option>
                <Option value="0">不含税</Option>
                <Option value="2">无税</Option>
              </Select>
            </span>
            <span>
              <Input
                v-model="item.price"
                v-tab="`price`"
                :disabled="checkDisabled('price')"
                s
                @on-change="update_row_total(index)"
              />
            </span>
            <span>
              <Input
                v-model="item.quantity"
                v-tab="'quantity'"
                :disabled="checkDisabled('quantity')"
                type="number"
                @on-change="update_row_total(index)"
              />
            </span>
            <span>
              <Input v-model="item.total" disabled />
            </span>
            <span v-if="checkMode" class="check-item">
              <div v-if="item.can_be_refunded">
                <Checkbox v-model="item._check">是否核销</Checkbox>
              </div>
            </span>
          </div>
        </ItemsEditor>
        <div class="flexRow bottom">
          <div class="bottom-left">
            <p>便捷操作提示：</p>
            <p>1.按“TAB”键切换同一行的单元格；</p>
            <p>2.按“上\下”键切换同一列的单元格。</p>
          </div>
          <div class="bottom-right">
            <div class="exclude">
              <FormItem label="运费及其他(不记税):">
                <Input v-model="localExcludeAmount" />
              </FormItem>
            </div>

            <div class="total">
              <Currency bold color="orange" type="£" :value="sum" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import isEqual from 'lodash/isEqual'
import debounce from 'lodash/debounce'
import filter from 'lodash/filter'
import cloneDeep from 'lodash/cloneDeep'
import ItemsEditor from '~/components/ItemsEditor'
import { createSyncData } from '~/utils'
import hscodeData from '~/assets/database/hscode.json'
import { productService } from '~/services/product.service'

export default {
  components: {
    ItemsEditor
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    value: {
      type: Array,
      required: true
    },
    excludeAmount: {
      type: [Number, String],
      default: 0
    },
    checkDisabled: {
      type: Function,
      default: () => false
    },
    checkMode: {
      type: Boolean,
      default: false
    },
    hideGoods: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      brands: [],
      goodsItems: [],
      searchGoodsResult: [],
      searchBarcodeResult: [],
      localExcludeAmount: 0,
      realtimeBrands: []
    }
  },
  computed: {
    hscodeData() {
      return hscodeData.map((item) => ({
        label: `${item.name} ${item.category}`,
        value: item.code,
        source: item
      }))
    },
    selectedGoodsItems() {
      return this.goodsItems.filter((item) => item._check)
    },
    sum() {
      const goodsItems = this.checkMode
        ? this.selectedGoodsItems
        : this.goodsItems

      const total = goodsItems.reduce((acc, cur) => {
        acc = acc + Number(cur.total || 0)
        return acc
      }, 0)

      return total + Number(this.excludeAmount)
    }
  },
  watch: {
    ...createSyncData(
      'localExcludeAmount',
      'excludeAmount',
      'update:excludeAmount'
    ),
    goodsItems: {
      deep: true,
      handler(value) {
        this.$emit('input', value)
      }
    },
    value: {
      deep: true,
      immediate: true,
      handler(value) {
        if (isEqual(value, this.goodsItems)) {
          return
        }

        this.goodsItems = cloneDeep(
          value.map((item) => ({ ...item, hscode: item.hscode || '' }))
        )
      }
    }
  },
  async mounted() {
    const data = await this.$api.fetchBrands()
    this.brands = data.map((item) => item.name)
  },
  methods: {
    handleSearchBrand(value) {
      const reg = new RegExp(value, 'i')

      this.realtimeBrands = filter(this.brands, (name) => reg.test(name))
    },
    highlightSelectedBrand(value, item) {
      return item.replace(
        new RegExp(`(${value})`, 'gi'),
        `<span style="color:#ea5d35">$1</span>`
      )
    },
    highlightSelectedGoods(value, item) {
      const str = `${item.barcode} | ${item.title}`

      return str
        .replace(/src=|href=|script/g, '')
        .replace(
          new RegExp(`(${value})`, 'gi'),
          `<span style="color:#ea5d35">$1</span>`
        )
    },
    handleSearchBarcode: debounce(async function(value) {
      this.searchBarcodeResult = []
      if (!value) {
        return
      }

      const result = await productService.fetchUserSpu({
        filters: { barcode: value, author: 'dear-sync' }
      })

      this.searchBarcodeResult = result.data
    }, 300),

    handleSearchGoods: debounce(async function(value) {
      this.searchGoodsResult = []
      if (!value) {
        return
      }

      const result = await productService.fetchUserSpu({
        filters: { title: value, author: 'dear-sync' }
      })

      this.searchGoodsResult = result.data
    }, 300),

    handleSelectGoods(item, index) {
      this.goodsItems[index].brand = item.brand || undefined
      this.goodsItems[index].barcode = item.barcode || undefined
      this.goodsItems[index].desc = item.title || undefined

      const hscode = this.hscodeData.find(
        (categoryItem) => categoryItem.source.category === item.category
      )
      if (hscode) {
        this.goodsItems[index].hscode = hscode.value
      }
    },

    handleFilterBrand(value, option) {
      return new RegExp(value, 'i').test(option)
    },
    update_row_total(index) {
      if (this.goodsItems[index].price > 1000000) {
        this.$Message.error('输入单价过大，请重新输入')
        return
      }
      if (this.goodsItems[index].quantity > 21) {
        this.$Message.error('最大数量为20，请重新输入')
        return
      }
      let oneTotal = 0
      if (
        this.goodsItems[index].is_inc_tax === '1' ||
        this.goodsItems[index].is_inc_tax === '2'
      ) {
        oneTotal = this.goodsItems[index].price
      } else {
        oneTotal = Number(this.goodsItems[index].price * 1.2).toFixed(2)
      }
      this.goodsItems[index].total = (
        oneTotal * this.goodsItems[index].quantity
      ).toFixed(2)
    },
    getGoodsTemplate() {
      return this.goodsItems.slice(-1)[0]
    }
  }
}
</script>

<style lang="less">
.goods-info {
  padding: 50px 0;

  &.check-mode {
    .edit-item {
      i {
        display: none;
      }
    }
  }

  .ivu-select-dropdown-list {
    overflow: auto;
  }

  h1 {
    font-size: 24px;
    text-align: center;
    font-weight: normal;
    color: #3b3b3b;
    padding-bottom: 20px;
    margin-bottom: 40px;
  }
  .form {
    display: flex;
    flex-flow: column wrap;
    width: 1200px;
    margin: 0 auto;
    .bottom {
      width: 100%;
      justify-content: space-between;
      .bottom-left {
        font-size: 12px;
        // color: #eee;
      }
      .bottom-right {
        text-align: right;
        margin-right: 92px;
        .exclude {
          .ivu-form-item {
            display: flex;
            .ivu-form-item-label {
              width: 150px;
              font-size: 15px;
            }
            .ivu-input-type-text {
              width: 100px;
            }
          }
        }
      }
    }
  }
  .item {
    position: relative;
    margin-bottom: 20px;

    &.head {
      font-size: 16px;
      color: #3b3b3b;

      > span {
        margin-left: 8px;
      }
    }
    > span {
      width: 120px;
      display: inline-block;
      & + span {
        margin-left: 10px;
      }

      &:nth-child(1) {
        width: 160px;
      }
      &:nth-child(2) {
        width: 170px;
      }
      &:nth-child(3) {
        width: 140px;
      }
      &:nth-child(4) {
        width: 120px;
      }
      &:nth-child(5) {
        width: 100px;
      }
      &:nth-child(7) {
        width: 80px;
      }
    }

    .check-item {
      position: absolute;
      top: 10px;
      margin-left: 10px;
      span {
        width: 16px;
        margin: 0 5px 0 0;
      }
    }

    .ivu-select-selection {
      line-height: 40px;
    }
  }
  .edit-item {
    align-items: flex-start;
    .ivu-icon-ios-remove-circle-outline {
      font-size: 40px;
      color: #ea5d35;
      margin-right: 5px;
    }
    .ivu-icon-ios-add-circle {
      font-size: 40px;
      color: #8cc9d6;
    }
    .ivu-icon-md-basket {
      font-size: 40px;
    }
  }
}
</style>
