<template>
  <div class="index-news-wrapper">
    <div
      class="i-news-wrapper"
      :class="isMobile === false ? 'new-center-container' : ''"
    >
      <h3 class="new-title">折扣资讯</h3>
      <div class="index-news-list flexRow">
        <div v-for="(item, i) in list" :key="i" class="index-news-item">
          <a :href="`/news/${item.id}`">
            <img v-lazy="item.cover" />
            <div class="content">
              <p class="date">{{ item.created_at }}</p>
              <h6>{{ item.title }}</h6>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      list: []
    }
  },
  mounted() {
    this.fetchList()
  },
  methods: {
    async fetchList() {
      const res = await this.$api.fetchNews()

      this.list = res.data.map((item) => ({
        ...item,
        created_at: this.$dayjs(item.created_at).format('YYYY/MM/DD HH:mm:ss')
      }))
    }
  }
}
</script>

<style lang="less">
.index-news-wrapper {
  margin-bottom: 70px;
  .new-title {
    margin-bottom: 40px;
  }
  .index-news-list {
    flex-wrap: wrap;
    .index-news-item {
      width: 350px;
      height: 350px;
      overflow: hidden;
      margin-bottom: 30px;
      margin-top: 30px;
      border-radius: 6px;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.08);
      border: solid 1px rgba(0, 0, 0, 0.1);
      background-color: #fff;
      position: relative;

      transition: 0.35s, transform 0.35s;

      &:hover {
        transform: translateY(-30px);
      }

      &:not(:nth-child(3n)) {
        margin-right: 30px;
      }
      a {
        display: block;
        img {
          width: 348px;
          height: 220px;
          border-radius: 6px;
          object-fit: cover;
        }
        .content {
          padding: 0 20px;

          .date {
            padding: 20px 0;
            line-height: 20px;
            color: #999;
            font-size: 14px;
          }
          h6 {
            font-size: 18px;
            line-height: 25px;
            color: #000;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 640px) {
  .index-news-wrapper {
    padding-left: 14px;
    padding-right: 14px;
    .new-title {
      margin-bottom: 20px;
    }
    .index-news-list {
      flex-wrap: nowrap;
      overflow: auto;
      .index-news-item {
        flex-shrink: 0;
        width: 222px;
        height: 258px;
        a {
          display: block;
          line-height: 0;
          img {
            width: 220px;
            height: 140px;
            border-radius: 6px;
            background: #eee;
          }
          .content {
            p.date {
              padding: 15px 0;
            }
          }
        }
      }
    }
  }
}
</style>
