<template>
  <ArticleLayout color="#98cab2" title="售后小贴士" :data="textData" />
</template>

<script>
import ArticleLayout from '~/components/ArticleLayout'
export default {
  layout: 'default',
  head() {
    return {
      title: '售后小贴士'
    }
  },
  components: {
    ArticleLayout
  },
  asyncData() {
    return {
      textData: '暂无内容'
    }
  }
}
</script>

<style lang="less"></style>
