<template>
  <div class="index-service-wrapper">
    <div
      class="i-service-content"
      :class="{ 'new-center-container': isMobile === false }"
    >
      <h3 class="new-title">我们的服务</h3>
      <ul class="flexRow">
        <li class="flexColumn">
          <span><IconFont size="40" name="tax" color="#e56131"/></span>
          <p>最高20%海淘退税</p>
        </li>
        <li class="flexColumn">
          <span><IconFont size="40" name="credit-card" color="#e56131"/></span>
          <p v-if="!isMobile">多渠道跨境支付·即时到账</p>
          <p v-if="isMobile" class="special">多渠道跨境支付<br />即时到账</p>
        </li>
        <li class="flexColumn">
          <span><IconFont size="40" name="plane" color="#e56131"/></span>
          <p>专业跨国快递物流</p>
        </li>
        <li class="flexColumn">
          <span><IconFont size="40" name="replace" color="#e56131"/></span>
          <p>一站式转运·出口·报关</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import IconFont from '../../iconfont/index.vue'
export default {
  components: {
    IconFont
  },
  props: {
    isMobile: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="less">
.index-service-wrapper {
  .new-title {
    margin-bottom: 72px;
  }
  ul {
    justify-content: space-around;
    li {
      width: 255px;
      height: 150px;
      border-radius: 5px;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.08);
      border: solid 1px rgba(0, 0, 0, 0.1);
      background-color: #fff;
      justify-content: center;
      align-items: center;

      span {
        display: inline-block;
        width: 58px;
        height: 58px;
        border-radius: 28px;
        border: solid 1px #e56131;
        margin-bottom: 15px;
        text-align: center;
        line-height: 58px;
        background: #fbf6f4;
      }
      p {
        font-size: 16px;
        font-weight: 300;
        color: #000;
      }

      &:hover {
        box-shadow: 0 2px 6px 0 rgba(229, 97, 49, 0.2);
        border: solid 1px #e56131;
      }
    }
  }
}
@media screen and (max-width: 640px) {
  .index-service-wrapper {
    .new-title {
      margin-bottom: 52px;
    }
    ul {
      justify-content: flex-start;
      flex-wrap: wrap;
      padding: 0 14px;
      li {
        width: 164px;
        height: 150px;
        margin-bottom: 20px;
        span {
          margin-bottom: 10px;
        }
        p {
          font-size: 14px;
          font-weight: normal;
          text-align: center;
          height: 40px;
          line-height: 40px;

          &.special {
            line-height: 20px;
          }
        }
        &:not(:nth-child(2n)) {
          margin-right: 16px;
        }
      }
    }
  }
}
</style>
