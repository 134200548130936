<template>
  <div class="m-upload-container min-h-[100vh] bg-[#fff] p-[20px] pb-[40px]">
    <h1 class="text-[24px] text-[#333]">iShopper 图片同步</h1>
    <p class="text-muted mb-[20px] text-[12px]">
      将图片发送到您<strong>当前浏览</strong>的电脑页面
    </p>

    <UploadList
      class="upload-list-wrapper"
      field-name="file"
      :data="{
        save_path: save_path
      }"
      :default-list="defaultList"
      :on-success="handleSuccess"
      :action-api="API_URL"
    >
      <template v-slot:item="{ data }">
        <div
          v-if="data.status === 'finished'"
          class="mt-[-20px] mb-[20px] p-[10px] pb-0 flex justify-between items-center text-[#555]"
        >
          <span>{{ data.name }}</span>
          <span class="flex-none">
            <a @click="() => debounceSend(data)">
              <Icon type="ios-share-alt-outline" /> 再次发送
            </a>
          </span>
        </div>
      </template>
    </UploadList>

    <div
      class="fixed bottom-0 left-0 h-[20px] leading-[20px] bg-white pb-[40px] w-full text-muted mt-[80px] text-[12px] text-center"
    >
      © 2024 iShopper Copyright. All rights reserved.
    </div>
  </div>
</template>

<script>
import { onMounted, reactive } from '@vue/composition-api'
import debounce from 'lodash/debounce'
import { useWs } from '~/use/useWs'
import UploadList from '~/components/UploadList.vue'
import { useVm } from '~/use'

export default {
  layout: 'empty',
  head() {
    return {
      title: 'Sync'
    }
  },
  components: {
    UploadList
  },
  setup() {
    const ws = useWs()
    const vm = useVm()
    const query = vm.$route.query
    const { token, save_path, uid } = query

    const API_URL = `${process.env.API_URL}/api/upload/`

    const defaultList = reactive([])

    const send = ({ url, name }) => {
      ws.emit('room_msg', {
        room_id: uid,
        type: 'image',
        data: {
          url: url.split('?')[0],
          name
        }
      })
      vm.$Message.success('已发送')
    }

    const debounceSend = debounce(send, 1500, {
      leading: true
    })

    const handleSuccess = (data, file) => {
      file.name = file.name || data.name
      file.url = `${data.url}?x-oss-process=image/resize,w_600`
      send({ url: data.url, name: file.name })
    }

    onMounted(() => {
      vm.$store.commit('SET_TOKEN', token)
    })

    return {
      save_path,
      API_URL,
      debounceSend,
      handleSuccess,
      defaultList,
      send
    }
  }
}
</script>

<style lang="less">
html {
  font-size: 0.3rem;
}

.m-upload-container {
  .upload-list-wrapper {
    display: block;
    .ivu-poptip-confirm {
      display: none !important;
    }

    .upload-list-cover,
    .upload-list {
      i {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .upload-list {
      width: 100%;
      height: initial;
      min-height: 100px;
      margin: 0 0 20px 0;
    }

    .ivu-upload {
      width: 100%;
      display: inline-block;
      margin: 0;

      .upload-list {
        margin: 0;
      }
    }
  }
}
</style>
