<template>
  <div class="landing-footer">
    <div class="landing-footer-menu">
      <div class="container flexRow">
        <div class="desc flexRow">
          <div class="desc-img-box">
            <img src="../../assets/images/common/qrcode.png" />
          </div>
          <div class="desc-text">
            ←关注iShopper公众号<br />随时获取第一手资讯及打折信息！
          </div>
        </div>
        <ul class="menus flexRow">
          <li class="flexColumn" @click="kfHandler">
            <div class="menus-img-box flexRow">
              <img src="../../assets/images/landing/message.svg" />
            </div>
            <p>在线客服</p>
          </li>
          <li class="flexColumn" @click="yjHandler">
            <div class="menus-img-box flexRow">
              <img src="../../assets/images/landing/pencil.svg" />
            </div>
            <p>意见反馈</p>
          </li>
          <li class="flexColumn" @click="questionHandler">
            <div class="menus-img-box flexRow">
              <img src="../../assets/images/landing/help.svg" />
            </div>
            <p>常见问题</p>
          </li>
          <li class="flexColumn" @click="concatUs">
            <div class="menus-img-box flexRow">
              <img src="../../assets/images/landing/phone.svg" />
            </div>
            <p>联系我们</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="landing-footer-bottom">
      <div class="container flexRow">
        <span class="left">© iSHOPPER 2021</span>
        <div class="copyright">
          <nuxt-link to="/terms-conditions">TERMS AND CONDITIONS</nuxt-link>
          <span>/</span>
          <nuxt-link to="/privacy-policy">PRIVACY POLICY</nuxt-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    kfHandler() {
      const user = this.$store.state.user
      if (user) {
        window._MEIQIA('metadata', {
          id: user.id,
          email: user.email,
          username: user.username,
          phone: user.phone
        })
      }

      window._MEIQIA('showPanel')
    },
    yjHandler() {
      window.open(
        'https://form.asana.com/?k=SATRLzvzK8u0F8UA5bUDkA&d=1183493859185320'
      )
    },
    questionHandler() {
      this.$router.push('/qa')
    },
    concatUs() {
      this.$router.push('/contact')
    }
  }
}
</script>

<style lang="less">
.landing-footer {
  background: #000;
  .landing-footer-menu {
    border: 1px solid rgba(255, 255, 255, 0.1);
    padding: 55px 0;
    justify-content: space-between;
    align-items: center;
    .container {
      justify-content: space-between;
      align-items: center;
      .desc {
        align-items: center;
        .desc-img-box {
          width: 100px;
          height: 100px;
          background: #fff;
          padding: 10px;
          margin-right: 20px;
          border-radius: 6px;
        }
        .desc-text {
          font-size: 14px;
          color: #fff;
          line-height: 30px;
        }
      }
      .menus {
        li {
          margin-left: 50px;
          color: #fff;
          align-items: center;
          cursor: pointer;

          &:hover {
            color: #e56131;

            .menus-img-box {
              background: #e56131;
            }
          }

          .menus-img-box {
            width: 40px;
            height: 40px;
            border-radius: 20px;
            background: rgba(255, 255, 255, 0.2);
            margin-bottom: 10px;
            align-items: center;
            justify-content: center;
            img {
              width: 18px;
              height: 18px;
            }
          }
        }
      }
    }
  }
  .landing-footer-bottom {
    line-height: 60px;
    .container {
      justify-content: space-between;
      .left {
        color: rgba(255, 255, 255, 0.3);
      }
      .copyright {
        a {
          color: rgba(255, 255, 255, 0.3);
          &:hover {
            color: #fff;
          }
        }
        span {
          margin: 0 20px;
        }
      }
    }
  }
}
@media screen and (max-width: 640px) {
  .landing-footer {
    .landing-footer-menu {
      padding: 0;
      .container {
        padding-top: 40px;
        flex-wrap: wrap;
        justify-content: center;
        .desc {
          flex-direction: column;
          .desc-text {
            margin-top: 10px;
            text-align: center;
          }
        }
        .menus {
          justify-content: space-between;
          width: 100%;
          margin-top: 40px;
          li {
            margin-left: 0;
            width: 25%;
          }
        }
      }
    }
    .landing-footer-bottom {
      line-height: 40px;
      .container {
        flex-direction: column;
        flex-wrap: wrap;
        align-content: center;
        text-align: center;
        .copyright {
          a {
            margin-left: 0;
          }
          span {
            margin: 0 10px;
          }
        }
      }
    }
  }
}
</style>
