<template>
  <register readonly />
</template>

<script>
import register from './register'

export default {
  components: {
    register
  }
}
</script>

<style lang="scss"></style>
