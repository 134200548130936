<template>
  <div class="container">
    <div class="text-center pt-[50px]">
      <Icon
        class="text-green-500 text-[70px]  mb-[15px]"
        type="ios-checkmark-circle"
      />
      <h1 class="mb-[30px]">
        {{ isBalance ? '您的返佣提现已到账' : '您的返佣提现申请已提交成功' }}
      </h1>
    </div>
    <div class="bg-[#f8f8fa] text-[#555] p-[30px] rounded leading-10 mb-[60px]">
      <p>
        * 如果您是申请提现到 iShopper 预订金余额，将<strong>立即到账</strong>
      </p>
      <p>
        *
        如果您是申请提现到个人银行卡，iShopper将在<strong>3-5个工作日</strong>内为您转账成功
      </p>
      <p>
        * 在审核成功之后，您的发票可以在<strong>站内信</strong>下载获取
      </p>
    </div>

    <div class="text-center mb-[60px]">
      <Button type="primary" to="/account">返回</Button>
    </div>
  </div>
</template>

<script>
import { useVm } from '~/use'

export default {
  setup() {
    const vm = useVm()
    const query = vm.$route.query
    const isBalance = vm.$ENUM.RECEIVING_TYPE_MAP.balance === Number(query.type)

    return {
      isBalance
    }
  }
}
</script>

<style lang="scss" scoped></style>
