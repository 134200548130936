<template>
  <div class="bussiness-wrapper-step2">
    <div class="container">
      <div class="title">
        <h3>我们的服务</h3>
        <span class="title-bottom-border" />
      </div>
      <div class="service-list">
        <ul class="flexRow">
          <li>
            <img src="../../../assets/images/landing/tax.svg" />
            <p>最高20%海淘退税</p>
          </li>
          <li>
            <img src="../../../assets/images/landing/credit-card.svg" />
            <p>多渠道跨境支付·即时到账</p>
          </li>
          <li>
            <img src="../../../assets/images/landing/plane.svg" />
            <p>专业跨国快递物流</p>
          </li>
          <li>
            <img src="../../../assets/images/landing/replace.svg" />
            <p>一站式转运·出口·报关</p>
          </li>
        </ul>
      </div>
      <div class="border-title">
        <span>一站式退税</span>
      </div>
      <div class="liucheng-list">
        <ul class="flexRow">
          <li class="flexRow">
            <div class="lc-img-box flexRow">
              <img src="../../../assets/images/landing/user-check.svg" />
            </div>
            <div class="lc-item-content">
              <h6>1 注册账户</h6>
              <p>完成注册并获取iShopper专属VISA消费卡</p>
            </div>
          </li>
          <li class="flexRow">
            <div class="lc-img-box flexRow">
              <img src="../../../assets/images/landing/shopping-cart.svg" />
            </div>
            <div class="lc-item-content">
              <h6>2 购物</h6>
              <p>使用消费卡在英国购物网站或实体店进行购物</p>
            </div>
          </li>
          <li class="flexRow">
            <div class="lc-img-box flexRow">
              <img src="../../../assets/images/landing/cloud-upload.svg" />
            </div>
            <div class="lc-item-content">
              <h6>3 上传凭证</h6>
              <p>使用账户进行管理消费记录，上传购物凭证，审核后开启退税申请</p>
            </div>
          </li>
          <li class="flexRow">
            <div class="lc-img-box flexRow">
              <img src="../../../assets/images/landing/truck.svg" />
            </div>
            <div class="lc-item-content">
              <h6>4 邮寄并退税</h6>
              <p>自行创建物流订单，定制个性化退税方案</p>
            </div>
          </li>
          <li class="flexRow">
            <div class="lc-img-box flexRow">
              <img src="../../../assets/images/landing/square-check.svg" />
            </div>
            <div class="lc-item-content">
              <h6>5 退税成功</h6>
              <p>物流投递成功，小票审核后进入退税流程，获得退税</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less">
.bussiness-wrapper-step2 {
  background: #ebedf0;
  .container {
    max-width: 1440px;
    background: #ebedf0 url('./triangle.svg') no-repeat 0 0;

    padding-top: 80px;
    padding-bottom: 80px;

    .service-list {
      padding: 0 55px;
      ul {
        justify-content: space-between;
        padding: 80px 0;

        li {
          text-align: center;

          p {
            margin-top: 20px;
            font-size: 16px;
            font-weight: 500;
          }
        }
      }
    }

    .border-title {
      padding-bottom: 80px;
      position: relative;
      span {
        display: inline-block;
        padding: 0 20px;
        position: relative;
        background: #e8eaec;
        z-index: 1;
      }
      &::after {
        display: block;
        content: '';
        width: 100%;
        height: 1px;
        background: rgba(0, 0, 0, 0.1);
        position: absolute;
        top: 12px;
      }
    }

    .liucheng-list {
      ul {
        justify-content: space-between;
        li {
          .lc-img-box {
            width: 28px;
            height: 28px;
            border-radius: 14px;
            background: #e56131;
            justify-content: center;
            align-items: center;
            box-shadow: 0 0 4px 4px rgba(229, 97, 49, 0.2);
            img {
              width: 18px;
              height: 18px;
            }
          }
          .lc-item-content {
            margin-left: 15px;
            h6 {
              font-size: 16px;
              font-weight: 500;
              line-height: 22px;
              padding-bottom: 7px;
            }
            p {
              display: inline-block;
              width: 123px;
              line-height: 24px;
              font-size: 12px;
              color: rgba(0, 0, 0, 0.5);
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 640px) {
  .bussiness-wrapper-step2 {
    .container {
      padding-top: 40px;
      padding-bottom: 0px;
      background: #fafafa url('./triangle-light.svg') no-repeat center -5px;

      .service-list {
        padding: 0;
        ul {
          flex-wrap: wrap;
          padding: 37px;
          li {
            width: 100%;
            p {
              margin-bottom: 40px;
            }
          }
        }
      }
      .border-title {
        margin-bottom: 40px;
        padding-bottom: 0;
        span {
          background: #f5f5f5;
        }
      }
      .liucheng-list {
        ul {
          flex-wrap: wrap;
          li {
            flex-direction: column;
            width: 140px;
            margin-bottom: 40px;
            align-items: center;
            &:last-child {
              position: relative;
              left: 50%;
              margin-left: -25%;
            }
            .lc-item-content {
              margin-left: 0;
              text-align: center;
              h6 {
                padding: 10px 0;
              }
              p {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
</style>
