<template>
  <div class="news-detail-container">
    <div class="header">
      <div class="container">
        <a href="javascript:" @click="goBack">
          <Icon type="ios-arrow-back" /> 返回
        </a>
      </div>
    </div>

    <div class="container main">
      <div class="news-header">
        <h1>{{ newsItem.title }}</h1>
        <span>{{ $dayjs(newsItem.created_at).format('YYYY-MM-DD') }}</span>
      </div>

      <RichText :data="newsItem.content" />
    </div>
  </div>
</template>

<script>
import RichText from '~/components/RichText'
export default {
  head() {
    return {
      title: '站内信消息'
    }
  },
  components: {
    RichText
  },
  async asyncData({ route, $api, app }) {
    const { data } = await $api.readMessage({ id: route.params.id })
    app.head.title = data.message.title

    return {
      newsItem: {
        ...data.message,
        created_at: data.created_at
      }
    }
  },
  mounted() {
    this.$api.readMessage({ id: this.$route.params.id })
  },
  methods: {
    goBack() {
      window.history.back()
    }
  }
}
</script>

<style lang="less">
.news-detail-container {
  .container {
    width: 910px;
  }

  .header {
    height: 65px;
    line-height: 65px;
    background: #ea6935;
    a {
      font-size: 16px;
      color: #fff;
    }
  }

  .main {
    padding: 50px 0;
  }

  .news-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h1 {
      font-size: 26px;
      color: #3b3b3b;
      font-weight: normal;

      & + span {
        font-size: 20px;
        color: #999999;
      }
    }
  }
}
</style>
