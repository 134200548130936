<template>
  <div class="new-actions">
    <div class="new-actions__tbg" />
    <div class="new-actions__bbg" />
    <div class="new-action__main">
      <div class="new-acion-box">
        <nuxt />
      </div>
      <div class="new-action-bottom">
        <div class="box">
          © 2024 Copyright. All rights reserved.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import '../assets/reversion.less'
import { changeToPadViewPort } from '~/utils'
import { detect } from '~/utils/detect'
export default {
  head() {
    return {
      link: [
        {
          rel: 'canonical',
          href: 'https://www.ishopper.com' + this.$route.path
        }
      ]
    }
  },
  watch: {
    $route() {
      if (detect(window.navigator.userAgent).isPad) {
        changeToPadViewPort()
      }
    }
  },
  mounted() {
    if (detect(window.navigator.userAgent).isPad) {
      changeToPadViewPort()
    }
  }
}
</script>

<style lang="less">
.new-actions {
  width: 100%;
  min-width: 1140px;
  min-height: 100vh;
  position: relative;
  .new-actions__tbg {
    width: 303px;
    height: 286px;
    position: absolute;
    left: 0;
    top: 0;
    background: url('../assets/images/common/login-bg.svg') no-repeat right
      bottom;
  }
  .new-actions__bbg {
    width: 303px;
    height: 286px;
    position: absolute;
    right: 0;
    bottom: 0;
    background: url('../assets/images/common/login-bg.svg') no-repeat left top;
  }
  .new-action__main {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    .new-acion-box {
      min-height: calc(100% - 60px);
    }
    .new-action-bottom {
      padding: 20px 0;
      .box {
        box-sizing: border-box;
        height: 20px;
        line-height: 20px;

        font-size: 12px;
        color: #222;
        font-size: 12px;
        text-align: center;
        opacity: 0.5;
      }
    }
  }
}
@media screen and (max-width: 640px) {
  .new-actions {
    .new-actions__tbg,
    .new-actions__bbg {
      width: 281px;
      height: 270px;
    }
  }
}
</style>
