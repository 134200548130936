<template>
  <i
    :class="`iconfont icon-${name} ${reverse ? `reverse-${reverse}` : ''}`"
    :style="{
      fontSize: `${size}px`,
      color: color
    }"
  />
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    reverse: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped lang="less">
@import url('./index.less');
.reverse-x {
  transform: rotate(180deg);
}
.reverse-y {
  transform: rotate(360deg);
}
.reverse-left {
  transform: rotate(-90deg);
}
.reverse-right {
  transform: rotate(90deg);
}
</style>
