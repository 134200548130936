<template>
  <transition name="cookie-slide">
    <div v-if="visible" class="cookie-policy">
      <p>
        iSHOPPER use cookies to ensure you get the best experience on our
        website.
        <Nuxt-link to="/privacy-policy">Learn More</Nuxt-link>
      </p>
      <button @click="handleAccept">Accept & continue</button>
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      visible: false
    }
  },
  mounted() {
    this.visible = !localStorage.getItem('accept-cookie')
  },
  methods: {
    handleAccept() {
      localStorage.setItem('accept-cookie', 1)
      this.visible = false
    }
  }
}
</script>

<style lang="less">
.cookie-policy.cookie-slide-enter-active,
.cookie-policy.cookie-slide-leave-active {
  bottom: 20px;
  transition: bottom 1.2s;
}
.cookie-policy.cookie-slide-enter,
.cookie-policy.cookie-slide-leave-to {
  bottom: -100%;
}

.cookie-policy {
  position: fixed;
  padding: 15px 12px;
  width: 240px;
  border-radius: 8px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
  background: #fff;
  display: flex;
  bottom: 20px;
  right: 15px;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: 0.9;

  p {
    display: inline-block;
    margin: 0 0 15px 0;
  }

  button {
    color: #fff;
    border: none;
    border-radius: 12px;
    padding: 3px 8px;
    font-size: 12px;
    background: #e35e39;
    cursor: pointer;
  }
}
</style>
