<template>
  <i
    :class="`iconfont-color icon-color-${name}`"
    :style="{
      fontSize: `${size}px`
    }"
  />
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped lang="less">
@import url('./color.less');
</style>
