<template>
  <div class="data-table-container">
    <Table
      ref="tableRef"
      :loading="!initd"
      :data="data"
      v-bind="$attrs"
      border
      stripe
      @on-sort-change="sortChange"
    />
    <div v-if="initd && !hidePage" class="pager">
      <Page :total="count" :current.sync="page" @on-change="handleChange" />
    </div>
  </div>
</template>

<script>
const PER_PAGE = 10

export default {
  inheritAttrs: false,
  props: {
    whenFetch: {
      type: Function,
      required: true
    },
    hidePage: {
      type: Boolean,
      default: false
    },
    auto: {
      type: Boolean,
      default: true
    },
    sortChange: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      initd: false,
      page: 1,
      curPerPage: PER_PAGE,
      count: 0,
      data: []
    }
  },
  async mounted() {
    if (this.auto) {
      try {
        await this.fetchData()
      } catch (err) {
        console.error(err)
      }
    }

    this.initd = true
  },
  methods: {
    async fetchData(page = 1, perPage = PER_PAGE) {
      page = Number(page)
      try {
        const { data = [], paginator = {} } = await this.whenFetch(
          page,
          perPage
        )

        this.perPage = paginator.per_page
        this.count = paginator.count
        this.page = page
        this.data = data
      } catch (err) {
        console.error(err)
      }
    },
    refresh() {
      return this.fetchData(this.page, this.curPerPage)
    },
    handleChange(page) {
      return this.fetchData(page)
    }
  }
}
</script>

<style lang="less">
.data-table-container {
  .pager {
    display: flex;
    justify-content: flex-end;
    padding: 20px 0;
  }
}
</style>
