<script>
import { cloneDeep, pick, omit } from 'lodash'

import dayjs from 'dayjs'
import FormRender from '~/components/FormRender'

export default {
  inheritAttrs: false,
  props: {
    onSubmit: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      model: pick(cloneDeep(this.$store.state.user), [
        'email',
        'country',
        'province',
        'city',
        'county',
        'street1',
        'street2',
        'street3',
        'postcode',
        'phone',
        'id_type',
        'id_number',
        'id_pinyin',
        'id_expired',
        'company_name',
        'company_address',
        'company_number',
        'company_vat'
      ])
    }
  },
  computed: {
    addressStore() {
      return this.$store.address
    }
  },
  methods: {
    handleSubmit() {
      this.loading = true
      this.$refs.form.validate(async (isPass) => {
        if (!isPass) {
          this.loading = false
          return
        }

        try {
          if (this.model.id_expired) {
            this.model.id_expired = dayjs(this.model.id_expired).format(
              'YYYY-MM-DD'
            )
          }

          await this.$api.updateUserInfo(omit(this.model, ['phone']))
          this.onSubmit()
        } catch (err) {
          console.error(err)
        }

        this.loading = false
      })
    }
  },
  render() {
    const { model } = this
    const rowItems1 = [
      {
        col: 7,
        prop: 'id_type',
        label: '证件类型',
        node: (
          <Select v-model={model.id_type}>
            <Option value={0}>身份证</Option>
            <Option value={1}>护照</Option>
          </Select>
        ),
        rules: {
          required: true,
          message: '请选择证件类型'
        }
      },
      {
        col: 9,
        prop: 'id_number',
        label: '证件号码',
        node: <Input v-model={model.id_number} maxlength="20" />,
        rules: [
          {
            required: true,
            trigger: 'blur',
            message: '请输入证件号码'
          }
        ]
      },
      {
        col: 8,
        prop: 'id_expired',
        label: '证件有效期',
        node: (
          <DatePicker
            type="date"
            v-model={model.id_expired}
            placeholder="选择证件到期日期"
          />
        ),
        rules: []
      }
    ]

    const rowItems2 = [
      {
        render: <Address v-model={this.model} />
      },
      {
        render: <div class="divide" />
      },
      {
        col: 12,
        prop: 'email',
        label: '联系邮箱',
        node: <Input v-model={model.email} maxlength="30" />,
        rules: {
          type: 'email',
          required: true,
          trigger: 'blur',
          pattern: /^[A-Za-zd]+([-_.][A-Za-zd]+)*@([A-Za-zd]+[-.])+[A-Za-zd]{2,5}$/g,
          message: '请输入正确的邮箱'
        }
      },
      {
        col: 12,
        prop: 'phone',
        label: '联系电话',
        node: <Input v-model={model.phone} disabled />,
        rules: {
          required: true,
          trigger: 'blur',
          message: '请输入联系电话'
        }
      }
    ]

    const rowItems3 = [
      {
        col: 12,
        prop: 'company_name',
        label: '公司名称',
        node: <Input v-model={model.company_name} maxlength="50" />
      },
      {
        col: 12,
        prop: 'company_address',
        label: '公司地址',
        node: <Input v-model={model.company_address} maxlength="50" />
      },
      {
        col: 12,
        prop: 'company_number',
        label: '公司登记号',
        node: <Input v-model={model.company_number} maxlength="50" />
      },
      {
        col: 12,
        prop: 'company_vat',
        label: 'VAT税号',
        node: <Input v-model={model.company_vat} maxlength="50" />
      }
    ]

    return (
      <div>
        <Form
          ref="form"
          props={{ model }}
          label-position="right"
          label-width={140}
          class="user-verify-step1"
        >
          <div class="rows">
            <FormRender rows={rowItems1} />
          </div>
          <div class="rows-even">
            <div class="rows">
              <FormRender rows={rowItems2} />
            </div>
          </div>
          <div class="rows address-info">
            <h2>注：以公司名义注册请填写以下公司信息</h2>
            <FormRender rows={rowItems3} />
            <div class="divide" />
            <FormItem class="actions">
              <Button
                loading={this.loading}
                onClick={this.handleSubmit}
                type="primary"
              >
                确认提交
              </Button>
            </FormItem>
          </div>
        </Form>
      </div>
    )
  }
}
</script>

<style lang="less">
.user-verify-step1 {
  margin: 50px auto 50px;

  .rows {
    width: 1010px;
    margin: 0 auto;
  }

  .rows-even {
    width: 100%;
    background: #fbfbfb;
    padding: 40px 0 20px;
  }

  .divide {
    margin: 0 0 25px;
    background: #ddd;
  }

  .address-info {
    padding: 40px 0 0;

    h2 {
      text-align: center;
      font-size: 12px;
      color: #000000;
      opacity: 0.5;
      margin-bottom: 40px;
    }
  }

  .actions {
    text-align: right;
  }
}
</style>
