<template>
  <div class="new-qa-kefu new-center-container">
    <div class="qa-kefu-box flexColumn">
      <h5>仍有疑问？</h5>
      <p class="desc">欢迎联系在线客服进行咨询。</p>
      <RButton size="middle" type="primary"
        >联系客服支持<IconFont size="30" name="arrow-right"
      /></RButton>
    </div>
  </div>
</template>

<script>
import RButton from '../../components/revision/RButton'
import IconFont from '../../iconfont'
export default {
  components: {
    IconFont,
    RButton
  }
}
</script>

<style lang="less">
.new-qa-kefu {
  box-sizing: border-box;
  height: 348px;
  border-radius: 5px;
  background-color: #fff;
  position: relative;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.08);
  margin-bottom: 100px;

  &::before {
    width: 227px;
    height: 251px;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    background: url('../../assets/images/new-qa/left.png') no-repeat right
      bottom;
    background-size: 223%;
  }
  &::after {
    width: 232px;
    height: 260px;
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    background: url('../../assets/images/new-qa/right.png') no-repeat left top;
    background-size: 223%;
  }
  .qa-kefu-box {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    h5 {
      font-size: 24px;
      font-weight: 600;
      color: #000;
      margin-bottom: 20px;
    }
    .desc {
      font-size: 14px;
      color: #000;
      margin-bottom: 20px;
    }
    .img_box {
      margin-top: 20px;
      box-sizing: border-box;
      padding: 5px;
      border-radius: 6px;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.08);
      border: solid 1px rgba(0, 0, 0, 0.1);
      background-color: #fff;
      width: 88px;
      height: 88px;
      margin-bottom: 10px;
    }
    .img-desc {
      color: #222;
      font-size: 12px;
    }
  }
}
@media screen and (max-width: 640px) {
  .new-qa-kefu {
    padding: 0;
    margin: 0 14px 100px;

    &::before {
      width: 167px;
      height: 192px;
    }
    &::after {
      width: 127px;
      height: 160px;
    }
  }
}
</style>
