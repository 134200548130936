<template>
  <div class="btns-wrapper">
    <div class="btns-pc">
      <div class="btns-two flexRow">
        <a href="/register" class="btns-two-left"
          >点击立即免费注册 <img src="../../assets/images/landing/Icon.svg"
        /></a>
        <a href="javascript:;" class="btns-two-right">
          开启海淘购物省钱之旅
        </a>
      </div>
      <div class="btns-one">
        <a href="javascript:;">现在注册立享最高20%退税优惠</a>
      </div>
    </div>

    <div class="btns-wap">
      <div class="btn-top">
        <a href="/register">
          <p class="one">开启海淘购物省钱之旅</p>
          <p class="two">现在注册立享最高20%退税优惠</p>
        </a>
      </div>
      <div class="btn-bottom">
        <a href="/register">
          点击立即免费注册<img src="../../assets/images/landing/Icon.svg" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less">
.btns-wrapper {
  .btns-wap {
    display: none;
  }
  .btns-two {
    border-radius: 6px;
    height: 52px;
    line-height: 52px;
    border: 1px solid #e56131;
    width: 444px;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.08);

    a {
      width: 50%;
      text-align: center;
      cursor: pointer;
      font-weight: 500;
      font-size: 16px;

      &.btns-two-left {
        background: #e56131;
        border-right: 1px solid #e56131;
        color: #fff;
        &:hover {
          background: #d95627;
        }
      }
      &.btns-two-right {
        cursor: default;
        color: #e56131;
      }
    }
  }
  .btns-one {
    width: 444px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    border: 1px dashed #000000;
    border-radius: 6px;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.08);
    background: #fff;
    a {
      cursor: default;
      color: #000;
      font-size: 14px;
      font-weight: bold;
    }
  }
}
@media screen and (max-width: 640px) {
  .btns-wrapper {
    width: 100%;
    .btns-pc {
      display: none;
    }
    .btns-wap {
      display: block;

      .btn-top {
        text-align: center;
        padding: 20px;
        border: 1px dashed #e56131;
        border-radius: 6px;
        margin-bottom: 20px;
        font-weight: 600;

        .one {
          padding-bottom: 20px;
          color: #e56131;
          font-size: 16px;
        }
        .two {
          color: #000;
          font-size: 14px;
        }
      }
      .btn-bottom {
        margin: 0 auto;
        width: 60%;
        font-size: 16px;
        border-radius: 6px;
        text-align: center;
        background: #e56131;
        font-size: 16px;
        height: 45px;
        line-height: 45px;
        a {
          color: #fff;
          font-weight: bold;
          img {
            margin-left: 10px;
          }
        }
      }
    }
  }
}
</style>
