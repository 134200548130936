<template>
  <div class="index-reim-wrapper new-pt-60 new-pb-60">
    <div
      class="i-reim-content"
      :class="{ 'new-center-container': isMobile === false }"
    >
      <h3 class="new-title">一站式退税</h3>
      <div class="index-reim-list">
        <ul class="flexRow">
          <li class="flexColumn">
            <div><IconFont size="24" name="user-check" color="#0a7aff" /></div>
            <h4>注册用户</h4>
            <p>完成注册并获取 <br />iShopper专属VISA消费卡</p>
          </li>
          <li class="flexColumn">
            <div>
              <IconFont size="24" name="shopping-cart" color="#e56131" />
            </div>
            <h4>购物</h4>
            <p>使用消费卡在英国购物网站或<br />实体店进行购物</p>
          </li>
          <li class="flexColumn">
            <div>
              <IconFont size="24" name="cloud-upload" color="#34c759" />
            </div>
            <h4>上传凭证</h4>
            <p>
              使用账户进行管理消费记录，<br />上传购物凭证，审核后开启退<br />税申请
            </p>
          </li>
          <li class="flexColumn">
            <div><IconFont size="24" name="truck" color="#ff3b30" /></div>
            <h4>邮寄并退税</h4>
            <p>自行创建物流订单，定制个性<br />化退税方案</p>
          </li>
          <li class="flexColumn">
            <div>
              <IconFont size="24" name="square-check" color="#af52de" />
            </div>
            <h4>退税成功</h4>
            <p>物流投递成功，小票审核后进入退税流程，获得退税</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import IconFont from '../../iconfont/index.vue'
export default {
  components: {
    IconFont
  },
  props: {
    isMobile: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="less">
.index-reim-wrapper {
  box-shadow: inset 0 -1px 0 0 rgba(229, 97, 49, 0.05);
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(251, 246, 244, 0.3)
  );
  .i-reim-content {
    .new-title {
      margin-bottom: 72px;
    }
    ul {
      justify-content: space-around;
      li {
        width: 160px;
        align-items: center;
        position: relative;
        &:not(:last-child)::after {
          width: 160px;
          content: '';
          border-top: dashed 1px rgba(0, 0, 0, 0.2);
          opacity: 0.8;
          position: absolute;
          top: 20px;
          right: -110px;
        }
        div {
          width: 36px;
          height: 36px;
          border-radius: 6px;
          box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.08);
          border: solid 1px rgba(0, 0, 0, 0.1);
          text-align: center;
          line-height: 36px;
        }
        h4 {
          font-size: 16px;
          margin: 10px 0;
          color: #000;
        }
        p {
          font-size: 12px;
          color: #999;
          text-align: center;
        }
      }
    }
  }
}
@media screen and (max-width: 640px) {
  .index-reim-wrapper {
    padding: 40px 0;
    .i-reim-content {
      .new-title {
        margin-bottom: 52px;
      }
      .index-reim-list {
        overflow: auto;
        ul {
          width: 1000px;
          li {
            &:not(:last-child)::after {
              width: 150px;
              right: -95px;
            }
          }
        }
      }
    }
  }
}
</style>
