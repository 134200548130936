<template>
  <div class="upload-container container">
    <h1 class="title-line"><span>采购票据上传</span></h1>
    <div>
      <Row class="summary">
        <i-col span="5">
          <div>ID</div>
          <div>{{ detail.id }}</div>
        </i-col>
        <i-col span="8">
          <div>采购时间</div>
          <div>
            {{ $dayjs(detail.purchase_time).format('YYYY/MM/DD HH:mm') }}
          </div>
        </i-col>
        <i-col span="8">
          <div>状态</div>
          <div>{{ $ENUM.PURCHASE_TYPE[detail.status] }}</div>
        </i-col>
        <i-col span="5">
          <div>持卡人</div>
          <div>{{ detail.card.name }}</div>
        </i-col>
        <i-col span="11">
          <div>消费卡</div>
          <div>{{ detail.card.number }}</div>
        </i-col>
        <i-col span="8">
          <div>金额({{ detail.purchase_type === 0 ? '消费' : '退款' }})</div>
          <div>
            <Currency bold color="orange" type="£" :value="detail.amount" />
          </div>
        </i-col>
        <i-col v-if="!!detail.reject_reason" span="5">
          <div>驳回原因</div>
        </i-col>
        <i-col v-if="!!detail.reject_reason" span="11">
          <div />
          <div>{{ detail.reject_reason }}</div>
        </i-col>
        <i-col v-if="!!detail.notes" span="24">
          <div>备注</div>
          <p>{{ detail.notes }}</p>
        </i-col>
      </Row>

      <div class="bill">
        <FormItem>
          <div slot="label">
            <Tooltip trigger="hover" :max-width="350">
              <span>采购状态<Icon type="ios-help-circle-outline" />:</span>
              <template #content>
                <div class="text-left whitespace-normal">
                  <p>
                    若您的采购存在疑问，可以<span class="font-bold"
                      >仅填写商品明细，不上传票据图片</span
                    >，但<span class="font-bold">无法参与返佣</span>
                  </p>
                  <p class="mt-[10px]">
                    同时该笔采购记录将被标记为"异常采购"，请在<span
                      class="font-bold"
                      >合适的发货订单里一并带上核销</span
                    >
                  </p>
                </div>
              </template>
            </Tooltip>
          </div>

          <Row>
            <i-col span="8">
              <div class="mt-[5px]">
                <Checkbox v-model="isTrouble">异常采购</Checkbox>
              </div>
            </i-col>
            <i-col span="12">
              <Select
                v-if="isTrouble"
                v-model="model.trouble"
                style="width:182px"
              >
                <Option
                  v-for="item in troubleOptions"
                  :key="item.id"
                  :value="item.id"
                  >{{ item.name }}</Option
                >
              </Select>
            </i-col>
          </Row>
        </FormItem>
        <FormItem label="票据类型:">
          <Select v-model="model.invoice_type">
            <Option :value="1">实体发票</Option>
            <Option :value="2">电子发票</Option>
          </Select>
        </FormItem>
        <FormItem>
          <div slot="label">
            <Poptip trigger="hover">
              <span>票据编号<Icon type="ios-help-circle-outline" />:</span>
              <template #content>
                <img
                  width="350px"
                  src="~/assets/images/common/sample-2.png"
                  alt=""
                />
              </template>
            </Poptip>
          </div>
          <Input v-model="model.invoice_number" />
        </FormItem>
        <FormItem label="备注:">
          <Input v-model="model.user_remark" />
        </FormItem>
        <FormItem label="上传票据:">
          <UploadList
            ref="uploadListRef"
            field-name="invoice"
            :default-list="defaultList"
            :data="upload_invoice_data"
            :action-api="apiConfig.apiUrl"
            :on-success="handleSuccess"
            :on-upload-click="handleUploadClick"
            :on-remove="(item) => handleRemove(item)"
            multiple
          >
            <UploadByQrcode :update="handleUploadByQrcode" />
          </UploadList>
        </FormItem>
        <FormItem
          v-if="showAdminInput && !isTrouble"
          class="admin-input"
          label="附加服务:"
        >
          人工填写票据 <i-switch v-model="model.is_admin_input" />

          <Alert v-if="model.is_admin_input" type="warning">
            {{
              freeInputCount >= FREE_INPUT_MAX_COUNT
                ? `本次服务手续${adminInputFee}英镑`
                : `本次服务不收费（剩余体验${FREE_INPUT_MAX_COUNT -
                    freeInputCount}次）`
            }}
          </Alert>
        </FormItem>
        <FormItem
          v-if="detail.status == 13 && detail.reject_reason"
          label="驳回原因:"
        >
          <Input :value="detail.reject_reason || '无'" disabled />
        </FormItem>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import isEqual from 'lodash/isEqual'
import cloneDeep from 'lodash/cloneDeep'
import { debounce } from 'lodash'
import UploadList from '~/components/UploadList.vue'
import UploadByQrcode from '~/components/purchase/UploadByQrcode'

export default {
  components: {
    UploadList,
    UploadByQrcode
  },
  props: {
    detail: {
      type: Object,
      required: true
    },
    purchaseId: {
      type: [Number, String],
      required: true
    },
    value: {
      type: Object,
      required: true
    },
    apiType: {
      type: String,
      default: ''
    },
    showAdminInput: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      model: {},
      isTrouble: false,
      uploadListRef: null,
      upload_invoice_data: {
        action: `upload_invoice`,
        purchase_id: this.purchaseId
      },
      previewVisible: false,
      curInvoice: null,
      FREE_INPUT_MAX_COUNT: 10,
      troubleOptions: [
        { id: 1, name: '遗失票据' },
        { id: 2, name: '自用采购' },
        { id: 3, name: '过期票据' },
        { id: 4, name: '其他(备注内填写详情)' }
      ]
    }
  },
  computed: {
    defaultList() {
      return this.detail.invoices.map((item) => ({
        ...item,
        name: item.id,
        url: item.url
      }))
    },
    apiConfig() {
      if (this.apiType === 'refund') {
        return {
          apiUrl: this.$API_URL + '/api/purchase/refund_purchase/',
          delApi: this.$api.updateRefundPurchase
        }
      } else {
        return {
          apiUrl: this.$API_URL + '/api/purchase/purchase/',
          delApi: this.$api.delPuchaseInvoice
        }
      }
    },
    freeInputCount() {
      return this.$store.state.user?.free_input_count
    },
    adminInputFee() {
      return 1
    }
  },
  watch: {
    model: {
      deep: true,
      handler(value) {
        this.$emit('input', value)
      }
    },
    value: {
      deep: true,
      immediate: true,
      handler(value) {
        if (isEqual(value, this.model)) {
          return
        }

        this.model = cloneDeep(value)
        this.isTrouble = !!this.model.trouble
      }
    },
    isTrouble(value) {
      if (value) {
        this.model.trouble = 1
        this.model.invoice_number = `PUR-${dayjs().format('YYYYMMDDHHmm')}`
      } else {
        this.model.trouble = null
        this.model.invoice_number = null
      }
    }
  },
  mounted() {
    this.watchDragStatus()
  },
  methods: {
    debounceEventUploadInvocie: debounce(() => {
      window.dataLayer.push({
        event: 'upload_invoice',
        method: 'drag'
      })
    }, 1000),
    handleUploadClick() {
      window.dataLayer.push({
        event: 'upload_invoice',
        method: 'click'
      })
    },
    handleUploadByQrcode(data) {
      window.dataLayer.push({
        event: 'upload_invoice',
        method: 'qrcode'
      })
      this.$refs.uploadListRef.$refs.uploadRef.$refs.upload.upload(data.file)
    },
    watchDragStatus() {
      const onDragover = (e) => {
        e.preventDefault()
        document.body.classList.add('drag')
      }
      document.addEventListener('dragover', onDragover)

      const onDrop = (e) => {
        e.preventDefault()
        if (e.target.classList.contains('ivu-upload-drag')) {
          document.body.classList.remove('drag')
          this.debounceEventUploadInvocie()
        }
      }
      document.addEventListener('dragleave', onDrop)
      document.addEventListener('drop', onDrop)

      this.$once('hook:beforeDestroy', () => {
        document.removeEventListener('dragover', onDragover)
        document.removeEventListener('dragleave', onDrop)
        document.removeEventListener('drop', onDrop)
      })
    },
    handleSuccess(data, file) {
      file.name = file.id = data.id
      file.url = data.invoice_url
    },
    handleRemove(item) {
      return this.apiConfig.delApi({
        action: 'remove_invoice',
        invoice_id: item.id
      })
    }
  }
}
</script>

<style lang="less">
.upload-container {
  padding: 50px 80px 40px;

  .admin-input {
    align-items: baseline;
    justify-content: center;
  }

  .title-line + div {
    display: flex;
    .summary {
      padding: 30px 0 0 0;
      width: 500px;
      border-right: 1px solid #f4f4f4;
      margin-right: 80px;
      margin-left: 30px;

      .ivu-col {
        div:first-child {
          font-size: 16px;
          color: #a4a4a4;
          margin-bottom: 5px;

          & + div {
            font-size: 20px;
            color: #3b3b3b;
            margin-bottom: 50px;
          }
        }
      }
    }
    .bill {
      padding-top: 35px;
      .ivu-form-item {
        display: flex;

        .ivu-form-item-label {
          width: 120px;
        }

        .ivu-form-item-content {
          width: 280px;
        }
      }
    }
  }
}
</style>
