<template>
  <div class="new-index">
    <Banner :is-mobile="isMobile" />
    <Service :is-mobile="isMobile" />
    <Reimbursement :is-mobile="isMobile" />
    <Customer :is-mobile="isMobile" />
    <Advantage :is-mobile="isMobile" />
    <News :is-mobile="isMobile" />
    <Advert :is-mobile="isMobile" />
    <Partner :is-mobile="isMobile" />
  </div>
</template>

<script>
import Banner from '../components/index/Banner'
import Service from '../components/index/Service'
import Reimbursement from '../components/index/Reimbursement'
import Partner from '../components/index/Partner'
import Customer from '../components/index/Customer'
import Advantage from '../components/index/Advantage'
import News from '../components/index/News'
import Advert from '../components/index/Advert'

export default {
  components: {
    Banner,
    Partner,
    Reimbursement,
    Service,
    Customer,
    Advantage,
    News,
    Advert
  },
  head() {
    return {
      title: '首页'
    }
  },
  data() {
    return {
      isMobile: null,
      isShowTp: false
    }
  },
  mounted() {
    this.comMobile()
    if (!this.isMobile) {
      const scrollTop = document.documentElement.scrollTop
      this.isShowTp = scrollTop > 0
      window.addEventListener('scroll', this.scroll, false)
    }
  },
  destroyed() {
    window.removeEventListener('scroll', this.scroll, false)
  },
  methods: {
    toTop() {
      window.scrollTo(0, 0)
    },
    customerService() {
      const user = this.$store.state.user
      if (user) {
        window._MEIQIA('metadata', {
          id: user.id,
          email: user.email,
          username: user.username,
          phone: user.phone
        })
      }
      window._MEIQIA('showPanel')
    },
    scroll() {
      const scrollTop = document.documentElement.scrollTop
      this.isShowTp = scrollTop > 0
    },
    comMobile() {
      const width = document.body.clientWidth
      this.isMobile = width <= 640
    }
  },
  layout() {
    return 'new-default'
  }
}
</script>
