<template>
  <div>
    <currency type="£" :value="value" />
    <div v-if="creditBalance > 0" class="relative left-[-27px]">
      <Tooltip
        max-width="320px"
        transfer-class-name="balance-tooltip"
        :content="
          `您拥有VIP额度: £${creditBalance}，当前剩余可用: £${curCreditBalance}`
        "
        placement="top-start"
      >
        <div class="flex justify-center items-center ">
          <Tag color="error">V</Tag>
          <currency type="£" :value="curCreditBalance" />
        </div>
      </Tooltip>
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'

import { useStore } from '~/use'

export default {
  props: {
    value: {
      type: [Number, String],
      required: true
    }
  },
  setup() {
    const {
      state: { user }
    } = useStore()

    const curCreditBalance = computed(() => {
      if (!user?.current_credit_balance) {
        return 0
      }

      return Number(
        user.gbp_balance >= 0
          ? user.credit_balance
          : user.current_credit_balance
      ).toFixed(2)
    })

    return {
      creditBalance: user?.credit_balance,
      curCreditBalance
    }
  }
}
</script>

<style lang="less">
.balance-tooltip {
  width: 320px;
}
</style>
