<template>
  <div class="login-wrapper reversion flexRow">
    <div class="login-box-wrapper">
      <div class="logo new-mb-40">
        <a href="/"><img src="../assets/images/index/logo.svg"/></a>
      </div>
      <div class="login-box">
        <div class="login-title flexRow new-mb-40">
          <h3>重设密码</h3>
        </div>
        <div class="login-account-box">
          <Form
            ref="form"
            hide-required-mark
            :model="model"
            :rules="rules"
            label-position="top"
          >
            <FormItem label="手机号码" prop="phone">
              <NewPhoneSelect v-model="model.phone" />
            </FormItem>
            <FormItem label="手机验证码" prop="code" class="code">
              <Input v-model="model.code" /><a
                class="code-btn"
                @click="handleSendVerifyCode"
                >{{ time === 60 ? '获取验证码' : `${time}s` }}</a
              >
            </FormItem>
            <FormItem label="新密码" prop="new_pwd">
              <Input v-model="model.new_pwd" type="password" />
            </FormItem>
            <FormItem>
              <Rbutton
                size="large"
                class="login-btn"
                type="primary"
                @click="submit"
              >
                {{ fetching ? '重设中..' : '重设密码' }}
              </Rbutton>
            </FormItem>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Rbutton from '../components/revision/RButton'
import NewPhoneSelect from '../components/newPhoneSelect'
export default {
  components: { Rbutton, NewPhoneSelect },
  head() {
    return {
      title: '忘记密码'
    }
  },
  data() {
    return {
      fetching: false,
      inviteCode: this.$route.query.aff_code || this.$cookies.get('aff_code'),
      model: {
        code: '',
        new_pwd: '',
        phone: ''
      },
      time: 60,
      rules: {
        phone: [
          {
            required: true,
            message: '请输入手机号',
            trigger: 'blur'
          }
        ],
        code: [
          {
            required: true,
            message: '请输入验证码',
            trigger: 'blur'
          }
        ],
        new_pwd: [
          {
            required: true,
            trigger: 'blur，change',
            pattern: /^(?=.*?[A-Za-z#?!@$%^&*-])(?=.*?[0-9]).{6,16}$/g,
            message: '密码至少6-16位，包含数字和字母'
          }
        ]
      }
    }
  },
  mounted() {},
  methods: {
    timer() {
      const t = setInterval(() => {
        if (this.time > 1) {
          this.time--
        } else {
          clearInterval(t)
          this.time = 60
        }
      }, 1000)
    },
    async handleSendVerifyCode() {
      if (this.fetching || this.time !== 60) return
      try {
        this.fetching = true
        await this.$api.sendVerifyCode({ phone: this.model.phone })
        this.timer()
      } catch (error) {
        console.log(error)
      } finally {
        this.fetching = false
      }
    },
    submit() {
      if (this.fetching) return
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return
        }
        this.fetching = true
        try {
          const model = this.model
          await this.$api.forgetPwd(model)

          this.$Message.success('密码已重设，请重新登录')
          this.$router.replace('/login')

          this.visible = false
        } catch (err) {
          this.fetching = false
          console.error(err)
        }
      })
    }
  },
  layout() {
    return 'new-login'
  }
}
</script>

<style lang="less">
.login-wrapper {
  width: 100%;
  min-height: 100%;
  justify-content: center;
  align-items: center;
  .login-box-wrapper {
    width: 540px;
    .logo {
      text-align: center;
    }
    .login-box {
      padding: 60px 95px;
      border-radius: 6px;
      box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.08);
      border: solid 1px rgba(0, 0, 0, 0.1);
      .login-title {
        justify-content: space-between;
        align-items: center;
        color: #000;
        h3 {
          font-size: 28px;
          font-weight: 600;
        }
        span {
          font-size: 14px;
          a {
            color: #e56131;
            text-decoration: underline;
          }
        }
      }
      .login-tab {
        a {
          width: 50%;
          height: 30px;
          position: relative;
          text-align: center;
          color: #666;
          &::after {
            content: '';
            height: 2px;
            width: 100%;
            background: #222;
            opacity: 0.1;
            position: absolute;
            bottom: 0px;
            left: 0;
          }

          &.active {
            color: #e56131;
            &::after {
              background: #e56131;
              opacity: 1;
            }
          }
        }
      }
      .login-account-box {
        .code {
          position: relative;
          .code-btn {
            position: absolute;
            right: 10px;
            top: 10px;
            font-size: 14px;
            color: #e56131;
          }
        }
        .login-btn {
          margin-top: 10px;
          width: 100%;
        }
        .login-tips {
          text-align: center;
          a {
            color: #666;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .new-actions {
    min-width: 100%;
    .new-action__main {
      .login-wrapper {
        align-items: flex-start;
        .login-box-wrapper {
          width: 100%;
          .logo {
            margin-top: 32px;
            margin-bottom: 60px;
          }
          .login-box {
            border: none;
            box-shadow: none;
            padding: 0 15px 0;
          }
        }
      }
    }
  }
}
</style>
