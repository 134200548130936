<template>
  <div class="service-container">
    <div class="header banner-center" />

    <div class="flow-container container">
      <Services />
    </div>

    <div class="s2">
      <div class="container">
        <h1>一站式退税</h1>
        <ul>
          <li v-for="item of steps" :key="item.title">
            <h5>{{ item.title }}</h5>
            <p>{{ item.desc }}</p>
          </li>
        </ul>
      </div>
    </div>

    <div class="s1 container">
      <h1 class="title">购物折扣</h1>
      <nuxt-link class="register-btn" to="/register">
        注册iShopper账号，获得购物退税
      </nuxt-link>
      <div v-if="cooperationList.length" class="cooperation-list">
        <h2>合作商家</h2>
        <ul>
          <li v-for="item of cooperationList" :key="item">
            <img :src="item" />
          </li>
        </ul>
      </div>
      <ul class="qrcode-list">
        <li>
          <p>扫码进入购物商城</p>
        </li>
        <li>
          <p>
            关注iShopper 微信服务号 <br />
            获得更多购物资讯
          </p>
        </li>
      </ul>
    </div>

    <div class="s3">
      <div class="container">
        <h1 class="title">直达物流</h1>

        <div>
          <img src="~@/assets/images/service/p-4.jpg" />
        </div>
        <div>
          <img src="~@/assets/images/service/p-5.png" />
        </div>

        <div class="contents">
          <p>
            Send2China是全英领先的跨境物流高科技公司，公司于2013年由美国硅谷投资，成立于英国，专注为海内外客户提供安全，快捷，方便的物流解决方案。业务范围遍布欧洲，包括英国，德国，爱尔兰，意大利，法国和西班牙等，不仅有布局各大重点城市的线下体验店，还拥有自营取件车队，
            自动化运营中心，数千个合作方投递揽收网点。
          </p>
          <p>
            Send2China已服务英国及欧洲的数百万客户，每年承运包裹数百万件，总货物运输量超万吨，在
            欧洲出口运输企业中排名前列。与国际巨头英国皇家邮政ParcelForce, Royal
            Mail，比利时邮政Bpost，UPS，DHL合作多年。并在2017年，成为阿里巴巴旗下菜鸟物流的英国唯一官方指定合作
            直邮物流商，为各类淘宝天猫海外跨境商家提供助力！
          </p>
          <p>Send2China速递中国为您提供最稳定，最负责，最安心的服务。</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Services from '~/components/Services'

export default {
  components: {
    Services
  },
  head() {
    return {
      title: '我们的服务'
    }
  },
  data() {
    return {
      cooperationList: [],
      steps: [
        {
          title: '注册',
          desc: '个人信息实名注册。'
        },
        {
          title: '申请消费卡',
          desc: '申请消费卡并支付货款进行消费。'
        },
        {
          title: '购物',
          desc: '使用消费卡在英国购物网站或实体店进行购物。'
        },
        {
          title: '账户管理',
          desc: '登录iShopper账户查看并管理消费记录。'
        },
        {
          title: '上传凭证',
          desc: '上传购物凭证，等待后台审核。'
        },
        {
          title: '邮寄回国',
          desc: '自行创建物流订单，多种邮寄方案可选。'
        },
        {
          title: '选择退税方案',
          desc: '每笔邮寄物品均可选择不同退税周期，获得不同退税金额。'
        },
        {
          title: '退税成功',
          desc: '物流投递成功通过审核即可进入退税流程，获得退税。'
        }
      ]
    }
  }
}
</script>

<style lang="less">
.service-container {
  .header {
    width: 100%;
    height: 500px;
    background-image: url('~@/assets/images/banners/B1.jpg');
  }

  .title {
    text-align: center;
    font-size: 40px;
    font-weight: normal;
    color: #3b3b3b;
    padding: 50px 0;
  }

  .register-btn {
    width: 585px;
    height: 69px;
    background: url('~@/assets/images/service/btn.png') no-repeat 0 0;
    display: block;
    font-size: 24px;
    line-height: 69px;
    color: #ffffff;
    padding: 0 30px 0 55px;
  }

  .flow-container {
    margin-top: 30px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.07);
    padding: 150px 0 0 0;
    background: #fff;
    position: relative;
    z-index: 2;
    margin-bottom: -20px;

    .title {
      margin-top: 10px;
    }
  }

  .s1 {
    .title {
      margin: 50px 0 20px;
    }

    padding-bottom: 320px;
    position: relative;

    h2 {
      text-align: center;
      font-weight: normal;
      font-size: 20px;
      color: #3b3b3b;
      margin-bottom: 20px;
    }

    .register-btn {
      margin: auto;
      margin-bottom: 30px;
    }

    .cooperation-list {
      width: 850px;
      margin: 0 auto;

      ul {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;

        li {
          margin: 0 15px;
          img {
            height: 45px;
          }
        }
      }
    }

    .qrcode-list {
      position: absolute;
      bottom: -50px;
      left: 30px;
      display: flex;
      margin-left: 50%;
      transform: translate(-50%, 0);
      z-index: 3;

      li {
        width: 272px;
        height: 302px;
        margin-right: 50px;
        background: url('~@/assets/images/service/p-1.png') no-repeat 0 0;

        &:nth-child(2) {
          background-image: url('~@/assets/images/service/p-2.png');
        }

        &:nth-child(1) {
          p {
            padding-top: 25px;
          }
        }

        p {
          font-size: 18px;
          height: 72px;
          color: #ffffff;
          padding: 10px 25px 0 20px;
          text-align: center;
        }
      }
    }
  }

  .s2 {
    height: 937px;
    background: #7b8b8f url('~@/assets/images/service/p-3.jpg') no-repeat center
      0;
    position: relative;

    .register-btn {
      position: absolute;
      bottom: 0;
      left: 0;
      bottom: -30px;
      margin-left: 50%;
      transform: translate(-50%, 0);
    }

    h1 {
      font-size: 40px;
      font-weight: normal;
      text-align: center;
      color: #ffffff;
      padding: 120px 0 55px;
    }

    ul {
      width: 280px * 4;
      margin: 0 auto;
      display: flex;
      flex-flow: wrap;

      li {
        width: 280px;
        height: 320px;
        flex: none;
        color: #fff;
        font-size: 16px;
        padding: 20px 0 0 98px;

        h5 {
          font-size: 23px;
          margin-bottom: 25px;
        }

        p {
          width: 155px;
          padding: 0;
        }
      }
    }
  }

  .s3 {
    background: #f8f8f8;

    .title {
      margin-bottom: 15px;
    }

    .container {
      width: 720px;
      text-align: center;
      padding: 20px 0 80px;
    }

    h1 {
      padding: 120px 0 30px;
    }

    img {
      margin-bottom: 30px;
    }

    p {
      margin-bottom: 30px;
      line-height: 2.5;
      letter-spacing: 1px;
    }

    .contents {
      padding: 0 28px;
    }
  }
}
</style>
