<template>
  <ArticleLayout class="privacy-policy-container" title="PRICACY POLICY">
    <iframe src="/pdfjs/web/viewer.html?file=/privacy-policy.pdf" />
  </ArticleLayout>
</template>

<script>
import ArticleLayout from '~/components/ArticleLayout'
export default {
  layout: 'default',
  head() {
    return {
      title: 'privacy policy'
    }
  },
  components: {
    ArticleLayout
  },
  asyncData() {
    return {}
  }
}
</script>

<style lang="less">
.privacy-policy-container {
  .main-container {
    padding: 0;

    iframe {
      width: 100%;
      height: 1000px;
      border: none;
      position: relative;
      z-index: 2;
    }
  }
}
</style>
