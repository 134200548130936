<template>
  <Form class="account-purchase-detail-container">
    <div class="detail-container container">
      <h1 class="title-line"><span>采购明细</span></h1>
      <div>
        <Row class="summary">
          <i-col span="5">
            <div>ID</div>
            <div>{{ detail.id }}</div>
          </i-col>
          <i-col span="11">
            <div>采购时间</div>
            <div>
              {{ $dayjs(detail.purchase_time).format('YYYY/MM/DD HH:mm') }}
            </div>
          </i-col>
          <i-col span="8">
            <div>状态</div>
            <div>{{ $ENUM.PURCHASE_TYPE[detail.status] }}</div>
          </i-col>
          <i-col span="5">
            <div>持卡人</div>
            <div>{{ detail.card.name }}</div>
          </i-col>
          <i-col span="11">
            <div>消费卡</div>
            <div>{{ detail.card.number }}</div>
          </i-col>
          <i-col span="8">
            <div>金额</div>
            <div><Currency type="£" :value="detail.amount" /></div>
          </i-col>
        </Row>

        <div class="bill">
          <Row class="summary">
            <i-col span="12">
              <Row>
                <i-col span="24">
                  <div>票据类型</div>
                  <div>
                    {{ $ENUM.INVOICE_TYPE[detail.invoice_type] }}
                    <div v-if="!!detail.trouble" class="inline-block">
                      (异常采购)
                    </div>
                  </div>
                </i-col>
                <i-col span="24">
                  <div>运费及其他(不记税)</div>
                  <div>
                    <Currency type="£" :value="detail.exclude_amount" />
                  </div>
                </i-col>
              </Row>
            </i-col>
            <i-col span="12">
              <Row>
                <i-col span="24">
                  <div>票据编号</div>
                  <div>{{ detail.invoice_number }}</div>
                </i-col>
                <i-col span="24">
                  <div>备注</div>
                  <div>{{ detail.user_remark }}</div>
                </i-col>
                <i-col span="24">
                  <div>发票信息</div>
                  <div class="invoices-list">
                    <a
                      v-for="item of detail.invoices"
                      :key="item.url"
                      :href="item.url"
                      target="_blank"
                      ><img :src="item.url" />
                    </a>
                  </div>
                </i-col>
              </Row>
            </i-col>
          </Row>
        </div>
      </div>
    </div>

    <div class="goods-info bg-gray">
      <div class="container">
        <h1>物品明细</h1>
        <Table
          border
          stripe
          class="table-blue"
          :columns="columns1"
          :data="detail.items"
        />
      </div>
    </div>

    <div class="actions">
      <Button type="primary" @click="$router.go(-1)">返 回</Button>
    </div>
  </Form>
</template>

<script>
export default {
  data() {
    return {
      columns1: [
        {
          title: '编号',
          key: 'item_id'
        },
        {
          title: '商品货号',
          key: 'barcode'
        },
        {
          title: '描述',
          key: 'desc'
        },
        {
          title: '价格',
          key: 'price',
          render: (_, { row }) => (
            <Currency color="normal" value={row.price} type="£" />
          )
        },
        {
          title: '数量',
          key: 'quantity'
        },
        {
          title: '含税总价',
          key: 'total',
          render: (_, { row }) => (
            <Currency color="normal" value={row.total} type="£" />
          )
        }
      ],
      detail: {}
    }
  },
  async asyncData({ $api, route }) {
    const detail = await $api.fetchPuchaseDetail({ id: route.query.id })
    return {
      detail
    }
  }
}
</script>

<style lang="less">
.account-purchase-detail-container {
  .currency-container {
    .currency-type {
      font-size: 20px;
    }
    .currency-int {
      font-size: 22px;
    }
    .currency-decimal {
      font-size: 16px;
    }
  }

  .detail-container {
    padding: 50px 80px 40px;

    .title-line + div {
      display: flex;
      .summary {
        padding: 30px 0 0 0;
        width: 500px;
        border-right: 1px solid #f4f4f4;
        margin-right: 80px;
        margin-left: 30px;

        .ivu-col {
          div:first-child {
            font-size: 16px;
            color: #a4a4a4;
            margin-bottom: 5px;

            & + div {
              font-size: 20px;
              color: #3b3b3b;
              margin-bottom: 50px;
            }
          }
        }
      }
      .bill {
        padding: 25px 0 0 0;
        .summary {
          width: auto;
          margin: 0;
          padding: 0;
          border: none;
        }

        .invoices-list {
          a {
            &:hover {
              img {
                border-color: #999;
              }
            }
            img {
              width: 50px;
              height: 50px;
              object-fit: contain;
              border: 1px solid #ddd;
              margin-right: 5px;
              transition: 0.3s;
            }
          }
        }
      }
    }
  }

  .goods-info {
    padding: 50px 0;
    h1 {
      font-size: 24px;
      text-align: center;
      font-weight: normal;
      color: #3b3b3b;
      border-bottom: 1px solid #e7e7e7;
      padding-bottom: 20px;
      margin-bottom: 40px;
    }
  }

  .actions {
    text-align: center;
    padding: 60px 0 100px;
  }
}
</style>
