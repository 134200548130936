<template>
  <div class="new-aff-kefu new-center-container">
    <div class="aff-kefu-content flexRow">
      <div class="aff-kefu-round" />
      <div class="aff-kefu-left flexColumn">
        <h5>新客户推荐语</h5>
        <p>扫码注册消费 · 获得£50现金奖励</p>
        <h6>“ 购物还能退税 ”</h6>
      </div>
      <div class="aff-kefu-right">
        <p>
          说明：
        </p>
        <p>
          有效推荐：新用户注册开卡起，12个月内需持卡累计完成
          £500消费，即为有效新用户。
        </p>
        <p>
          新用户需扫描推荐人发送的二维码，或点击链接进入注册页面，或在注册页面手动输入邀请码，注册成功即产生推荐记录。
        </p>
        <RButton class="aff-kefu-btn" type="white" size="middle"
          >联系客服立即加入 <IconFont reverse="y" size="30" name="arrow-right"
        /></RButton>
      </div>
    </div>
  </div>
</template>

<script>
import RButton from '../../components/revision/RButton'
import IconFont from '../../iconfont'
export default {
  components: {
    RButton,
    IconFont
  }
}
</script>

<style lang="less">
.new-aff-kefu {
  height: 380px;
  .aff-kefu-content {
    padding: 88px 95px;
    justify-content: space-between;
    border-radius: 6px;
    box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.1);
    background: #e56131;
    position: relative;
    &::after {
      content: '';
      width: 250px;
      height: 250px;
      opacity: 0.1;
      border: solid 10px #fff;
      border-radius: 125px;
      position: absolute;
      bottom: -67px;
      left: -32px;
    }
    &::before {
      content: '';
      width: 338px;
      height: 338px;
      opacity: 0.1;
      border: solid 10px #fff;
      border-radius: 169px;
      position: absolute;
      top: -76px;
      right: -80px;
    }
    .aff-kefu-round {
      position: absolute;
      width: 248px;
      height: 248px;
      opacity: 0.05;
      background-color: #fff;
      border-radius: 124px;
      right: -35px;
      top: -31px;
    }
    .aff-kefu-left {
      // box-sizing: border-box;
      width: 310px;
      height: 205px;
      flex-shrink: 0;
      padding: 0 20px;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      backdrop-filter: blur(4px);
      border: solid 1px rgba(255, 255, 255, 0.2);
      background-color: rgba(255, 255, 255, 0.1);
      position: relative;
      z-index: 2;
      color: #fff;
      overflow: hidden;
      &::before {
        content: '';
        width: 59%;
        height: 1px;
        background: rgba(255, 255, 255, 0.2);
        position: absolute;
        top: 0;
        left: 0;
        transform: rotate(32deg);
        transform-origin: left top;
      }
      &::after {
        content: '';
        width: 59%;
        height: 1px;
        background: rgba(255, 255, 255, 0.2);
        position: absolute;
        top: 0;
        right: 0;
        transform: rotate(-32deg);
        transform-origin: right top;
      }
      h5 {
        font-size: 18px;
        position: relative;
        margin-bottom: 30px;
        &::after {
          content: '';
          width: 100%;
          height: 1px;
          background: #fff;
          position: absolute;
          left: 0;
          bottom: -5px;
        }
      }
      p {
        width: 250px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        border-radius: 6px;
        background-color: rgba(2, 2, 2, 0.15);
        font-size: 14px;
        font-weight: 600;
      }
      h6 {
        font-size: 18px;
        font-weight: 500;
        width: 100%;
        margin-top: 15px;
        padding-top: 20px;
        border-top: 1px dashed rgba(255, 255, 255, 0.2);
        text-align: center;
      }
    }
    .aff-kefu-right {
      position: relative;
      z-index: 2;
      width: 445px;
      flex-shrink: 0;
      color: #fff;
      p {
        line-height: 20px;
        padding-bottom: 10px;

        &:not(:nth-child(1)) {
          padding-bottom: 20px;
        }
      }
      .aff-kefu-btn {
        margin-top: 10px;
        &:hover {
          color: #000;
        }
        .iconfont {
          font-weight: normal;
        }
      }
    }
  }
}
@media screen and (max-width: 640px) {
  .new-aff-kefu {
    height: auto;
    .aff-kefu-content {
      flex-direction: column;
      padding: 40px 20px;
      .aff-kefu-left {
        width: 100%;
      }
      .aff-kefu-right {
        margin-top: 20px;
        width: 100%;
        .aff-kefu-btn {
          position: relative;
          left: 50%;
          margin-left: -97px;
        }
      }
    }
  }
}
</style>
