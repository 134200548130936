<template>
  <div class="en-container">
    <div id="home" class="header banner-center">
      <img src="~assets/images/home/p-1.jpg" />
    </div>

    <!-- <div class=""> -->
    <div id="about-us">
      <h1 class="title">About iShopper</h1>
      <div class="subtitle">
        iShopper is a shopping service company based in UK,<br />
        offers cash back products and also operates in payment technology,
        dynamic currency conversion and logistical services.
        <div class="br" />
        Oriental Bu Trading Ltd was found in 2007, <br />The new sub-brand -
        iShopper launched in 2020.
      </div>
      <div class="hr" />

      <h3>Company Development from 2007</h3>

      <img class="center" src="~/assets/images/en/1.png" alt="" />

      <div class="hr" />
      <h3>Turn over from 2016 to 2019</h3>

      <img class="center" src="~/assets/images/en/2.png" alt="" />
    </div>
    <!-- </div> -->

    <div id="customer-review">
      <div class="container">
        <h1 class="title">Customer Review</h1>
        <div class="subtitle">
          Our thousands of clients include professional buyers,<br />
          international e-commerce companies, online distributers.
          <div class="br" />
          They are active all over the world with <br />insight of global
          consumption trending.
        </div>

        <ul class="detail-list">
          <li>
            <div>
              Oriental Bu’s all in one service is high recommended. They are
              very professional from photos taking to delivery service. Team is
              super helpful.
            </div>
            <div class="author">Helen Wu</div>
          </li>
          <li>
            <div>
              It feels really good to be able to purchase things from the UK
              designer outlet without travelling to the UK. Thanks to the
              Oriental Bu fashion buyer for helping me look for my dream bag.
            </div>
            <div class="author">Vanessa Song</div>
          </li>
          <li>
            <div>
              Have scored many nice pieces from Oriental Bu’s stock list. Very
              quick delivery and excellent customer service.
            </div>
            <div class="author">Vivian Qu</div>
          </li>
        </ul>
      </div>
    </div>

    <div id="our-service">
      <h1 class="title">Our Service</h1>
      <div class="subtitle">
        iShopper provides comprehensive solutions including
        <br />
        payment, purchase, logistics, cash back and related services.
      </div>

      <ul class="service-list">
        <li v-for="(item, index) of serviceList" :key="index">
          <div class="icon">
            <img :src="item.icon" alt="" />
          </div>
          <div>
            <div :style="{ color: item.color }" class="service-title">
              {{ item.title }}
            </div>
            <div>{{ item.detail }}</div>
          </div>
        </li>
      </ul>
    </div>

    <div id="cash-back">
      <h1 class="title">
        iShopper Cash Back
      </h1>

      <div class="steps">
        <div v-for="(item, index) of steps" :key="item">
          <div class="step-id">0{{ index + 1 }}</div>
          <div class="step-text">{{ item }}</div>
        </div>
      </div>
    </div>

    <div id="partners">
      <h1 class="title">Our Partners</h1>

      <div class="type-list">
        <li>Legal Affairs</li>
        <li>Taxation</li>
        <li>Logistics</li>
      </div>
      <img src="~/assets/images/en/11.jpg" alt="" />

      <div class="partners-line">
        <span>Finance</span>
      </div>
      <img src="~/assets/images/en/12.jpg" alt="" />

      <div class="partners-line"><span>Tourism</span></div>
      <img src="~/assets/images/en/13.jpg" alt="" />
    </div>

    <div id="contact-us">
      <div class="header">
        <img src="~@/assets/images/en/14.jpg" />
      </div>
      <div class="s1">
        <h1 class="title">
          Contact us
        </h1>

        <ul>
          <li v-for="item of contactList" :key="item.name">
            <div class="icon">
              <img :src="item.icon" />
            </div>
            <div>
              <div>{{ item.name }}:</div>
              <div v-if="item.name === '邮件'">
                <a :href="`mailto:${item.desc}`">{{ item.desc }}</a>
              </div>
              <div v-else>{{ item.desc }}</div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  layout: 'en',
  head() {
    return {
      titleTemplate: '',
      title: 'iShopper'
    }
  },
  components: {},
  data() {
    return {
      serviceList: [
        {
          icon: require('~/assets/images/en/9.png'),
          title: 'Payment',
          detail: `iShopper provides prepaid card, real-time rate currencies 
exchange, multiple payment options, flexible cash back and etc.`,
          color: '#e48267'
        },
        {
          icon: require('~/assets/images/en/8.png'),
          title: 'Purchase',
          detail: `Professional buyers based in London and Bicester purchase &
share hundreds of products on our mobile shopping store.`,
          color: '#efba4b'
        },
        {
          icon: require('~/assets/images/en/7.png'),
          title: 'Logistics',
          detail: `A variety of logistic options are available with professional 
packaging, warehousing and logistics teams.`,
          color: '#a8d072'
        },
        {
          icon: require('~/assets/images/en/6.png'),
          title: 'Cash Back',
          detail: `Users could choose flexible cash back rate according to periods. 
The maximum could up to 20% of purchase amount.`,
          color: '#96c0df'
        }
      ],
      steps: [
        `Sign up for an iShopper 
card. (prepaid MasterCard publish by Equals)`,
        `Purchase with merchant by iShopper card`,
        `View statement and manage multi-cards`,
        `Upload purchase evidences
for checking`,
        `Send goods to iShopper warehouse in London & Bicester and ship to the address out of EEA`,
        `Choose cash back option with different rate and periods`,
        `More solutions for the encashment of iShopper account`,
        `Get cash back once the good is successfully shipping out of EEA`
      ],
      contactList: [
        {
          name: 'Tel',
          desc: '＋44 01869 320216',
          icon: require('~/assets/images/contact/p-1.png')
        },
        {
          name: 'Email',
          desc: 'info@ishopper.com',
          icon: require('~/assets/images/contact/p-2.png')
        },
        {
          name: 'Address',
          desc: 'Unit 6 Minton Place, Victoria Road, Bicester  OX26 6QB',
          icon: require('~/assets/images/contact/p-3.png')
        }
      ]
    }
  }
}
</script>

<style lang="less">
.en-container {
  zoom: 0.8;
  .center {
    display: block;
    margin: 0 auto;
  }

  .hr {
    width: 470px;
    height: 1px;
    background: #ccc;
    margin: 74px auto;
  }

  .title {
    font-size: 40px;
    text-align: center;
    color: #3b3b3b;
    font-weight: 500;
    margin-bottom: 30px;
    & + .subtitle {
      text-align: center;
      max-width: 920px;
      margin: 0 auto;
      font-size: 30px;
      color: #9fa0a0;

      .br {
        margin-bottom: 20px;
      }
    }
  }

  .header {
    width: 100%;
    // height: 761px;
    // background: url(../../assets/images/home/p-1.jpg) no-repeat 0 0;
    background-size: cover;
  }

  #about-us {
    padding: 84px 0 120px;
    position: relative;
    background: #fff;
    margin-bottom: -20px;
    z-index: 2;

    // &::before {
    //   content: '';
    //   width: 100%;
    //   height: 2000px;
    //   position: absolute;
    //   bottom: 0;
    //   left: 0;
    //   box-shadow: 0 20px 10px 1px rgba(#000, 0.1);
    // }
    h3 {
      width: 488px;
      height: 28px;
      font-size: 30px;
      text-align: center;
      margin: 0 auto 80px;
      font-weight: 500;
    }
  }

  #customer-review {
    height: 1111px;
    background: #757575 url('~@/assets/images/en/15.jpg') no-repeat center;
    background-size: cover;
    color: #fff;
    padding: 140px 0 0;

    .title {
      color: #fff;
    }
    .subtitle {
      font-size: 30px;
      font-weight: 200;
      font-stretch: normal;
      line-height: 1;
      letter-spacing: 0px;
      color: #c9caca;
      margin-bottom: 80px;
    }

    .detail-list {
      display: flex;
      justify-content: center;
      li {
        width: 274px;
        height: 447px;
        background-image: url('~@/assets/images/en/3.png');
        text-align: center;
        padding: 27px;
        font-size: 20px;
        position: relative;

        & + li {
          margin-left: 30px;
        }

        &::before {
          content: '';
          width: 55px;
          height: 47px;
          display: block;
          margin: 0 auto 20px;
          background-image: url('~@/assets/images/en/4.png');
        }

        .author {
          width: 100%;
          position: absolute;
          left: 0;
          bottom: -100px;
          text-align: center;

          &::before {
            content: '';
            width: 222px;
            height: 48px;
            display: block;
            margin: 0 auto 5px;
            background-image: url('~@/assets/images/en/5.png');
          }
        }
      }
    }
  }

  #our-service {
    max-width: 1059px;
    margin: 0 auto 0;
    padding: 70px 0 70px;

    .service-list {
      margin-top: 80px;
      li {
        display: flex;

        & + li {
          margin-top: 50px;
        }

        .icon {
          margin-right: 40px;
          flex: none;
        }

        .service-title {
          font-size: 40px;
          margin-bottom: 5px;

          & + div {
            font-size: 30px;
            color: #221815;
            line-height: 1.2;
          }
        }
      }
    }
  }

  #cash-back {
    padding: 120px 0 220px;
    background: #efefef;

    .title {
      margin-bottom: 50px;
    }

    .steps {
      max-width: 1055px;
      height: 1120px;
      margin: 0 auto 0;
      position: relative;
      background-image: url('~@/assets/images/en/10.png');

      > div {
        width: 250px;
        text-align: center;
        position: absolute;

        &:nth-child(1) {
          left: 18px;
          top: 285px;
        }
        &:nth-child(2) {
          left: 350px;
          top: 285px;
        }
        &:nth-child(3) {
          left: 678px;
          top: 285px;
        }
        &:nth-child(4) {
          left: 595px;
          top: 678px;
        }
        &:nth-child(5) {
          left: 225px;
          top: 678px;
        }
        &:nth-child(6) {
          left: 122px;
          top: 1110px;
        }
        &:nth-child(7) {
          left: 460px;
          top: 1110px;
        }
        &:nth-child(8) {
          left: 780px;
          top: 1110px;
        }
      }

      .step-id {
        font-size: 30px;
        font-weight: 500;
        color: #727171;
      }

      .step-text {
        font-size: 18px;
        color: #726e67;
        line-height: 1.5;
      }
    }
  }

  #partners {
    max-width: 876px;
    margin: 0 auto;
    padding: 110px 0 130px;

    .type-list {
      display: flex;

      li {
        width: 250px;
        text-align: center;
        font-size: 24px;
        color: #585b55;
        margin-bottom: 30px;

        &:nth-child(2) {
          margin-left: 60px;
        }
        &:nth-child(3) {
          margin-left: 50px;
        }
      }
    }

    .partners-line {
      color: #585757;
      font-size: 24px;
      text-align: center;
      position: relative;
      padding: 40px 0;

      span {
        display: inline-block;
        padding: 0 30px;
        background: #fff;
        z-index: 2;
        position: relative;
      }
      &::before {
        content: '';
        width: 100%;
        height: 1px;
        display: block;
        position: absolute;
        top: 50%;
        background: #ccc;
      }
    }
  }

  #contact-us {
    max-width: 1200px;
    margin: 0 auto;
    padding-bottom: 50px;
    // border-bottom: 30px solid #fb5020;

    .header {
      width: 100%;
      // height: 500px;
      // background-image: url('~@/assets/images/en/14.jpg');
      // background-size: cover;
    }

    .title {
      margin-top: 90px;
      margin-bottom: 40px;
    }

    .s1 {
      width: 480px;
      margin: 0 auto;
      padding-bottom: 45px;

      a {
        text-decoration: underline;
      }

      ul {
        display: flex;
        flex-flow: row wrap;

        li {
          display: flex;
          align-items: center;
          margin-bottom: 50px;
          font-size: 16px;

          &:first-child {
            margin-right: 50px;
          }

          .icon {
            width: 48px;
            margin-right: 20px;
            display: flex;
            justify-content: center;
          }
        }
      }
    }
  }
}
</style>
